import React from "react";
import { overviewProps } from "../../../../../../Types";
import moment from "moment";

const FinalQuotation = ({ clientsData, getUserDetails }: overviewProps) => {
  return (
    <div className="mt-5">
      <div className="bg-[#EEEEEE] p-4 flex items-center">
        <div className="w-1/5">
          <span className="font-[Gm] font-medium text-[16px] text-black">
            Quote ID
          </span>
        </div>
        <div className="w-1/5">
          <span className="font-[Gm] font-medium text-[16px] text-black">
            Package Name
          </span>
        </div>
        <div className="w-1/5">
          <span className="font-[Gm] font-medium text-[16px] text-black">
            Amount
          </span>
        </div>
        <div className="w-1/5 ">
          <span className="font-[Gm] font-medium text-[16px] text-black">
            Date Of Creation
          </span>
        </div>
        <div className="w-1/5">
          <div className="font-[Gm] font-medium text-[16px] text-black">
            Action
          </div>
        </div>
      </div>
      <div>
        {/* {finalQuote?.map((item)=> (
            
        ))} */}
        <div className="">
          {clientsData?.quotation
            ?.filter((item) => item?.isFinalQuote)
            ?.map((item, i) => (
              <div key={i} className="flex items-center bg-white p-4">
                <div className="font-[Gm] w-1/5 font-medium text-[16px] text-black">
                  <span>{item?.quote_ID}</span>
                </div>
                <div className="font-[Gm] w-1/5 font-medium text-[16px] text-black">
                  <span>{item?.package_name}</span>
                </div>
                <div className="font-[Gm] w-1/5 font-medium text-[16px] text-black">
                  <span> {item?.amount}</span>
                </div>
                <div className="font-[Gm] w-1/5 font-medium text-[16px] text-black">
                  <span> {moment(item?.createdAt).format("DD/MM/YYYY")}</span>
                </div>
                <div className="font-[Gm] w-1/5 font-medium text-[16px] text-black">
                  <a
                    className="text-[#7BE257] underline"
                    target="_black"
                    href={`${process.env.REACT_APP_IMAGE_URL}/${item?.file}`}
                  >
                    View Doc
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FinalQuotation;
