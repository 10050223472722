import React, { useState } from "react";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import AllDocumentList from "./AllDocumentList";
import DesignFileList from "./DesignFileList";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const styleObject: any = {
  background: "transparent",
  border: "none",
  textTransform: "none",
  color: "#1D2939",
  fonFamily: "gsb",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "normal",
};

const Documents = () => {
  const [value, setValue] = useState<string>("1");
  const [route, setroute] = useState<any>(window.location.pathname);
  const navigate = useNavigate();

  const handleChangeONE = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="my-[30px] mr-[20px] bg-[#F5F8FA] border-[0.5px] border-[#DEDEDE] rounded-[2px]">
      {route === "/Home/ActionDOC" && (
        <div
          onClick={() => navigate(-1)}
          className="backbutton flex  items-center px-3 py-2 cursor-pointer"
        >
          <KeyboardBackspaceIcon />
          Back
        </div>
      )}
      <div className="p-6">
        {route === "/Home/ActionDOC" ? (
          <span className="font-[Gm] font-semibold text-[20px] text-black">
            Documents {`(${sessionStorage.getItem("clientName")})`}
          </span>
        ) : (
          <span className="font-[Gm] font-semibold text-[20px] text-black">
            Documents
          </span>
        )}
        <div className="mt-3">
          <TabContext value={value}>
            <Box sx={{ borderBottom: "1.5px solid #D8D8D8" }}>
              <TabList
                onChange={handleChangeONE}
                aria-label="lab API tabs example"
                style={{ textTransform: "none" }}
                TabIndicatorProps={{
                  style: { background: "#F6BA00", width: "156px" },
                }}
              >
                <Tab
                  className="con_tab"
                  label="Documents"
                  value="1"
                  style={{
                    padding: "0px",
                    background: "transparent",
                    border: "none",
                    textTransform: "none",
                    color: "#1D2939",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                />
                <Tab
                  className="con_tab"
                  label="Design Files"
                  value="2"
                  style={{
                    padding: "0px",
                    background: "transparent",
                    border: "none",
                    textTransform: "none",
                    color: "#1D2939",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel style={{ padding: "0px" }} className="mt-6" value="1">
              <AllDocumentList />
            </TabPanel>
            <TabPanel style={{ padding: "0px" }} className="mt-6" value="2">
              <DesignFileList />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
};

export default Documents;
