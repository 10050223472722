import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

type loaderProps = {
  customHeight: string;
};

const Loader = ({ customHeight }: loaderProps) => {
  return (
    <div>
      <div
        style={{ height: customHeight ? customHeight : "70vh" }}
        className={`flex justify-center items-center`}
      >
        <div>
          <Box sx={{ display: "flex" }}>
            <CircularProgress sx={{ color: "black" }} />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Loader;
