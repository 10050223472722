import { CustomOptionProps, multiSelect } from "../.././Types";
import React from "react";
import Select, { components, OptionProps } from "react-select";

interface MoreSelectedBadgeProps {
  items: string[];
}

const MoreSelectedBadge: React.FC<MoreSelectedBadgeProps> = ({ items }) => {
  const style = {
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Gm",
    fontSize: "11px",
    padding: "3px",
    width: "27px",
    zIndex: 10,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }: any) => {
  const maxToShow = 1;
  const overflow = getValue()
    .slice(maxToShow)
    ?.map((x: any) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const CustomOption: React.FC<OptionProps<CustomOptionProps, boolean, any>> = (
  props
) => {
  const { innerRef, innerProps, isSelected, label } = props;

  return (
    <components.Option {...props}>
      <div ref={innerRef} {...innerProps}>
        <input
          style={{ background: "white", marginRight: "10px" }}
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
        />
        <label style={{ color: "black" }}>{label}</label>
      </div>
    </components.Option>
  );
};

const MultiSelect = ({
  placeholder,
  options,
  handleChange,
  value,
  defaultValue,
}: multiSelect) => {
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: "#FFFFF",
      borderRadius: "6px",
      border: "0.5px solid #CFCFCF",
      minHeight: "44px",
      boxShadow: "none",
      cursor: "pointer",
      borderColor: state.isFocused ? "#CFCFCF" : "#CFCFCF",
      "&:hover": { borderColor: state.isFocused ? "#CFCFCF" : "#CFCFCF" },
    }),
    option: (base: any, state: any) => ({
      ...base,
      background: state.isSelected ? "#f2f2f2" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": { background: "#f2f2f2", color: "white" },
    }),
    multiValue: (base: any, state: any) => ({
      ...base,
      maxWidth: "84px",
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <div>
      <Select
        menuPlacement={"auto"}
        styles={customStyles}
        defaultValue={defaultValue}
        onChange={handleChange}
        options={options}
        value={value}
        placeholder={placeholder}
        isMulti
        // menuIsOpen={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option: CustomOption, MultiValue } as any}
      />
    </div>
  );
};

export default MultiSelect;
