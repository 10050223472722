import { allDocumentFilters } from "../../../Types";
import { RootState } from "./../../AllComponents/App/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: allDocumentFilters = {
  docPage: 1,
  docPerPage: 10,
  docTotalCount: 0,
  docSearch: "",
  docDateRange: [],
  docStartDate: "",
  docEndDate: "",
};

export const documentSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocPage: (state, action: PayloadAction<number>) => {
      state.docPage = action.payload;
    },
    setDocPerPage: (state, action: PayloadAction<number>) => {
      state.docPerPage = action.payload;
    },
    setDocTotalCount: (state, action: PayloadAction<number>) => {
      state.docTotalCount = action.payload;
    },
    setDocSearch: (state, action: PayloadAction<string>) => {
      state.docSearch = action.payload;
    },
    clearClientStateDoc: (state) => {
      return initialState; // Reset state to initial state
    },
  },
});

export const {
  setDocPage,
  setDocPerPage,
  setDocTotalCount,
  setDocSearch,
  clearClientStateDoc,
} = documentSlice.actions;

export const selectDocPage = (state: RootState) => state.documents.docPage;
export const selectDocPerPage = (state: RootState) =>
  state.documents.docPerPage;
export const selectDocTotalCount = (state: RootState) =>
  state.documents.docPerPage;
export const selectDocSearch = (state: RootState) => state.documents.docPerPage;

export default documentSlice.reducer;
