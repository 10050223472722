import moment from "moment";
import { overviewProps } from "../../../../../../Types";
import React from "react";

const SalesInfo = ({ clientsData, getUserDetails }: overviewProps) => {
  return (
    <div className="mt-5">
      <div className="bg-white border-solid border-[1px]">
        <div className="border-b-[1px] p-4">
          <span className="font-[Gm] font-medium text-[20px]">Sales Info</span>
        </div>
        <div className="py-6 px-4 salesInfo_grid">
          <div>
            <p className="allo_heading">Creation Date</p>
            <p className="allo_content">
              {moment(clientsData?.crm_sales_info?.creation_date).format(
                "DD/MM/YYYY"
              )}
            </p>
          </div>
          <div>
            <p className="allo_heading">Construction Start Date</p>
            <p className="allo_content">
              {clientsData?.crm_sales_info?.construction_start_date
                ? moment(
                    clientsData?.crm_sales_info?.construction_start_date
                  ).format("DD/MM/YYYY")
                : "-"}
            </p>
          </div>
          <div>
            <p className="allo_heading">Plot Dimension</p>
            <p className="allo_content">
              {clientsData?.crm_sales_info?.plot_length} ft X{" "}
              {clientsData?.crm_sales_info?.plot_width} ft
            </p>
          </div>
          <div>
            <p className="allo_heading">Plot Size</p>
            <p className="allo_content">
              {clientsData?.crm_sales_info?.plot_size || "-"}
            </p>
          </div>
          <div>
            <p className="allo_heading">SUBA</p>
            <p className="allo_content">
              {clientsData?.crm_sales_info?.suba || "-"}
            </p>
          </div>
          <div>
            <p className="allo_heading">No. of Floors</p>
            <p className="allo_content">
              G + {clientsData?.crm_sales_info?.no_of_floors || "-"}
            </p>
          </div>
          <div>
            <p className="allo_heading">Budget</p>
            <p className="allo_content">
              {clientsData?.crm_sales_info?.lead_budget || "-"}
            </p>
          </div>
          <div>
            <p className="allo_heading">Registration Date</p>
            <p className="allo_content">
              {clientsData?.crm_sales_info?.registration_date
                ? moment(clientsData?.crm_sales_info?.registration_date).format(
                    "DD/MM/YYYY"
                  )
                : "-"}
            </p>
          </div>
          <div>
            <p className="allo_heading">Khata Done</p>
            <p className="allo_content">
              {clientsData?.crm_sales_info?.khata_done || "-"}
            </p>
          </div>
          <div>
            <p className="allo_heading">Gated Community</p>
            <p className="allo_content">
              {clientsData?.crm_sales_info?.gated_community || "-"}
            </p>
          </div>
        </div>
        <div className="m-3">
          <p className="allo_heading">Deal Won Description</p>
          <p className="allo_content">
            The Partner app ecosystem helped me be more efficient at what I do
            when I shifted to the efficient online platform. The Partner app
            ecosystem helped me be more efficient at what I do when I shifted to
            the efficient online platform.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SalesInfo;
