import { RootState } from "./../../AllComponents/App/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { option, userFilters } from "../../../Types";

const initialState: userFilters = {
  userPage: 1,
  userPerPage: 10,
  userTotalCount: 0,
  usersearch: "",
  userStatus: "",
  role: [],
  joiningDateRange: [],
  joiningStartDate: "",
  joiningEndDate: "",
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserPage: (state, action: PayloadAction<number>) => {
      state.userPage = action.payload;
    },
    setUserPerPage: (state, action: PayloadAction<number>) => {
      state.userPerPage = action.payload;
    },
    setUserTotalCount: (state, action: PayloadAction<number>) => {
      state.userTotalCount = action.payload;
    },
    setUserSearch: (state, action: PayloadAction<string>) => {
      state.usersearch = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<string>) => {
      state.userStatus = action.payload;
    },
    setUsersRole: (state, action: PayloadAction<option[]>) => {
      state.role = action.payload;
    },
    setJoiningDateRange: (
      state,
      action: PayloadAction<
        [(Date | null | undefined)?, (Date | null | undefined)?]
      >
    ) => {
      state.joiningDateRange = action.payload;
    },
    setJoiningStartDate: (state, action: PayloadAction<string>) => {
      state.joiningStartDate = action.payload;
    },
    setJoiningEndDate: (state, action: PayloadAction<string>) => {
      state.joiningEndDate = action.payload;
    },
    clearClientStateuser: (state) => {
      return initialState; // Reset state to initial state
    },
  },
});

export const {
  setUserPage,
  setUserPerPage,
  setUserTotalCount,
  setUserSearch,
  setUserStatus,
  setUsersRole,
  setJoiningDateRange,
  setJoiningStartDate,
  setJoiningEndDate,
  clearClientStateuser,
} = userSlice.actions;

export const selectuserPage = (state: RootState) => state.users.userPage;
export const selectuserPerPage = (state: RootState) => state.users.userPerPage;
export const selectuserTotalCount = (state: RootState) =>
  state.users.userTotalCount;
export const selectusersearch = (state: RootState) => state.users.usersearch;
export const selectUserStatus = (state: RootState) => state.users.userStatus;
export const selectRole = (state: RootState) => state.users.role;
export const selectJoiningDateRange = (state: RootState) =>
  state.users.joiningDateRange;
export const selectJoiningStartDate = (state: RootState) =>
  state.users.joiningStartDate;
export const selectJoiningEndDate = (state: RootState) =>
  state.users.joiningEndDate;

export default userSlice.reducer;
