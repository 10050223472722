import React from "react";

const PersonalDetails = () => {
  return (
    <div>
      <div>Personal Details</div>
    </div>
  );
};

export default PersonalDetails;
