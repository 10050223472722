import axiosClientInterceptors from "../../../../../ApiConfig/axiosClientInterceptor";
import {
  allocationDataType,
  allocationFormik,
  allocationUsersType,
  overviewProps,
  resType,
} from "../../../../../../Types";
import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { useFormik } from "formik";
import SmallLoader from "../../../../../Tools/SmallLoader";
import { Toaster } from "../../../../../Tools/Toaster";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MultiSelect from "../../../../../Tools/MultiSelect";
import CircularProgress from "@mui/material/CircularProgress";

interface DataObject {
  fullname: string;
  role: string;
  id: string;
}

interface NestedData {
  data: DataObject[];
}

const Allocation = ({ clientsData, getUserDetails }: overviewProps) => {
  const [Allocationopen, setAllocationopen] = useState(false);
  const [sLoader, setSLoader] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [CO, setCO] = useState<string>("");
  const [architech, setArchitech] = useState<string>("");
  const [SE, setSE] = useState<string>("");
  const [PM, setPM] = useState<string>("");
  const [PC, setPC] = useState<string>("");
  const [TL, setTL] = useState<string>("");
  const [loader, setloder] = useState<boolean>(false);
  const [cusCoordinator, setCusCoordinator] = useState<any>([]);
  const [Architect, setArchitect] = useState<any>([]);
  const [SiteEngineer, setSiteEngineer] = useState<any>([]);
  const [ProjectManager, setProjectManager] = useState<any>([]);
  const [TeamLead, setTeamLead] = useState<any>([]);
  const [ProjectCoordinator, setProjectCoordinator] = useState<any>([]);
  const [allocationData, setAllocationData] = useState<allocationDataType[]>(
    []
  );
  const [cusCoordinatorValue, setcusCoordinatorValue] = useState<any>(
    clientsData?.customer_coordinator?.map((val: any) => {
      return { label: val.fullname, value: val._id };
    })
  );
  const [ArchitectValue, setArchitectValue] = useState<any>(
    clientsData?.architect?.map((val: any) => {
      return { label: val.fullname, value: val._id };
    })
  );
  const [SiteEngineerValue, setSiteEngineerValue] = useState<any>(
    clientsData?.site_engineer?.map((val: any) => {
      return { label: val, value: val };
    })
  );
  const [ProjectManagerValue, setProjectManagerValue] = useState<any>(
    clientsData?.project_manager?.map((val: any) => {
      return { label: val, value: val };
    })
  );
  const [ProjectCoordinatorValue, setProjectCoordinatorValue] = useState<any>(
    clientsData?.project_coordinator?.map((val: any) => {
      return { label: val, value: val };
    })
  );
  const [TeamLeadValue, setTeamLeadValue] = useState<any>(
    clientsData?.team_lead?.map((val: any) => {
      return { label: val, value: val };
    })
  );

  const handleChangeUser = () => {};

  const getAllocationData = () => {
    axiosClientInterceptors
      .get(`/general/getprocurement-users`)
      .then((res: AxiosResponse) => {
        const checTheresult = res.data?.result.result.map((value: any) => {
          console.log(value);
          if (value._id === "Site Engineer") {
            const siteeEngineer = value.data.map((val: any) => {
              return { label: val, value: val };
            });
            setSiteEngineer(siteeEngineer);
          }
          if (value._id === "Project Manager") {
            const ProjectsManager = value.data.map((val: any) => {
              return { label: val, value: val };
            });
            console.log("value", value);
            setProjectManager(ProjectsManager);
          }
          if (value._id === "Team Lead") {
            const TeamLeads = value.data.map((val: any) => {
              return { label: val, value: val };
            });
            setTeamLead(TeamLeads);
          }
          if (value._id === "Project Coordinator") {
            const ProjectCoordinators = value.data.map((val: any) => {
              return { label: val, value: val };
            });
            setProjectCoordinator(ProjectCoordinators);
          }
        });
        setAllocationData(res.data?.result?.result);
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
      });
  };

  const updateAllocationData = () => {
    setloder(true);
    setSLoader(true);
    axiosClientInterceptors
      .put(`/clients/${clientsData?._id}`, {
        customer_coordinator:
          cusCoordinatorValue?.map((val: any) => val.value) ||
          clientsData?.customer_coordinator?.map((val: any) => {
            return val.id;
          }),
        architect:
          ArchitectValue?.map((val: any) => val.value) ||
          clientsData?.architect,
        site_engineer:
          SiteEngineerValue?.map((val: any) => val.value) ||
          clientsData?.site_engineer,
        project_coordinator:
          ProjectCoordinatorValue.map((val: any) => val.value) ||
          clientsData?.project_coordinator,
        project_manager:
          ProjectManagerValue.map((val: any) => val.value) ||
          clientsData?.project_manager,
        team_lead:
          TeamLeadValue.map((val: any) => val.value) || clientsData?.team_lead,
      })
      .then((res: AxiosResponse) => {
        const data: resType = res.data;
        if (data?.success) {
          setloder(false);
          getUserDetails();
          setToggle(false);
          setSLoader(false);
          Toaster("success", data?.message);
        }
        // Toaster("error", "internal Error");
      })
      .catch((err) => {
        setloder(false);
        Toaster("error", err?.response?.data?.message);
        console.log(err);
        setSLoader(false);
      });
  };

  const getUsers = () => {
    axiosClientInterceptors
      .get(`/general/users`)
      .then((res: AxiosResponse) => {
        let flatArray: DataObject[] = res.data?.result.reduce(
          (
            acc: DataObject[],
            current: NestedData | { _id: string; data: DataObject[] }
          ) => {
            // If the current object has a "data" property, concatenate it to the accumulator
            if ("data" in current) {
              acc = acc.concat(current.data);
            }
            return acc;
          },
          []
        );

        const coFilteredData = flatArray.filter((value) => value.role === "CO");
        const arFilteredData = flatArray.filter((value) => value.role === "AR");

        console.log("coFilteredData", coFilteredData);
        setCusCoordinator(
          coFilteredData.map((val) => {
            return { label: val.fullname, value: val.id };
          })
        );
        setArchitect(
          arFilteredData.map((val) => {
            return { label: val.fullname, value: val.id };
          })
        );

        // if (res.data?.result[0]._id === "CO") {
        //   const CustomerCO = res.data?.result[0].data.map((val: any) => {
        //     return { label: val.fullname, value: val.id };
        //   });
        //   setCusCoordinator(CustomerCO);
        // } else {
        //   const Architect = res.data?.result[1].data.map((val: any) => {
        //     return { label: val.fullname, value: val.id };
        //   });
        //   setArchitect(Architect);
        // }

        // if (res.data?.result[1]._id === "AR") {
        //   const Architect = res.data?.result[1].data.map((val: any) => {
        //     return { label: val.fullname, value: val.id };
        //   });
        //   setArchitect(Architect);
        // } else {
        //   const CustomerCO = res.data?.result[0].data.map((val: any) => {
        //     return { label: val.fullname, value: val.id };
        //   });
        //   setCusCoordinator(CustomerCO);
        // }
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllocationData();
    getUsers();
  }, []);

  return (
    <div>
      <Dialog
        open={Allocationopen}
        onClose={() => {
          setAllocationopen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "600px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">Create Team</p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAllocationopen(false);
              }}
            />
          </div>
          <div className="mt-3 grid grid-cols-2 gap-4">
            <div>
              <p
                className="Stage__FormTittle mb-3"
                onClick={() => {
                  console.log(cusCoordinatorValue);
                }}
              >
                Customer Coordinator
              </p>

              <MultiSelect
                placeholder={"Choose Customer Coordinator"}
                options={cusCoordinator}
                value={cusCoordinatorValue}
                defaultValue={cusCoordinatorValue}
                handleChange={(data: any) => {
                  setcusCoordinatorValue(data);
                }}
              />
            </div>
            <div>
              <p
                className="Stage__FormTittle mb-3"
                onClick={() => {
                  console.log(ArchitectValue);
                }}
              >
                Architect
              </p>
              <MultiSelect
                placeholder={"Choose Architect"}
                options={Architect}
                value={ArchitectValue}
                defaultValue={ArchitectValue}
                handleChange={(data: any) => {
                  setArchitectValue(data);
                }}
              />
            </div>
            <div>
              <p
                className="Stage__FormTittle mb-3"
                onClick={() => {
                  console.log(SiteEngineerValue);
                }}
              >
                Site Engineer
              </p>
              <MultiSelect
                placeholder={"Choose  Site Engineer"}
                options={SiteEngineer}
                value={SiteEngineerValue}
                defaultValue={SiteEngineerValue}
                handleChange={(data: any) => {
                  setSiteEngineerValue(data);
                }}
              />
            </div>
            <div>
              <p
                className="Stage__FormTittle mb-3"
                onClick={() => {
                  console.log(ProjectManagerValue);
                }}
              >
                Project Manager
              </p>
              <MultiSelect
                placeholder={"Choose Project Manager"}
                options={ProjectManager}
                value={ProjectManagerValue}
                defaultValue={ProjectManagerValue}
                handleChange={(data: any) => {
                  setProjectManagerValue(data);
                }}
              />
            </div>
            <div>
              <p
                className="Stage__FormTittle mb-3"
                onClick={() => {
                  console.log(ProjectCoordinatorValue);
                }}
              >
                Project Coordinator
              </p>
              <MultiSelect
                placeholder={"Choose Project Coordinator"}
                options={ProjectCoordinator}
                value={ProjectCoordinatorValue}
                defaultValue={ProjectCoordinatorValue}
                handleChange={(data: any) => {
                  setProjectCoordinatorValue(data);
                }}
              />
            </div>
            <div>
              <p
                className="Stage__FormTittle mb-3"
                onClick={() => {
                  console.log(TeamLeadValue);
                }}
              >
                Team Lead
              </p>
              <MultiSelect
                placeholder={"Choose Team Lead"}
                options={TeamLead}
                value={TeamLeadValue}
                defaultValue={TeamLeadValue}
                handleChange={(data: any) => {
                  setTeamLeadValue(data);
                }}
              />
            </div>
          </div>

          {loader ? (
            <div className="flex justify-center items-center w-full mt-4">
              <CircularProgress color="inherit" size={20} />
            </div>
          ) : (
            <button
              onClick={() => {
                updateAllocationData();
              }}
              className="ProjectStages__activateButton mt-4"
            >
              Create Team
            </button>
          )}
        </div>
      </Dialog>
      <div className="bg-white border-solid border-[1px]">
        <>
          <div className="flex justify-between items-center p-4 border-b-[1px]">
            <p className="font-[Gm] font-medium text-[20px] ">
              Allocation of Team
            </p>
            <button
              className="ProjectStages__activateButton  "
              style={{ width: "10%" }}
              onClick={() => {
                setAllocationopen(true);
                console.log(clientsData);
              }}
            >
              Allocate Team
            </button>
          </div>
          <div className="p-4 flex items-start justify-between">
            <div>
              <p className="allo_heading">Customer Coordinator</p>
              <p className="allo_content">
                {clientsData?.customer_coordinator
                  ? clientsData?.customer_coordinator?.map((val: any) => (
                      <p>{val.fullname}</p>
                    ))
                  : "-"}
              </p>
            </div>
            <div>
              <p className="allo_heading">Architect</p>
              <p className="allo_content">
                {clientsData?.architect
                  ? clientsData!.architect.map((val: any) => (
                      <p>{val.fullname}</p>
                    ))
                  : "-"}
              </p>
            </div>
            <div>
              <p className="allo_heading">Site Engineer</p>
              <p className="allo_content">
                {clientsData?.site_engineer
                  ? clientsData?.site_engineer?.map((val) => <p>{val}</p>)
                  : "-"}
              </p>
            </div>
            <div>
              <p className="allo_heading">Project Manager</p>
              <p className="allo_content">
                {clientsData?.project_manager
                  ? clientsData!.project_manager!.map((val) => <p>{val}</p>)
                  : "-"}
              </p>
            </div>
            <div>
              <p
                className="allo_heading"
                onClick={() => {
                  console.log(clientsData?.project_coordinator);
                }}
              >
                Project Coordinator
              </p>
              <p className="allo_content">
                {clientsData?.project_coordinator?.length > 0
                  ? clientsData?.project_coordinator.map((val: any) => (
                      <p>{val}</p>
                    ))
                  : "-"}
              </p>
            </div>
            <div>
              <p className="allo_heading">Team Lead</p>
              <p className="allo_content">
                {clientsData?.team_lead
                  ? clientsData!.team_lead!.map((val) => val)
                  : "-"}
              </p>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Allocation;
