import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import axiosClientInterceptors from "../../../../../../ApiConfig/axiosClientInterceptor";
import { selectClientID } from "../../../../../../Features/AllClients/AllClients";
import { useSelector } from "react-redux";
import { paymentDataobj, paymentobj } from "../../../../../../../Types";
import { useFormik } from "formik";
import * as yup from "yup";
import LensIcon from "@mui/icons-material/Lens";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Dialog, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import { Toaster } from "../../../../../../Tools/Toaster";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import nodata from "../../../../../Assets/nodata.jpg";
import CircularProgress from "@mui/material/CircularProgress";

interface FormValues {
  InvoiceDate: string;
  InvoiceNumber: string;
  InvoiceAmount: string;
}
interface FormValuespayment {
  PaymentType: string;
  PaymentDate: string;
  TotalAmount: string;
}

const validationSchema = yup.object({
  InvoiceDate: yup.string().required("InvoiceDate is required"),
  InvoiceNumber: yup.string().required("InvoiceNumber is required"),
  InvoiceAmount: yup.string().required("InvoiceAmount is required"),
});
const validationSchemapay = yup.object({
  PaymentType: yup.string().required("PaymentType is required"),
  PaymentDate: yup.string().required("PaymentType is required"),
  TotalAmount: yup
    .string()
    .test("PaymentType", "TotalAmount is required ", function (value) {
      const { PaymentType } = this.parent;
      if (PaymentType && PaymentType !== "full") {
        return yup.string().required("required").isValidSync(value);
      }
      return true;
    }),
});

const Payments = ({ siglestageData }: any) => {
  const clientID = useSelector(selectClientID);
  const [addinvoiceLoader, setaddinvoiceLoader] = useState(false);
  const [loader, setloader] = useState<boolean>(false);
  const [uploadFileOpen, setuploadFileOpen] = useState<boolean>(false);
  const [AddStagePaymentOpen, setAddStagePaymentOpen] =
    useState<boolean>(false);

  const [paymentData, setpaymentData] = useState<paymentDataobj[]>([]);
  const [ToggleEditAdd, setToggleEditAdd] = useState<any>("");
  const [Bluckfile, setBluckfile] = useState<File[]>([]);
  const [EditData, setEditData] = useState<any>(null);
  const [expanded, setExpanded] = useState(false);

  const [PaymentReciept, setPaymentReciept] = useState<any>([]);
  const [PaymentProof, setPaymentProof] = useState<any>([]);

  // const [paymentProof, setpaymentProof] = useState("");
  // accepting file from dropzone and storing it to state
  const handleDrop = (acceptedFiles: any) => {
    setBluckfile((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };
  const deletFile = (i: any) => {
    let filesone = [...Bluckfile];
    filesone.splice(i, 1);
    setBluckfile(filesone);
  };

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const getlistingPayment = () => {
    axiosClientInterceptors
      .get(`/clients/stages/payments/${clientID}/${siglestageData._id}`)
      .then((response) => {
        const result = response.data.result;
        setpaymentData(result);
        console.log("resultPay======>", result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      InvoiceDate:
        ToggleEditAdd === "Add"
          ? ""
          : moment(EditData?.invoice_date).format("YYYY-MM-DD"),
      InvoiceNumber: ToggleEditAdd === "Add" ? "" : EditData?.invoice_number,
      InvoiceAmount: ToggleEditAdd === "Add" ? "" : EditData?.invoice_amount,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      if (ToggleEditAdd === "Add") {
        if (Bluckfile?.length > 0) {
          setaddinvoiceLoader(true);
          const formdata = new FormData();

          formdata.append("invoice", Bluckfile[0]);

          formdata.append("invoice_date", values.InvoiceDate);
          formdata.append("invoice_amount", values.InvoiceAmount);
          formdata.append("invoice_number", values.InvoiceNumber);

          const headers = {
            "content-type": "multipart/form-data",
          };
          axiosClientInterceptors
            .post(
              `/clients/stages/payments/${clientID}/${siglestageData._id}`,
              formdata,
              {
                headers,
              }
            )

            .then((res) => {
              console.log(res);

              setuploadFileOpen(false);
              setaddinvoiceLoader(false);

              getlistingPayment();
              formik.resetForm({});
              setBluckfile([]);
            })
            .catch((err) => {
              setaddinvoiceLoader(false);

              console.log(err);
            });
        } else {
          // alert("please select file");
          Toaster("info", "please select file");
        }
      } else {
        if (Bluckfile?.length > 0) {
          setaddinvoiceLoader(true);

          const formdata = new FormData();

          formdata.append("invoice", Bluckfile[0]);

          formdata.append("invoice_date", values.InvoiceDate);
          formdata.append("invoice_amount", values.InvoiceAmount);
          formdata.append("invoice_number", values.InvoiceNumber);

          const headers = {
            "content-type": "multipart/form-data",
          };
          axiosClientInterceptors
            .patch(`/clients/stages/payments/${EditData._id}`, formdata, {
              headers,
            })

            .then((res) => {
              console.log(res);
              setuploadFileOpen(false);
              setaddinvoiceLoader(false);
              Toaster("success", res.data.message);
              getlistingPayment();
              formik.resetForm({});
              setBluckfile([]);
            })
            .catch((err) => {
              setaddinvoiceLoader(false);
              Toaster("success", err.response.data.message);
              console.log(err);
            });
        } else {
          Toaster("info", "please select file");
        }
      }
    },
  });
  // payment
  const formikpayment = useFormik<FormValuespayment>({
    initialValues: {
      PaymentType: "",
      PaymentDate: "",
      TotalAmount: "",
    },
    validationSchema: validationSchemapay,
    onSubmit: (values) => {
      if (PaymentReciept?.length > 0 && PaymentProof?.length > 0) {
        // alert(JSON.stringify(values, null, 2));
        setloader(true);
        const formdata = new FormData();

        formdata.append("payment_receipt", PaymentReciept[0]);
        formdata.append("payment_proof", PaymentProof[0]);

        formdata.append("payment_type", values.PaymentType);
        formdata.append("payment_date", values.PaymentDate);
        if (formikpayment.values.PaymentType === "full") {
          formdata.append("payment_amount", EditData.outstanding_amt);
        } else {
          formdata.append("payment_amount", values.TotalAmount);
        }

        const headers = {
          "content-type": "multipart/form-data",
        };
        axiosClientInterceptors
          .put(`/clients/stages/payments/${EditData._id}`, formdata, {
            headers,
          })

          .then((res) => {
            console.log(res);
            setAddStagePaymentOpen(false);
            getlistingPayment();
            formikpayment.resetForm({});
            setPaymentProof([]);
            setPaymentReciept([]);
            Toaster("success", res.data.message);
            setloader(false);
          })
          .catch((err) => {
            Toaster("success", err.response.data.message);
            setloader(false);
            console.log(err);
          });
      } else {
        alert("please Select the file");
      }
    },
  });

  useEffect(() => {
    getlistingPayment();
  }, []);
  return (
    <div className="mt-4">
      {/* dropzone */}
      <Dialog
        open={uploadFileOpen}
        onClose={() => {
          setuploadFileOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <form
          style={{ borderTop: "7px solid  #FCC200" }}
          className="p-4"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex justify-between items-center">
            <p
              className="ProjectStagesPopFill__Stage__Tittle"
              onClick={() => {
                console.log("editdata ", EditData);
              }}
            >
              {ToggleEditAdd} Invoice
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setuploadFileOpen(false);
              }}
            />
          </div>
          <div className=" grid grid-cols-2  gap-2 mt-3">
            <div>
              <p className="Stage__FormTittle">Invoice Date*</p>
              <input
                type="date"
                name="InvoiceDate"
                id=""
                max={moment().format("YYYY-MM-DD")}
                value={formik.values.InvoiceDate}
                onChange={formik.handleChange}
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formik.touched.InvoiceDate && formik.errors.InvoiceDate && (
                <p className="required_message">{formik.errors.InvoiceDate}</p>
              )}
            </div>
            <div>
              <p className="Stage__FormTittle">Invoice Number*</p>
              <input
                type="text"
                name="InvoiceNumber"
                id=""
                value={formik.values.InvoiceNumber}
                onChange={formik.handleChange}
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formik.touched.InvoiceNumber && formik.errors.InvoiceNumber && (
                <p className="required_message">
                  {formik.errors.InvoiceNumber}
                </p>
              )}
            </div>
          </div>
          <div className="mt-3">
            <p className="Stage__FormTittle">Invoice Amount*</p>
            <input
              type="number"
              name="InvoiceAmount"
              id=""
              value={formik.values.InvoiceAmount}
              onChange={formik.handleChange}
              placeholder="eg. 300000"
              className="ProjectStages___Stage__input w-full mt-2"
            />
            {formik.touched.InvoiceAmount && formik.errors.InvoiceAmount && (
              <p className="required_message">{formik.errors.InvoiceAmount}</p>
            )}
          </div>
          <p className="Stage__FormTittle mt-3">Payment Proof*</p>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <>
                {Bluckfile?.length > 0 ? (
                  <div className="listingfile" style={{ overflowX: "scroll" }}>
                    <ul style={{ padding: "10px" }}>
                      {/* {Bluckfile?.map((value, i: any) => ( */}
                      <div className="flex items-center">
                        <LensIcon
                          className="me-1"
                          style={{
                            color: " #000000",
                            width: "10px",
                          }}
                        />
                        <li className="fileName">
                          {Bluckfile[0]?.name
                            ? Bluckfile[0]?.name
                            : Bluckfile[0]?.name}
                        </li>
                        <CloseIcon
                          fontSize="small"
                          className="ms-1"
                          onClick={() => {
                            // e.stopPropagation();
                            // console.log(value);

                            setBluckfile([]);
                          }}
                          style={{ color: " #000000" }}
                        />
                      </div>
                      {/* ))} */}
                    </ul>
                  </div>
                ) : (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <div>
                      <div className="mb-3 flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="71"
                          height="51"
                          viewBox="0 0 71 51"
                          fill="none"
                        >
                          <path
                            d="M4.49219 5.3924C4.49219 2.41426 6.90645 0 9.88459 0H20.4238C21.1783 0 21.9293 0.158376 22.6154 0.472263C29.6513 3.69111 31.3036 5.38607 38.6491 6.66482C38.9417 6.71576 39.2427 6.74051 39.5397 6.74051H60.2137C63.1918 6.74051 65.6061 9.15477 65.6061 12.1329V27.8608C65.6061 30.8389 63.1919 33.2532 60.2137 33.2532H9.8846C6.90646 33.2532 4.49219 30.8389 4.49219 27.8608V5.3924Z"
                            fill="#FCC200"
                          />
                          <path
                            d="M0.732739 24.0042C0.346006 21.297 2.44663 18.875 5.18125 18.875H65.9635C68.6474 18.875 70.7329 21.2124 70.4281 23.8789L67.8603 46.3473C67.6009 48.6169 65.6801 50.3307 63.3957 50.3307H8.39101C6.15473 50.3307 4.25876 48.6863 3.9425 46.4725L0.732739 24.0042Z"
                            fill="url(#paint0_linear_1010_81020)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_1010_81020"
                              x1="35.5"
                              y1="11.0111"
                              x2="41.6543"
                              y2="54.5155"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#FFE185" />
                              <stop offset="1" stop-color="#F7BA00" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <input name="fileNames" {...getInputProps()} />
                      <p className="vendor_bulk_upload_text">
                        Drag & Drop Your File here <br /> or
                      </p>
                    </div>
                    <div>
                      <div className="browse_bulk_vendor">Browse Files</div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Dropzone>
          {/* <div className="mt-3">
            <p className="Stage__FormTittle">Payment Proof*</p>

            <div
              className="flex items-center  p-2 mt-2"
              style={{ border: "1px solid #CFCFCF" }}
            >
              <button className="flex-1 text-left font-semibold">
                {"Choose Document"}
              </button>
              <label htmlFor="file-upload" className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 16V7.85L8.4 10.45L7 9L12 4L17 9L15.6 10.45L13 7.85V16H11ZM4 20V15H6V18H18V15H20V20H4Z"
                    fill="black"
                  />
                </svg>
              </label>
              <input
                id="file-upload"
                type="file"
                className="hidden"
                name="ChooseDocumentInvoice"
                value={AddInvoicefile}
                onChange={(e) => {
                  setAddInvoicefile(e.target.value);
                  console.log("e.target", e.target);
                }}
              />
            </div>
          </div> */}

          {addinvoiceLoader ? (
            <div className="flex justify-center items-center w-full">
              {" "}
              <CircularProgress color="inherit" size={20} />
            </div>
          ) : (
            <button
              className="ProjectStages__activateButton mt-1"
              type="submit"
            >
              {ToggleEditAdd} Invoice
            </button>
          )}
        </form>
      </Dialog>

      {/* Add Stage Payment */}
      <Dialog
        open={AddStagePaymentOpen}
        onClose={() => {
          setAddStagePaymentOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <form
          onSubmit={formikpayment.handleSubmit}
          style={{ borderTop: "7px solid  #FCC200" }}
          className="p-4"
        >
          <div className="flex justify-between items-center">
            <p
              className="ProjectStagesPopFill__Stage__Tittle"
              onClick={() => {
                console.log(PaymentReciept?.length);
              }}
            >
              Add Stage Payment
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAddStagePaymentOpen(false);
              }}
            />
          </div>
          <div className=" grid grid-cols-2  gap-2 mt-3">
            <div>
              <p className="Stage__FormTittle">Choose Payment Type*</p>
              <select
                name="PaymentType"
                id="cars"
                value={formikpayment.values.PaymentType}
                onChange={formikpayment.handleChange}
                className="ProjectStages___Stage__input w-full mt-2"
              >
                <option value="" disabled selected>
                  Select Payment Type
                </option>
                <option value="partial">partial</option>
                <option value="full">Full</option>
              </select>
              {formikpayment.touched.PaymentType &&
                formikpayment.errors.PaymentType && (
                  <p className="required_message">
                    {formikpayment.errors.PaymentType}
                  </p>
                )}
            </div>
            <div>
              <p className="Stage__FormTittle">Payment Date*</p>
              <input
                type="date"
                name="PaymentDate"
                value={formikpayment.values.PaymentDate}
                onChange={formikpayment.handleChange}
                max={moment().format("YYYY-MM-DD")}
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formikpayment.touched.PaymentDate &&
                formikpayment.errors.PaymentDate && (
                  <p className="required_message">
                    {formikpayment.errors.PaymentDate}
                  </p>
                )}
            </div>
          </div>
          {formikpayment.values.PaymentType === "full" ? (
            <div className="mt-3">
              <p className="Stage__FormTittle">Total Amount</p>
              <div className=" border p-3 mt-1 ">
                ₹{EditData.outstanding_amt}/-
              </div>
            </div>
          ) : (
            <div className="mt-3">
              <p className="Stage__FormTittle">Total Amount*</p>
              <input
                type="text"
                name="TotalAmount"
                value={formikpayment.values.TotalAmount}
                onChange={formikpayment.handleChange}
                id=""
                placeholder="eg. 300000"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formikpayment.touched.TotalAmount &&
                formikpayment.errors.TotalAmount && (
                  <p className="required_message">
                    {formikpayment.errors.TotalAmount}
                  </p>
                )}
            </div>
          )}
          <div className="mt-3">
            <p className="Stage__FormTittle">Payment Proof*</p>

            <div
              className="flex items-center  p-2 mt-2"
              style={{ border: "1px solid #CFCFCF" }}
            >
              <p className="flex-1 text-left font-semibold">
                {" "}
                {PaymentProof.length > 0
                  ? PaymentProof[0]?.name
                  : "Choose Document"}{" "}
              </p>
              <label htmlFor="file-upload" className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 16V7.85L8.4 10.45L7 9L12 4L17 9L15.6 10.45L13 7.85V16H11ZM4 20V15H6V18H18V15H20V20H4Z"
                    fill="black"
                  />
                </svg>
              </label>
              <input
                id="file-upload"
                type="file"
                className="hidden"
                name="PaymentProof"
                onChange={(e) => setPaymentProof(e.target.files)}
              />
            </div>
          </div>
          <div className="mt-3">
            <p
              className="Stage__FormTittle"
              onClick={() => {
                console.log(PaymentReciept[0].name);
              }}
            >
              Payment Reciept*
            </p>

            <div
              className="flex items-center  p-2 mt-2"
              style={{ border: "1px solid #CFCFCF" }}
            >
              <p className="flex-1 text-left font-semibold">
                {PaymentReciept.length > 0
                  ? PaymentReciept[0]?.name
                  : "Choose Document"}{" "}
              </p>
              <label htmlFor="file-upload1" className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 16V7.85L8.4 10.45L7 9L12 4L17 9L15.6 10.45L13 7.85V16H11ZM4 20V15H6V18H18V15H20V20H4Z"
                    fill="black"
                  />
                </svg>
              </label>
              <input
                id="file-upload1"
                type="file"
                className="hidden"
                onChange={(e) => setPaymentReciept(e.target.files)}
                name="PaymentReciept"
              />
            </div>
          </div>
          {loader ? (
            <div className=" flex justify-center items-center w-full">
              <CircularProgress color="inherit" size={20} />
            </div>
          ) : (
            <button
              className="ProjectStages__activateButton mt-1"
              type="submit"
            >
              Add Payment
            </button>
          )}
        </form>
      </Dialog>
      <div className="flex justify-between items-center">
        <p
          className="stage__status"
          onClick={() => {
            console.log(siglestageData);
          }}
        >
          Payments for {siglestageData?.stage_name || "-"}
        </p>
        <button
          className="MUIstayle__button"
          onClick={() => {
            setuploadFileOpen(true);
            setToggleEditAdd("Add");
            setEditData(null);
          }}
        >
          Add Invoice
        </button>
      </div>
      {paymentData.length > 0 ? (
        <>
          {paymentData?.map((value) => (
            <AccordionTransition
              value={value}
              setAddStagePaymentOpen={setAddStagePaymentOpen}
              setuploadFileOpen={setuploadFileOpen}
              setToggleEditAdd={setToggleEditAdd}
              setEditData={setEditData}
            />
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center mt-28">
          {/* <h3>No Data Found</h3> */}
          <img className="h-[250px] w-[220px]" src={nodata} alt="no-data" />
        </div>
      )}
    </div>
  );
};

export default Payments;

function AccordionTransition({
  value,
  setAddStagePaymentOpen,
  setuploadFileOpen,
  setToggleEditAdd,
  setEditData,
}: any) {
  const [expanded, setExpanded] = useState(false);
  const [EditView, setEditView] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const fileNameSplit = (str: any) => {
    if (str) {
      return str.split("/").at(-1).split("-").slice(1).join("-");
    }
  };

  return (
    <div className="mt-5" key={value?._id}>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          style={{ width: "100%" }}
          expandIcon={
            value?.payment_details?.length !== 0 && <ExpandMoreIcon />
          }
          aria-controls={`panel1-content${value?._id}`}
          id={`panel1-content${value?._id}`}
        >
          <div className="bg-[#FFF]  flex justify-between items-center p-5 w-full">
            <div>
              <p
                className="StageAccordionDetails__title"
                onClick={() => {
                  console.log(value);
                }}
              >
                Invoice Date
              </p>
              <p className="StageAccordionDetails__subtitle">
                {value?.invoice_date
                  ? moment(value?.invoice_date).format("DD/MM/YYYY")
                  : "-"}
              </p>
            </div>
            <div>
              <p className="StageAccordionDetails__title">Invoice Amount</p>
              <p className="StageAccordionDetails__subtitle">
                ₹{value?.invoice_amount}/-
              </p>
            </div>
            <div>
              <p className="StageAccordionDetails__title">Invoice Number</p>
              <p className="StageAccordionDetails__subtitle">
                {value?.invoice_number}
              </p>
            </div>
            <div>
              <p className="StageAccordionDetails__title">Payment Due Days</p>
              <p className="StageAccordionDetails__subtitle">
                {value?.days_due}
              </p>
            </div>
            <div>
              <p className="StageAccordionDetails__title">Invoice Proof</p>
              <p
                className="StageAccordionDetails__subtitle underline"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_IMAGE_URL}/${value?.invoice_file}`
                  );
                }}
              >
                View Proof
              </p>
            </div>
            <div className="flex justify-start items-center gap-4">
              {value.payment_details.length === 0 && (
                <button
                  onClick={(e) => {
                    setuploadFileOpen(true);
                    setToggleEditAdd("Edit");
                    setEditData(value);

                    e.stopPropagation();
                  }}
                  style={{
                    border: "1.333px solid var(--Gray-300, #D0D5DD)",
                    color: "#1364C2",
                    fontFamily: "gsb",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "26px",
                    padding: "6px",
                  }}
                >
                  Edit Details{" "}
                </button>
              )}
              {value?.outstanding_amt !== 0 && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddStagePaymentOpen(true);
                    setEditData(value);
                  }}
                  style={{
                    border: "1.333px solid var(--Gray-300, #D0D5DD)",
                    color: "#38B000",
                    fontFamily: "gsb",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "26px",
                    padding: "6px",
                  }}
                >
                  Add Payment{" "}
                </button>
              )}
            </div>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {value?.payment_details?.length > 0 && (
            <table className="w-full">
              <thead className="h-[50px] sticky top-0 z-10 bg-[#111111] font-[Gr] text-[14px] text-[#FFFFFF] font-medium">
                <tr className="h-[50px] top-0 z-10">
                  <th className="text-start px-6 whitespace-nowrap w-[69px]">
                    SL.No
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[180px]">
                    Payment Date{" "}
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[150px]">
                    Total Amount
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[110px]">
                    Payment Type
                  </th>

                  <th className="text-start  px-6 whitespace-nowrap w-[120px]">
                    Payment Proof
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[130px]">
                    Payment Receipt
                  </th>
                </tr>
              </thead>

              <tbody>
                {value?.payment_details.map((value: any, index: any) => (
                  <tr className="hover:opacity-[0.7] hover:bg-[#F6F6F6] border-b-[2px] border-[#F5F8FA] h-[66px] bg-white cursor-pointer">
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {index + 1}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {value?.payment_date
                        ? moment(value?.payment_date).format("DD/MM/YYYY")
                        : "-"}{" "}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {value?.payment_amount}{" "}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {value?.payment_type}
                    </td>
                    <td
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_IMAGE_URL}/${value.payment_proof}`
                        );
                      }}
                      className="px-[20px] font-[Gm] font-medium text-[14px] underline"
                    >
                      View Proof
                    </td>
                    <td
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_IMAGE_URL}/${value.payment_receipt}`
                        );
                      }}
                      className="px-[20px] font-[Gm] font-medium text-[14px] underline"
                    >
                      View Receipt
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
