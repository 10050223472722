import { activeScreens } from "../../../Types";
import { RootState } from "./../../AllComponents/App/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: activeScreens = {
  activeScreen: "AllClients",
};

export const screenSlice = createSlice({
  name: "screens",
  initialState,
  reducers: {
    setActiveScreen: (state, action: PayloadAction<string>) => {
      state.activeScreen = action.payload;
    },
    clearClientStatescreen: (state) => {
      return initialState; // Reset state to initial state
    },
  },
});

export const { setActiveScreen, clearClientStatescreen } = screenSlice.actions;

export const selectActiveScreen = (state: RootState) =>
  state.screens.activeScreen;

export default screenSlice.reducer;
