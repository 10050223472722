import React, { ChangeEvent, useEffect, useState } from "react";
import "./document.css";
import axiosClientInterceptors from "../../../ApiConfig/axiosClientInterceptor";
import { AxiosResponse } from "axios";
import { Alldocuments, fileCategory, resType } from "../../../../Types";
import { addMonths, addYears, endOfDay, startOfDay } from "date-fns";
import { DateRangePicker } from "rsuite";
import nodata from "../../Assets/nodata.jpg";
import Loader from "../../../Tools/Loader";
import moment from "moment";
import RsuitePagination from "../../../Tools/RsuitePagination";
import ClearIcon from "@mui/icons-material/Clear";
import { PaginationProps } from "antd";
import { saveAs } from "file-saver";
import { selectClientID } from "../../../Features/AllClients/AllClients";
import { useSelector } from "react-redux";
import { selectActiveScreen } from "../../../Features/Screens/Screens";

const fileNameSplit = (str: any) => {
  if (str) {
    return str.split("/").at(-1).split("-").slice(1).join("-");
  }
};

const AllDocumentList = () => {
  const panel = useSelector(selectActiveScreen);
  const [route, setroute] = useState<any>(window.location.pathname);

  const clientID = useSelector(selectClientID);
  const [fileCategory, setFileCategory] = useState<fileCategory[]>([]);
  const [allDoc, setAllDoc] = useState<Alldocuments[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [defSearch, setDefSearch] = useState<string>("");
  const [dateRange, setDateRange] = useState<
    [(Date | null | undefined)?, (Date | null | undefined)?]
  >([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [fileCat, setFileCat] = useState<string>("");

  const getFileCategory = () => {
    axiosClientInterceptors
      .get(`/general/config?heading=Other File Category`)
      .then((res: AxiosResponse) => {
        setFileCategory(res.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllDocuments = () => {
    setLoader(true);
    axiosClientInterceptors
      .post(`/documents?page=${page}&limit=${perPage}&search=${search}`, {
        file_type: fileCat,
        client_id: route === "/Home/Documents" ? "" : clientID,
        // display: "all"
        date_from: startDate,
        date_to: endDate,
      })
      .then((res) => {
        setAllDoc(res?.data?.result?.data);
        setTotalCount(res?.data?.result?.totalCount);
        setPage(res.data?.result?.currentPage);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage: PaginationProps["onChange"] = (page, perPage) => {
    setPerPage(perPage);
    setPage(page);
  };

  const onChangeDate = (date: [Date, Date]) => {
    if (date) {
      setDateRange(date);
      const [startDate, endDate] = date;
      setStartDate(moment(startDate).format("YYYY-MM-DD"));
      setEndDate(moment(endDate).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    getFileCategory();
  }, []);

  useEffect(() => {
    getAllDocuments();
  }, [page, perPage, defSearch, startDate, endDate, fileCat, panel]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="relative">
          <input
            className="searchdocument outline-none"
            type="text"
            style={{ position: "relative" }}
            placeholder="Search by Lead name"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setDefSearch(search);
              }
            }}
          />
          {defSearch?.length > 0 && (
            <ClearIcon
              sx={{
                position: "absolute",
                top: "8px",
                right: "11px",
                cursor: "pointer",
              }}
              onClick={() => {
                setDefSearch("");
                setSearch("");
              }}
            />
          )}
          <div className="absolute top-3 left-3">
            <svg
              width="15"
              height="15"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="5.39301"
                cy="5.39319"
                r="4.11976"
                stroke="#667085"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.25836 8.47266L9.87354 10.0836"
                stroke="#667085"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="flex items-center gap-6">
          <select
            className="fileCate px-3"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setFileCat(e.target.value);
            }}
          >
            <option value="" hidden selected>
              File Category
            </option>
            {fileCat && <option value="">All Files</option>}
            {fileCategory?.map((file, i) => (
              <option key={i} value={file._id}>
                {file?.section_name}
              </option>
            ))}
          </select>
          <div>
            <DateRangePicker
              placement="leftStart"
              format="dd-MM-yyyy"
              placeholder="Choose Date Range"
              ranges={[
                {
                  label: "today",
                  value: [startOfDay(new Date()), endOfDay(new Date())],
                  closeOverlay: false,
                },
                {
                  label: "Month",
                  value: [
                    endOfDay(new Date()),
                    startOfDay(addMonths(new Date(), 1)),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "3 Months",
                  value: [
                    endOfDay(new Date()),
                    startOfDay(addMonths(new Date(), 3)),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "6 Months",
                  value: [
                    endOfDay(new Date()),
                    startOfDay(addMonths(new Date(), 6)),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "Year",
                  value: [
                    endOfDay(new Date()),
                    startOfDay(addYears(new Date(), 1)),
                  ],
                  closeOverlay: false,
                },
              ]}
              onOk={onChangeDate}
              className="w-[215px]"
              cleanable={true}
              onClean={() => {
                setDateRange([]);
                setStartDate("");
                setEndDate("");
              }}
              defaultValue={(() => dateRange as [Date, Date])()}
            />
          </div>
        </div>
      </div>
      {loader ? (
        <Loader customHeight={"80vh"} />
      ) : (
        <div className="allTable bg-white mt-[15px]">
          <table className="w-full">
            <thead className="h-[50px] sticky top-0 z-10 bg-[#111111] font-[Gr] text-[14px] text-[#FFFFFF] font-medium">
              <tr className="h-[50px] top-0 z-10">
                <th className="text-start px-6 whitespace-nowrap">Sl. No.</th>
                <th className="text-start px-4 whitespace-nowrap">FIle Name</th>
                <th className="text-start px-4 whitespace-nowrap">Lead Name</th>
                <th className="text-start px-4 whitespace-nowrap">Lead ID</th>
                <th className="text-start px-4 whitespace-nowrap">
                  Uploaded By
                </th>
                <th className="text-start px-4 whitespace-nowrap">
                  File Category
                </th>
                <th className="text-start px-4 whitespace-nowrap">
                  Upload Date & Time
                </th>
                <th className="text-start px-4 whitespace-nowrap">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allDoc?.map((doc, i) => (
                <tr
                  key={i}
                  className="hover:opacity-[0.7] hover:bg-[#ffffff] border-b-[2px] border-[#F5F8FA] h-[66px] bg-white cursor-pointer"
                >
                  <td className="px-6 font-[Gm] font-medium text-[14px]">
                    {(page - 1) * 10 + i + 1}
                  </td>
                  <td className="px-4 font-[Gm] font-medium text-[14px]">
                    {fileNameSplit(doc?.file)}
                  </td>
                  <td className="px-4 font-[Gm] font-medium text-[14px] w-[100px]">
                    {doc?.client_name || "-"}
                  </td>
                  <td className="px-4 font-[Gm] font-medium text-[14px] w-[100px]">
                    {doc?.lead_id || "-"}
                  </td>
                  <td className="px-4 font-[Gm] font-medium text-[14px] min-w-[130px]">
                    {doc?.uploaded_by || "-"}
                  </td>
                  <td className="px-4 font-[Gm] font-medium text-[14px] min-w-[130px]">
                    {doc?.file_type || "-"}
                  </td>
                  <td className="px-4 font-[Gm] font-medium text-[14px] w-[200px]">
                    {moment(doc?.createdAt).format("HH:mm A, Do MMMM YYYY") ||
                      "-"}
                  </td>
                  <td className="px-4 font-[Gm] font-medium text-[14px]">
                    <div className="flex items-center gap-5">
                      <button
                        className="viewDoc"
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_IMAGE_URL}/${doc?.file}`
                          );
                        }}
                      >
                        View
                      </button>
                      <button
                        className="dwnDoc"
                        onClick={() => {
                          saveAs(
                            `${process.env.REACT_APP_IMAGE_URL}/${doc?.file}`,
                            `${fileNameSplit(doc?.file)}`
                          );
                        }}
                      >
                        Download
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            {allDoc?.length === 0 && (
              <div
                className="flex justify-center items-center bg-white mt-[2px] flex-col"
                style={{ height: "72vh" }}
              >
                <img
                  className="h-[250px] w-[220px]"
                  src={nodata}
                  alt="no-data"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {allDoc?.length > 0 && (
        <div className="dealsPagination mt-10">
          <RsuitePagination
            handleChangePage={handleChangePage}
            limit={perPage}
            total={totalCount}
            currentPage={page}
          />
        </div>
      )}
    </div>
  );
};

export default AllDocumentList;
