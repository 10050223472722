import React, { useEffect, useState } from "react";
import {
  individualTask,
  option,
  projectStatusType,
  taskList,
  userlisting,
} from "../../../../Types";
import axiosClientInterceptors from "../../../ApiConfig/axiosClientInterceptor";
import MultiSelect from "../../../Tools/MultiSelect";
import { DateRangePicker } from "rsuite";
import Loader from "../../../Tools/Loader";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import {
  addMonths,
  addYears,
  endOfDay,
  startOfDay,
  subMonths,
  subYears,
} from "date-fns";
import { AxiosResponse } from "axios";
import moment from "moment";
import nodata from "../../Assets/nodata.jpg";
import RsuitePagination from "../../../Tools/RsuitePagination";
import { PaginationProps } from "antd";
import { Dialog } from "@mui/material";
import SmallLoader from "../../../Tools/SmallLoader";

const Task = (): JSX.Element => {
  const [allocationUsers, setAllocationType] = useState<userlisting[]>([]);
  const [projectStatus, setProjectStatus] = useState<projectStatusType[]>([]);
  const [user, setUser] = useState<option[]>([]);
  const [taskType, setTaskType] = useState<option[]>([]);
  const [sortBy, setSortBy] = useState<option[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [taskLoader, setTaskLoader] = useState<boolean>(false);
  const [taskList, setTaskList] = useState<taskList[]>([]);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dateRange, setDateRange] = useState<
    [(Date | null | undefined)?, (Date | null | undefined)?]
  >([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [defSearch, setDefSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [idvTaskDetails, setIdvTaskDetails] = useState<individualTask>();

  const getUsers = () => {
    axiosClientInterceptors
      .get(`/general/users/getallusers`)
      .then((res) => {
        setAllocationType(res.data?.result);
        console.log("resresres", res.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectStatus = () => {
    axiosClientInterceptors
      .get(`/general/config?heading=Task Type`)
      .then((res) => {
        setProjectStatus(res.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const assignedToOptions = allocationUsers?.map((x) => {
    return {
      value: x._id || "",
      label: x.fullname || "",
    };
  });

  const handleChangeAssignedTo = (value: any) => {
    setUser(value);
  };

  const taskTypeOptions = projectStatus?.map((x) => {
    return {
      value: x._id,
      label: x.section_name,
    };
  });

  const handleTaskType = (value: any) => {
    setTaskType(value);
  };

  const handleTaskSortBy = (value: any) => {
    setSortBy(value);
  };

  const getTaskList = () => {
    setLoader(true);
    axiosClientInterceptors
      .post(`/tasks?page=${page}&limit=${perPage}&search=${search}`, {
        assigned_to: user?.map((x) => x.value),
        task_type: taskType?.map((x) => x.value),
        task_from: startDate,
        task_to: endDate,
        task_status: sortBy?.map((x) => x.value),
      })
      .then((res: AxiosResponse) => {
        console.log(res);
        setTaskList(res?.data?.result?.data);
        setTotalCount(res?.data?.result?.totalCount);
        setPage(res.data?.result?.currentPage);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage: PaginationProps["onChange"] = (page, perPage) => {
    setPerPage(perPage);
    setPage(page);
  };

  const handleDateChange = (date: [Date, Date]) => {
    if (date) {
      setDateRange(date);
      const [startDate, endDate] = date;
      setStartDate(moment(startDate).format("YYYY-MM-DD"));
      setEndDate(moment(endDate).format("YYYY-MM-DD"));
    }
  };

  const getTaskDetails = (ID: string) => {
    setTaskLoader(true);
    axiosClientInterceptors
      .get(`/tasks?id=${ID}`)
      .then((res: AxiosResponse) => {
        setIdvTaskDetails(res?.data?.result[0]);
        setTaskLoader(false);
        // console.log(res?.data?.result, "sss");
      })
      .catch((err) => {
        console.log(err);
        setTaskLoader(true);
      });
  };

  useEffect(() => {
    getUsers();
    getProjectStatus();
  }, []);

  useEffect(() => {
    getTaskList();
  }, [page, perPage, user, taskType, sortBy, startDate, endDate, defSearch]);

  return (
    <div className="my-[30px] mr-[20px] bg-[#F5F8FA] border-[0.5px] border-[#DEDEDE] rounded-[2px]">
      <div className="p-6">
        <div className="flex pb-[21px] justify-between items-center">
          <p className="font-[Gm] font-bold text-[20px] text-[#151414]">
            Task List
          </p>
        </div>
        <div className="flex justify-between items-center">
          <div className="relative">
            <input
              className="searchdocument outline-none"
              type="text"
              style={{ position: "relative" }}
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setDefSearch(search);
                }
              }}
            />
            {defSearch?.length > 0 && (
              <ClearIcon
                className="cursor-pointer"
                sx={{ position: "absolute", top: "8px", right: "11px" }}
                onClick={() => {
                  setSearch("");
                  setDefSearch("");
                }}
              />
            )}
            <div className="absolute top-3 left-3">
              <svg
                //   className="top-[29%] left-[3%]"
                width="15"
                height="15"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="5.39301"
                  cy="5.39319"
                  r="4.11976"
                  stroke="#667085"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.25836 8.47266L9.87354 10.0836"
                  stroke="#667085"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="min-w-[175px]">
            <MultiSelect
              placeholder={"Assigned to"}
              options={assignedToOptions}
              value={user}
              defaultValue={user}
              handleChange={handleChangeAssignedTo}
            />
          </div>
          <div className="min-w-[175px]">
            <MultiSelect
              placeholder={"Task Type"}
              options={taskTypeOptions}
              value={taskType}
              defaultValue={taskType}
              handleChange={handleTaskType}
            />
          </div>
          <div>
            <DateRangePicker
              placement="leftStart"
              format="dd-MM-yyyy"
              placeholder="Choose Date Range"
              ranges={[
                {
                  label: "today",
                  value: [endOfDay(new Date()), startOfDay(new Date())],
                  closeOverlay: false,
                },
                {
                  label: "Month",
                  value: [
                    endOfDay(subMonths(new Date(), 1)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "3 Months",
                  value: [
                    endOfDay(subMonths(new Date(), 3)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "6 Months",
                  value: [
                    endOfDay(subMonths(new Date(), 6)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "Year",
                  value: [
                    endOfDay(subYears(new Date(), 1)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
              ]}
              onOk={handleDateChange}
              className="w-[215px] h-11"
              cleanable={true}
              onClean={() => {
                setDateRange([]);
                setStartDate("");
                setEndDate("");
              }}
              defaultValue={(() => dateRange as [Date, Date])()}
            />
          </div>
          <div className="min-w-[175px]">
            <MultiSelect
              placeholder={"Sort by"}
              options={[
                {
                  value: "Due Today",
                  label: "Due Today",
                },
                {
                  value: "Upcoming",
                  label: "Upcoming",
                },
                {
                  value: "Completed",
                  label: "Completed",
                },
                {
                  value: "Overdue",
                  label: "Overdue",
                },
              ]}
              handleChange={handleTaskSortBy}
              defaultValue={sortBy}
              value={sortBy}
            />
          </div>
        </div>
        <div>
          {loader ? (
            <Loader customHeight={"80vh"} />
          ) : (
            <div className="allTable bg-white mt-[15px]">
              <table className="w-full">
                <thead className="h-[50px] sticky top-0 bg-[#111111] font-[Gr] text-[14px] text-[#FFFFFF] font-medium">
                  <tr className="h-[50px] top-0">
                    <th className="text-start px-6 whitespace-nowrap">
                      SL. No.
                    </th>
                    <th className="text-start px-4 whitespace-nowrap">
                      User Name
                    </th>
                    {/* <th className="text-start px-4 whitespace-nowrap">
                      User Department
                    </th> */}
                    <th className="text-start px-4 whitespace-nowrap">
                      Task Type
                    </th>
                    <th className="text-start px-4 whitespace-nowrap">
                      Task Date & Time
                    </th>
                    <th className="text-start px-4 whitespace-nowrap">
                      Client Name
                    </th>
                    <th className="text-start px-4 whitespace-nowrap">
                      Task Status
                    </th>
                    <th className="text-start px-4 whitespace-nowrap">
                      Stage name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {taskList?.map((task, i) => (
                    <tr
                      onClick={() => {
                        getTaskDetails(task?._id!);
                        setOpen(true);
                      }}
                      key={i}
                      className="hover:opacity-[0.7] hover:bg-[#ffffff] border-b-[2px] border-[#F5F8FA] h-[66px] bg-white cursor-pointer"
                    >
                      <td className="px-6 font-[Gm] font-medium text-[14px]">
                        {(page - 1) * 10 + i + 1}
                      </td>
                      <td className="px-4 font-[Gm] font-medium text-[14px]">
                        {task?.created_by}
                      </td>
                      {/* <td className="px-4 font-[Gm] font-medium text-[14px] w-[100px]">
                        {task?.user_department}
                      </td> */}
                      <td className="px-4 font-[Gm] font-medium text-[14px] min-w-[100px]">
                        {task?.task_type}
                      </td>
                      <td className="px-4 font-[Gm] font-medium text-[14px] min-w-[100px]">
                        {moment(task?.createdAt).format(
                          "HH:mm A, Do MMMM YYYY"
                        )}
                      </td>
                      <td className="px-4 font-[Gm] font-medium text-[14px] min-w-[130px]">
                        {task?.client_name}
                      </td>
                      <td className="px-4 font-[Gm] font-medium text-[14px] w-[130px]">
                        {task?.task_status}
                      </td>
                      <td className="px-4 font-[Gm] font-medium text-[14px]">
                        {task?.stage_name || "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {taskList?.length === 0 && (
                  <div
                    className="flex justify-center items-center bg-white mt-[2px] flex-col"
                    style={{ height: "72vh" }}
                  >
                    <img
                      className="h-[250px] w-[220px]"
                      src={nodata}
                      alt="no-data"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {taskList?.length > 0 && (
            <div className="dealsPagination mt-10">
              <RsuitePagination
                handleChangePage={handleChangePage}
                limit={perPage}
                total={totalCount}
                currentPage={page}
              />
            </div>
          )}
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "1098px",
              minHeight: "320px",
            },
          },
        }}
      >
        <div>
          {taskLoader ? (
            <Loader customHeight={"40vh"} />
          ) : (
            <div>
              <div className="p-5 flex justify-between items-center border-b-[1px]">
                <span className="font-[Gm] font-semibold text-[14px] w-[20] text-black">
                  {idvTaskDetails?.task_type || "-"}
                </span>
                <span
                  className={`${
                    idvTaskDetails?.task_status === "Completed"
                      ? "text-[#38B000]"
                      : idvTaskDetails?.task_status === "Delayed" ||
                        idvTaskDetails?.task_status === "Overdue"
                      ? "text-[#FF0000]"
                      : idvTaskDetails?.task_status === "Due Today"
                      ? "text-orange-400"
                      : "text-[#5A5959]"
                  } font-[Gm] font-medium text-[14px] w-[20]`}
                >
                  {idvTaskDetails?.task_status || "-"}
                </span>
                <span className="font-[Gm] font-medium text-[14px] w-[20] text-[#5A5959]">
                  {`Task Created on: ${
                    moment(idvTaskDetails?.createdAt).format(
                      "h:mm A D, MMM YYYY"
                    ) || "-"
                  }`}
                </span>
                <span className="font-[Gm] font-medium text-[14px] w-[20] text-[#5A5959]">
                  {`Due Date: ${
                    moment(idvTaskDetails?.due_date).format("D, MMMM YYYY") ||
                    "-"
                  }`}
                </span>
                <CloseIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </div>
              <div className="p-5 flex justify-between w-full items-center">
                <div>
                  <p className="mb-1 font-[Gm] font-medium text-[13px] text-[#969696]">
                    Task created by
                  </p>
                  <span className="font-[Gm] font-semibold text-[14px] text-[#221E1F]">
                    {idvTaskDetails?.created_by || "-"}
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-[Gm] font-medium text-[13px] text-[#969696]">
                    Assigned to
                  </p>
                  <span className="font-[Gm] font-semibold text-[14px] text-[#221E1F]">
                    {idvTaskDetails?.assigned_to || "-"}
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-[Gm] font-medium text-[13px] text-[#969696]">
                    Task Type
                  </p>
                  <span className="font-[Gm] font-semibold text-[14px] text-[#221E1F]">
                    {idvTaskDetails?.task_type || "-"}
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-[Gm] font-medium text-[13px] text-[#969696]">
                    Remind Date
                  </p>
                  <span className="font-[Gm] font-semibold text-[14px] text-[#221E1F]">
                    {`${moment(idvTaskDetails?.reminder_time, "HH:mm").format(
                      "hh:mm A"
                    )}, ${moment(idvTaskDetails?.reminder_date).format(
                      "D MMMM YYYY"
                    )}` || "-"}
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-[Gm] font-medium text-[13px] text-[#969696]">
                    Task Priority
                  </p>
                  <span
                    className={`${
                      idvTaskDetails?.priority === "High"
                        ? "text-[#FF0000]"
                        : idvTaskDetails?.priority === "Medium"
                        ? "text-[#FF9900]"
                        : "text-[#00970F]"
                    } font-[Gm] font-semibold text-[14px]`}
                  >
                    {idvTaskDetails?.priority || "-"}
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-[Gm] font-medium text-[13px] text-[#969696]">
                    Last Updated on
                  </p>
                  <span className="font-[Gm] font-semibold text-[14px] text-[#221E1F]">
                    {idvTaskDetails?.updatedAt
                      ? moment(idvTaskDetails?.updatedAt).format(
                          "h:mm A D, MMMM YYYY"
                        )
                      : "-"}
                  </span>
                </div>
                <div>
                  <p className="mb-1 font-[Gm] font-medium text-[13px] text-[#969696]">
                    Submitted on
                  </p>
                  <span className="font-[Gm] font-semibold text-[14px] text-[#221E1F]">
                    {idvTaskDetails?.submitted_on
                      ? moment(idvTaskDetails?.submitted_on).format(
                          "h:mm A D, MMMM YYYY"
                        )
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="mt-5 px-5">
                <p className="mb-1 font-[Gm] font-medium text-[13px] text-[#969696]">
                  Description
                </p>
                <span className="font-[Gm] font-semibold text-[14px] text-[#221E1F]">
                  {idvTaskDetails?.description || "-"}
                </span>
              </div>
              <div className="mt-5 px-5">
                <p className="mb-1 font-[Gm] font-medium text-[13px] text-[#969696]">
                  Task Outcome
                </p>
                <span className="font-[Gm] font-semibold text-[14px] text-[#221E1F]">
                  {idvTaskDetails?.task_outcome || "-"}
                </span>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default Task;
