import MultiSelect from "../../../../../Tools/MultiSelect";
import axiosClientInterceptors from "../../../../../ApiConfig/axiosClientInterceptor";
import { selectClientID } from "../../../../../Features/AllClients/AllClients";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import {
  addMonths,
  addYears,
  endOfDay,
  startOfDay,
  subMonths,
  subYears,
} from "date-fns";
import moment from "moment";
import { stagenameobject } from "../../../../../../Types";

const Activities = () => {
  const clientID = useSelector(selectClientID);
  const [activityData, setactivityData] = useState<any>([]);
  const [meetingDateRange, setmeetingDateRange] = useState<Date[]>([]);
  const [meetingStartDate, setmeetingStartDate] = useState<string>("");
  const [meetingEndDate, setmeetingEndDate] = useState<string>("");
  const [stageListing, setstageListing] = useState<stagenameobject[]>([]);
  const [StageName, setStageName] = useState<any>([]);
  const [SectionType, setSectionType] = useState<any>([]);

  const getActivities = () => {
    axiosClientInterceptors
      .post(`/clients/activities/${clientID}`, {
        stages: StageName?.map((val: any) => val.value),
        section_names: SectionType?.map((val: any) => val.value),
        from_date: meetingStartDate,
        to_date: meetingEndDate,
      })
      .then((resp) => {
        const result = resp.data.result;
        console.log("resp", result);
        setactivityData(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getActivities();
  }, [StageName, SectionType, meetingStartDate, meetingEndDate]);
  // activity: "Meeting Scheduled in Site Measurement by Akshay(AD)";
  // createdAt: "2024-05-28T06:07:46.069Z";
  // isDeleted: false;
  // lead_id: "665039df5357625862f15ad1";
  // section_name: "Pre-Construction";
  // stage_id: "66503e8bfe8074cad7e6ce29";
  // tab_section: "Meetings";
  // updatedAt: "2024-05-28T06:07:46.069Z";
  // _id: "665574b2a5c071d65db4148a";

  const handleChangeSectionType = (data: any) => {
    setSectionType(data);
  };
  const handleChangeStageName = (data: any) => {
    setStageName(data);
  };
  const handlestartDateChange = (date: [Date, Date]) => {
    if (date) {
      setmeetingDateRange(date);
      const [startDate, endDate] = date;
      setmeetingStartDate(moment(startDate).format("YYYY-MM-DD"));
      setmeetingEndDate(moment(endDate).format("YYYY-MM-DD"));
    }
  };
  const getSTageListing = () => {
    axiosClientInterceptors
      .get(
        `/general/stages-list?lead_id=${clientID}&section_name=Pre-Construction`
      )
      .then((res) => {
        const result = res.data.result;
        console.log("data======>", result);
        // setNoteTypeListing(result);
        setstageListing(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSTageListing();
  }, []);
  return (
    <div>
      <>
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-6">
            <MultiSelect
              placeholder={"Choose Section Type"}
              options={[
                {
                  value: "Tasks",
                  label: "Tasks",
                },
                {
                  value: "Notes",
                  label: "Notes",
                },
                {
                  value: "Designs",
                  label: "Designs",
                },
                {
                  value: "Stage Documents",
                  label: "Stage Documents",
                },
                {
                  value: "Meetings",
                  label: "Meetings",
                },
                {
                  value: "Payments",
                  label: "Payments",
                },
              ]}
              value={SectionType}
              defaultValue={SectionType}
              handleChange={handleChangeSectionType}
            />

            <DateRangePicker
              placement="bottomStart"
              format="dd-MM-yyyy"
              placeholder="Choose Date Range"
              ranges={[
                {
                  label: "today",
                  value: [endOfDay(new Date()), startOfDay(new Date())],
                  closeOverlay: false,
                },
                {
                  label: "Month",
                  value: [
                    endOfDay(subMonths(new Date(), 1)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "3 Months",
                  value: [
                    endOfDay(subMonths(new Date(), 3)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "6 Months",
                  value: [
                    endOfDay(subMonths(new Date(), 6)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "Year",
                  value: [
                    endOfDay(subYears(new Date(), 1)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
              ]}
              onClean={() => {
                setmeetingDateRange([]);
                setmeetingStartDate("");
                setmeetingEndDate("");
              }}
              onOk={handlestartDateChange}
              className=" w-[400px]  focus:border-gray-400  border-gray-300 "
              cleanable={true}
              defaultValue={(() => meetingDateRange as [Date, Date])()}
            />
            <MultiSelect
              placeholder={"Choose Stage Name"}
              options={stageListing?.map((value: any) => {
                return {
                  value: value._id,
                  label: value.name,
                };
              })}
              value={StageName}
              defaultValue={StageName}
              handleChange={handleChangeStageName}
            />
          </div>
        </div>
        {activityData.map((values: any) => (
          <div className=" p-5 bg-[#FFF] flex justify-between items-center mt-4">
            <p className="ProjectStages__Card__Title">{values?.activity}</p>
            <p className="ProjectStages__UpdatedBy">
              Activity Date & Time: {values?.updatedAt}
            </p>
          </div>
        ))}
      </>
    </div>
  );
};

export default Activities;
