import * as React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Task from "../ProjectStages/Task/Task";
import Meetings from "../ProjectStages/Meetings/Meetings";
import ConstructionDrawings from "../ConstructionDrawings/ConstructionDrawings";

const styleObject: any = {
  background: "transparent",
  border: "none",
};

const ConstructionProjectStage = () => {
  const [value, setValue] = React.useState<string>("1");

  const handleChangeONE = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      {" "}
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChangeONE}
            aria-label="lab API tabs example"
            TabIndicatorProps={{
              style: { background: "#F6BA00" },
            }}
          >
            <Tab
              className="con_tab"
              label="Meetings"
              value="1"
              style={styleObject}
            />
            <Tab
              className="con_tab"
              label="Drawings"
              value="2"
              style={styleObject}
            />
            <Tab
              className="con_tab"
              label="Tasks"
              value="3"
              style={styleObject}
            />
          </TabList>
        </Box>
        <TabPanel
          className="min-h-[650px] p-10  bg-[#F5F8FA] mt-3"
          value="1"
          style={{ padding: "0px" }}
        >
          <Meetings />
        </TabPanel>
        <TabPanel
          style={{ padding: "0px" }}
          className="min-h-[650px] p-10  bg-[#F5F8FA]"
          value="2"
        >
          <ConstructionDrawings />
        </TabPanel>
        <TabPanel
          style={{ padding: "0px" }}
          className="min-h-[650px] p-10  bg-[#F5F8FA]"
          value="3"
        >
          <Task />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default ConstructionProjectStage;
