import React from "react";

const Dashboard = () => {
  return (
    <div className="flex justify-between items-center w-[100%] h-[100%]">
      <div className="flex justify-center items-center w-[100%] h-[700px] my-[30px] mr-[20px] bg-[#F5F8FA] border-[0.5px] border-[#DEDEDE] rounded-[2px]">
        <h1>Coming Soon</h1>
      </div>
    </div>
  );
};

export default Dashboard;
