import React from "react";
import { Pagination, PaginationProps } from "antd";
import { pagninationProps } from "../../Types";

const RsuitePagination = ({
  currentPage,
  limit,
  total,
  handleChangePage,
}: pagninationProps) => {
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <div className="myan">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="11"
              fill="none"
              viewBox="0 0 16 11"
            >
              <path
                stroke="#051B44"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M15.5 5.5H.5M5.5.5l-5 5 5 5"
              ></path>
            </svg>
          </div>
          <p> Prev</p>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="myan">
          <p>Next</p>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="11"
              fill="none"
              viewBox="0 0 16 11"
            >
              <path
                stroke="#051B44"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M.5 5.5h15M10.5.5l5 5-5 5"
              ></path>
            </svg>
          </div>
        </div>
      );
    }
    return originalElement;
  };
  return (
    <div>
      <Pagination
        onChange={handleChangePage}
        pageSize={limit}
        total={total}
        // showTotal={(total, range) =>
        //   `Showing ${range[0]} to ${range[1]} of ${total} Results`
        // }
        current={currentPage}
        // defaultCurrent={Page}
        // showSizeChanger={false}
        pageSizeOptions={[10, 20, 50, 100]}
        showSizeChanger
        itemRender={itemRender}
      />
    </div>
  );
};

export default RsuitePagination;
