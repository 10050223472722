import { useSortable } from "@dnd-kit/sortable";
import React, { ReactNode, useEffect, useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import {
  itemData,
  option,
  projectStage,
  projectStageFormik,
  resType,
} from "../../../../../Types";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useFormik } from "formik";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "../../../Assets/svg/deletelogo.svg";
import MultiSelect from "../../../../Tools/MultiSelect";
import axiosClientInterceptors from "../../../../ApiConfig/axiosClientInterceptor";
import { AxiosResponse } from "axios";
import { Toaster } from "../../../../Tools/Toaster";
import SmallLoader from "../../../../Tools/SmallLoader";
import { validateProjectStage } from "../../../../Tools/Validation";

const PreConstructionCard = ({
  item,
  index,
  toggle,
  roleOptions,
  user,
  getProjectStages,
}: itemData) => {
  const [openStage, setOpenStage] = useState<boolean>(false);
  const [delID, setDelID] = useState<string>("");
  // const [openDel, setOpenDel] = useState<boolean>(false);
  const [stageData, setStageData] = useState<projectStage>({
    stage_name: "",
    assigned_to: [],
    display_payment: false,
    display_design: false,
    _id: "",
    createdAt: "",
    id: "",
    rank: 0,
    updatedAt: "",
  });
  const [userRole, setUserRole] = useState<option[]>([]);
  // const [defalutRole, setDefaultRole] = useState<string[]>([]);
  const [editStageLoader, setEditStageLoader] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [delLoader, setDelLoader] = useState<boolean>(false);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  } as React.CSSProperties;

  const editProjectStatus = useFormik<projectStageFormik>({
    enableReinitialize: true,
    initialValues: {
      stage_name: stageData.stage_name,
      display_payment: stageData.display_payment,
      display_design: stageData.display_design,
    },
    validationSchema: validateProjectStage,
    onSubmit: (val) => {
      console.log(val);
      if (userRole?.length > 0) {
        setEditStageLoader(true);
        axiosClientInterceptors
          .put(`/config/stages/${stageData._id}`, {
            stage_name: val.stage_name,
            assigned_to: userRole?.map((x) => x.value) || [],
            display_design: val.display_design,
            display_payment: val.display_payment,
          })
          .then((res: AxiosResponse) => {
            const data: resType = res.data;
            if (data?.success) {
              getProjectStages();
              setOpenStage(false);
              Toaster("success", data?.message);
              setEditStageLoader(false);
              setUserRole([]);
              editProjectStatus.resetForm({});
            }
          })
          .catch((err: AxiosResponse) => {
            console.log(err);
            setEditStageLoader(false);
          });
      }
    },
  });

  const handleChangeUser = (value: any) => {
    setUserRole(value);
    setErrorMsg("");
  };

  const validateStage = () => {
    if (userRole?.length === 0) {
      setErrorMsg("Stage Assigned to is required");
    } else {
      setErrorMsg("");
    }
  };

  // const deleteStage = () => {
  //   setDelLoader(true);
  //   axiosClientInterceptors
  //     .delete(`/config/stages/${delID}`)
  //     .then((res: AxiosResponse) => {
  //       const data: resType = res.data;
  //       if (data?.success) {
  //         getProjectStages();
  //         setOpenDel(false);
  //         Toaster("success", data?.message);
  //         setDelLoader(false);
  //       } else {
  //         Toaster("error", data?.message);
  //         setDelLoader(false);
  //       }
  //     })
  //     .catch((err: AxiosResponse) => {
  //       console.log(err);
  //       setDelLoader(false);
  //     });
  // };

  // useEffect(() => {
  //   let data = stageData.assigned_to?.map((x) => {
  //     return {
  //       value: x,
  //       label: x,
  //     };
  //   });
  //   setDefaultRole(data);
  // }, [defalutRole]);

  return (
    <div>
      <div ref={setNodeRef} style={style} {...attributes}>
        <div>
          <div className="p-6 flex w-full items-center gap-16 bg-white border-t-2 border-solid border-[#f5f8fa] py-[10px]">
            <div className="text-start flex w-1/5" {...listeners}>
              {toggle ? (
                <DragIndicatorIcon
                  sx={{ marginRight: "20px", color: "black" }}
                />
              ) : (
                ""
              )}
              <p className="text-[black] flex items-center font-[Gm] font-medium text-[16px] text-left">
                {index + 1}
              </p>
            </div>
            <div className="text-[black] flex items-center text-start w-3/5 font-[Gm] font-medium text-[16px]">
              {item?.stage_name || "-"}
            </div>
            <div className="text-[black] flex items-center text-start w-1/5 font-[Gm] font-medium text-[16px]">
              {item?.display_design ? (
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.68493 11.2C5.44493 11.2 5.22491 11.12 5.04491 10.94L1.06493 6.96004C0.704932 6.60004 0.704932 6.04004 1.06493 5.68004C1.42493 5.32004 1.98491 5.32004 2.34491 5.68004L5.70492 9.02003L13.6849 1.06004C14.0449 0.700037 14.6049 0.700037 14.9649 1.06004C15.3249 1.42004 15.3249 1.98004 14.9649 2.34004L6.34491 10.94C6.14491 11.12 5.92493 11.2 5.68493 11.2Z"
                    fill="#00B412"
                  />
                </svg>
              ) : (
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3002 0.710224C13.2077 0.61752 13.0978 0.543973 12.9768 0.493791C12.8559 0.44361 12.7262 0.417779 12.5952 0.417779C12.4643 0.417779 12.3346 0.44361 12.2136 0.493791C12.0926 0.543973 11.9827 0.61752 11.8902 0.710224L7.00022 5.59022L2.11022 0.700223C2.01764 0.607642 1.90773 0.534202 1.78677 0.484097C1.6658 0.433992 1.53615 0.408203 1.40522 0.408203C1.27429 0.408203 1.14464 0.433992 1.02368 0.484097C0.902716 0.534202 0.792805 0.607642 0.700223 0.700223C0.607642 0.792805 0.534202 0.902716 0.484097 1.02368C0.433992 1.14464 0.408203 1.27429 0.408203 1.40522C0.408203 1.53615 0.433992 1.6658 0.484097 1.78677C0.534202 1.90773 0.607642 2.01764 0.700223 2.11022L5.59022 7.00022L0.700223 11.8902C0.607642 11.9828 0.534202 12.0927 0.484097 12.2137C0.433992 12.3346 0.408203 12.4643 0.408203 12.5952C0.408203 12.7262 0.433992 12.8558 0.484097 12.9768C0.534202 13.0977 0.607642 13.2076 0.700223 13.3002C0.792805 13.3928 0.902716 13.4662 1.02368 13.5163C1.14464 13.5665 1.27429 13.5922 1.40522 13.5922C1.53615 13.5922 1.6658 13.5665 1.78677 13.5163C1.90773 13.4662 2.01764 13.3928 2.11022 13.3002L7.00022 8.41022L11.8902 13.3002C11.9828 13.3928 12.0927 13.4662 12.2137 13.5163C12.3346 13.5665 12.4643 13.5922 12.5952 13.5922C12.7262 13.5922 12.8558 13.5665 12.9768 13.5163C13.0977 13.4662 13.2076 13.3928 13.3002 13.3002C13.3928 13.2076 13.4662 13.0977 13.5163 12.9768C13.5665 12.8558 13.5922 12.7262 13.5922 12.5952C13.5922 12.4643 13.5665 12.3346 13.5163 12.2137C13.4662 12.0927 13.3928 11.9828 13.3002 11.8902L8.41022 7.00022L13.3002 2.11022C13.6802 1.73022 13.6802 1.09022 13.3002 0.710224Z"
                    fill="#E20000"
                  />
                </svg>
              )}
            </div>
            <div className="text-[black] flex items-center text-start w-1/5 font-[Gm] font-medium text-[16px]">
              {item?.display_payment ? (
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.68493 11.2C5.44493 11.2 5.22491 11.12 5.04491 10.94L1.06493 6.96004C0.704932 6.60004 0.704932 6.04004 1.06493 5.68004C1.42493 5.32004 1.98491 5.32004 2.34491 5.68004L5.70492 9.02003L13.6849 1.06004C14.0449 0.700037 14.6049 0.700037 14.9649 1.06004C15.3249 1.42004 15.3249 1.98004 14.9649 2.34004L6.34491 10.94C6.14491 11.12 5.92493 11.2 5.68493 11.2Z"
                    fill="#00B412"
                  />
                </svg>
              ) : (
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3002 0.710224C13.2077 0.61752 13.0978 0.543973 12.9768 0.493791C12.8559 0.44361 12.7262 0.417779 12.5952 0.417779C12.4643 0.417779 12.3346 0.44361 12.2136 0.493791C12.0926 0.543973 11.9827 0.61752 11.8902 0.710224L7.00022 5.59022L2.11022 0.700223C2.01764 0.607642 1.90773 0.534202 1.78677 0.484097C1.6658 0.433992 1.53615 0.408203 1.40522 0.408203C1.27429 0.408203 1.14464 0.433992 1.02368 0.484097C0.902716 0.534202 0.792805 0.607642 0.700223 0.700223C0.607642 0.792805 0.534202 0.902716 0.484097 1.02368C0.433992 1.14464 0.408203 1.27429 0.408203 1.40522C0.408203 1.53615 0.433992 1.6658 0.484097 1.78677C0.534202 1.90773 0.607642 2.01764 0.700223 2.11022L5.59022 7.00022L0.700223 11.8902C0.607642 11.9828 0.534202 12.0927 0.484097 12.2137C0.433992 12.3346 0.408203 12.4643 0.408203 12.5952C0.408203 12.7262 0.433992 12.8558 0.484097 12.9768C0.534202 13.0977 0.607642 13.2076 0.700223 13.3002C0.792805 13.3928 0.902716 13.4662 1.02368 13.5163C1.14464 13.5665 1.27429 13.5922 1.40522 13.5922C1.53615 13.5922 1.6658 13.5665 1.78677 13.5163C1.90773 13.4662 2.01764 13.3928 2.11022 13.3002L7.00022 8.41022L11.8902 13.3002C11.9828 13.3928 12.0927 13.4662 12.2137 13.5163C12.3346 13.5665 12.4643 13.5922 12.5952 13.5922C12.7262 13.5922 12.8558 13.5665 12.9768 13.5163C13.0977 13.4662 13.2076 13.3928 13.3002 13.3002C13.3928 13.2076 13.4662 13.0977 13.5163 12.9768C13.5665 12.8558 13.5922 12.7262 13.5922 12.5952C13.5922 12.4643 13.5665 12.3346 13.5163 12.2137C13.4662 12.0927 13.3928 11.9828 13.3002 11.8902L8.41022 7.00022L13.3002 2.11022C13.6802 1.73022 13.6802 1.09022 13.3002 0.710224Z"
                    fill="#E20000"
                  />
                </svg>
              )}
            </div>
            <div className="flex justify-start w-1/5">
              <div className="flex gap-[12px]">
                <div
                  className=""
                  onClick={() => {
                    setStageData(item);
                    setUserRole(
                      item?.assigned_to.map((x) => {
                        return {
                          value: x,
                          label: x,
                        };
                      })
                    );
                    setOpenStage(true);
                  }}
                >
                  <p className="edit_testimonial">Edit</p>
                </div>
                {/* <div
                  className="text-right px-0"
                  onClick={() => {
                    setDelID(item._id);
                    setOpenDel(true);
                  }}
                >
                  <p className="remove_testimonial">Delete</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Stage pop up starts */}
      <Dialog
        open={openStage}
        onClose={() => {
          setOpenStage(false);
          editProjectStatus.resetForm({});
          setUserRole([]);
          setErrorMsg("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "386px",
              minHeight: "262px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-between items-center">
            <p className="font-[Gm] font-semibold text-[18px] text-[#000000]">
              Edit Stage
            </p>
            <CloseRoundedIcon
              className="cursor-pointer"
              onClick={() => {
                setOpenStage(false);
                editProjectStatus.resetForm({});
                setUserRole([]);
                setErrorMsg("");
              }}
            />
          </div>
          <form onSubmit={editProjectStatus.handleSubmit}>
            <div className="mt-4">
              <div className="stageTop py-3 px-4">
                <div>
                  <label
                    htmlFor=""
                    className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                  >
                    Stage Name
                  </label>
                  <input
                    name="stage_name"
                    type="text"
                    placeholder="Enter Stage Name"
                    className="add_input mt-3 w-full"
                    defaultValue={stageData.stage_name}
                    onChange={editProjectStatus.handleChange}
                  />
                  {editProjectStatus.touched.stage_name &&
                    editProjectStatus.errors.stage_name && (
                      <p className="required_message">
                        {editProjectStatus.errors.stage_name}
                      </p>
                    )}
                </div>

                <div className="mt-3">
                  <p className="font-[Gm] font-medium text-[13px] text-[#221E1F]">
                    Features
                  </p>
                  <div className="flex gap-5 items-center mt-3 checkbox-wrapper">
                    <span>Payment</span>
                    <input
                      name="display_payment"
                      type="checkbox"
                      id="check1"
                      defaultChecked={stageData.display_payment}
                      onChange={editProjectStatus.handleChange}
                    />
                    <label htmlFor="check1" />
                    <span>Design Files</span>
                    <input
                      name="display_design"
                      type="checkbox"
                      id="check2"
                      defaultChecked={stageData.display_design}
                      // onChange={(e) => {
                      //   console.log(e.target.checked);
                      // }}
                      onChange={editProjectStatus.handleChange}
                    />
                    <label htmlFor="check2" />
                  </div>
                </div>
              </div>
              <div className="stageTop py-3 px-4 mt-3">
                <label
                  htmlFor=""
                  className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                >
                  Stage Assigned To
                </label>
                <div className="mt-3">
                  <MultiSelect
                    placeholder={"Edit Role"}
                    options={roleOptions}
                    value={userRole}
                    defaultValue={userRole}
                    handleChange={handleChangeUser}
                  />
                  <p>
                    {errorMsg && <p className="required_message">{errorMsg}</p>}
                  </p>
                </div>
              </div>
              <button
                type="submit"
                className="addstage_btn"
                onClick={() => {
                  validateStage();
                }}
              >
                {editStageLoader ? <SmallLoader color="" /> : "Save"}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      {/* Edit Stage pop up ends */}

      {/* delete pop starts */}
      {/* <Dialog
        open={openDel}
        onClose={() => {
          setOpenDel(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "404px",
              height: "265px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-center items-center">
            <img
              style={{
                width: "80px",
              }}
              src={DeleteIcon}
              alt=""
            />
          </div>
          <div className="deleteText mt-6">
            Are you sure you want to Delete this Stage?
          </div>
          <div className="flex justify-between items-center mt-9">
            <button
              className="cancelDelUser"
              onClick={() => {
                setOpenDel(false);
              }}
            >
              Cancel
            </button>
            <button
              className="delUser"
              onClick={() => {
                deleteStage();
              }}
            >
              {delLoader ? <SmallLoader color="" /> : "Delete"}
            </button>
          </div>
        </div>
      </Dialog> */}
      {/* delete pop ends */}
    </div>
  );
};

export default PreConstructionCard;
