import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";
import AllClients from "./AllClients";
import "./Config.css";

// const useStyles = makeStyles({
//   root: {
//     flexGrow: 1,
//     width: 100,
//     height: 40,
//   },
// });

const Configuration = () => {
  //   const classes = useStyles();
  const [value, setValue] = useState<string>("1");

  // theme for mui tab section
  const theme = createTheme({
    overrides: {
      MuiTab: {
        root: {
          textTransform: "capitalize",
          backgroundColor: "white",
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Gm",
          color: "#1d2939 !important",
          opacity: "9 !important",
          "&.MuiTab-textColorInherit.Mui-selected": {
            backgroundColor: "#F5F8FA",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Inter",
          },
          "&.MuiButtonBase-root:nth-child(1)": {
            border: "1px solid #D0D5DD",
            borderTopLeftRadius: "8px",
            minWidth: "85px",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Gsb",
          },
          "&.MuiButtonBase-root:nth-child(2)": {
            border: "1px solid #D0D5DD",
            minWidth: "64px",
            padding: "10px 0px",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Inter",
            borderRight: "none",
            borderBottom: "0.5px solid #ebeef1",
          },
          "&.MuiButtonBase-root:nth-child(3)": {
            border: "1px solid #D0D5DD",
            minWidth: "64px",
            padding: "10px 0px",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Inter",
            borderBottom: "0.5px solid #ebeef1",
          },
          "&.MuiButtonBase-root:nth-child(4)": {
            border: "1px solid #D0D5DD",
            borderLeft: "none",
            borderTopRightRadius: "8px",
            minWidth: "123px",
            padding: "10px 0px",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Inter",
            borderBottom: "0.5px solid #ebeef1",
          },
        },
      },
    },
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="my-[30px] mr-[20px] bg-white">
      <div className="tabcolor">
        <div className="mt-5 bg-white">
          <Box>
            <ThemeProvider theme={theme}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                  >
                    <Tab className="con_tab" label="All Clients" value="1" />
                  </TabList>
                </Box>
                <TabPanel
                  className="min-h-[650px] p-10 border-[1px] border-solid border-[#D0D5DD] bg-[#F5F8FA]"
                  value="1"
                >
                  <AllClients />
                </TabPanel>
              </TabContext>
            </ThemeProvider>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Configuration;
