import React, { useEffect, useState } from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { CircularProgress, Dialog, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import MultiSelect from "../../../../../../Tools/MultiSelect";
import Dropzone from "react-dropzone";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosClientInterceptors from "../../../../../../ApiConfig/axiosClientInterceptor";
import {
  userlisting,
  dprtListing,
  meetingType,
  meetingtabData,
  deletdoc,
  FormValuesMarkmeetingCompleted,
  FormValuesMarkmeetingcancle,
} from "../../../../../../../Types";
import { selectClientID } from "../../../../../../Features/AllClients/AllClients";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import moment from "moment";
import LensIcon from "@mui/icons-material/Lens";
import { DateRangePicker } from "rsuite";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { Toaster } from "../../../../../../Tools/Toaster";
import nodata from "../../../../../Assets/nodata.jpg";

import {
  addMonths,
  addYears,
  endOfDay,
  startOfDay,
  subMonths,
  subYears,
} from "date-fns";

interface FormValues {
  MeetingTitle: string;
  Department: string;
  MainAttendee: string;
  Mode: string;
  MDate: string;
  StartTime: string;
  Endtime: string;
  ReminderDate: string;
  Reminder_time: string;
  MeetingLink: string;
  location?: string;
  Description: string;
  MeetingType: string;
}

const today = new Date();
today.setHours(0, 0, 0, 0);

const fileNameSplit = (str: any) => {
  if (str) {
    return str.split("/").at(-1).split("-").slice(1).join("-");
  }
};

const Meetings = ({ siglestageData }: any) => {
  const clientID = useSelector(selectClientID);

  const [CreateMeetingopen, setCreateMeetingopen] = useState<boolean>(false);
  const [EditMeetingopen, setEditMeetingopen] = useState<boolean>(false);
  const [uploadFileOpen, setuploadFileOpen] = useState<boolean>(false);
  const [MeetingOutcomeopen, setMeetingOutcomeopen] = useState<boolean>(false);
  const [CancelMeetingpopup, setCancelMeetingpopup] = useState<boolean>(false);
  const [Viewpopup, setViewpopup] = useState<boolean>(false);
  const [AllUsers, setAllUsers] = useState<userlisting[]>([]);
  const [AddParticipants, setAddParticipants] = useState<any>([]);

  const [departmentlisting, setdepartmentlisting] = useState<dprtListing[]>([]);
  const [meetingType, setmeetingType] = useState<meetingType[]>([]);
  const [AllmeetingData, setAllmeetingData] = useState<meetingtabData[]>([]);
  const [EditMeeting, setEditMeeting] = useState<any>({});
  const [EditParticipants, setEditParticipants] = useState<any>([]);
  const [Bluckfile, setBluckfile] = useState<File[]>([]);
  const [MeetingTypeFilter, setMeetingTypeFilter] = useState<any>([]);
  const [meetingDateRange, setmeetingDateRange] = useState<Date[]>([]);
  const [meetingStartDate, setmeetingStartDate] = useState<string>("");
  const [meetingEndDate, setmeetingEndDate] = useState<string>("");
  const [search, setsearch] = useState<string>("");
  const [selectedSlot, setselectedSlot] = useState<any>("");
  const [slot, setslot] = useState<any>([]);
  const [Loader, setLoader] = useState<any>({ docsLoader: false });
  const [attendeeData, setattendeeData] = useState<any>([]);

  const validationSchema = yup.object({
    MeetingTitle: yup.string().required("MeetingTitle is required"),
    Department: yup.string().required("Department is required"),
    MainAttendee: yup.string().required("MainAttendee is required"),
    Mode: yup.string().required("meeting mode is required"),
    MDate: yup
      .date()
      .min(today, `Date must be from ${new Date().toDateString()}`)
      .required("Date is required"),
    StartTime: yup
      .string()
      .required("Meeting start time is required")
      .test(
        "valid-time",
        "Invalid time format. Please use HH:mm format.",
        (value) => {
          if (!value) return true; // Allow empty value if not required
          const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // HH:mm format regex
          return timeRegex.test(value);
        }
      )
      .test(
        "within-time-slot",
        "Start time must be within the selected time slot",
        function (value) {
          const { Endtime } = this.parent;
          const startMoment = moment(value, "HH:mm");
          const endMoment = moment(Endtime, "HH:mm");
          const slotStartMoment = moment(
            selectedSlot?.split("-")[0]?.trim(),
            "h:mm A"
          );
          const slotEndMoment = moment(
            selectedSlot?.split("-")[1]?.trim(),
            "h:mm A"
          );

          return (
            startMoment.isSameOrAfter(slotStartMoment) &&
            startMoment.isBefore(slotEndMoment) &&
            startMoment.isBefore(endMoment)
          ); // Ensure start time is before end time
        }
      ),
    Endtime: yup
      .string()
      .required("Meeting end time is required")
      .test(
        "valid-time",
        "Invalid time format. Please use HH:mm format.",
        (value) => {
          if (!value) return true;
          const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
          return timeRegex.test(value);
        }
      )
      .test(
        "within-time-slot",
        "End time must be within the selected time slot",
        function (value) {
          const { StartTime } = this.parent;
          const startMoment = moment(StartTime, "HH:mm");
          const endMoment = moment(value, "HH:mm");
          const slotStartMoment = moment(
            selectedSlot?.split("-")[0]?.trim(),
            "h:mm A"
          );
          const slotEndMoment = moment(
            selectedSlot?.split("-")[1]?.trim(),
            "h:mm A"
          );

          return (
            endMoment.isSameOrAfter(slotStartMoment) &&
            endMoment.isBefore(slotEndMoment) &&
            endMoment.isAfter(startMoment)
          ); // Ensure end time is after start time
        }
      ),
    MeetingLink: yup
      .string()

      .test(
        "meetingLinkCondition",
        "Meeting Link is required ",
        function (value) {
          const { Mode } = this.parent;
          if (Mode && Mode === "online") {
            return yup.string().required("required").isValidSync(value);
          }
          return true;
        }
      ),
    location: yup
      .string()
      .test("locationCondition", "Location is required", function (value) {
        const { Mode } = this.parent;
        if (Mode && Mode === "Ofline") {
          return yup.string().required("required").isValidSync(value);
        }
        return true;
      }),
    Description: yup.string().required("Description is required"),
    ReminderDate: yup
      .date()
      .required("ReminderDate is required")
      .test(
        "is-between",
        "ReminderDate should be between the current date and the Meeting Scheduled date",
        function (value) {
          const { MDate } = this.parent;
          const now = new Date();
          const currentDate = new Date(now.setHours(0, 0, 0, 0)); // Ensure currentDate includes today
          return value >= currentDate && value <= new Date(MDate);
        }
      ),
    Reminder_time: yup
      .string()
      // .required("Reminder Time is required")
      .test(
        "is-valid-reminder-time",
        "Reminder end time cannot be past start time",
        function (val) {
          const { meeting_start_time, meeting_date, reminder_date } =
            this.parent;

          // Convert dates to moment objects for easier comparison
          const momentMeetingDate = moment(meeting_date, "YYYY-MM-DD");
          const momentReminderDate = moment(reminder_date, "YYYY-MM-DD");

          // If meeting_date and reminder_date are the same
          if (momentMeetingDate.isSame(momentReminderDate)) {
            const momentMeetingStartTime = moment(meeting_start_time, "HH:mm");
            const momentReminderTime = moment(val, "HH:mm");

            // Ensure the reminder_time is not past the meeting_start_time
            return momentReminderTime.isSameOrBefore(momentMeetingStartTime);
          } else {
            // If meeting_date and reminder_date are not the same, allow any time
            return true;
          }
        }
      ),
    MeetingType: yup.string().required("MeetingType is required"),
  });

  // accepting file from dropzone and storing it to state
  const handleDrop = (acceptedFiles: any) => {
    setBluckfile((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleChangeMeetingType = (data: any) => {
    setMeetingTypeFilter(data);
  };
  const deletFile = (i: any) => {
    let filesone = [...Bluckfile];
    filesone.splice(i, 1);
    setBluckfile(filesone);
  };

  const handleChangeUser = (data: any) => {
    setAddParticipants(data);
    console.log(data);
  };
  const handleChangeUserEdit = (data: any) => {
    setEditParticipants(data);
  };

  const handlestartDateChange = (date: [Date, Date]) => {
    if (date) {
      setmeetingDateRange(date);
      const [startDate, endDate] = date;
      setmeetingStartDate(moment(startDate).format("YYYY-MM-DD"));
      setmeetingEndDate(moment(endDate).format("YYYY-MM-DD"));
    }
  };

  const getmeetingListing = () => {
    // &search=${search}
    axiosClientInterceptors
      .post(`/clients/stages/meetings/get-meetings`, {
        client_id: clientID,
        stage_id: siglestageData._id,
        section_name: "Pre-Construction",

        meeting_type: MeetingTypeFilter?.map((value: any) => value.value),
        due_from: meetingStartDate,
        due_to: meetingEndDate,
      })
      .then((response) => {
        const result = response.data.result;
        setAllmeetingData(result);
        console.log("response", result);
      })
      .catch((errr) => {
        console.log(errr);
      });
  };

  // add meeeting
  const formik = useFormik<FormValues>({
    initialValues: {
      MeetingTitle: "",
      Department: "",
      MainAttendee: "",
      Mode: "",
      MDate: "",
      StartTime: "",
      Endtime: "",
      MeetingLink: "",
      location: "",
      Description: "",
      ReminderDate: "",
      Reminder_time: "",
      MeetingType: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      axiosClientInterceptors
        .post(`/clients/stages/meetings/`, {
          client_id: clientID,
          stage_id: siglestageData._id,
          meeting_type: values.MeetingType,
          assigned_to: values.MainAttendee,
          section_name: "Pre-Construction",
          meeting_title: values.MeetingTitle,
          assigned_department: values.Department,
          meeting_date: values.MDate,
          meeting_start_time: values.StartTime,
          meeting_location: values.location,
          meeting_end_time: values.Endtime,
          reminder_date: values.ReminderDate,
          reminder_time: values.Reminder_time,
          attendees: AddParticipants.map((value: any) => value.value),
          meeting_mode: values.Mode,
          meeting_link: values.MeetingLink,
          meeting_description: values.Description,
        })
        .then((res) => {
          console.log(res);
          getmeetingListing();
          setCreateMeetingopen(false);
          formik.resetForm({});
          setslot([]);
          setselectedSlot("");
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(values);
    },
  });
  // Edit meeting

  const formikEdit = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      MeetingTitle: EditMeeting.meeting_title,
      Department: EditMeeting.assigned_department,
      MainAttendee: EditMeeting.assigned_to?._id,
      Mode: EditMeeting.meeting_mode,
      MDate: moment(EditMeeting.meeting_date).format("YYYY-MM-DD"),
      StartTime: EditMeeting.meeting_start_time,
      Endtime: EditMeeting.meeting_end_time,
      MeetingLink: EditMeeting.meeting_link,
      location: EditMeeting.meeting_location,
      Description: EditMeeting.meeting_description,
      ReminderDate: moment(EditMeeting.reminder_date).format("YYYY-MM-DD"),
      Reminder_time: EditMeeting.reminder_time,
      MeetingType: EditMeeting?.meeting_type?._id,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      axiosClientInterceptors
        .put(`/clients/stages/meetings/?meet_id=${EditMeeting._id}`, {
          client_id: clientID,
          stage_id: siglestageData._id,
          meeting_type: values.MeetingType,
          assigned_to: values.MainAttendee,
          section_name: "Pre-Construction",
          meeting_title: values.MeetingTitle,
          assigned_department: values.Department,
          meeting_date: values.MDate,
          meeting_start_time: values.StartTime,
          meeting_end_time: values.Endtime,
          reminder_date: values.ReminderDate,
          reminder_time: values.Reminder_time,
          attendees: EditParticipants.map((value: any) => value.value),
          meeting_mode: values.Mode,
          meeting_link: values.MeetingLink,
          meeting_description: values.Description,
        })
        .then((res) => {
          console.log(res);
          getmeetingListing();
          setEditMeetingopen(false);
          formikEdit.resetForm({});
          setslot([]);
          setselectedSlot("");
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(values);
    },
  });

  // meeting Done validation
  const formikMarkCompleted = useFormik<FormValuesMarkmeetingCompleted>({
    initialValues: {
      meeting_outcome: "",
    },
    validationSchema: yup.object({
      meeting_outcome: yup.string().required("meeting_outcome is required"),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      axiosClientInterceptors
        .put(`/clients/stages/meetings/?meet_id=${EditMeeting._id}`, {
          meeting_done: true,
          meeting_outcome: values.meeting_outcome,
          stage_id: siglestageData._id,
        })
        .then((res) => {
          getmeetingListing();
          setMeetingOutcomeopen(false);
          formikMarkCompleted.resetForm({});
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  // cancel meeting
  const formikMarkCancle = useFormik<FormValuesMarkmeetingcancle>({
    initialValues: {
      cancellation_reason: "",
    },
    validationSchema: yup.object({
      cancellation_reason: yup
        .string()
        .required("cancellation_reason is required"),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      axiosClientInterceptors
        .put(`/clients/stages/meetings/?meet_id=${EditMeeting._id}`, {
          cancelled: true,
          cancellation_reason: values.cancellation_reason,
          stage_id: siglestageData._id,
        })
        .then((res) => {
          getmeetingListing();
          setMeetingOutcomeopen(false);
          setCancelMeetingpopup(false);
          formikMarkCancle.resetForm({});
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  const getUsers = () => {
    axiosClientInterceptors
      .get(`/general/users/getallusers`)
      .then((res) => {
        // setAllocationType(res.data?.result);
        setAllUsers(res.data?.result);
        console.log("resresresmeeting", res.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getattendee = () => {
    axiosClientInterceptors
      .get(`/general/users/users-by-dept?dept=${formik.values.Department}`)
      .then((res) => {
        const result = res.data.result;
        console.log("resultDAta", result);
        setattendeeData(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getattendee();
  }, [formik.values.Department]);

  const getdepartment = () => {
    axiosClientInterceptors
      .get(`/general/users/dept`)
      .then((res) => {
        const result = res.data.result;
        setdepartmentlisting(result);
        console.log("dpt", result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectStatus = () => {
    axiosClientInterceptors
      .get(`/general/config?heading=Meeting Type`)
      .then((res) => {
        // setProjectStatus(res.data?.result);
        const result = res.data?.result;
        setmeetingType(result);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const DeletDocz = (doc: string) => {
    axiosClientInterceptors
      .delete(
        `/clients/stages/meetings/?meet_id=${EditMeeting?._id}&doc=${doc}`
      )
      .then((res) => {
        console.log(res);
        getmeetingListing();
        setViewpopup(false);
        Toaster("success", "Document Deleted Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // upload Doc
  const UploadDocs = () => {
    setLoader({ docsLoader: true });
    const formdata = new FormData();
    Bluckfile.map((value) => formdata.append("docs", value));

    const headers = {
      "content-type": "multipart/form-data",
    };
    axiosClientInterceptors
      .patch(`/clients/stages/meetings/?meet_id=${EditMeeting._id}`, formdata, {
        headers,
      })

      .then((res) => {
        console.log(res);

        setuploadFileOpen(false);
        setLoader({ docsLoader: false });

        setBluckfile([]);
        getmeetingListing();
      })
      .catch((err) => {
        setLoader({ docsLoader: false });
        Toaster("error", err.response.data.message);
        console.log(err.response.data.message);
      });
  };

  const getmeetingSlot = () => {
    axiosClientInterceptors
      .post(`/clients/stages/meetings/time-slot`, {
        user_id: formik.values.MainAttendee,
        meeting_date: formik.values.MDate,
      })
      .then((res) => {
        const result = res.data?.result;
        console.log("result", result);

        setslot(result);
        setselectedSlot("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getmeetingSlotEdit = () => {
    axiosClientInterceptors
      .post(`/clients/stages/meetings/time-slot`, {
        user_id: formikEdit.values.MainAttendee,
        meeting_date: formikEdit.values.MDate,
      })
      .then((res) => {
        const result = res.data?.result;
        console.log("result", result);

        setslot(result);
        setselectedSlot("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getmeetingSlot();
  }, [formik.values.MainAttendee, formik.values.MDate]);
  useEffect(() => {
    getmeetingSlotEdit();
  }, [formikEdit.values.MainAttendee, formikEdit.values.MDate]);

  useEffect(() => {
    getdepartment();
    getUsers();
    getProjectStatus();
  }, []);

  useEffect(() => {
    getmeetingListing();
  }, [MeetingTypeFilter, meetingDateRange, search]);
  return (
    <>
      {" "}
      {/* add .meeting */}
      <Dialog
        open={CreateMeetingopen}
        onClose={() => {
          setCreateMeetingopen(false);
          setslot([]);
          setselectedSlot("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "671px",
              //   height: "246px",
            },
          },
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          style={{ borderTop: "7px solid  #FCC200" }}
          className="p-4"
        >
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Schedule Meeting
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCreateMeetingopen(false);
                setslot([]);
              }}
            />
          </div>
          <div className="mt-3">
            <p className="Stage__FormTittle">Meeting Title </p>
            <input
              maxLength={30}
              value={formik.values.MeetingTitle}
              onChange={formik.handleChange}
              type="text"
              name="MeetingTitle"
              placeholder="Enter Meeting Title"
              id=""
              className="ProjectStages___Stage__input w-full mt-2"
            />
            {formik.touched.MeetingTitle && formik.errors.MeetingTitle && (
              <p className="required_message">{formik.errors.MeetingTitle}</p>
            )}
            {/* <select
              name="cars"
              id="cars"
              className="ProjectStages___Stage__input w-full mt-2"
            >
              <option value="volvo">Enter Details</option>
              <option value="saab">Saab</option>
              <option value="mercedes">Mercedes</option>
              <option value="audi">Audi</option>
            </select> */}
          </div>
          <div className="grid grid-cols-3 gap-4 mt-3">
            <div>
              <p className="Stage__FormTittle">Department</p>
              <select
                value={formik.values.Department}
                onChange={formik.handleChange}
                name="Department"
                id="Department"
                className="ProjectStages___Stage__input w-full mt-2"
              >
                <option value="" selected disabled>
                  Select Department
                </option>
                {departmentlisting?.map((value: dprtListing) => (
                  <option value={value.department}>{value.department}</option>
                ))}
              </select>
              {formik.touched.Department && formik.errors.Department && (
                <p className="required_message">{formik.errors.Department}</p>
              )}
            </div>
            <div>
              <p className="Stage__FormTittle">Meeting Type</p>
              <select
                value={formik.values.MeetingType}
                onChange={formik.handleChange}
                name="MeetingType"
                id="MeetingType"
                className="ProjectStages___Stage__input w-full mt-2"
              >
                <option value="" selected disabled>
                  Select Meeting Type
                </option>
                {meetingType?.map((value: meetingType) => (
                  <option value={value._id}>{value.section_name}</option>
                ))}
              </select>
              {formik.touched.MeetingType && formik.errors.MeetingType && (
                <p className="required_message">{formik.errors.MeetingType}</p>
              )}
            </div>
            <div>
              <p className="Stage__FormTittle">Main Attendee</p>
              <select
                value={formik.values.MainAttendee}
                onChange={formik.handleChange}
                name="MainAttendee"
                id="MainAttendee"
                className="ProjectStages___Stage__input w-full mt-2"
              >
                <option value="" selected disabled>
                  Select Main Attendee
                </option>
                {attendeeData?.map((value: any) => (
                  <option
                    value={value._id}
                  >{`${value.fullname}(${value.role})`}</option>
                ))}
              </select>
              {formik.touched.MainAttendee && formik.errors.MainAttendee && (
                <p className="required_message">{formik.errors.MainAttendee}</p>
              )}
            </div>
            <div>
              <p className="Stage__FormTittle">Date </p>
              <input
                value={formik.values.MDate}
                onChange={formik.handleChange}
                type="date"
                name="MDate"
                id="Date"
                min={moment().format("YYYY-MM-DD")}
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formik.touched.MDate && formik.errors.MDate && (
                <p className="required_message">{formik.errors.MDate}</p>
              )}{" "}
            </div>
            <div className=" col-span-2">
              <p className="Stage__FormTittle">Available Time Slots</p>
              <select
                name=""
                className="ProjectStages___Stage__input w-full mt-2"
                value={selectedSlot}
                onChange={(e) => {
                  setselectedSlot(e.target.value);
                }}
                // value={formik.values.slot}
                // onChange={formik.handleChange}
              >
                <option
                  value=""
                  disabled
                  selected
                  className="BDE_dropdown_option"
                >
                  Time Slots
                </option>
                {slot?.map((val: any) => (
                  <option className="BDE_dropdown_option" value={val}>
                    {val}
                  </option>
                ))}
              </select>
              {/* <div className="timeSlot"></div> */}
            </div>

            <div>
              <p className="Stage__FormTittle">Start Time</p>
              <input
                value={formik.values.StartTime}
                onChange={formik.handleChange}
                type="time"
                name="StartTime"
                id="StartTime"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formik.touched.StartTime && formik.errors.StartTime && (
                <p className="required_message">{formik.errors.StartTime}</p>
              )}
            </div>
            <div>
              <p className="Stage__FormTittle">End time</p>
              <input
                value={formik.values.Endtime}
                onChange={formik.handleChange}
                type="time"
                name="Endtime"
                id="Endtime"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formik.touched.Endtime && formik.errors.Endtime && (
                <p className="required_message">{formik.errors.Endtime}</p>
              )}
            </div>
            <div>
              <p className="Stage__FormTittle">Mode</p>
              <select
                value={formik.values.Mode}
                onChange={formik.handleChange}
                name="Mode"
                id="Mode"
                className="ProjectStages___Stage__input w-full mt-2"
              >
                <option value="" selected disabled>
                  Select Mode
                </option>
                <option value="Online">Online</option>
                <option value="Ofline">Ofline</option>
              </select>
              {formik.touched.Mode && formik.errors.Mode && (
                <p className="required_message">{formik.errors.Mode}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-3">
            <div>
              <p className="Stage__FormTittle">Reminder Date </p>
              <input
                value={formik.values.ReminderDate}
                onChange={formik.handleChange}
                type="date"
                name="ReminderDate"
                id="ReminderDate"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formik.touched.ReminderDate && formik.errors.ReminderDate && (
                <p className="required_message">{formik.errors.ReminderDate}</p>
              )}
            </div>
            <div>
              <p className="Stage__FormTittle">Reminder time</p>
              <input
                value={formik.values.Reminder_time}
                onChange={formik.handleChange}
                type="time"
                name="Reminder_time"
                id="Reminder_time"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formik.touched.Reminder_time && formik.errors.Reminder_time && (
                <p className="required_message">
                  {formik.errors.Reminder_time}
                </p>
              )}
            </div>
            <div>
              <p
                className="Stage__FormTittle"
                onClick={() => {
                  console.log(AddParticipants);
                }}
              >
                Add Participants
              </p>
              <div className="mt-2">
                <MultiSelect
                  placeholder={"Choose User"}
                  options={AllUsers?.map((value: any) => {
                    return {
                      fullname: value.fullname,
                      role: value.role,
                      value: value._id,
                      label: `${value.fullname}(${value.role})`,
                    };
                  })}
                  value={AddParticipants}
                  defaultValue={AddParticipants}
                  handleChange={handleChangeUser}
                />
              </div>
            </div>

            {formik.values.Mode === "Online" ? (
              <div>
                <p className="Stage__FormTittle">Meeting Link</p>
                <input
                  value={formik.values.MeetingLink}
                  onChange={formik.handleChange}
                  placeholder="Enter Meeting Link"
                  type="text"
                  name="MeetingLink"
                  id="MeetingLink"
                  className="ProjectStages___Stage__input w-full mt-2"
                />
                {formik.touched.MeetingLink && formik.errors.MeetingLink && (
                  <p className="required_message">
                    {formik.errors.MeetingLink}
                  </p>
                )}
              </div>
            ) : (
              <div>
                <p className="Stage__FormTittle">Meeting Location</p>
                <input
                  className="ProjectStages___Stage__input w-full mt-2"
                  type="text"
                  name="location"
                  placeholder="Enter Meeting Location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                />
                {formik.errors.location && formik.touched.location && (
                  <p className="required_message">{formik.errors.location}</p>
                )}
              </div>
            )}

            <div className=" col-span-2">
              <p className="Stage__FormTittle">Description (if any)</p>
              <textarea
                maxLength={300}
                value={formik.values.Description}
                onChange={formik.handleChange}
                name="Description"
                id="Description"
                placeholder="Eg.xyz"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formik.touched.Description && formik.errors.Description && (
                <p className="required_message">{formik.errors.Description}</p>
              )}
            </div>
          </div>
          <button className="ProjectStages__activateButton mt-3" type="submit">
            Schedule Meeting
          </button>
        </form>
      </Dialog>
      {/* edit meeting */}
      <Dialog
        open={EditMeetingopen}
        onClose={() => {
          setEditMeetingopen(false);
          setslot([]);
          setselectedSlot("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "671px",
              //   height: "246px",
            },
          },
        }}
      >
        <form
          onSubmit={formikEdit.handleSubmit}
          style={{ borderTop: "7px solid  #FCC200" }}
          className="p-4"
        >
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">Edit Meeting</p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditMeetingopen(false);
                setslot([]);
                setselectedSlot("");
              }}
            />
          </div>
          <div className="mt-3">
            <p className="Stage__FormTittle">Meeting Title</p>
            <input
              maxLength={30}
              value={formikEdit.values.MeetingTitle}
              onChange={formikEdit.handleChange}
              type="text"
              name="MeetingTitle"
              placeholder="Enter Meeting Title"
              id=""
              className="ProjectStages___Stage__input w-full mt-2"
            />
            {formikEdit.touched.MeetingTitle &&
              formikEdit.errors.MeetingTitle && (
                <p className="required_message">
                  {formikEdit.errors.MeetingTitle}
                </p>
              )}
            {/* <select
              name="cars"
              id="cars"
              className="ProjectStages___Stage__input w-full mt-2"
            >
              <option value="volvo">Enter Details</option>
              <option value="saab">Saab</option>
              <option value="mercedes">Mercedes</option>
              <option value="audi">Audi</option>
            </select> */}
          </div>
          <div className="grid grid-cols-3 gap-4 mt-3">
            <div>
              <p className="Stage__FormTittle">Department</p>
              <select
                value={formikEdit.values.Department}
                onChange={formikEdit.handleChange}
                name="Department"
                id="Department"
                className="ProjectStages___Stage__input w-full mt-2"
              >
                <option value="" selected disabled>
                  Select Department
                </option>
                {departmentlisting?.map((value: dprtListing) => (
                  <option value={value.department}>{value.department}</option>
                ))}
              </select>
              {formikEdit.touched.Department &&
                formikEdit.errors.Department && (
                  <p className="required_message">
                    {formikEdit.errors.Department}
                  </p>
                )}
            </div>
            <div>
              <p className="Stage__FormTittle">Meeting Type</p>
              <select
                value={formikEdit.values.MeetingType}
                onChange={formikEdit.handleChange}
                name="MeetingType"
                id="MeetingType"
                className="ProjectStages___Stage__input w-full mt-2"
              >
                <option value="" selected disabled>
                  Select Meeting Type
                </option>
                {meetingType?.map((value: meetingType) => (
                  <option value={value._id}>{value.section_name}</option>
                ))}
              </select>
              {formikEdit.touched.MeetingType &&
                formikEdit.errors.MeetingType && (
                  <p className="required_message">
                    {formikEdit.errors.MeetingType}
                  </p>
                )}
            </div>
            <div>
              <p className="Stage__FormTittle">Main Attendee</p>
              <select
                value={formikEdit.values.MainAttendee}
                onChange={formikEdit.handleChange}
                name="MainAttendee"
                id="MainAttendee"
                className="ProjectStages___Stage__input w-full mt-2"
              >
                <option value="" selected disabled>
                  Select Main Attendee
                </option>
                {attendeeData?.map((value: any) => (
                  <option
                    value={value._id}
                  >{`${value.fullname}(${value.role})`}</option>
                ))}
              </select>
            </div>
            <div>
              <p className="Stage__FormTittle">Date</p>
              <input
                value={formikEdit.values.MDate}
                onChange={formikEdit.handleChange}
                type="date"
                name="MDate"
                id="Date"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formikEdit.touched.MDate && formikEdit.errors.MDate && (
                <p className="required_message">{formikEdit.errors.MDate}</p>
              )}{" "}
            </div>
            <div className=" col-span-2">
              <p className="Stage__FormTittle">Available Time Slots</p>
              <select
                name=""
                className="ProjectStages___Stage__input w-full mt-2"
                value={selectedSlot}
                onChange={(e) => {
                  setselectedSlot(e.target.value);
                }}
                // value={formik.values.slot}
                // onChange={formik.handleChange}
              >
                <option
                  value=""
                  disabled
                  selected
                  className="BDE_dropdown_option"
                >
                  Time Slots
                </option>
                {slot?.map((val: any) => (
                  <option className="BDE_dropdown_option" value={val}>
                    {val}
                  </option>
                ))}
              </select>
              {/* <div className="timeSlot"></div> */}
            </div>

            <div>
              <p className="Stage__FormTittle">Start Time</p>
              <input
                value={formikEdit.values.StartTime}
                onChange={formikEdit.handleChange}
                type="time"
                name="StartTime"
                id="StartTime"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formikEdit.touched.StartTime && formikEdit.errors.StartTime && (
                <p className="required_message">
                  {formikEdit.errors.StartTime}
                </p>
              )}
            </div>
            <div>
              <p className="Stage__FormTittle">End time</p>
              <input
                value={formikEdit.values.Endtime}
                onChange={formikEdit.handleChange}
                type="time"
                name="Endtime"
                id="Endtime"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formikEdit.touched.Endtime && formikEdit.errors.Endtime && (
                <p className="required_message">{formikEdit.errors.Endtime}</p>
              )}
            </div>
            {/* ==========================================>> */}
            <div>
              <p className="Stage__FormTittle">Mode</p>
              <select
                value={formikEdit.values.Mode}
                onChange={formikEdit.handleChange}
                name="Mode"
                id="Mode"
                className="ProjectStages___Stage__input w-full mt-2"
              >
                <option value="" selected disabled>
                  Select Mode
                </option>
                <option value="Online">Online</option>
                <option value="Ofline">Ofline</option>
              </select>
              {formikEdit.touched.Mode && formikEdit.errors.Mode && (
                <p className="required_message">{formikEdit.errors.Mode}</p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-3">
            <div>
              <p className="Stage__FormTittle">Reminder Date </p>
              <input
                value={formikEdit.values.ReminderDate}
                onChange={formikEdit.handleChange}
                type="date"
                name="ReminderDate"
                id="ReminderDate"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formikEdit.touched.ReminderDate &&
                formikEdit.errors.ReminderDate && (
                  <p className="required_message">
                    {formikEdit.errors.ReminderDate}
                  </p>
                )}
            </div>
            <div>
              <p className="Stage__FormTittle">Reminder time</p>
              <input
                value={formikEdit.values.Reminder_time}
                onChange={formikEdit.handleChange}
                type="time"
                name="Reminder_time"
                id="Reminder_time"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formikEdit.touched.Reminder_time &&
                formikEdit.errors.Reminder_time && (
                  <p className="required_message">
                    {formikEdit.errors.Reminder_time}
                  </p>
                )}
            </div>
            <div>
              <p
                className="Stage__FormTittle"
                onClick={() => {
                  console.log(EditParticipants);
                }}
              >
                Add Participants
              </p>
              <div className="mt-2">
                <MultiSelect
                  placeholder={"Choose User"}
                  options={AllUsers?.map((value: any) => {
                    return {
                      fullname: value.fullname,
                      role: value.role,
                      value: value._id,
                      label: `${value.fullname}(${value.role})`,
                    };
                  })}
                  value={EditParticipants}
                  defaultValue={EditParticipants}
                  handleChange={handleChangeUserEdit}
                />
              </div>
            </div>

            {formik.values.Mode === "Online" ? (
              <div>
                <p className="Stage__FormTittle">Meeting Link</p>
                <input
                  value={formikEdit.values.MeetingLink}
                  onChange={formikEdit.handleChange}
                  placeholder="Enter Meeting Link"
                  type="text"
                  name="MeetingLink"
                  id="MeetingLink"
                  className="ProjectStages___Stage__input w-full mt-2"
                />
                {formikEdit.touched.MeetingLink &&
                  formikEdit.errors.MeetingLink && (
                    <p className="required_message">
                      {formikEdit.errors.MeetingLink}
                    </p>
                  )}
              </div>
            ) : (
              <div>
                <p className="Stage__FormTittle">Meeting Location</p>
                <input
                  className="ProjectStages___Stage__input w-full mt-2"
                  type="text"
                  name="location"
                  placeholder="Enter  Meeting Location"
                  value={formikEdit.values.location}
                  onChange={formikEdit.handleChange}
                />
                {formikEdit.touched.location && formikEdit.errors.location && (
                  <p className="required_message">
                    {formikEdit.errors.location}
                  </p>
                )}
              </div>
            )}

            <div className=" col-span-2">
              <p className="Stage__FormTittle">Description (if any)</p>
              <textarea
                value={formikEdit.values.Description}
                onChange={formikEdit.handleChange}
                name="Description"
                id="Description"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formikEdit.touched.Description &&
                formikEdit.errors.Description && (
                  <p className="required_message">
                    {formikEdit.errors.Description}
                  </p>
                )}
            </div>
          </div>
          <button className="ProjectStages__activateButton mt-3" type="submit">
            Schedule Meeting
          </button>
        </form>
      </Dialog>
      {/* DropZone */}
      <Dialog
        open={uploadFileOpen}
        onClose={() => {
          setuploadFileOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Upload Document
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setuploadFileOpen(false);
              }}
            />
          </div>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <>
                {Bluckfile?.length > 0 ? (
                  <div className="listingfile" style={{ overflowX: "scroll" }}>
                    <ul style={{ padding: "10px" }}>
                      {Bluckfile?.map((value, i: any) => (
                        <div className="flex items-center">
                          <LensIcon
                            className="me-1"
                            style={{
                              color: " #000000",
                              width: "10px",
                            }}
                          />
                          <li className="fileName">
                            {value?.name ? value?.name : value?.name}
                          </li>
                          <CloseIcon
                            fontSize="small"
                            className="ms-1"
                            onClick={() => {
                              // e.stopPropagation();
                              console.log(value);

                              deletFile(i);
                            }}
                            style={{ color: " #000000" }}
                          />
                        </div>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <div>
                      <div className="mb-3 flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="71"
                          height="51"
                          viewBox="0 0 71 51"
                          fill="none"
                        >
                          <path
                            d="M4.49219 5.3924C4.49219 2.41426 6.90645 0 9.88459 0H20.4238C21.1783 0 21.9293 0.158376 22.6154 0.472263C29.6513 3.69111 31.3036 5.38607 38.6491 6.66482C38.9417 6.71576 39.2427 6.74051 39.5397 6.74051H60.2137C63.1918 6.74051 65.6061 9.15477 65.6061 12.1329V27.8608C65.6061 30.8389 63.1919 33.2532 60.2137 33.2532H9.8846C6.90646 33.2532 4.49219 30.8389 4.49219 27.8608V5.3924Z"
                            fill="#FCC200"
                          />
                          <path
                            d="M0.732739 24.0042C0.346006 21.297 2.44663 18.875 5.18125 18.875H65.9635C68.6474 18.875 70.7329 21.2124 70.4281 23.8789L67.8603 46.3473C67.6009 48.6169 65.6801 50.3307 63.3957 50.3307H8.39101C6.15473 50.3307 4.25876 48.6863 3.9425 46.4725L0.732739 24.0042Z"
                            fill="url(#paint0_linear_1010_81020)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_1010_81020"
                              x1="35.5"
                              y1="11.0111"
                              x2="41.6543"
                              y2="54.5155"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#FFE185" />
                              <stop offset="1" stop-color="#F7BA00" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <input name="fileNames" {...getInputProps()} />
                      <p className="vendor_bulk_upload_text">
                        Drag & Drop Your File here <br /> or
                      </p>
                    </div>
                    <div>
                      <button className="browse_bulk_vendor">
                        Browse Files
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Dropzone>

          {Loader.docsLoader ? (
            <div className="w-full flex justify-center items-center">
              <CircularProgress color="inherit" size={20} />
            </div>
          ) : (
            <button
              className="ProjectStages__activateButton mt-1"
              onClick={() => {
                UploadDocs();
              }}
            >
              Upload Document
            </button>
          )}
        </div>
      </Dialog>
      {/*Meeting Outcome pop */}
      <Dialog
        open={MeetingOutcomeopen}
        onClose={() => {
          setMeetingOutcomeopen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <form
          onSubmit={formikMarkCompleted.handleSubmit}
          style={{ borderTop: "7px solid  #FCC200" }}
          className="p-4"
        >
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Meeting Outcome
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMeetingOutcomeopen(false);
              }}
            />
          </div>
          <div className="mt-3">
            {/* <p className="Stage__FormTittle">Meeting Title</p> */}

            <textarea
              name="meeting_outcome"
              value={formikMarkCompleted.values.meeting_outcome}
              onChange={formikMarkCompleted.handleChange}
              cols={30}
              rows={10}
              className="ProjectStages___Stage__input w-full mt-2"
            />
            {formikMarkCompleted.touched.meeting_outcome &&
              formikMarkCompleted.errors.meeting_outcome && (
                <p className="required_message">
                  {formikMarkCompleted.errors.meeting_outcome}
                </p>
              )}
          </div>
          <button className="ProjectStages__activateButton mt-1" type="submit">
            Submit & Mark as Completed
          </button>
        </form>
      </Dialog>
      {/* Cancel Meeting pop */}
      <Dialog
        open={CancelMeetingpopup}
        onClose={() => {
          setCancelMeetingpopup(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <form
          onSubmit={formikMarkCancle.handleSubmit}
          style={{ borderTop: "7px solid  #FCC200" }}
          className="p-4"
        >
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Cancel Meeting
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCancelMeetingpopup(false);
              }}
            />
          </div>
          <div className="mt-3">
            <p className="Stage__FormTittle">Specify the reason</p>

            <textarea
              name="cancellation_reason"
              value={formikMarkCancle.values.cancellation_reason}
              onChange={formikMarkCancle.handleChange}
              cols={30}
              rows={10}
              className="ProjectStages___Stage__input w-full mt-2"
            />
            {formikMarkCancle.touched.cancellation_reason &&
              formikMarkCancle.errors.cancellation_reason && (
                <p className="required_message">
                  {formikMarkCancle.errors.cancellation_reason}
                </p>
              )}
          </div>
          <button className="ProjectStages__activateButton mt-1" type="submit">
            Cancel Meeting
          </button>
        </form>
      </Dialog>
      {/* view All documents */}
      <Dialog
        open={Viewpopup}
        onClose={() => {
          setViewpopup(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Uploaded Documents
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setViewpopup(false);
              }}
            />
          </div>
          {EditMeeting?.meeting_documents?.map((valuedoc: string) => (
            <div className="flex justify-start items-center gap-2 mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M15.9837 4.027C14.0519 2.09517 10.906 2.09517 8.97619 4.027L3.62365 9.37544C3.58879 9.4103 3.57033 9.45747 3.57033 9.50669C3.57033 9.55591 3.58879 9.60308 3.62365 9.63794L4.38039 10.3947C4.41498 10.4291 4.4618 10.4485 4.51061 10.4485C4.55943 10.4485 4.60625 10.4291 4.64084 10.3947L9.99338 5.04624C10.6578 4.38179 11.5417 4.01675 12.481 4.01675C13.4202 4.01675 14.3041 4.38179 14.9665 5.04624C15.631 5.71069 15.996 6.59458 15.996 7.53179C15.996 8.47105 15.631 9.35288 14.9665 10.0173L9.51145 15.4704L8.62756 16.3542C7.80109 17.1807 6.45783 17.1807 5.63137 16.3542C5.23146 15.9543 5.01203 15.4232 5.01203 14.8572C5.01203 14.2912 5.23146 13.76 5.63137 13.3601L11.0434 7.95015C11.1808 7.8148 11.3612 7.73892 11.554 7.73892H11.5561C11.7488 7.73892 11.9273 7.8148 12.0626 7.95015C12.2 8.08755 12.2738 8.26802 12.2738 8.46079C12.2738 8.65151 12.198 8.83198 12.0626 8.96733L7.63908 13.3868C7.60422 13.4216 7.58576 13.4688 7.58576 13.518C7.58576 13.5672 7.60422 13.6144 7.63908 13.6493L8.39582 14.406C8.43041 14.4404 8.47723 14.4598 8.52604 14.4598C8.57486 14.4598 8.62168 14.4404 8.65627 14.406L13.0778 9.98452C13.4859 9.57642 13.7094 9.03501 13.7094 8.45874C13.7094 7.88247 13.4838 7.33901 13.0778 6.93296C12.2349 6.09009 10.865 6.09214 10.0221 6.93296L9.49709 7.46001L4.61213 12.3429C4.28058 12.6725 4.01777 13.0647 3.83893 13.4966C3.6601 13.9286 3.5688 14.3917 3.57033 14.8592C3.57033 15.8087 3.94152 16.7008 4.61213 17.3714C5.30734 18.0646 6.21789 18.4112 7.12844 18.4112C8.03898 18.4112 8.94953 18.0646 9.64269 17.3714L15.9837 11.0345C16.9168 10.0994 17.4336 8.85454 17.4336 7.53179C17.4357 6.20698 16.9189 4.96216 15.9837 4.027Z"
                  fill="url(#paint0_linear_1010_81762)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1010_81762"
                    x1="4.16567"
                    y1="1.41703"
                    x2="19.9401"
                    y2="2.78853"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#626262" />
                    <stop offset="1" stop-color="#151414" />
                  </linearGradient>
                </defs>
              </svg>
              <p className="StageAccordionDetails__Agreement">
                {fileNameSplit(valuedoc)}
              </p>
              <p
                className="m-0 StageAccordionDetails__AgreementV  cursor-pointer"
                onClick={() => {
                  window.open(`${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`);
                }}
              >
                View
              </p>
              <p
                className="m-0 StageAccordionDetails__AgreementV cursor-pointer"
                onClick={() => {
                  saveAs(
                    `${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`,
                    `${fileNameSplit(valuedoc)}`
                  );
                }}
              >
                Download
              </p>
              <p
                className="m-0 StageAccordionDetails__AgreementV "
                style={{ color: "#F05F5F", cursor: "pointer" }}
                onClick={() => {
                  DeletDocz(valuedoc);
                }}
              >
                Delete
              </p>
            </div>
          ))}

          <button className="ProjectStages__activateButton mt-1 ">
            Download All
          </button>
        </div>
      </Dialog>
      {/* <h1
        onClick={() => {
          console.log(meetingType);
        }}
      >
        karan
      </h1> */}
      {/* filter Search */}
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-6">
          <div className="group p-4">
            <svg viewBox="0 0 24 24" aria-hidden="true" className="icon">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              value={search}
              onChange={(e) => setsearch(e.target.value)}
              className="input"
              type="search"
              placeholder="Search Meeting"
            />
          </div>

          <MultiSelect
            placeholder={"Choose Meeting Type"}
            options={meetingType?.map((value: any) => {
              return {
                fullname: value.section_name,

                value: value._id,
                label: value.section_name,
              };
            })}
            value={MeetingTypeFilter}
            defaultValue={MeetingTypeFilter}
            handleChange={handleChangeMeetingType}
          />
          <DateRangePicker
            placement="bottomStart"
            format="dd-MM-yyyy"
            placeholder="Choose Date Range"
            ranges={[
              {
                label: "Today",
                value: [startOfDay(new Date()), endOfDay(new Date())],
                closeOverlay: false,
              },
              {
                label: "Month",
                value: [
                  startOfDay(new Date()),
                  endOfDay(addMonths(new Date(), 1)),
                ],
                closeOverlay: false,
              },
              {
                label: "3 Months",
                value: [
                  startOfDay(new Date()),
                  endOfDay(addMonths(new Date(), 3)),
                ],
                closeOverlay: false,
              },
              {
                label: "6 Months",
                value: [
                  startOfDay(new Date()),
                  endOfDay(addMonths(new Date(), 6)),
                ],
                closeOverlay: false,
              },
              {
                label: "Year",
                value: [
                  startOfDay(new Date()),
                  endOfDay(addYears(new Date(), 1)),
                ],
                closeOverlay: false,
              },
            ]}
            onOk={handlestartDateChange}
            onClean={() => {
              setmeetingDateRange([]);
              setmeetingStartDate("");
              setmeetingEndDate("");
            }}
            className=" w-[400px]  focus:border-gray-400  border-gray-300 "
            cleanable={true}
            defaultValue={(() => meetingDateRange as [Date, Date])()}
          />
        </div>

        <button
          onClick={() => {
            setCreateMeetingopen(true);
          }}
          className="ProjectStages__activateButton "
          style={{ width: "200px" }}
        >
          Create Meeting
        </button>
      </div>
      {AllmeetingData.length > 0 ? (
        <>
          {" "}
          {AllmeetingData?.map((value: meetingtabData) => (
            <AccordionTransition
              value={value}
              setCreateMeetingopen={setCreateMeetingopen}
              setuploadFileOpen={setuploadFileOpen}
              setMeetingOutcomeopen={setMeetingOutcomeopen}
              setCancelMeetingpopup={setCancelMeetingpopup}
              setViewpopup={setViewpopup}
              setEditMeeting={setEditMeeting}
              getmeetingListing={getmeetingListing}
              setEditMeetingopen={setEditMeetingopen}
              setEditParticipants={setEditParticipants}
            />
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center mt-28">
          {/* <h3>No Data Found</h3> */}
          <img className="h-[250px] w-[220px]" src={nodata} alt="no-data" />
        </div>
      )}
    </>
  );
};

export default Meetings;
type Propsvalue = {
  value: meetingtabData;
  setCreateMeetingopen?: (value: boolean) => void;
  setuploadFileOpen?: (value: boolean) => void;
  setMeetingOutcomeopen?: (value: boolean) => void;
  setCancelMeetingpopup?: (value: boolean) => void;
  setViewpopup?: (value: boolean) => void;
  setEditMeeting?: (value: any) => void;
  setEditMeetingopen?: (value: boolean) => void;
  setEditParticipants?: any;
  getmeetingListing?: any;
};
function AccordionTransition({
  value,
  setCreateMeetingopen,
  setuploadFileOpen,
  setMeetingOutcomeopen,
  setCancelMeetingpopup,
  setViewpopup,
  setEditMeeting,
  setEditMeetingopen,
  setEditParticipants,
  getmeetingListing,
}: Propsvalue) {
  const [expanded, setExpanded] = React.useState(false);
  const [EditView, setEditView] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const DeletDocz = ({ doc, ID }: deletdoc) => {
    axiosClientInterceptors
      .delete(`/clients/stages/meetings/?meet_id=${ID}&doc=${doc}`)
      .then((res) => {
        console.log(res);
        getmeetingListing();
        Toaster("success", "Document Deleted Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mt-3">
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          style={{ width: "100%" }}
          //   expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="flex justify-between items-center w-full px-3">
            <p className="ProjectStages__Card__Title">
              {value.meeting_title || "-"}
            </p>
            {value.status === "Completed" && (
              <p
                className="ProjectStages__InProgress  p-2"
                style={{
                  background: "hsl(99.69deg 29.27% 57.69%)",
                  color: "green",
                }}
              >
                {value.status}
              </p>
            )}
            {value.status === "Cancelled" && (
              <p
                className="ProjectStages__InProgress  p-2"
                style={{
                  background: "pink",
                  color: "red",
                }}
              >
                {value.status}
              </p>
            )}
            {value.status === "Overdue" && (
              <p
                className="ProjectStages__InProgress  p-2"
                style={{
                  background: "pink",
                  color: "red",
                }}
              >
                {value.status}
              </p>
            )}
            {value.status === "Due Today" && (
              <p
                className="ProjectStages__InProgress  p-2"
                style={{
                  background: "red",
                  color: "red",
                }}
              >
                {value.status}
              </p>
            )}
            {value.status === "Scheduled" && (
              <p
                className="ProjectStages__InProgress  p-2"
                // style={{
                //   background: "yellow",
                //   color: "yellow",
                // }}
              >
                {value.status}
              </p>
            )}
            <p className="ProjectStages__UpdatedBy">{value.meeting_ID}</p>
            <p className="ProjectStages__UpdatedBy">
              Created on:{" "}
              {value.createdAt
                ? moment(value.createdAt).format("h:mm A, D MMMM YYYY")
                : "-"}
            </p>
            <div className="flex justify-center items-start">
              {" "}
              <ExpandMoreIcon />{" "}
              {!expanded ? (
                <p className="ProjectStages__More">View More</p>
              ) : (
                <p className="ProjectStages__More">View Less</p>
              )}
            </div>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <div className=" grid grid-cols-5 mt-3">
            <div>
              <p className="StageAccordionDetails__title">Created by</p>
              <p className="StageAccordionDetails__subtitle">
                {value?.created_by.fullname}
              </p>
            </div>
            <div>
              <p
                className="StageAccordionDetails__title"
                onClick={() => {
                  console.log(value.meeting_start_time);
                }}
              >
                Start time*
              </p>
              <p className="StageAccordionDetails__subtitle">
                {value.meeting_start_time ? value.meeting_start_time : "-"}{" "}
                {value.meeting_date
                  ? moment(value.meeting_date).format(", D MMMM YYYY")
                  : "-"}{" "}
              </p>
            </div>
            <div>
              <p className="StageAccordionDetails__title">End time</p>
              <p className="StageAccordionDetails__subtitle">
                {" "}
                {value.meeting_end_time ? value.meeting_end_time : "-"}{" "}
                {value.meeting_date
                  ? moment(value.meeting_date).format(", D MMMM YYYY")
                  : "-"}{" "}
              </p>
            </div>
            <div>
              <p className="StageAccordionDetails__title">Remind Date</p>
              <p className="StageAccordionDetails__subtitle">
                {value.reminder_date
                  ? moment(value.reminder_date).format(" D MMMM YYYY")
                  : "-"}{" "}
              </p>
            </div>
            <div>
              <p className="StageAccordionDetails__title">Remind Time</p>
              <p className="StageAccordionDetails__subtitle">
                {value.reminder_time ? value.reminder_time : "-"}{" "}
              </p>
            </div>
            <div className="mt-3">
              <p className="StageAccordionDetails__title">Mode</p>
              <p className="StageAccordionDetails__subtitle">
                {value.meeting_mode}
              </p>
            </div>
            <div className="mt-3">
              <p className="StageAccordionDetails__title">
                Assigned Department
              </p>
              <p className="StageAccordionDetails__subtitle">
                {value.assigned_department}
              </p>
            </div>
            <div className="mt-3">
              <p className="StageAccordionDetails__title">Assigned User</p>
              <p className="StageAccordionDetails__subtitle">
                {value.assigned_to.fullname}
              </p>
            </div>
            {value.meeting_mode === "Online" ? (
              <div className="mt-3">
                <p className="StageAccordionDetails__title">Link</p>
                <p className="StageAccordionDetails__subtitle">
                  {value.meeting_link}
                  {value.meeting_link && (
                    <ContentCopyTwoToneIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(value.meeting_link);
                        Toaster(
                          "info",
                          "Text copied to clipboard successfully"
                        );
                      }}
                    />
                  )}
                </p>
              </div>
            ) : (
              <div
                className="mt-3"
                onClick={() => {
                  console.log(value);
                }}
              >
                <p className="StageAccordionDetails__title">location</p>
                <p className="StageAccordionDetails__subtitle">
                  {value.meeting_location || "-"}
                </p>
              </div>
            )}
          </div>
          <div className="mt-3">
            <p
              className="StageAccordionDetails__title"
              onClick={() => {
                console.log(value);
              }}
            >
              Description
            </p>
            <p className="StageAccordionDetails__subtitle">
              {value.meeting_description}
            </p>
          </div>{" "}
          {value.cancellation_reason != "" && (
            <div className="mt-3">
              <p
                className="StageAccordionDetails__title"
                onClick={() => {
                  console.log(value);
                }}
              >
                cancel Reason
              </p>
              <p className="StageAccordionDetails__subtitle">
                {value.cancellation_reason || "-"}
              </p>
            </div>
          )}
          {value.meeting_outcome_description !== "" && (
            <div className="mt-3">
              <p
                className="StageAccordionDetails__title"
                onClick={() => {
                  console.log(value);
                }}
              >
                Meeting Description
              </p>
              <p className="StageAccordionDetails__subtitle">
                {value.meeting_outcome_description || "-"}
              </p>
            </div>
          )}
          <div className="my-5">
            <p className="StageAccordionDetails__title">Attendees</p>
            {value?.attendees.length > 0 ? (
              <div className="flex justify-start items-center gap-3 w-full flex-wrap">
                {value?.attendees.map((values: userlisting) => (
                  <p className="StageAccordionDetails__Ssubtitle mt-3">
                    @{values.fullname}{" "}
                  </p>
                ))}
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className="mt-2 flex  justify-start items-center gap-6 flex-wrap">
            {value?.meeting_documents?.slice(0, 3).map((valuedoc: string) => (
              <div className="mt-2 flex  justify-start items-center gap-6 ">
                <div className="flex justify-start items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M15.9837 4.027C14.0519 2.09517 10.906 2.09517 8.97619 4.027L3.62365 9.37544C3.58879 9.4103 3.57033 9.45747 3.57033 9.50669C3.57033 9.55591 3.58879 9.60308 3.62365 9.63794L4.38039 10.3947C4.41498 10.4291 4.4618 10.4485 4.51061 10.4485C4.55943 10.4485 4.60625 10.4291 4.64084 10.3947L9.99338 5.04624C10.6578 4.38179 11.5417 4.01675 12.481 4.01675C13.4202 4.01675 14.3041 4.38179 14.9665 5.04624C15.631 5.71069 15.996 6.59458 15.996 7.53179C15.996 8.47105 15.631 9.35288 14.9665 10.0173L9.51145 15.4704L8.62756 16.3542C7.80109 17.1807 6.45783 17.1807 5.63137 16.3542C5.23146 15.9543 5.01203 15.4232 5.01203 14.8572C5.01203 14.2912 5.23146 13.76 5.63137 13.3601L11.0434 7.95015C11.1808 7.8148 11.3612 7.73892 11.554 7.73892H11.5561C11.7488 7.73892 11.9273 7.8148 12.0626 7.95015C12.2 8.08755 12.2738 8.26802 12.2738 8.46079C12.2738 8.65151 12.198 8.83198 12.0626 8.96733L7.63908 13.3868C7.60422 13.4216 7.58576 13.4688 7.58576 13.518C7.58576 13.5672 7.60422 13.6144 7.63908 13.6493L8.39582 14.406C8.43041 14.4404 8.47723 14.4598 8.52604 14.4598C8.57486 14.4598 8.62168 14.4404 8.65627 14.406L13.0778 9.98452C13.4859 9.57642 13.7094 9.03501 13.7094 8.45874C13.7094 7.88247 13.4838 7.33901 13.0778 6.93296C12.2349 6.09009 10.865 6.09214 10.0221 6.93296L9.49709 7.46001L4.61213 12.3429C4.28058 12.6725 4.01777 13.0647 3.83893 13.4966C3.6601 13.9286 3.5688 14.3917 3.57033 14.8592C3.57033 15.8087 3.94152 16.7008 4.61213 17.3714C5.30734 18.0646 6.21789 18.4112 7.12844 18.4112C8.03898 18.4112 8.94953 18.0646 9.64269 17.3714L15.9837 11.0345C16.9168 10.0994 17.4336 8.85454 17.4336 7.53179C17.4357 6.20698 16.9189 4.96216 15.9837 4.027Z"
                      fill="url(#paint0_linear_1010_81762)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1010_81762"
                        x1="4.16567"
                        y1="1.41703"
                        x2="19.9401"
                        y2="2.78853"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#626262" />
                        <stop offset="1" stop-color="#151414" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p className="StageAccordionDetails__Agreement">
                    {fileNameSplit(valuedoc)}
                  </p>
                  <p
                    className="m-0 StageAccordionDetails__AgreementV cursor-pointer"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`
                      );
                    }}
                  >
                    View
                  </p>
                  <a
                    className="m-0 StageAccordionDetails__AgreementV cursor-pointer "
                    onClick={() => {
                      saveAs(
                        `${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`,
                        `${fileNameSplit(valuedoc)}`
                      );
                      // console.log(
                      //   `${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`,
                      //   `${fileNameSplit(valuedoc)}`
                      // );
                    }}
                  >
                    Download
                  </a>

                  <p
                    onClick={() => {
                      DeletDocz({ doc: valuedoc, ID: value?._id });
                    }}
                    className="m-0 StageAccordionDetails__AgreementV  cursor-pointer"
                    style={{ color: "#F05F5F" }}
                  >
                    Delete
                  </p>
                </div>
              </div>
            ))}
            {value.meeting_documents?.length > 3 && (
              <svg
                onClick={() => {
                  setViewpopup && setViewpopup(true);
                  setEditMeeting && setEditMeeting(value);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="25"
                viewBox="0 0 36 25"
                fill="none"
              >
                <path
                  d="M2.23438 5.3924C2.23438 2.41426 4.64864 0 7.62678 0H9.5719C10.3264 0 11.0743 0.154811 11.757 0.47617C14.7574 1.88865 15.6998 2.67382 18.9803 3.27128C19.2725 3.3245 19.5706 3.34811 19.8676 3.34811H27.1982C30.1763 3.34811 32.5906 5.76237 32.5906 8.74051V11.1249C32.5906 14.1031 30.1763 16.5173 27.1982 16.5173H7.62678C4.64863 16.5173 2.23438 14.1031 2.23438 11.1249V5.3924Z"
                  fill="#FCC200"
                />
                <path
                  d="M0.732739 14.5042C0.346005 11.797 2.44663 9.375 5.18125 9.375H30.2303C32.9142 9.375 34.9996 11.7124 34.6949 14.3789L33.9363 21.0161C33.677 23.2857 31.7561 24.9995 29.4717 24.9995H6.12941C3.89314 24.9995 1.99716 23.3551 1.68091 21.1413L0.732739 14.5042Z"
                  fill="url(#paint0_linear_1010_81784)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1010_81784"
                    x1="17.6334"
                    y1="5.46887"
                    x2="20.6903"
                    y2="27.0782"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFE185" />
                    <stop offset="1" stop-color="#F7BA00" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
          {value.meeting_done || value.isCancelled ? (
            "-"
          ) : (
            <div className="flex justify-start items-center gap-3 mt-5">
              <button
                disabled
                style={{ color: "#000000" }}
                onClick={() => {
                  setEditView(true);
                }}
                className=" ProjectStages__Activate"
              >
                Share
              </button>
              <button
                onClick={() => {
                  setEditMeetingopen && setEditMeetingopen(true);
                  setEditMeeting && setEditMeeting(value);
                  setEditParticipants &&
                    setEditParticipants(
                      value?.attendees?.map((value: any) => {
                        return {
                          value: value._id,
                          label: value.fullname,
                        };
                      })
                    );
                }}
                style={{ color: "#1364C2" }}
                className=" ProjectStages__Activate"
              >
                Edit
              </button>
              <button
                onClick={() => {
                  setuploadFileOpen && setuploadFileOpen(true);
                  setEditMeeting && setEditMeeting(value);
                }}
                style={{ color: "#000000" }}
                className=" ProjectStages__Activate"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 16V7.85L8.4 10.45L7 9L12 4L17 9L15.6 10.45L13 7.85V16H11ZM4 20V15H6V18H18V15H20V20H4Z"
                    fill="#6A6A6A"
                  />
                </svg>
              </button>
              <button
                onClick={() => {
                  setMeetingOutcomeopen && setMeetingOutcomeopen(true);
                  setEditMeeting && setEditMeeting(value);
                }}
                style={{ color: "#009D06" }}
                className=" ProjectStages__Activate"
              >
                Done
              </button>
              <button
                onClick={() => {
                  setCancelMeetingpopup && setCancelMeetingpopup(true);
                  setEditMeeting && setEditMeeting(value);
                }}
                style={{ color: "#FF0000" }}
                className=" ProjectStages__Activate"
              >
                Cancel Meeting
              </button>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
