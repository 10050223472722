import * as yup from "yup";
import {
  startOfDay,
  endOfDay,
  addDays,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
// import { dateRange } from "../../Types";

export const validateAddUser = yup.object({
  fullName: yup
    .string()
    .min(3, "Name must be atleast 3 characters")
    .max(25, "Name exceeds the limit 25")
    .required("Name is required"),
  empID: yup
    .string()
    .required("Emp ID is required")
    .min(6)
    .max(6)
    // .matches(!/^(?=(?:[a-z]*\d){3})(?=(?:\d*[a-z]){3})\w{6}$/i, "No period")
    // .matches(
    //   /^(?=(?:[a-z]*\d){3})(?=(?:\d*[a-z]){3})\w{6}$/,
    //   "Emp ID should contain Alphabets and Numbers"
    // )
    .required("Emp ID is required"),
  mobileNumber: yup
    .string()
    .nullable()
    .min(10, "10 digit mobile number is required")
    .matches(/^[6-9][0-9]*$/, "Enter a valid Mobile Number")
    .required("Mobile Number is required"),
  userID: yup
    .string()
    .email("Enter a valid email address")
    .required("User ID is required"),
  password: yup.string().required("Password is required"),
  joiningDate: yup.string().required("Joining Date is required"),
  userType: yup.string().required("User Type is required"),
  city: yup.string().required("City is required"),
});

export const validateAddDoc = yup.object({
  Doc_name: yup.string().required("Document name is required"),
});

export const validateProjectInfo = yup.object({
  plot_length: yup
    .string()
    .max(3, "Length is Required")
    .required("Length is Required"),
  plot_breadth: yup
    .string()
    .max(3, "Breadth is Required")
    .required("Breadth is Required"),
  no_of_floors: yup
    .string()
    .max(2, "The maximum is 2 digits")
    .required("Floor is Required"),
  budget: yup
    .string()
    .required("Budget is Required")
    .max(15, "The maximum is 15 digits"),
  current_pin: yup
    .string()
    .required("Current Pin is Required")
    .min(6, "Current Pin must be 6 digit"),
  plot_pin: yup
    .string()
    .required("Plot Pin is Required")
    .min(6, "Plot Pin must be 6 digit"),
  sbua: yup
    .string()
    .max(10, "The maximum is 10 digits")
    .required("SUBA is required"),
});

export const validateProjectStatus = yup.object({
  projectStatus: yup
    .string()
    .required("Project Status is required")
    .max(50, "Project Status exceeds the limit 50"),
});

export const validateProjectStage = yup.object({
  stage_name: yup
    .string()
    .required("Stage Name is required")
    .max(100, "Project Status exceeds the limit 100 character"),
});

export const LastActivityDateLead = [
  {
    label: "today",
    value: [endOfDay(new Date()), startOfDay(new Date())],
    closeOverlay: false,
  },
  {
    label: "Month",
    value: [endOfDay(subMonths(new Date(), 1)), startOfDay(new Date())],
    closeOverlay: false,
  },
  {
    label: "3 Months",
    value: [endOfDay(subMonths(new Date(), 3)), startOfDay(new Date())],
    closeOverlay: false,
  },
  {
    label: "6 Months",
    value: [endOfDay(subMonths(new Date(), 6)), startOfDay(new Date())],
    closeOverlay: false,
  },
  {
    label: "Year",
    value: [endOfDay(subYears(new Date(), 1)), startOfDay(new Date())],
    closeOverlay: false,
  },
];

// export const fileNameSplit = (str?: string) => {
//   return str.split("/").at(-1).split("-").slice(1).join("-");
// };

export const validateUpdateUser = yup.object({
  fullName: yup
    .string()
    .min(3, "Name must be atleast 3 characters")
    .max(25, "Name exceeds the limit 25")
    .required("Name is required"),
  empID: yup
    .string()
    .required("Emp ID is required")
    .min(6)
    .max(6)
    // .matches(!/^(?=(?:[a-z]*\d){3})(?=(?:\d*[a-z]){3})\w{6}$/i, "No period")
    // .matches(
    //   /^(?=(?:[a-z]*\d){3})(?=(?:\d*[a-z]){3})\w{6}$/,
    //   "Emp ID should contain Alphabets and Numbers"
    // )
    .required("Emp ID is required"),
  mobileNumber: yup
    .string()
    .nullable()
    .min(10, "10 digit mobile number is required")
    .matches(/^[6-9][0-9]*$/, "Enter a valid Mobile Number")
    .required("Mobile Number is required"),
  city: yup.string().required("City is required"),
});

export const validateClientDetails = yup.object({
  fullname: yup
    .string()
    .min(3, "Name must be atleast 3 characters")
    .max(50, "Name exceeds the limit 50")
    .required("Client name is required"),
  number: yup
    .string()
    .nullable()
    .min(10, "10 digit mobile number is required")
    .matches(/^[6-9][0-9]*$/, "Enter a valid Mobile Number")
    .required("Mobile Number is required"),
  alt_number: yup
    .string()
    .nullable()
    .min(10, "10 digit mobile number is required")
    .matches(/^[6-9][0-9]*$/, "Enter a valid Mobile Number"),
  // .required("Mobile Number is required"),
  emailID: yup
    .string()
    .email("Enter a valid email address")
    .required("User ID is required"),
  city: yup.string().required("City is required"),
  projectStatus: yup.string().required("projectStatus is required"),
});

export const validateActivityStatus = yup.object({
  projectStatus: yup
    .string()
    .required("Name is required")
    .max(50, "Name exceeds the limit 50 characters"),
});

export const validateDesignFiles = yup.object({
  preConstruciton: yup
    .string()
    .required("File category name is required")
    .max(50, "File category name exceeds the limit 50 characters"),
});

export const validateConstruction = yup.object({
  construciton: yup
    .string()
    .required("File category name is required")
    .max(50, "File category name exceeds the limit 50 characters"),
});

export const validateOtherFiles = yup.object({
  otherFiles: yup
    .string()
    .required("File category name is required")
    .max(50, "File category name exceeds the limit 50 characters"),
});
