import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Calendar,
  DateLocalizer,
  EventPropGetter,
  NavigateAction,
  ToolbarProps,
  momentLocalizer,
} from "react-big-calendar";
import MultiSelect from "../../../Tools/MultiSelect";
import "./MeetingCalender.css";
import axiosClientInterceptors from "../../../ApiConfig/axiosClientInterceptor";
import { AxiosResponse } from "axios";
import { departmentType, meetingDataType, option } from "../../../../Types";

const localizer = momentLocalizer(moment);

interface MyEvent {
  title: string;
  start: Date;
  end: Date;
  calender_bg_color?: string;
  calender_border_color?: string;
  meeting_type?: string;
  client_name?: string;
}

type Views = "month" | "week" | "day" | "agenda";

const meetview: Views = "month";

const MeetingCalender = () => {
  const [taskStatusMulti, setTaskStatusMulti] = useState<option[]>([]);
  const [startDate, setStartDate] = useState(new Date().toISOString());
  const [view, setView] = useState(meetview);
  const [totalMeeting, setTotalMeeting] = useState<number>(0);
  const [meetingData, setMeetingData] = useState<meetingDataType[]>([]);
  const events: MyEvent[] = meetingData?.map((val) => ({
    title: val?.user_name || "",
    start: val.start ? new Date(val.start) : new Date(),
    end: val.end ? new Date(val.end) : new Date(),
    calender_bg_color: val?.calender_bg_color,
    calender_border_color: val?.calender_border_color,
    meeting_type: val?.meeting_type,
    client_name: val?.client_name,
  }));
  const [dept, setDept] = useState<departmentType[]>([]);
  const [deptData, setDeptData] = useState<string>("");

  const eventPropGetter: EventPropGetter<MyEvent> = (event) => {
    const backgroundColor = event.calender_bg_color ?? "#ffffff";
    const borderColor = event.calender_border_color
      ? `2px solid ${event.calender_border_color}`
      : "2px solid #ffffff";
    return {
      style: {
        backgroundColor,
        borderLeft: borderColor,
      },
    };
  };

  const getCalender = () => {
    console.log(moment(startDate).format("DD/MM/YYYY"));
    axiosClientInterceptors
      .post(`/calendar?filter=${view}&from_date=${startDate}`, {
        dept: deptData,
        meeting_status: taskStatusMulti?.map((x) => x?.value),
      })
      .then((res: AxiosResponse) => {
        setMeetingData(res?.data?.result?.data);
        setTotalMeeting(res?.data?.result?.total_meeting);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepartment = () => {
    axiosClientInterceptors
      .get(`/general/users/dept`)
      .then((res: AxiosResponse) => {
        console.log(res);
        setDept(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onView = useCallback((newView: any) => setView(newView), [setView]);

  function addAgenda(date: Date, days: number) {
    date.setDate(date.getDate() + days);
    setStartDate(new Date(date).toISOString());
    return date;
  }

  function addMonths(date: Date, months: number) {
    console.log(moment(date).format("YYYY/MM/DD"));
    console.log(months);
    const d = date.getDate();
    console.log(d, "d");
    date.setMonth(date.getMonth() + months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    setStartDate(new Date(date).toISOString());
    return date;
  }

  function addWeeks(date: Date, weeks: number) {
    console.log(moment(date).format("YYYY/MM/DD"));
    date.setDate(date.getDate() + 7 * weeks);
    setStartDate(new Date(date).toISOString());
    return date;
  }

  function addDays(date: Date, days: number) {
    console.log(moment(date).format("YYYY/MM/DD"));
    date.setDate(date.getDate() + days);
    setStartDate(new Date(date).toISOString());
    return date;
  }
  //multi select handel change
  const handelTaskStatusMulti = (value: any) => {
    setTaskStatusMulti(value);
  };

  const onNavigate = useCallback(
    (newDate: any) => {
      console.log(newDate, "new");
      return setStartDate(newDate);
    },
    [setStartDate]
  );

  const CustomTool: React.FC<ToolbarProps<any, any>> = (props) => {
    // const goToBack = () => {
    //   let action: NavigateAction = "PREV";
    //   let date: Date;

    //   if (props.view === "month") {
    //     date = addMonths(props.date, -1);
    //   } else if (props.view === "week") {
    //     date = addWeeks(props.date, -1);
    //   } else if (props.view === "agenda") {
    //     date = addAgenda(props.date, -90);
    //     // action = "NEXT";
    //   } else {
    //     date = addDays(props.date, -1);
    //   }
    //   props.onNavigate(action, date);
    // };
    const goToBack = () => {
      let action: NavigateAction = "PREV";
      let date: Date;

      console.log(`Current View: ${props.view}`);
      console.log(
        `Current Date: ${moment(props.date.toISOString()).format("YYYY/MM/DD")}`
      );

      if (props.view === "month") {
        date = addMonths(props.date, -1);
      } else if (props.view === "week") {
        date = addWeeks(props.date, -1);
      } else if (props.view === "agenda") {
        date = addAgenda(props.date, -90);
      } else {
        date = addDays(props.date, -1);
      }

      console.log(`New Date after back: ${date.toISOString()}`);
      props.onNavigate(action, date);
    };

    const goToNext = () => {
      let action: NavigateAction = "NEXT";
      let date: Date;

      if (props.view === "month") {
        date = addMonths(props.date, 1);
      } else if (props.view === "week") {
        date = addWeeks(props.date, 1);
      } else if (props.view === "agenda") {
        date = addAgenda(props.date, 90);
      } else {
        date = addDays(props.date, 1);
      }

      props.onNavigate(action, date);
    };

    const goToCurrent = () => {
      const now = new Date();
      props.onNavigate("TODAY" as NavigateAction, now);
      props.onView("day");
    };

    const handleDayChange = (event: any) => {
      props.onView(event.target.value);
    };

    return (
      <div>
        <div className="p-3" style={{ borderBottom: "1px solid #DFDFDF" }}>
          <div className="flex justify-between items-center">
            <div>
              <p className="meet_cal_show_txt m-0 flex items-center gap-[20px]">
                Showing Meeting Calendar for{" "}
                <span className="meet_name flex items-center gap-[20px]">
                  <div className="flex items-center gap-4">
                    <select
                      className="p-2 deptSelect"
                      onChange={(e) => {
                        setDeptData(e.target.value);
                      }}
                    >
                      <option value="" selected hidden>
                        Choose Deparment
                      </option>
                      {deptData !== "" && <option value="">Choose All</option>}
                      {dept?.map((val, i) => (
                        <option key={i} value={val?.department}>
                          {val?.department}
                        </option>
                      ))}
                    </select>
                    <MultiSelect
                      placeholder={"Select Task Status"}
                      options={[
                        {
                          value: "Scheduled",
                          label: "Scheduled",
                          // color: "#00B8D9",
                        },
                        {
                          value: "Rescheduled",
                          label: "Re-scheduled",
                          // color: "#0052CC",
                        },
                        {
                          value: "Cancelled",
                          label: "Canceled",
                          // color: "#5243AA",
                        },
                        {
                          value: "Done",
                          label: "Done",
                          // color: "#FF5630"
                        },
                      ]}
                      handleChange={handelTaskStatusMulti}
                      defaultValue={taskStatusMulti}
                      value={taskStatusMulti}
                    />
                  </div>
                </span>
              </p>
            </div>
            <div className="flex" style={{ gap: "10px" }}>
              <button className="meet_today_btn" onClick={goToCurrent}>
                Today
              </button>

              <div className="flex items-center" style={{ gap: "10px" }}>
                <button className="meet_arrow_btn" onClick={goToBack}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3335 12.6668L5.66683 8.00016L10.3335 3.3335"
                      stroke="#151414"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <label className="meet_label_date">
                  {props.view === "day" ? (
                    <div>{moment(props.date).format("Do MMM  YYYY")}</div>
                  ) : props.view === "week" ? (
                    <div>
                      {moment(props.date).startOf("week").format("MMM DD")} -{" "}
                      {moment(props.date).endOf("week").format("MMM DD")}
                    </div>
                  ) : props.view === "agenda" ? (
                    <div>
                      {moment(props.date).format("Do MMM YYYY")} -{" "}
                      {moment(props.date).add(90, "day").format("Do MMM YYYY")}
                    </div>
                  ) : (
                    <div>{moment(props.date).format("MMMM YYYY")} </div>
                  )}
                </label>
                <button className="meet_arrow_btn" onClick={goToNext}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.6665 12.6668L10.3332 8.00016L5.6665 3.3335"
                      stroke="#151414"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="rbc-btn-group">
                <select
                  className="meet_select"
                  onChange={(e) => handleDayChange(e)}
                  value={props.view}
                >
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                  <option value="agenda">Agenda</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          className="py-4 px-3"
          style={{ borderBottom: "1px solid #DFDFDF" }}
        >
          <div className="flex items-center" style={{ gap: "10px" }}>
            {props.view === "day" ? (
              <div>
                <span className="font-[Gr] font-semibold text-[16px] text-[#151414]">
                  {moment(props.date).format("Do")} {""}
                </span>
                <span className="font-[Gm] font-medium text-[16px] text-[#7D7D7D]">
                  ({moment(props.date).format("dddd")})
                </span>
              </div>
            ) : props.view === "week" ? (
              <div>
                <p className="">
                  {moment(props.date.toISOString())
                    .startOf("week")
                    .format("MMM DD")}{" "}
                  -{" "}
                  {moment(props.date.toISOString())
                    .endOf("week")
                    .format("MMM DD")}
                </p>
              </div>
            ) : props.view === "agenda" ? (
              <div>
                <p className="">
                  {moment(props.date.toISOString()).format("Do MMM YYYY")} -{" "}
                  {moment(props.date.toISOString())
                    .add(90, "day")
                    .format("Do MMM YYYY")}
                </p>
              </div>
            ) : (
              <div>
                <p className="">
                  {moment(props.date.toISOString()).format("MMMM YYYY")}{" "}
                </p>
              </div>
            )}

            <div>
              <button className="total_meeting_btn">
                Total Meeting : {totalMeeting}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  let formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
    dateFormat: (date: Date, culture: any, localizer: any) =>
      localizer.format(date, "D", culture),
    timeGutterFormat: (date: Date, culture: any, localizer: any) =>
      localizer.format(date, "h a", culture),
  };

  const event = ({ event }: any) => {
    return (
      <div
        title={`
      ${event.title} has a ${event.meeting_type} with ${event.client_name}`}
      >
        <p style={{ color: "#151414", fontWeight: 500 }}>
          <span style={{ fontWeight: 700 }}>{event.title} has a </span>
          <span style={{ fontWeight: 700 }}>
            {event.meeting_type}
          </span> with{" "}
          <span style={{ fontWeight: 700 }}>{event.client_name}</span>
          <span style={{ fontWeight: 700 }}>{event.lead_name}</span>
        </p>
      </div>
    );
  };

  useEffect(() => {
    getCalender();
  }, [view, startDate, deptData, taskStatusMulti]);

  useEffect(() => {
    getDepartment();
  }, []);

  return (
    <div>
      <div className="my-[30px] mr-[20px] bg-[#F5F8FA] border-[0.5px] border-[#DEDEDE] rounded-[2px]">
        <div className="p-6">
          <p className="font-[gm] font-semibold text-[17px] text-black">
            Meeting Calender
          </p>
          <div className="mt-4">
            <div className="bg-[#FFFFFF]">
              <Calendar
                dayLayoutAlgorithm={"no-overlap"}
                view={view}
                views={["month", "week", "day", "agenda"]}
                localizer={localizer}
                events={events}
                defaultDate={moment().toDate()}
                startAccessor={(event) => {
                  return new Date(event.start);
                }}
                endAccessor={(event) => {
                  return new Date(event.end);
                }}
                length={90}
                onView={onView}
                style={{ height: 700 }}
                messages={{
                  noEventsInRange:
                    "There are no Meetings Schedule in this range",
                }}
                components={{
                  toolbar: CustomTool,
                  event: event,
                }}
                formats={formats}
                onNavigate={(date) => {
                  let formatDate = new Date(date);
                  let confrimedDate = new Date(
                    formatDate.getTime() -
                      formatDate.getTimezoneOffset() * 60000
                  );
                  setStartDate(confrimedDate.toISOString());
                  console.log(
                    moment(confrimedDate.toISOString()).format("DD/MM/YYYY"),
                    "onavi"
                  );
                }}
                // onNavigate={(date) => {
                //   const confirmedDate = new Date(date);
                //   setStartDate(confirmedDate.toISOString());
                //   console.log(
                //     moment(confirmedDate).format("DD/MM/YYYY"),
                //     "onavi"
                //   );
                // }}
                // onNavigate={(date) => {
                //   const confirmedDate = new Date(
                //     date.getTime() - date.getTimezoneOffset() * 60000
                //   );
                //   setStartDate(confirmedDate.toISOString());
                // }}
                eventPropGetter={eventPropGetter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingCalender;
