import React, { useEffect, useState } from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Dialog, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import axiosClientInterceptors from "../../../../../../ApiConfig/axiosClientInterceptor";
import { selectClientID } from "../../../../../../Features/AllClients/AllClients";
import { useSelector } from "react-redux";
import { DesignFileobj, PinUnPinparam } from "../../../../../../../Types";
import { saveAs } from "file-saver";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import nodata from "../../../../../Assets/nodata.jpg";
import { Toaster } from "../../../../../../Tools/Toaster";
import CloseIcon from "@mui/icons-material/Close";

const fileNameSplit = (str: any) => {
  if (str) {
    return str.split("/").at(-1).split("-").slice(1).join("-");
  }
};

const DesignFiles = ({ siglestageData }: any) => {
  const [IDPIN, setIDPIN] = useState("");

  const clientID = useSelector(selectClientID);
  const [DesignFileData, setDesignFileData] = useState<DesignFileobj[]>([]);
  const getDesignFiles = () => {
    axiosClientInterceptors
      .get(`/clients/stages/designfiles/${clientID}/${siglestageData._id}`)
      .then((res) => {
        const result = res.data.result;
        console.log("hii", result);
        setDesignFileData(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const PinUnPin = () => {
    axiosClientInterceptors
      .put(`/clients/stages/designfiles/${IDPIN}`)
      .then((res) => {
        console.log(res);
        getDesignFiles();
        Toaster("success", res.data.message);
      })
      .catch((err) => {
        Toaster("error", err.response.data.message);

        console.log(err);
      });
  };

  useEffect(() => {
    getDesignFiles();
  }, []);
  return (
    <div>
      <div className="mt-4">
        {DesignFileData.length > 0 ? (
          <>
            {" "}
            {DesignFileData.map((val) => (
              <AccordionTransition
                value={val}
                PinUnPin={PinUnPin}
                setIDPIN={setIDPIN}
              />
            ))}
          </>
        ) : (
          <div className="flex justify-center items-center mt-3">
            <img className="h-[250px] w-[220px]" src={nodata} alt="no-data" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DesignFiles;
type Propsvalue = {
  value?: DesignFileobj;
  PinUnPin?: any;
  setIDPIN?: any;
  //   setMeetingOutcomeopen?: (value: boolean) => void;
  //   setopenAddTask?: (value: boolean) => void;
  //   setuploadFileOpen?: (value: boolean) => void;
  //   setViewpopup?: (value: boolean) => void;
};

function AccordionTransition({ value, PinUnPin, setIDPIN }: Propsvalue) {
  const [expanded, setExpanded] = useState(false);
  const [EditView, setEditView] = useState(false);
  const [finlefile, setfinlefile] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className="mt-3">
      {/* are you sure pin */}
      <Dialog
        open={finlefile}
        onClose={() => {
          setfinlefile(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle"></p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setfinlefile(false);
              }}
            />
          </div>
          <h3 className=" flex justify-center items-center my-8">
            Are you Sure !
          </h3>
          <div className="flex justify-between items-center gap-3">
            <button
              style={{ padding: "10px" }}
              className="w-full bg-red-600 text-white"
              onClick={() => {
                setfinlefile(false);
              }}
            >
              Cancle
            </button>{" "}
            <button
              className="w-full bg-green-500 text-white"
              style={{ padding: "10px" }}
              onClick={() => {
                setfinlefile(false);
                PinUnPin();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Dialog>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          style={{ width: "100%" }}
          //   expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="flex justify-between items-center w-full px-3">
            <p className="ProjectStages__Card__Title">{value?._id}</p>

            <p className="ProjectStages__UpdatedBy">
              Activity Date: {value?.activity_date}
            </p>
            <p className="ProjectStages__UpdatedBy">
              Total Files : {value?.data?.length}
            </p>
            <div className="flex justify-center items-start">
              {" "}
              <ExpandMoreIcon />{" "}
              {!expanded ? (
                <p className="ProjectStages__More">View More</p>
              ) : (
                <p className="ProjectStages__More">View Less</p>
              )}
            </div>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {value?.data?.map((values) => (
            <div className="p-4">
              <div
                className="flex justify-between items-center p-3 bg-[#F5F8FA]"
                style={{ border: "1px solid #DBDBDB" }}
              >
                <div className="flex justify-start items-center">
                  {values?.isFinal ? (
                    <BookmarkIcon
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <TurnedInNotIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setfinlefile(true);
                        setIDPIN(values?._id);
                      }}
                    />
                  )}
                  <p className="ProjectStages__Card__Title">
                    {fileNameSplit(values.file)}
                  </p>
                </div>
                <p className="ProjectStages__UpdatedBy">
                  Upload Date: {values.updatedAt}
                </p>
                <div className="flex gap-2 items-center">
                  <button
                    className="ProjectStages__Activate"
                    style={{ color: "#344054" }}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_IMAGE_URL}/${values.file}`
                      );
                    }}
                  >
                    View
                  </button>
                  <button
                    style={{ color: "#3E68FE" }}
                    className="ProjectStages__Activate"
                    onClick={() => {
                      saveAs(
                        `${process.env.REACT_APP_IMAGE_URL}/${values.file}`,
                        `${fileNameSplit(values.file)}`
                      );
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
