import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage"; // Correct import for local storage
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

// // Import your reducers
import ManageUsers from "../../Features/ManageUsers/ManageUsersSlice";
import AllClients from "../../Features/AllClients/AllClients";
import Screens from "../../Features/Screens/Screens";
import Documents from "../../Features/Documents/Documents";

const persistconfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  users: ManageUsers,
  clients: AllClients,
  screens: Screens,
  documents: Documents,
});

const persistorReducer = persistReducer(persistconfig, reducer);

export const store = configureStore({
  reducer: persistorReducer,
});
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import { persistStore } from "redux-persist";
// import { persistReducer } from "redux-persist/es/persistReducer";
// import storage from "redux-persist/lib/storage"; // Correct import for local storage
// import encryptTransform from "redux-persist-transform-encrypt";
// import { TypedUseSelectorHook, useSelector } from "react-redux";

// // Import your reducers
// import ManageUsers from "../../Features/ManageUsers/ManageUsersSlice";
// import AllClients from "../../Features/AllClients/AllClients";
// import Screens from "../../Features/Screens/Screens";
// import Documents from "../../Features/Documents/Documents";

// // Define the state shape
// interface RootState {
//   users: ReturnType<typeof ManageUsers>;
//   clients: ReturnType<typeof AllClients>;
//   screens: ReturnType<typeof Screens>;
//   documents: ReturnType<typeof Documents>;
// }

// // Combine your reducers
// const rootReducer = combineReducers({
//   users: ManageUsers,
//   clients: AllClients,
//   screens: Screens,
//   documents: Documents,
// });

// const encryptor = encryptTransform({
//   secretKey: "7090030506",
//   onError: function (error) {
//     console.error("Encryption error:", error);
//   },
// });

// // Configure the persist settings
// const persistConfig = {
//   key: "root",
//   storage,
//   transforms: [encryptor],
// };

// // Create a persisted reducer
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Configure the store
// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [
//           "persist/PERSIST",
//           "persist/REHYDRATE",
//           "persist/PAUSE",
//           "persist/PERSIST",
//           "persist/PURGE",
//           "persist/REGISTER",
//         ],
//       },
//     }),
// });

// // Configure persistor
// export const persistor = persistStore(store);

// // Create types for RootState and AppDispatch
// export type AppDispatch = typeof store.dispatch;

// // Typed selector hook
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
