import moment from "moment";
import {
  overviewProps,
  projectInfoFormik,
  resType,
} from "../../../../../../Types";
import React, { useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { useFormik } from "formik";
import { number } from "yup";
import axiosClientInterceptors from "../../../../../ApiConfig/axiosClientInterceptor";
import { AxiosResponse } from "axios";
import SmallLoader from "../../../../../Tools/SmallLoader";
import { Toaster } from "../../../../../Tools/Toaster";
import { validateProjectInfo } from "../../../../../Tools/Validation";

const ProjectInfo = ({ clientsData, getUserDetails }: overviewProps) => {
  const [sLoader, setSLoader] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const plotSize: number =
    (clientsData?.plot_length || 0) * (clientsData?.plot_breadth || 0);

  // const = defaultRegistrationDate = moment(clientsData?.registration_date).format("YYY-MM-")

  const editProjectInfoFormik = useFormik<projectInfoFormik>({
    enableReinitialize: true,
    initialValues: {
      gated: clientsData?.gated_community,
      preStartData: clientsData?.start_date || null,
      plot_length: clientsData?.plot_length,
      plot_breadth: clientsData?.plot_breadth,
      no_of_floors: clientsData?.no_of_floors,
      sbua: clientsData?.sbua,
      demolition_required: clientsData?.demolition_required,
      budget: clientsData?.budget,
      registration_date: clientsData?.registration_date,
      khata: clientsData?.khata,
      current_location: clientsData?.current_location,
      plot_location: clientsData?.plot_location,
      current_pin: clientsData?.current_pin,
      plot_pin: clientsData?.plot_pin,
    },
    validationSchema: validateProjectInfo,
    onSubmit: (val) => {
      setSLoader(true);
      axiosClientInterceptors
        .put(`/clients/${clientsData?._id}`, {
          start_date: moment(val.preStartData).format("YYYY-MM-DD") || null,
          plot_length: val.plot_length || null,
          plot_breadth: val.plot_breadth || null,
          no_of_floors: val.no_of_floors || null,
          sbua: val.sbua || null,
          demolition_required: val.demolition_required || null,
          budget: val.budget || null,
          registration_date:
            moment(val.registration_date).format("YYYY-MM-DD") || null,
          khata: val.khata || null,
          gated_community: val.gated,
          current_location: val.current_location,
          plot_location: val.plot_location,
          current_pin: val.current_pin,
          plot_pin: val.plot_pin,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getUserDetails();
            setToggle(false);
            setSLoader(false);
            Toaster("success", data?.message);
          } else {
            Toaster("error", data?.message);
          }
        })
        .catch((err: AxiosResponse) => {
          console.log(err);
          setSLoader(false);
        });
    },
  });

  return (
    <div className="mt-5">
      <div className="bg-white border-solid border-[1px]">
        {!toggle ? (
          <>
            <div className="flex justify-between items-center border-b-[1px] p-4">
              <span className="font-[Gm] font-medium text-[20px]">
                Project Info
              </span>
              <button
                className="edit_allocation"
                onClick={() => {
                  setToggle(true);
                }}
              >
                Edit Info.
              </button>
            </div>
            <div className="p-4">
              <div className=" flex items-center">
                <div className="w-1/5">
                  <p className="allo_heading">Pre Construction Start Date</p>
                  <p className="allo_content">
                    {moment(clientsData?.start_date).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="w-1/5">
                  <p className="allo_heading">Plot Size</p>
                  <p className="allo_content">{plotSize} sq/ft</p>
                </div>
                <div className="w-1/5">
                  <p className="allo_heading">No. of Floors</p>
                  <p className="allo_content">
                    {clientsData?.no_of_floors || "-"}
                  </p>
                </div>
                <div className="w-1/5">
                  <p className="allo_heading">SBUA</p>
                  <p className="allo_content">{clientsData?.sbua || "-"}</p>
                </div>
                <div className="w-1/5">
                  <p className="allo_heading">Demolition Required</p>
                  <p className="allo_content">
                    {clientsData?.demolition_required === null
                      ? "-"
                      : clientsData?.demolition_required
                      ? "Yes"
                      : "No"}
                  </p>
                </div>
              </div>
              <div className=" flex items-center mt-10">
                <div className="w-1/5">
                  <p className="allo_heading">Budget</p>
                  <p className="allo_content">{clientsData?.budget || "-"}</p>
                </div>
                <div className="w-1/5">
                  <p className="allo_heading">Registration Date</p>
                  <p className="allo_content">
                    {clientsData?.registration_date
                      ? moment(clientsData?.registration_date).format(
                          "DD/MM/yyyy"
                        )
                      : "-"}
                  </p>
                </div>
                <div className="w-1/5">
                  <p className="allo_heading">Khata Done</p>
                  <p className="allo_content">
                    {clientsData?.khata === null
                      ? "-"
                      : clientsData?.khata
                      ? "Yes"
                      : "No"}
                  </p>
                </div>
                <div className="w-1/5">
                  <p className="allo_heading">Gated Community</p>
                  <p className="allo_content">
                    {clientsData?.gated_community || "-"}
                  </p>
                </div>
                <div className="w-1/5"></div>
              </div>
              <div className="flex items-center mt-10">
                <div className="w-2/5">
                  <p className="allo_heading">Current Location</p>
                  <p className="allo_content">
                    {clientsData?.current_location || "-"}
                  </p>
                </div>
                <div className="w-3/5">
                  <p className="allo_heading">Plot Location</p>
                  <p className="allo_content">
                    {clientsData?.plot_location || "-"}
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-10">
                <div className="w-2/5">
                  <p className="allo_heading">Current Pin</p>
                  <p className="allo_content">
                    {clientsData?.current_pin || "-"}
                  </p>
                </div>
                <div className="w-3/5">
                  <p className="allo_heading">Plot Pin</p>
                  <p className="allo_content">{clientsData?.plot_pin || "-"}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <form onSubmit={editProjectInfoFormik.handleSubmit}>
              <div className="flex justify-between items-center border-b-[1px] p-4">
                <span className="font-[Gm] font-medium text-[20px]">
                  Project Info
                </span>
                <div className="flex gap-3">
                  <button
                    className="allo_discard"
                    onClick={() => {
                      setToggle(false);
                    }}
                  >
                    Discard
                  </button>
                  <button
                    className="allo_save"
                    type="submit"
                    onClick={() => {
                      //   setToggle(false);
                    }}
                  >
                    {sLoader ? <SmallLoader color={""} /> : "Save"}
                  </button>
                </div>
              </div>
              <div className="p-4">
                <div className="flex">
                  <div className="w-2/5">
                    <p className="allo_heading mb-3">
                      Pre Construction Start Date
                    </p>
                    <input
                      name="preStartData"
                      onChange={editProjectInfoFormik.handleChange}
                      type="date"
                      className="project_Info"
                      defaultValue={moment(clientsData?.start_date).format(
                        "YYYY-MM-DD"
                      )}
                    />
                  </div>
                  <div className="w-2/5">
                    <div>
                      <p className="allo_heading mb-3">Plot Dimension</p>
                      <div className="flex gap-3">
                        <div className="flex">
                          <input
                            name="plot_length"
                            type="text"
                            className="plot_info"
                            defaultValue={clientsData?.plot_length}
                            onChange={editProjectInfoFormik.handleChange}
                          />
                          <span className="ft">ft.</span>
                        </div>
                        <div className="flex">
                          <input
                            name="plot_breadth"
                            defaultValue={clientsData?.plot_breadth}
                            type="text"
                            className="plot_info"
                            onChange={editProjectInfoFormik.handleChange}
                          />
                          <span className="ft">ft.</span>
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <p className="w-[103px] allo_heading mt-2">Length</p>
                        <p className="w-[103px] allo_heading mt-2">Breadth</p>
                      </div>
                      <div className="flex gap-3">
                        {editProjectInfoFormik.touched.plot_length &&
                          editProjectInfoFormik.errors.plot_length && (
                            <p className="required_message min-w-[103px]">
                              {editProjectInfoFormik.errors.plot_length}
                            </p>
                          )}
                        {editProjectInfoFormik.touched.plot_breadth &&
                          editProjectInfoFormik.errors.plot_breadth && (
                            <p className="required_message min-w-[103px]">
                              {editProjectInfoFormik.errors.plot_breadth}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/5">
                    <p className="allo_heading mb-3">No. of Floors</p>
                    <div className="flex">
                      <span className="ft">G +</span>
                      <input
                        name="no_of_floors"
                        defaultValue={clientsData?.no_of_floors}
                        onChange={editProjectInfoFormik.handleChange}
                        type="text"
                        className="plot_info"
                      />
                    </div>
                    {editProjectInfoFormik.touched.no_of_floors &&
                      editProjectInfoFormik.errors.no_of_floors && (
                        <p className="required_message">
                          {editProjectInfoFormik.errors.no_of_floors}
                        </p>
                      )}
                  </div>
                  <div className="w-1/5">
                    <p className="allo_heading mb-3">SBUA</p>
                    <input
                      name="sbua"
                      type="number"
                      defaultValue={clientsData?.sbua}
                      className="project_Info"
                      onChange={editProjectInfoFormik.handleChange}
                    />
                    {editProjectInfoFormik.touched.sbua &&
                      editProjectInfoFormik.errors.sbua && (
                        <p className="required_message">
                          {editProjectInfoFormik.errors.sbua}
                        </p>
                      )}
                  </div>
                  <div className="w-1/5">
                    <p className="allo_heading mb-3">Demolition Required</p>
                    <div className="project_Info">
                      <select
                        name="demolition_required"
                        onChange={editProjectInfoFormik.handleChange}
                        defaultValue={clientsData?.demolition_required}
                        className="w-[120px] bg-[#f5f8fa] outline-none"
                      >
                        <option hidden>
                          {clientsData?.demolition_required === null
                            ? "Select"
                            : clientsData?.demolition_required
                            ? "Yes"
                            : "No"}
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="flex items-center">
                    <div className="w-2/5">
                      <p className="allo_heading mb-3">Budget</p>
                      <input
                        type="tel"
                        name="budget"
                        defaultValue={clientsData?.budget}
                        onChange={editProjectInfoFormik.handleChange}
                        className="project_Info"
                      />
                      {editProjectInfoFormik.touched.budget &&
                        editProjectInfoFormik.errors.budget && (
                          <p className="required_message">
                            {editProjectInfoFormik.errors.budget}
                          </p>
                        )}
                    </div>
                    <div className="w-2/5">
                      <p className="allo_heading mb-3">Registration Date</p>
                      <input
                        name="registration_date"
                        type="date"
                        className="project_Info"
                        onChange={editProjectInfoFormik.handleChange}
                        defaultValue={moment(
                          clientsData?.registration_date
                        ).format("YYYY-MM-DD")}
                      />
                    </div>
                    <div className="w-1/5">
                      <p className="allo_heading mb-3">Khata Done</p>
                      <div className="project_Info">
                        <select
                          name="khata"
                          onChange={editProjectInfoFormik.handleChange}
                          defaultValue={clientsData?.khata}
                          className="w-[120px] bg-[#f5f8fa] outline-none"
                        >
                          <option value="">
                            {clientsData?.khata === null
                              ? "Select"
                              : clientsData?.khata
                              ? "Yes"
                              : "No"}
                          </option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="w-2/5">
                      <p className="allo_heading mb-3">Gated Community </p>
                      <input
                        name="gated"
                        defaultValue={clientsData?.gated_community}
                        type="text"
                        className="gated"
                        onChange={editProjectInfoFormik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="flex items-center">
                    <div className="w-[57%]">
                      <p className="allo_heading mb-3">Current Location</p>
                      <textarea
                        name="current_location"
                        defaultValue={clientsData?.current_location}
                        onChange={editProjectInfoFormik.handleChange}
                        className="location_input w-[540px]"
                      />
                    </div>
                    <div className="w-[43%]">
                      <p className="allo_heading mb-3">Plot Location</p>
                      <textarea
                        className="location_input w-[463px]"
                        name="plot_location"
                        defaultValue={clientsData?.plot_location}
                        onChange={editProjectInfoFormik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-10">
                  <div className="w-[57%]">
                    <p className="allo_heading mb-3">Current Pin</p>
                    <input
                      name="current_pin"
                      defaultValue={clientsData?.current_pin}
                      onChange={editProjectInfoFormik.handleChange}
                      type="number"
                      className="project_Info"
                    />
                    {editProjectInfoFormik.touched.current_pin &&
                      editProjectInfoFormik.errors.current_pin && (
                        <p className="required_message">
                          {editProjectInfoFormik.errors.current_pin}
                        </p>
                      )}
                  </div>
                  <div className="w-[43%]">
                    <p className="allo_heading mb-3">Plot Pin</p>
                    <input
                      name="plot_pin"
                      defaultValue={clientsData?.plot_pin}
                      onChange={editProjectInfoFormik.handleChange}
                      type="number"
                      className="project_Info"
                    />
                    {editProjectInfoFormik.touched.plot_pin &&
                      editProjectInfoFormik.errors.plot_pin && (
                        <p className="required_message">
                          {editProjectInfoFormik.errors.plot_pin}
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectInfo;
