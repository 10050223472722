import React, { useEffect, useState } from "react";
import axiosClientInterceptors from "../../../../../ApiConfig/axiosClientInterceptor";
import { overviewProps, progressType } from "../../../../../../Types";
import { AxiosResponse } from "axios";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";
import Loader from "../../../../../Tools/Loader";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <div className="flex items-center w-full">
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} color="success" />
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box> */}
    </div>
  );
}

const ProgressBar = ({ clientsData, getUserDetails }: overviewProps) => {
  const [progressData, setProgressData] = useState<progressType>({});
  const [progress, setProgress] = useState<number>(0);

  const getProgress = () => {
    axiosClientInterceptors
      .get(`/clients/progress/${clientsData?._id}`)
      .then((res: AxiosResponse) => {
        setProgressData(res?.data?.result);
        const totPer =
          (res?.data?.result?.completed! * res?.data?.result?.total! * 10) /
          res?.data?.result?.total!;
        setProgress(totPer);
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProgress();
  }, [clientsData?._id]);

  return (
    // <div className="bg-white border-solid border-[1px] mt-5">
    //   <div className="p-4 border-b-[1px] flex justify-between items-center">
    //     <p className="font-[Gm] font-medium text-[20px]">Project Progress</p>
    //     <span className="font-[Gm] font-normal text-[14px] text-[#969696]">
    //       Estimated Due Date:{" "}
    //       <span className="font-[Gm] font-normal text-[14px] text-black">
    //         {progressData?.estimated_due_date
    //           ? moment(progressData?.estimated_due_date).format("DD/MM/YYYY")
    //           : "-"}
    //       </span>
    //     </span>
    //   </div>
    //   <div className="p-4">
    //     <div className="flex justify-between items-center mb-2">
    //       <span className="font-[Gm] font-normal text-[16px] text-[#969696]">
    //         {progressData?.completed} of {progressData?.total} completed
    //       </span>
    //       <span className="font-[Gm] font-normal text-[14px] text-[#969696]">
    //         Days Remaining:
    //         <span className="font-[Gm] font-normal text-[14px] text-black">
    //           {" "}
    //           {progressData?.days} Days
    //         </span>{" "}
    //       </span>
    //       <span className="font-[Gm] font-normal text-[16px] text-[#969696]">
    //         {progress}% Completed
    //       </span>
    //     </div>
    //     <LinearProgressWithLabel value={progress} />
    //   </div>
    // </div>

    <div className="bg-zinc-100  font-sans mt-3">
      <div className="bg-white shadow rounded-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex justify-between items-center gap-3">
            <p className="font-[Gm] font-medium text-[20px] ">
              Project Progress
            </p>
            <div
              className=" text-[#FF0000] py-1 px-3 rounded font-[gsb]"
              style={{ background: "rgba(255, 0, 0, 0.09)" }}
            >
              Days on Hold: 0
            </div>
          </div>
          <div className="text-right mt-1 text-sm  font-[gsb]">
            Estimate Due Date: 11th April, 2024
          </div>
        </div>
        <div className="w-full bg-zinc-300 rounded-full h-2.5 dark:bg-zinc-700">
          <div className="bg-green-500 h-2.5 rounded-full w-[8%]"></div>
        </div>
        <div className="flex justify-between items-center mt-2 text-sm font-[gsb]">
          <span>1 of 13 steps completed</span>
          <span>Days Remaining: 200</span>
          <span>0% Completed</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
