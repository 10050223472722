import React, { useEffect, useState } from "react";
import cendrollogo from "./Assets/cendrollogo.png";
import "./login.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosClientInterceptors from "../ApiConfig/axiosClientInterceptor";
import axios, { AxiosError, AxiosResponse } from "axios";
// import { Toaster } from "../Tools/Toaster";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resType } from "../../Types";
import { useNavigate } from "react-router-dom";
import { Toaster } from "../Tools/Toaster";
import SmallLoader from "../Tools/SmallLoader";
import { setActiveScreen } from "../Features/Screens/Screens";
import { useDispatch } from "react-redux";
// import {resultType} from

const Login = () => {
  const dispatch = useDispatch();
  const [userID, setUserID] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  let navigate = useNavigate();

  const login = () => {
    setLoader(true);
    type Credentails = {
      user: string;
      password: string;
    };
    const cred: Credentails = {
      user: userID,
      password: password,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/users/login`, cred)
      .then((res: AxiosResponse) => {
        console.log(res, "res");
        const data: resType = res.data;
        if (data?.success) {
          localStorage.setItem("token", data.result.token);
          localStorage.setItem("userid", data.result.userID);
          localStorage.setItem("userName", data.result.fullname);
          localStorage.setItem("_id", data.result._id!);
          dispatch(setActiveScreen("AllClients"));
          sessionStorage.setItem("UserType", data.result.role);

          navigate("/Home/AllClients");
          // localStorage.setItem("userType", data.result.role);

          // toast.success(data.message, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          getData();
          Toaster("success", data.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        Toaster("error", err.response?.data?.message);
        setLoader(false);
      });
  };

  const getData = () => {
    axiosClientInterceptors
      .get(`/users/type`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  return (
    <div className="Login_bg">
      <div className="login">
        <div className="flex justify-center items-center min-w-[1400px]">
          <div className="p-10 w-[550px] bg-[#ffff] rounded-[20px] h-[510px]">
            <div className="flex justify-center items-center">
              <img
                src={cendrollogo}
                width="35%"
                style={{ marginTop: "-104px" }}
              />
            </div>
            <div className="flex justify-center items-center">
              <p className="login_txt">Pre - Construction Login</p>
            </div>
            <div className="mt-10">
              <div className="row">
                <div className="col-12">
                  <p className="label_txt mt-5">User ID</p>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="mt-2 login_input_fileds"
                    onChange={(e) => setUserID(e.target.value)}
                    placeholder="Enter User ID"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="label_txt mt-5">Password</p>
                  <div className="mt-2 password_wrapper">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="password_field"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter Your Password"
                      autoComplete="true"
                    />
                    <span
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword === true ? (
                        <VisibilityIcon className="password_eye" />
                      ) : (
                        <VisibilityOffIcon className="password_eye" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <button
                  // disabled={BtnLoader}
                  //   onClick={() => {
                  //   logind();
                  // persistor.purge();
                  //   purgeData();
                  //   }}
                  onClick={() => {
                    login();
                  }}
                  className="mt-5 submit_btn flex items-center justify-center relative"
                >
                  {/* <div className="absolute top-[33%] left-[38%] ">
                  </div> */}
                  {loader ? <SmallLoader color={""} /> : "Login"}
                </button>
              </div>
              <div className="mt-3 flex justify-center">
                <span className="version">
                  version : {process.env.REACT_APP_VERSION}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
