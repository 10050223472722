import React from "react";

const ConstructionOverview = () => {
  return (
    <div>
      <div className="bg-[#FFF] p-6">
        <p className="ConstructionDetails_tittle my-2">Construction Details</p>
        <div className="grid grid-cols-5 gap-6 mt-3">
          <div>
            <p className="StageAccordionDetails__title">Creation Date</p>
            <p className="StageAccordionDetails__subtitle">Normal</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Project Health</p>
            <p className="StageAccordionDetails__subtitle">Normal</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">No. of Floors</p>
            <p className="StageAccordionDetails__subtitle">G + 3</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">SBUA</p>
            <p className="StageAccordionDetails__subtitle">2000 sq. ft</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Built up Area</p>
            <p className="StageAccordionDetails__subtitle">2000 sq. ft</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">
              Construction Start Date
            </p>
            <p className="StageAccordionDetails__subtitle">12th April, 2023</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">
              Actual Start Construction Date
            </p>
            <p className="StageAccordionDetails__subtitle">12th April, 2023</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">
              Construction End Date
            </p>
            <p className="StageAccordionDetails__subtitle">12th April, 2023</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">
              Actual Construction End Date
            </p>
            <p className="StageAccordionDetails__subtitle">12th April, 2023</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Style of Home</p>
            <p className="StageAccordionDetails__subtitle">12th April, 2023</p>
          </div>
          <div className="col-span-2">
            <p className="StageAccordionDetails__title">Site Address</p>
            <p className="StageAccordionDetails__subtitle">
              The Partner app ecosystem helped me be more efficient at what I do
              when I shifted to.
            </p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Site PIN</p>
            <p className="StageAccordionDetails__subtitle">560078</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Package Selected</p>
            <p className="StageAccordionDetails__subtitle">
              Cendrol Prime+ (1890 sq.ft)
            </p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Style of Kitchen</p>
            <p className="StageAccordionDetails__subtitle">L Shape</p>
          </div>
          <div className="col-span-2">
            <p className="StageAccordionDetails__title ">Current Address</p>
            <p className="StageAccordionDetails__subtitle">
              The Partner app ecosystem helped me be more efficient at what I do
              when I shifted to.
            </p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Current PIN</p>
            <p className="StageAccordionDetails__subtitle">560078</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">No. of Bedrooms</p>
            <p className="StageAccordionDetails__subtitle">4</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">No. of Bathrooms</p>
            <p className="StageAccordionDetails__subtitle">4</p>
          </div>
        </div>
      </div>
      <div className="bg-[#FFF] p-6 mt-3">
        <div className=" frlx justify-between items-center">
          <p className="ConstructionDetails_tittle my-2">Allocated Team</p>
          <p className="DaysLeft">Days Left : 60</p>
        </div>

        <div>
          <p></p>
        </div>
      </div>
      <div className="bg-[#FFF] p-6 mt-3">
        <p className="ConstructionDetails_tittle my-2">Allocated Team</p>
        <div className="grid grid-cols-6 gap-6 mt-3">
          <div>
            <p className="StageAccordionDetails__title">Customer Coordinator</p>
            <p className="StageAccordionDetails__subtitle">Rakesh Sharma</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Architect</p>
            <p className="StageAccordionDetails__subtitle">Rakesh Sharma</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Site Engineer</p>
            <p className="StageAccordionDetails__subtitle">Rakesh Sharma</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Project Manager</p>
            <p className="StageAccordionDetails__subtitle">Rakesh Sharma</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Project Coordinator</p>
            <p className="StageAccordionDetails__subtitle">Rakesh Sharma</p>
          </div>
          <div>
            <p className="StageAccordionDetails__title">Team Lead</p>
            <p className="StageAccordionDetails__subtitle">Rakesh Sharma</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConstructionOverview;
