import * as React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Meetings from "./Meetings/Meetings";
import Design from "./Design/Design";
import Task from "./Task/Task";
import Payments from "./Payments/Payments";
import Documents from "./Documents/Documents";
import DesignFiles from "./DesignFiles/DesignFiles";
import { StageObject } from "../../../../../../Types";
import { useSelector } from "react-redux";
import { selectindividualStageData } from "../../../../../Features/AllClients/AllClients";

const styleObject: any = {
  background: "transparent",
  border: "none",
};

export default function StageTabs() {
  const [value, setValue] = React.useState<string>("1");
  const siglestageData = useSelector(selectindividualStageData);
  const handleChangeONE = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  console.log(
    "siglestageData===================================>",
    siglestageData
  );

  return (
    <TabContext value={value}>
      <Box>
        <TabList
          onChange={handleChangeONE}
          aria-label="lab API tabs example"
          TabIndicatorProps={{
            style: { background: "#F6BA00" },
          }}
        >
          <Tab
            className="con_tab"
            label="Meetings"
            value="1"
            style={styleObject}
          />
          <Tab
            disabled={!siglestageData?.display_design}
            className="con_tab "
            label="Design"
            value="2"
            style={styleObject}
          />
          <Tab
            // disabled={!siglestageData?.display_task}
            className="con_tab"
            label="Tasks"
            value="3"
            style={styleObject}
          />

          <Tab
            disabled={!siglestageData?.display_design}
            className="con_tab"
            label="Design Files"
            value="4"
            style={styleObject}
          />
          {/* {siglestageData?.display_payment && ( */}
          <Tab
            disabled={!siglestageData?.display_payment}
            className="con_tab "
            label="Payments"
            value="5"
            style={styleObject}
          />
          {/* )} */}
          <Tab
            className="con_tab"
            label="Documents"
            value="6"
            style={styleObject}
          />
        </TabList>
      </Box>
      <TabPanel
        className="min-h-[650px] p-10  bg-[#F5F8FA] mt-3"
        value="1"
        style={{ padding: "0px" }}
      >
        <Meetings siglestageData={siglestageData} />
      </TabPanel>
      <TabPanel
        style={{ padding: "0px" }}
        className="min-h-[650px] p-10  bg-[#F5F8FA]"
        value="2"
      >
        <Design siglestageData={siglestageData} />
      </TabPanel>
      {siglestageData?.display_task && (
        <TabPanel
          style={{ padding: "0px" }}
          className="min-h-[650px] p-10  bg-[#F5F8FA]"
          value="3"
        >
          <Task siglestageData={siglestageData} />
        </TabPanel>
      )}
      {siglestageData?.display_payment && (
        <TabPanel
          style={{ padding: "0px" }}
          className="min-h-[650px] p-10  bg-[#F5F8FA]"
          value="5"
        >
          <Payments siglestageData={siglestageData} />
        </TabPanel>
      )}
      <TabPanel
        style={{ padding: "0px" }}
        className="min-h-[650px] p-10  bg-[#F5F8FA]"
        value="6"
      >
        <Documents siglestageData={siglestageData} />
      </TabPanel>
      <TabPanel
        style={{ padding: "0px" }}
        className="min-h-[650px] p-10  bg-[#F5F8FA]"
        value="4"
      >
        <DesignFiles siglestageData={siglestageData} />
      </TabPanel>
    </TabContext>
  );
}
