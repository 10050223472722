import React, { KeyboardEvent, useEffect, useState } from "react";
import {
  resType,
  usePersonalInfoFormik,
  userCredFromik,
  userData,
  userDetailProps,
} from "../../../../Types";
import axiosClientInterceptors from "../../../ApiConfig/axiosClientInterceptor";
import { AxiosResponse } from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { number } from "yup";
import moment from "moment";
import { useFormik } from "formik";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Dialog } from "@mui/material";
import { Toaster } from "../../../Tools/Toaster";
import Loader from "../../../Tools/Loader";
import { validateUpdateUser } from "../../../Tools/Validation";

const UserDetails = ({
  userID,
  setSwitchScreen,
  city,
}: userDetailProps): JSX.Element => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [toggleInfo, setToggleInfo] = useState<boolean>(false);
  const [actionMenu, setActionMenu] = useState<boolean>(false);

  const [loader, setLoader] = useState<boolean>(false);

  const [openDeactivate, setOpenDeactivate] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<userData>({
    city: "",
    doj: "",
    emp_ID: "",
    fullname: "",
    mobile: 0,
    password: "",
    role: "",
    userID: "",
    user_status: "",
    _id: "",
  });

  const updateUserFormik = useFormik<usePersonalInfoFormik>({
    enableReinitialize: true,
    initialValues: {
      fullName: userInfo.fullname || "",
      empID: userInfo.emp_ID || "",
      mobileNumber: userInfo.mobile || 0,
      city: userInfo.city || "",
    },
    validationSchema: validateUpdateUser,
    onSubmit: (value) => {
      console.log(value);
      axiosClientInterceptors
        .put(`/users/${userID}`, {
          fullname: value.fullName,
          emp_ID: value.empID,
          mobile: value.mobileNumber,
          city: value.city,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getUserDetails();
            setToggle(false);
            Toaster("success", data?.message);
          } else {
            Toaster("error", data?.message);
          }
        })
        .catch((err: AxiosResponse) => {
          console.log(err);
        });
    },
  });

  const updateCredFormik = useFormik<userCredFromik>({
    enableReinitialize: true,
    initialValues: {
      userID: userInfo.userID || "",
      password: userInfo.password || "",
    },
    onSubmit: (value) => {
      console.log(value);
      axiosClientInterceptors
        .put(`/users/${userID}`, {
          userID: value.userID,
          password: value.password,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            setToggleInfo(false);
            getUserDetails();
            Toaster("success", data?.message);
          } else {
            Toaster("error", data?.message);
          }
        })
        .catch((err: AxiosResponse) => {
          console.log(err);
        });
    },
  });

  const getUserDetails = () => {
    setLoader(true);
    axiosClientInterceptors
      .get(`/users/${userID}`)
      .then((res: AxiosResponse) => {
        setUserInfo(res.data.result);
        setLoader(false);
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
        setLoader(false);
      });
  };

  const deleteUser = () => {
    axiosClientInterceptors
      .post(`/users/${userID}`)
      .then((res: AxiosResponse) => {
        const data: resType = res.data;
        if (data?.success) {
          setSwitchScreen("userListing");
          Toaster("success", data?.message);
        } else {
          Toaster("error", data?.message);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div>
      <div className="p-6 h-[716px]">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex">
              <p
                className="usersPath text-[#969696] cursor-pointer"
                onClick={() => {
                  setSwitchScreen("userListing");
                }}
              >
                Manage Users /
              </p>{" "}
              <p className="mt-0 usersPath indent-1 text-black cursor-pointer">
                {" "}
                User Details
              </p>
            </div>
            <div>
              <button
                className="action_btn"
                onClick={() => {
                  setActionMenu(!actionMenu);
                }}
              >
                Action <KeyboardArrowDownIcon sx={{ marginLeft: "3px" }} />
                {actionMenu && (
                  <ClickAwayListener
                    onClickAway={() => {
                      setActionMenu(false);
                    }}
                  >
                    <div className="menulist">
                      <div
                        className="menu_item rounded-t-lg border-b-[1px] border-solid"
                        onClick={() => {
                          setOpenDeactivate(true);
                        }}
                      >
                        Deactivate User
                      </div>
                      {/* <div
                        className="menu_item rounded-b-lg"
                        onClick={() => {
                          // setImportDataOpen(true);
                        }}
                      >
                        Import Data
                      </div> */}
                    </div>
                  </ClickAwayListener>
                )}
              </button>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="p-4 bg-white mt-8 w-3/5 min-h-[258px]">
              {loader ? (
                <Loader customHeight={"28vh"} />
              ) : (
                <>
                  {!toggle ? (
                    <div>
                      <div className="pb-4 flex justify-between items-center border-b border-[#f2f2f2]">
                        <div className="flex gap-4">
                          <p className="font[Gm] font-semibold text-[16px] text-[#000000]">
                            Personal Info
                          </p>
                          <div
                            className={
                              userInfo?.user_status === "active"
                                ? "activeUser"
                                : "inactiveUser"
                            }
                          >
                            {userInfo?.user_status || "-"}
                          </div>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setToggle(true);
                          }}
                        >
                          <svg
                            width="29"
                            height="29"
                            viewBox="0 0 29 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="14.5"
                              cy="14.5"
                              r="14.5"
                              fill="#F7F7F7"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.8602 19.5174L18.3517 12.4159C18.6502 12.0329 18.7563 11.5902 18.6568 11.1394C18.5706 10.7295 18.3186 10.3399 17.9405 10.0442L17.0187 9.31191C16.2162 8.67365 15.2213 8.74084 14.651 9.47316L14.0342 10.2733C13.9546 10.3735 13.9745 10.5213 14.0739 10.6019C14.0739 10.6019 15.6325 11.8515 15.6657 11.8784C15.7718 11.9792 15.8514 12.1136 15.8713 12.2748C15.9044 12.5906 15.6856 12.8862 15.3606 12.9265C15.2081 12.9467 15.0622 12.8996 14.956 12.8123L13.3179 11.5089C13.2383 11.4491 13.1189 11.4619 13.0526 11.5425L9.15946 16.5814C8.90744 16.8972 8.82122 17.307 8.90744 17.7034L9.40485 19.8601C9.43138 19.9743 9.53087 20.0549 9.65025 20.0549L11.8389 20.0281C12.2368 20.0213 12.6082 19.8399 12.8602 19.5174ZM15.9246 18.8462H19.4934C19.8416 18.8462 20.1248 19.1331 20.1248 19.4858C20.1248 19.8392 19.8416 20.1254 19.4934 20.1254H15.9246C15.5764 20.1254 15.2932 19.8392 15.2932 19.4858C15.2932 19.1331 15.5764 18.8462 15.9246 18.8462Z"
                              fill="#FCC314"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="flex py-4">
                        <div className="w-1/4">
                          <p className="user_title">Full Name</p>
                          {/* <p className="mt-0 user_value">{userInfo?.name || "-"}</p> */}
                          <p className="user_value">{userInfo.fullname}</p>
                        </div>
                        <div className="w-1/4">
                          <p className="user_title">Employee ID</p>
                          <p className="user_value">{userInfo.emp_ID}</p>
                        </div>
                        <div className="w-1/4">
                          <p className="user_title">Joining Date</p>
                          <p className="user_value">
                            {userInfo?.doj
                              ? moment(userInfo?.doj).format("DD/MM/yyyy")
                              : "-"}
                          </p>
                        </div>
                        <div className="w-1/4">
                          <p className="user_title">User Type</p>
                          <p className="mt-0 user_value">
                            {userInfo?.role || "-"}
                          </p>
                        </div>
                      </div>
                      <div className="flex py-4">
                        <div className="w-1/4">
                          <p className="user_title">Mobile Number</p>
                          <p className="mt-0 user_value">
                            {"+91"}
                            {userInfo?.mobile || "-"}
                          </p>
                        </div>
                        <div className="w-1/4">
                          <p className="user_title">City</p>
                          <p className="mt-0 user_value">
                            {userInfo.city || "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <form onSubmit={updateUserFormik.handleSubmit}>
                        <div className="flex pb-4 justify-between items-center border-b border-[#f2f2f2]">
                          <p className="font[Gm] font-semibold text-[16px] text-[#000000]">
                            Personal Info
                          </p>
                          <div>
                            <button type="submit" className="saveForm">
                              Save
                            </button>
                          </div>
                        </div>
                        <div className="flex py-4">
                          <div className="w-1/4">
                            <label htmlFor="fullName" className="user_title">
                              Full Name
                            </label>
                            <input
                              name="fullName"
                              defaultValue={userInfo.fullname}
                              type="text"
                              className="userInput mt-1"
                              onChange={updateUserFormik.handleChange}
                            />
                            {updateUserFormik.touched.fullName &&
                              updateUserFormik.errors.fullName && (
                                <p className="required_message">
                                  {updateUserFormik.errors.fullName}
                                </p>
                              )}
                          </div>
                          <div className="w-1/4">
                            <label htmlFor="empId" className="user_title">
                              Employee ID
                            </label>
                            <input
                              name="empID"
                              defaultValue={userInfo?.emp_ID}
                              type="text"
                              className="userInput mt-1"
                              onChange={updateUserFormik.handleChange}
                              onKeyDown={(e: KeyboardEvent) => {
                                let element = e.target as HTMLInputElement;
                                let value = element.value;
                                // let num = e.target.value;
                                // if (e.keyCode === 69 || e.key == "." || e.key === "-" || e.key === "+" || num.length >= 10 && e.keyCode !== 8) {
                                //   e.preventDefault();
                                // }
                                if (value?.length >= 6 && e.keyCode !== 8) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {updateUserFormik.touched.empID &&
                              updateUserFormik.errors.empID && (
                                <p className="required_message">
                                  {updateUserFormik.errors.empID}
                                </p>
                              )}
                          </div>
                          <div className="w-1/4">
                            <label className="user_title mb-1">
                              Joining Date
                            </label>
                            <div className="userInput2 mt-1">
                              {userInfo.doj === ""
                                ? "-"
                                : moment(userInfo?.doj).format("DD/MM/yyyy")}
                            </div>
                          </div>
                          <div className="w-1/4">
                            <label className="user_title mb-1">User Type</label>
                            <div className="userInput2 mt-1">
                              {userInfo?.role || "-"}
                            </div>
                          </div>
                        </div>
                        <div className="flex py-4">
                          <div className="w-1/4">
                            <label
                              htmlFor="mobileNumber"
                              className="user_title mb-1"
                            >
                              Mobile Number
                            </label>
                            <div className="flex">
                              <div className="plus91 mt-1">
                                <p>+91</p>
                              </div>
                              <div>
                                <input
                                  name="mobileNumber"
                                  defaultValue={userInfo?.mobile}
                                  type="text"
                                  className="numberinput mt-1"
                                  onChange={updateUserFormik.handleChange}
                                  onKeyDown={(e: KeyboardEvent) => {
                                    let element = e.target as HTMLInputElement;
                                    let value = element.value;
                                    // let num = e.target.value;
                                    // if (e.keyCode === 69 || e.key == "." || e.key === "-" || e.key === "+" || num.length >= 10 && e.keyCode !== 8) {
                                    //   e.preventDefault();
                                    // }
                                    if (
                                      value?.length >= 10 &&
                                      e.keyCode !== 8
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            {updateUserFormik.touched.mobileNumber &&
                              updateUserFormik.errors.mobileNumber && (
                                <p className="required_message">
                                  {updateUserFormik.errors.mobileNumber}
                                </p>
                              )}
                          </div>
                          <div className="w-1/4">
                            <label htmlFor="city" className="user_title mb-1">
                              City
                            </label>
                            <div>
                              <select
                                name="city"
                                className="userInput mt-1"
                                defaultValue={userInfo.city}
                                onChange={updateUserFormik.handleChange}
                              >
                                <option hidden>
                                  {userInfo.city ? userInfo.city : "Select"}
                                </option>
                                <option hidden></option>
                                {city?.map((city, i) => (
                                  <option key={i} value={city.city}>
                                    {city.city}
                                  </option>
                                ))}
                              </select>{" "}
                              {updateUserFormik.touched.city &&
                                updateUserFormik.errors.city && (
                                  <p className="required_message">
                                    {updateUserFormik.errors.city}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="p-4 bg-white mt-8 w-2/5">
              {loader ? (
                <Loader customHeight={"28vh"} />
              ) : (
                <>
                  {!toggleInfo ? (
                    <div>
                      <div className="flex pb-4 justify-between items-center border-b border-[#f2f2f2]">
                        <p className="font[Gm] font-semibold text-[16px] text-[#000000]">
                          Credentials
                        </p>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setToggleInfo(true);
                          }}
                        >
                          <svg
                            width="29"
                            height="29"
                            viewBox="0 0 29 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="14.5"
                              cy="14.5"
                              r="14.5"
                              fill="#F7F7F7"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.8602 19.5174L18.3517 12.4159C18.6502 12.0329 18.7563 11.5902 18.6568 11.1394C18.5706 10.7295 18.3186 10.3399 17.9405 10.0442L17.0187 9.31191C16.2162 8.67365 15.2213 8.74084 14.651 9.47316L14.0342 10.2733C13.9546 10.3735 13.9745 10.5213 14.0739 10.6019C14.0739 10.6019 15.6325 11.8515 15.6657 11.8784C15.7718 11.9792 15.8514 12.1136 15.8713 12.2748C15.9044 12.5906 15.6856 12.8862 15.3606 12.9265C15.2081 12.9467 15.0622 12.8996 14.956 12.8123L13.3179 11.5089C13.2383 11.4491 13.1189 11.4619 13.0526 11.5425L9.15946 16.5814C8.90744 16.8972 8.82122 17.307 8.90744 17.7034L9.40485 19.8601C9.43138 19.9743 9.53087 20.0549 9.65025 20.0549L11.8389 20.0281C12.2368 20.0213 12.6082 19.8399 12.8602 19.5174ZM15.9248 18.8458H19.4936C19.8418 18.8458 20.125 19.1327 20.125 19.4854C20.125 19.8388 19.8418 20.125 19.4936 20.125H15.9248C15.5766 20.125 15.2934 19.8388 15.2934 19.4854C15.2934 19.1327 15.5766 18.8458 15.9248 18.8458Z"
                              fill="#FCC314"
                            />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <div className="py-4">
                          <p className="user_title">User ID</p>
                          <p className="mt-0 user_value">{userInfo?.userID}</p>
                        </div>
                        <div className="py-4">
                          <p className="user_title">Password</p>
                          <p className="mt-0 user_value">
                            {userInfo.password ? "***" : "NA"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <form onSubmit={updateCredFormik.handleSubmit}>
                        <div className="flex pb-4 justify-between items-center border-b border-[#f2f2f2]">
                          <p className="font[Gm] font-semibold text-[16px] text-[#000000]">
                            Credentials
                          </p>
                          <div>
                            <button type="submit" className="saveForm">
                              Save
                            </button>
                          </div>
                        </div>
                        <div className="">
                          <div className=" py-4">
                            <p className="user_title">User ID</p>
                            <input
                              name="userID"
                              type="email"
                              defaultValue={userInfo?.userID}
                              className="userInput mt-1"
                              onChange={updateCredFormik.handleChange}
                            />
                          </div>
                          <div className=" py-4">
                            <p className="user_title">Password</p>
                            <input
                              name="password"
                              type="text"
                              defaultValue={userInfo?.password}
                              className="userInput mt-1"
                              onChange={updateCredFormik.handleChange}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* deactivate user pop up starts */}
      <Dialog
        open={openDeactivate}
        onClose={() => {
          setOpenDeactivate(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "393px",
            },
          },
        }}
      >
        <div className="border-t-[4px] border-t-[#E54B4B]">
          <div className="p-5">
            <div className="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="71"
                height="71"
                fill="none"
                viewBox="0 0 71 71"
              >
                <g>
                  <path
                    fill="#FE3E3E"
                    fillRule="evenodd"
                    d="M.918 35.497C.918 16.411 16.411.914 35.501.914c19.125 0 34.584 15.497 34.584 34.583 0 19.094-15.46 34.584-34.584 34.584C16.411 70.08.918 54.59.918 35.497zm31.54-13.107c0-1.656 1.383-3.043 3.043-3.043 1.66 0 3.01 1.387 3.01 3.043v15.286a3.009 3.009 0 01-3.01 3.009c-1.66 0-3.043-1.345-3.043-3.009V22.39zm3.078 29.296a3.048 3.048 0 01-3.043-3.044 3.01 3.01 0 013.008-3.008c1.695 0 3.044 1.349 3.044 3.008 0 1.66-1.35 3.044-3.01 3.044z"
                    clipRule="evenodd"
                  ></path>
                </g>
              </svg>
            </div>
            <div>
              <p className="deactivateContent mt-3">
                Are you sure <br /> want to deactivate this user ?{" "}
              </p>
            </div>
            <div className="mt-7 flex justify-between items-center">
              <button
                onClick={() => {
                  deleteUser();
                }}
                className="deactivate_btn"
              >
                Deactivate
              </button>
              <button
                className="cancelbtn"
                onClick={() => {
                  setOpenDeactivate(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      {/* deactivate user pop up ends */}
    </div>
  );
};

export default UserDetails;
