import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Toaster = (
  type: "success" | "error" | "info",
  message: string
) => {
  return toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
