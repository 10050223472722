import React, { KeyboardEvent, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import doc from "../../../Assets/svg/Doc.svg";
import haltIcon from "../../../Assets/svg/haltIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import note from "../../../Assets/svg/NoteIcon.svg";
import email from "../../../Assets/svg/Email.svg";
import call from "../../../Assets/svg/Call.svg";
import whatsapp from "../../../Assets/svg/whatsapp.svg";
import FileIcone from "../../../Assets/svg/FileIcone.svg";

import deleteIcon from "../../../Assets/svg/del.svg";
import delIcon from "../../../Assets/svg/deletelogo.svg";
import Dialog from "@mui/material/Dialog";
import ErrorIcon from "@mui/icons-material/Error";
import {
  cities,
  clientDataType,
  clientFormik,
  haltReasonFormik,
  projectStatusType,
  resType,
} from "../../../../../Types";
import axios, { AxiosResponse } from "axios";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import axiosClientInterceptors from "../../../../ApiConfig/axiosClientInterceptor";
import { useFormik } from "formik";
import Tabs from "./PreconstructionTabs";
import SmallLoader from "../../../../Tools/SmallLoader";
import { Toaster } from "../../../../Tools/Toaster";
import Loader from "../../../../Tools/Loader";
import { validateClientDetails } from "../../../../Tools/Validation";
import { selectClientID } from "../../../../Features/AllClients/AllClients";
import { useAppSelector } from "../../../App/store";
import { useNavigate } from "react-router-dom";

const ClientDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const clientID = useAppSelector(selectClientID);
  const [sLoader, setSLoader] = useState<boolean>(false);
  const [actionMenu, setActionMenu] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [clientsData, setClientsData] = useState<clientDataType>();
  const [city, setCity] = useState<cities[]>([]);
  const [openDel, setOpenDel] = useState<boolean>(false);
  const [openHalt, setOpenHalt] = useState<boolean>(false);
  const [projectStatus, setProjectStatus] = useState<projectStatusType[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [clientPstatus, setClientPstatus] = useState<string>("");

  const editFormik = useFormik<clientFormik>({
    enableReinitialize: true,
    initialValues: {
      fullname: clientsData?.fullname,
      number: clientsData?.mobile,
      alt_number: clientsData?.alt_number,
      emailID: clientsData?.email,
      city: clientsData?.city,
      occupation: clientsData?.occupation,
      projectStatus: clientsData?.project_status,
    },
    validationSchema: validateClientDetails,
    onSubmit: (val) => {
      setSLoader(true);
      axiosClientInterceptors
        .put(`/clients/${clientID}`, {
          fullname: val.fullname,
          number: val.number,
          alt_number: val.alt_number,
          email: val.emailID,
          city: val.city,
          occupation: val.occupation,
          project_status: val.projectStatus,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            setSLoader(false);
            getUserDetails();
            setToggle(false);
            Toaster("success", data?.message);
          } else {
            Toaster("error", data?.message);
          }
        })
        .catch((err: AxiosResponse) => {
          console.log(err);
          setSLoader(false);
        });
    },
  });

  const getProjectStatus = () => {
    axiosClientInterceptors
      .get(`/general/config?heading=Project Status`)
      .then((res: AxiosResponse) => {
        setProjectStatus(res.data?.result);
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
      });
  };

  const addHaltReasonFormik = useFormik<haltReasonFormik>({
    initialValues: {
      reason: "",
    },
    onSubmit: (val) => {
      axiosClientInterceptors
        .patch(`/clients/${clientID}`, {
          status: "inactive",
          halt_reason: val.reason,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getUserDetails();
            setOpenHalt(false);
            addHaltReasonFormik.resetForm({});
            Toaster("success", data?.message);
          } else {
            Toaster("error", data?.message);
          }
        });
    },
  });

  const getAllCity = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_CRM}/get-city`)
      .then((res: AxiosResponse) => {
        setCity(res.data?.result);
      });
  };

  const getUserDetails = () => {
    setLoader(true);
    axiosClientInterceptors
      .get(`/clients/${clientID}`)
      .then((res: AxiosResponse) => {
        setClientsData(res.data?.result[0]);
        setLoader(false);
      })
      .catch((err: AxiosResponse) => {
        setLoader(false);
      });
  };

  const deleteUser = () => {
    axiosClientInterceptors
      .delete(`/clients/${clientID}`)
      .then((res: AxiosResponse) => {
        const data: resType = res.data;
        if (data.success) {
          // setClientScreens("listing");
          Toaster("success", data?.message);
        } else {
          Toaster("error", data?.message);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserDetails();
    getProjectStatus();
    getAllCity();
  }, []);

  // useEffect(() => {
  //   getAllCity();
  // }, []);

  return (
    <>
      <div className="py-4 px-3">
        <div className="flex items-center border-b-[1px] pb-[18px]">
          <span
            className="text-[16px] text-[#667085] font-medium cursor-pointer"
            onClick={() => {
              navigate("/Home/AllClients"); // Example navigation
            }}
          >
            All Clients
          </span>
          <div className="p-1">
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 0.5L5.5 5L1 9.5" stroke="#667085" />
            </svg>
          </div>
          <span className="text-[16px] text-[#667085] font-medium">
            Clients Details
          </span>
        </div>
        {loader ? (
          <Loader customHeight={"55vh"} />
        ) : (
          <>
            {clientsData?.status === "inactive" && (
              <div className="p-3 h-[113px] bg-[#FFF4F4]">
                <p className="flex items-center font-sans text-[13px] font-normal text-[#E92323]">
                  <ErrorIcon
                    sx={{
                      fontSize: "16px",
                      color: "#E92323",
                      paddingRight: "2px",
                    }}
                  />{" "}
                  Halt Reason
                </p>
                <p className="font-sans font-normal text-[13px] text-black">
                  {clientsData?.status_reason || "-"}
                </p>
              </div>
            )}

            {!toggle ? (
              <div className="p-3">
                <div className="flex items-center justify-between">
                  <span className="font-[Gm] text-[18px] font-medium">
                    Client Details
                  </span>
                  <div className="flex gap-3">
                    <button
                      className="edit_details"
                      onClick={() => {
                        setToggle(true);
                      }}
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.584 1.58714C10.7685 1.39612 10.9892 1.24375 11.2332 1.13894C11.4772 1.03412 11.7397 0.978946 12.0052 0.976638C12.2708 0.97433 12.5342 1.02493 12.78 1.1255C13.0257 1.22606 13.2491 1.37456 13.4368 1.56235C13.6246 1.75013 13.7731 1.97344 13.8737 2.21923C13.9743 2.46502 14.0249 2.72838 14.0225 2.99394C14.0202 3.2595 13.9651 3.52194 13.8603 3.76595C13.7554 4.00996 13.6031 4.23065 13.412 4.41514L12.619 5.20814L9.79105 2.38014L10.584 1.58714ZM8.37705 3.79414L-0.00195312 12.1731V15.0011H2.82605L11.206 6.62214L8.37605 3.79414H8.37705Z"
                          fill="#F6F6F6"
                        />
                      </svg>
                      Edit
                    </button>
                    <button
                      // disabled={true}
                      className="action_btn"
                      onClick={() => {
                        setActionMenu(!actionMenu);
                      }}
                    >
                      Action{" "}
                      <KeyboardArrowDownIcon sx={{ marginLeft: "3px" }} />
                      {actionMenu && (
                        <ClickAwayListener
                          onClickAway={() => {
                            setActionMenu(false);
                          }}
                        >
                          <div className="actionList">
                            {/* <div
                        className="menuItem rounded-t-lg border-b-[1px] border-solid"
                        onClick={() => {
                          //   setOpenDeactivate(true);
                        }}
                      >
                        <img src={doc} /> View All Document
                      </div> */}
                            <div
                              className="hover:opacity-[0.7] hover:bg-[#F6F6F6]  menuItem border-solid"
                              onClick={() => {
                                setOpenHalt(true);
                              }}
                            >
                              <img src={haltIcon} /> Make Project Halt
                            </div>
                            <div
                              className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid"
                              onClick={() => {
                                // setImportDataOpen(true);
                              }}
                            >
                              <img src={note} /> Create Note
                            </div>
                            <div
                              className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid"
                              onClick={() => {
                                // setImportDataOpen(true);
                              }}
                            >
                              <img src={email} /> Send Email
                            </div>
                            <div
                              className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid"
                              onClick={() => {
                                // setImportDataOpen(true);
                              }}
                            >
                              <img src={call} /> Call
                            </div>
                            <div
                              className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid"
                              onClick={() => {
                                // setImportDataOpen(true);
                              }}
                            >
                              <img src={whatsapp} /> WhatsApp Call
                            </div>

                            <div
                              className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid "
                              onClick={() => {
                                setOpenDel(true);
                                navigate("/Home/ActionDOC");
                              }}
                            >
                              <img src={FileIcone} /> Documents
                            </div>
                          </div>
                        </ClickAwayListener>
                      )}
                    </button>
                  </div>
                </div>
                <div>
                  <p className=" mt-4 font-[Gm] font-medium text-[15px] text-[#969696]">
                    Client Name
                  </p>
                  <p className="font-[Gm] font-semibold text-[24px] text-[#252C32]">
                    {clientsData?.fullname || "-"}
                  </p>
                </div>
                <div className="mt-4 flex items-center gap-20">
                  <div className="w-full">
                    <p className="user_heading">Mobile Number</p>
                    <p className="user_data">
                      +91 {clientsData?.mobile || "-"}
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="user_heading">Alt Mobile Number</p>
                    <p className="user_data">
                      +91 {clientsData?.alt_number || "-"}
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="user_heading">Email ID</p>
                    <p className="user_data">{clientsData?.email || "-"}</p>
                  </div>
                  <div className="w-full">
                    <p className="user_heading">Client ID</p>
                    <p className="user_data">
                      {clientsData?.customer_no || "-"}
                    </p>
                  </div>
                </div>
                <div className="mt-8 flex items-center gap-20">
                  <div className="w-full">
                    <p className="user_heading">City</p>
                    <p className="user_data">{clientsData?.city || "-"}</p>
                  </div>
                  <div className="w-full">
                    <p className="user_heading">Occupation</p>
                    <p className="user_data">
                      {clientsData?.occupation || "-"}
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="user_heading">Package</p>
                    <p className="user_data">
                      {" "}
                      {clientsData?.package?.package_name
                        ? `${clientsData?.package?.package_name} (${clientsData?.package?.price_per_sqft} sq/ft)`
                        : "-"}
                    </p>
                  </div>
                  <div className="w-full">
                    <p className="user_heading">Project Status</p>
                    <p className="user_data">
                      {clientsData?.project_status || "-"}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-3">
                <form onSubmit={editFormik.handleSubmit}>
                  <div className="flex items-center justify-between">
                    <span className="font-[Gm] text-[18px] font-medium">
                      Client Details
                    </span>
                    <div className="flex gap-4">
                      <button
                        className="discard"
                        onClick={() => {
                          setToggle(false);
                        }}
                      >
                        Discard
                      </button>
                      <button className="save" type="submit">
                        {sLoader ? <SmallLoader color={""} /> : "Save"}
                      </button>
                    </div>
                  </div>
                  <div>
                    <p className=" mt-4 font-[Gm] font-medium text-[15px] text-[#969696]">
                      Client Name
                    </p>
                    <input
                      name="fullname"
                      type="text"
                      defaultValue={clientsData?.fullname}
                      className="user_input text-[#221e1f]"
                      onChange={editFormik.handleChange}
                    />{" "}
                    {editFormik.touched.fullname &&
                      editFormik.errors.fullname && (
                        <p className="required_message">
                          {editFormik.errors.fullname}
                        </p>
                      )}
                  </div>
                  <div className="mt-4 flex items-center gap-20">
                    <div className="w-full">
                      <p className="user_heading">Mobile Number</p>
                      <input
                        name="number"
                        type="text"
                        defaultValue={clientsData?.mobile}
                        className="user_input text-[#221e1f]"
                        onChange={editFormik.handleChange}
                        onKeyDown={(e: KeyboardEvent) => {
                          let element = e.target as HTMLInputElement;
                          let value = element.value;
                          // let num = e.target.value;
                          // if (e.keyCode === 69 || e.key == "." || e.key === "-" || e.key === "+" || num.length >= 10 && e.keyCode !== 8) {
                          //   e.preventDefault();
                          // }
                          if (value?.length >= 10 && e.keyCode !== 8) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {editFormik.touched.number &&
                        editFormik.errors.number && (
                          <p className="required_message">
                            {editFormik.errors.number}
                          </p>
                        )}
                    </div>
                    <div className="w-full">
                      <p className="user_heading">Alt Mobile Number</p>
                      <input
                        name="alt_number"
                        type="text"
                        defaultValue={clientsData?.alt_number}
                        className="user_input text-[#221e1f]"
                        onChange={editFormik.handleChange}
                        onKeyDown={(e: KeyboardEvent) => {
                          let element = e.target as HTMLInputElement;
                          let value = element.value;
                          // let num = e.target.value;
                          // if (e.keyCode === 69 || e.key == "." || e.key === "-" || e.key === "+" || num.length >= 10 && e.keyCode !== 8) {
                          //   e.preventDefault();
                          // }
                          if (value?.length >= 10 && e.keyCode !== 8) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {editFormik.touched.alt_number &&
                        editFormik.errors.alt_number && (
                          <p className="required_message">
                            {editFormik.errors.alt_number}
                          </p>
                        )}
                    </div>
                    <div className="w-full">
                      <p className="user_heading">Email ID</p>
                      <input
                        name="emailID"
                        type="email"
                        className="user_input text-[#221e1f]"
                        defaultValue={clientsData?.email}
                        onChange={editFormik.handleChange}
                      />
                      {editFormik.touched.emailID &&
                        editFormik.errors.emailID && (
                          <p className="required_message">
                            {editFormik.errors.emailID}
                          </p>
                        )}
                    </div>
                    <div className="w-full">
                      <p className="user_heading">Client ID</p>
                      <div className="user_input text-[#969696]">
                        {clientsData?.client_id || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 flex gap-20">
                    <div className="w-full">
                      <p className="user_heading">City</p>
                      {/* <Select
                        name="city"
                        onValueChange={(val) => {
                          setClientCity(val);
                        }}
                        defaultValue={clientsData?.city}
                      >
                        <SelectTrigger className="w-[168px] h-[42px] mt-2">
                          <SelectValue
                            placeholder={
                              `${clientsData?.city}` || "Select City"
                            }
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup className="font-[Gr] text-[14px] font-semibold">
                            {city?.map((city, i) => (
                              <SelectItem value={city?.city}>
                                {city?.city}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select> */}
                      <select
                        name="city"
                        className="user_input text-[#221e1f]"
                        onChange={editFormik.handleChange}
                      >
                        <option value="" hidden>
                          {clientsData?.city || "Select City"}
                        </option>
                        {city?.map((city, i) => (
                          <option value={city?.city}>{city?.city}</option>
                        ))}
                      </select>
                      {editFormik.touched.city && editFormik.errors.city && (
                        <p className="required_message">
                          {editFormik.errors.city}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <p className="user_heading">Occupation j</p>
                      <input
                        name="occupation"
                        type="text"
                        maxLength={30}
                        className="user_input text-[#221e1f]"
                        defaultValue={clientsData?.occupation}
                        onChange={editFormik.handleChange}
                      />
                      {/* {editFormik.touched.occupation &&
                        editFormik.errors.occupation && (
                          <p className="required_message">
                            {editFormik.errors.occupation}
                          </p>
                        )} */}
                    </div>
                    <div className="w-full">
                      <p className="user_heading">Package</p>
                      <div className="user_input text-[#969696]">
                        {clientsData?.package?.package_name
                          ? `${clientsData?.package?.package_name} (${clientsData?.package?.price_per_sqft} sq/ft)`
                          : "-"}
                      </div>
                    </div>
                    <div className="w-full">
                      <p className="user_heading">Project Status</p>
                      {/* <input type="text" className="user_input text-[#221e1f]" /> */}
                      <select
                        name="projectStatus"
                        className="user_input text-[#221e1f]"
                        onChange={editFormik.handleChange}
                      >
                        <option value="" hidden>
                          {clientsData?.project_status || "Select "}
                        </option>
                        {projectStatus?.map((status, i) => (
                          <option value={status?.section_name}>
                            {status?.section_name}
                          </option>
                        ))}
                      </select>
                      {editFormik.touched.projectStatus &&
                        editFormik.errors.projectStatus && (
                          <p className="required_message">
                            {editFormik.errors.projectStatus}
                          </p>
                        )}
                      {/* <Select
                        name="projectStatus"
                        onValueChange={(val) => {
                          setClientPstatus(val);
                        }}
                        // defaultValue={clientsData?.project_status}
                      >
                        <SelectTrigger className="w-[168px] h-[42px] mt-2">
                          <SelectValue
                            placeholder={`${
                              clientsData?.project_status || "select"
                            }`}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup className="font-[Gr] text-[14px] font-semibold">
                            {projectStatus?.map((status, i) => (
                              <SelectItem key={i} value={status?.section_name}>
                                {status?.section_name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select> */}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </>
        )}

        {/* delete pop starts */}
        <Dialog
          open={openDel}
          onClose={() => {
            setOpenDel(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "404px",
                height: "265px",
              },
            },
          }}
        >
          <div className="p-5">
            <div className="flex justify-center items-center">
              <img
                style={{
                  width: "80px",
                }}
                src={delIcon}
                alt=""
              />
            </div>
            <div className="deleteText mt-6">
              Are you sure want to Delete this User?
            </div>
            <div className="flex justify-between items-center mt-9">
              <button
                className="cancelDelUser"
                onClick={() => {
                  setOpenDel(false);
                }}
              >
                Cancel
              </button>
              <button
                className="delUser"
                onClick={() => {
                  deleteUser();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Dialog>
        {/* delete pop ends */}

        {/* halt reason popup start */}
        <Dialog
          open={openHalt}
          onClose={() => {
            setOpenHalt(false);
            addHaltReasonFormik.resetForm({});
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "459px",
                height: "285px",
              },
            },
          }}
        >
          <div className="p-4">
            <div className="flex justify-between items-center">
              <p className="font-[Gm] font-semibold text-[18px] text-[#0E0E0E]">
                Add Halt Reason
              </p>
              <CloseIcon
                onClick={() => {
                  setOpenHalt(false);
                  addHaltReasonFormik.resetForm({});
                }}
              />
            </div>
            <form onSubmit={addHaltReasonFormik.handleSubmit}>
              <div className="mt-4">
                <p className="font-[Gm] font-medium text-[13px] text-[#0E0E0E]">
                  Specify the Reason
                </p>
                <textarea
                  name="reason"
                  placeholder="Write Reason Here..."
                  className="haltReason"
                  onChange={addHaltReasonFormik.handleChange}
                />
              </div>
              <button type="submit" className="submit_reason">
                Submit
              </button>
            </form>
          </div>
        </Dialog>
        {/* halt reason popup end */}
      </div>
      <div>
        {loader ? (
          <Loader customHeight={"70vh"} />
        ) : (
          <Tabs clientsData={clientsData} getUserDetails={getUserDetails} />
        )}
      </div>
    </>
  );
};

export default ClientDetails;
