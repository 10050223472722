import React from "react";
import ProjectStatus from "./ProjectStatus";
import PreConstruction from "./PreConstruction";

const General = () => {
  return (
    <>
      <div className="bg-white">
        <ProjectStatus />
      </div>
      <div className="bg-white mt-10">
        <PreConstruction />
      </div>
    </>
  );
};

export default General;
