import React from "react";
import NoteType from "./NoteType";
import MeetingType from "./MeetingType";
import TaskType from "./TaskType";

const Activity = () => {
  return (
    <div>
      <div className="bg-white">
        <MeetingType />
      </div>
      <div className="bg-white mt-10">
        <NoteType />
      </div>
      <div className="bg-white mt-10">
        <TaskType />
      </div>
    </div>
  );
};

export default Activity;
