import React, { useEffect, useState } from "react";
import "./AllClients.css";
import Dialog from "@mui/material/Dialog";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import axiosClientInterceptors from "../../../ApiConfig/axiosClientInterceptor";
import axios, { AxiosError, AxiosResponse } from "axios";

import nodata from "../../Assets/nodata.jpg";
import {
  allocationDataType,
  allocationUsersType,
  cities,
  clientDataType,
  clientListType,
  option,
  packageDataType,
  projectStatusType,
} from "../../../../Types";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  addMonths,
  addYears,
  endOfDay,
  startOfDay,
  subMonths,
  subYears,
} from "date-fns";
import ClearIcon from "@mui/icons-material/Clear";
import { DateRangePicker } from "rsuite";
import { PaginationProps } from "antd";
import MultiSelect from "../../../Tools/MultiSelect";
import moment from "moment";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../App/store";
import {
  selectAdvFilter,
  selectClientPage,
  selectClientPerPage,
  selectClientSearch,
  selectClientTotalCount,
  setAdvFilter,
  setClientID,
  setClientPage,
  setClientPerPage,
  setClientSearch,
  setClientTotalCount,
} from "../../../Features/AllClients/AllClients";
import RsuitePagination from "../../../Tools/RsuitePagination";
import Loader from "../../../Tools/Loader";
import SmallLoader from "../../../Tools/SmallLoader";
import { useNavigate } from "react-router-dom";

const AllConstructionStage = ({ valueDAta }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const defCLientData = useAppSelector(selectAdvFilter);
  const page = useAppSelector(selectClientPage);
  const perPage = useAppSelector(selectClientPerPage);
  const totalCount = useAppSelector(selectClientTotalCount);
  const defSearch = useAppSelector(selectClientSearch);

  const [loader, setLoader] = useState<boolean>(false);
  const [applyLoader, setApplyLoader] = useState<boolean>(false);
  const [resetLoader, setResetLoader] = useState<boolean>(false);

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [clientScreens, setClientScreens] = useState<string>("listing");
  const [clientList, setClientList] = useState<clientListType[]>([]);
  // const [clientID, setClientID] = useState<string>("");
  const [allocationUsers, setAllocationType] = useState<allocationUsersType[]>(
    []
  );
  const [allocationData, setAllocationData] = useState<allocationDataType[]>(
    []
  );
  const [packageData, setPackageData] = useState<packageDataType[]>([]);
  const [projectStatus, setProjectStatus] = useState<projectStatusType[]>([]);
  const [city, setCity] = useState<cities[]>([]);
  const [packages, setPackages] = useState<option[]>(
    defCLientData?.setClientPackages || []
  );
  // defCLientData?.setClientPackages || [] []
  const [cities, setCities] = useState<option[]>(
    defCLientData?.setClientCity || []
  );
  const [pStatus, setpStatus] = useState<option[]>(
    defCLientData?.setClientStatus || []
  );
  const [CC, setCC] = useState<option[]>(defCLientData?.setClientCC || []);
  const [architech, setArchitech] = useState<option[]>(
    defCLientData?.setClientArchitech || []
  );
  const [SE, setSE] = useState<option[]>(defCLientData?.setClientSE || []);
  const [PM, setPM] = useState<option[]>(defCLientData?.setClientPM || []);
  const [PC, setPC] = useState<option[]>(defCLientData?.setClientPC || []);
  const [TL, setTL] = useState<option[]>(defCLientData?.setClientTL || []);
  const [gatedApproval, setGatedApproval] = useState<option[]>(
    defCLientData?.setClientGovtApproval || []
  );
  const [electricApproval, setElectricApproval] = useState<option[]>(
    defCLientData?.setClientElectricApproval || []
  );
  const [govtApproval, setGovtApproval] = useState<option[]>(
    defCLientData?.setClientGovtApproval || []
  );
  const [bankApproval, setBankApproval] = useState<option[]>(
    defCLientData?.setClientBankApproval || []
  );
  const [preDateRange, setPreDateRange] = useState<
    [(Date | null | undefined)?, (Date | null | undefined)?]
  >(defCLientData?.setClientStartDateRange || []);
  const [preStartDate, setPreStartDate] = useState<string>(
    defCLientData?.setClientPreStartDateFrom || ""
  );
  const [preEndDate, setPreEndDate] = useState<string>(
    defCLientData?.setClientPreStartDateTo || ""
  );
  const [registrationDateRange, setRegistrationDateRange] = useState<
    [(Date | null | undefined)?, (Date | null | undefined)?]
  >(defCLientData?.setClientRegistrationDateRange || []);
  const [registrationStartDate, setRegistrationStartDate] = useState<string>(
    defCLientData?.setClientRegistrationDateFrom || ""
  );
  const [registrationEndDate, setRegistrationEndDate] = useState<string>(
    defCLientData?.setClientRegistrationDateto || ""
  );
  const [gatedComm, setGatedComm] = useState<string>(
    defCLientData?.setClientGatedName || ""
  );
  const [plotLocation, setPlotLocation] = useState<string>(
    defCLientData?.setClientPlotLocation || ""
  );
  const [budgetFrom, setBudgetFrom] = useState<number | null>(
    defCLientData?.setClientBudgetStart || null
  );
  const [budgetTo, setBudgetTo] = useState<number | null>(
    defCLientData?.setClientBudgetEnd || null
  );
  const [search, setSearch] = useState<string>(defSearch || "");
  const [value, setValue] = React.useState<string>("1");

  const handleChangeONE = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const getUsers = () => {
    axiosClientInterceptors
      .get(`/general/users`)
      .then((res: AxiosResponse) => {
        setAllocationType(res.data?.result);
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  //multiselect
  const handleChangePackages = (value: any) => {
    setPackages(value);
  };

  const packageOptions = packageData?.map((x) => {
    return {
      value: x.package_name,
      label: x.package_name,
    };
  });

  const cityOptions = city?.map((x) => {
    return {
      value: x.city,
      label: x.city,
    };
  });

  const handleChangecities = (value: any) => {
    setCities(value);
  };

  const projectStatusOptions = projectStatus?.map((x) => {
    return {
      value: x.section_name,
      label: x.section_name,
    };
  });

  const handleChangeStatus = (value: any) => {
    setpStatus(value);
  };

  const cusCoordinatorOptions = allocationUsers
    ?.filter((item) => item._id === "CO")[0]
    ?.data?.map((x, i) => {
      return {
        value: x.fullname,
        label: x.fullname,
        ID: i,
      };
    });

  const handleChangeCC = (value: any) => {
    setCC(value);
  };

  const architechOptions = allocationUsers
    ?.filter((item) => item._id === "AR")[0]
    ?.data?.map((x, i) => {
      return {
        value: x.fullname,
        label: x.fullname,
        ID: i,
      };
    });

  const handleChangeArchitech = (value: any) => {
    setArchitech(value);
  };

  const SEOptions = allocationData
    ?.filter((item) => item._id === "Site Engineer")[0]
    ?.data?.map((x) => {
      return {
        value: x,
        label: x,
      };
    });

  const handleChangeSE = (value: any) => {
    setSE(value);
  };

  const PMOptions = allocationData
    ?.filter((item) => item._id === "Project Manager")[0]
    ?.data?.map((x) => {
      return {
        value: x,
        label: x,
      };
    });

  const handleChangePM = (value: any) => {
    setPM(value);
  };

  const PCOptions = allocationData
    ?.filter((item) => item._id === "Project Coordinator")[0]
    ?.data?.map((x) => {
      return {
        value: x,
        label: x,
      };
    });

  const handleChangePC = (value: any) => {
    setPC(value);
  };

  const TLOptions = allocationData
    ?.filter((item) => item._id === "Team Lead")[0]
    ?.data?.map((x) => {
      return {
        value: x,
        label: x,
      };
    });

  const handleChangeTL = (value: any) => {
    setTL(value);
  };

  const handleGatedApproval = (value: any) => {
    setGatedApproval(value);
  };

  const handleElectricApproval = (value: any) => {
    setElectricApproval(value);
  };

  const handleGovtApproval = (value: any) => {
    setGovtApproval(value);
  };

  const handleBankApproval = (value: any) => {
    setBankApproval(value);
  };

  const handlestartDateChange = (date: [Date, Date]) => {
    if (date) {
      setPreDateRange(date);
      const [startDate, endDate] = date;
      setPreStartDate(moment(startDate).format("YYYY-MM-DD"));
      setPreEndDate(moment(endDate).format("YYYY-MM-DD"));
    }
  };

  const handleRegistrationDateChange = (date: [Date, Date]) => {
    if (date) {
      setRegistrationDateRange(date);
      const [startDate, endDate] = date;
      setRegistrationStartDate(moment(startDate).format("YYYY-MM-DD"));
      setRegistrationEndDate(moment(endDate).format("YYYY-MM-DD"));
    }
  };

  const getAllCity = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_CRM}/get-city`)
      .then((res: AxiosResponse) => {
        setCity(res.data?.result);
      });
  };

  const getAllPackages = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_CRM}/get-project-packages?apiKey=${process.env.REACT_APP_AUTH_KEY}`
      )
      .then((res: AxiosResponse) => {
        setPackageData(res.data?.result);
      });
  };

  const handleChangePage: PaginationProps["onChange"] = (page, perPage) => {
    dispatch(setClientPage(page));
    dispatch(setClientPerPage(perPage));
  };

  const getAllClients = (apply?: string) => {
    setLoader(true);
    {
      apply === "reset" ? setResetLoader(true) : setApplyLoader(true);
    }
    if (apply === "reset") {
      setCities([]);
      setpStatus([]);
      setCC([]);
      setArchitech([]);
      setSE([]);
      setPC([]);
      setTL([]);
      setGatedApproval([]);
      setElectricApproval([]);
      setGatedApproval([]);
      setBankApproval([]);
      setPreDateRange([]);
      setPreStartDate("");
      setPreEndDate("");
      setRegistrationDateRange([]);
      setRegistrationStartDate("");
      setRegistrationEndDate("");
      setGatedComm("");
      setPlotLocation("");
      setBudgetFrom(null);
      setBudgetTo(null);
      setPackages([]);
    }

    let data = {
      setClientPackages: apply === "reset" ? [] : packages,
      setClientCity: apply === "reset" ? [] : cities,
      setClientStatus: apply === "reset" ? [] : pStatus,
      setClientCC: apply === "reset" ? [] : CC,
      setClientArchitech: apply === "reset" ? [] : architech,
      setClientSE: apply === "reset" ? [] : SE,
      setClientPM: apply === "reset" ? [] : PM,
      setClientPC: apply === "reset" ? [] : PC,
      setClientTL: apply === "reset" ? [] : TL,
      setClientStartDateRange: apply === "reset" ? [] : preDateRange,
      setClientRegistrationDateRange:
        apply === "reset" ? [] : registrationDateRange,
      setClientPreStartDateFrom: apply === "reset" ? "" : preStartDate,
      setClientPreStartDateTo: apply === "reset" ? "" : preEndDate,
      setClientRegistrationDateFrom:
        apply === "reset" ? "" : registrationStartDate,
      setClientRegistrationDateto: apply === "reset" ? "" : registrationEndDate,
      setClientGatedName: apply === "reset" ? "" : gatedComm,
      setClientGatedApproval: apply === "reset" ? [] : gatedApproval,
      setClientElectricApproval: apply === "reset" ? [] : electricApproval,
      setClientGovtApproval: apply === "reset" ? [] : govtApproval,
      setClientBankApproval: apply === "reset" ? [] : bankApproval,
      setClientPlotLocation: apply === "reset" ? "" : plotLocation,
      setClientBudgetStart: apply === "reset" ? null : budgetFrom,
      setClientBudgetEnd: apply === "reset" ? null : budgetTo,
    };

    dispatch(setAdvFilter(data));

    axiosClientInterceptors
      .post(`/clients?page=${page}&limit=${perPage}&search=${search}`, {
        city: apply === "reset" ? [] : cities?.map((x) => x.value),
        package: apply === "reset" ? [] : packages?.map((x) => x.value),
        project_status: apply === "reset" ? [] : pStatus?.map((x) => x.value),
        cc: apply === "reset" ? [] : CC?.map((x) => x.value),
        architects: apply === "reset" ? [] : architech?.map((x) => x.value),
        site_engineer: apply === "reset" ? [] : SE?.map((x) => x.value),
        project_manager: apply === "reset" ? [] : PM?.map((x) => x.value),
        project_coordinator: apply === "reset" ? [] : PC?.map((x) => x.value),
        team_lead: apply === "reset" ? [] : TL?.map((x) => x.value),
        gated_comm: apply ? gatedComm : "",
        gated_comm_approval:
          apply === "reset" ? [] : gatedApproval?.map((x) => x.value),
        meter_approval:
          apply === "reset" ? [] : electricApproval?.map((x) => x.value),
        govt_approval:
          apply === "reset" ? [] : gatedApproval?.map((x) => x.value),
        bank_approval:
          apply === "reset" ? [] : bankApproval?.map((x) => x.value),
        plot_location: apply === "reset" ? "" : plotLocation,
        start_from: apply === "reset" ? "" : preStartDate,
        start_to: apply === "reset" ? "" : preEndDate,
        reg_start_from: apply === "reset" ? "" : registrationStartDate,
        reg_start_to: apply === "reset" ? "" : registrationEndDate,
        budget_from: apply === "reset" ? null : budgetFrom,
        budget_to: apply === "reset" ? null : budgetTo,
      })
      .then((res: AxiosResponse) => {
        setClientList(res?.data?.result.data);
        dispatch(setClientPage(res?.data?.result?.currentPage));
        dispatch(setClientTotalCount(res?.data?.result?.totalCount));
        setLoader(false);
        setApplyLoader(false);
        setResetLoader(false);
        setOpenFilter(false);
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
        setLoader(false);
        setApplyLoader(false);
        setResetLoader(false);
        setOpenFilter(false);
      });
  };

  const getAllocationData = () => {
    axiosClientInterceptors
      .get(`/general/getprocurement-users`)
      .then((res: AxiosResponse) => {
        setAllocationData(res.data?.result?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectStatus = () => {
    axiosClientInterceptors
      .get(`/general/config?heading=Project Status`)
      .then((res: AxiosResponse) => {
        setProjectStatus(res.data?.result);
      })
      .catch((err: AxiosError) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    getUsers();
    getAllCity();
    getProjectStatus();
    getAllocationData();
    getAllPackages();
  }, []);

  useEffect(() => {
    getAllClients();
  }, [page, perPage, defSearch, clientScreens, valueDAta]);
  return (
    <div>
      {/* filter pop up starts */}
      <Dialog
        open={openFilter}
        onClose={() => {
          setOpenFilter(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "964px",
              height: "564px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex items-center justify-between">
            <span className="font-[gm] font-bold text-[20px] text-[#151414]">
              Filter
            </span>
            <div>
              <CloseRoundedIcon
                className="cursor-pointer"
                onClick={() => {
                  setOpenFilter(false);
                }}
              />
            </div>
          </div>
          <div className="mt-5">
            <div className="flex justify-between items-center">
              <div className="">
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Packages
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Select Package"}
                    options={packageOptions}
                    value={packages}
                    defaultValue={packages}
                    handleChange={handleChangePackages}
                  />
                </div>
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  City
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Choose City"}
                    options={cityOptions}
                    value={cities}
                    defaultValue={cities}
                    handleChange={handleChangecities}
                  />
                </div>
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Project Status
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Choose Status"}
                    options={projectStatusOptions}
                    value={pStatus}
                    defaultValue={pStatus}
                    handleChange={handleChangeStatus}
                  />
                </div>
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Customer Coordinator
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Choose User"}
                    options={cusCoordinatorOptions}
                    value={CC}
                    defaultValue={CC}
                    handleChange={handleChangeCC}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-4 justify-between items-center">
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Architect
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Choose User"}
                    options={architechOptions}
                    value={architech}
                    defaultValue={architech}
                    handleChange={handleChangeArchitech}
                  />
                </div>
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Site Engineer
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Choose User"}
                    options={SEOptions}
                    value={SE}
                    defaultValue={SE}
                    handleChange={handleChangeSE}
                  />
                </div>
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Project Manager
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Choose User"}
                    options={PMOptions}
                    value={PM}
                    defaultValue={PM}
                    handleChange={handleChangePM}
                  />
                </div>
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Project Coordinator
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Choose User"}
                    options={PCOptions}
                    value={PC}
                    defaultValue={PC}
                    handleChange={handleChangePC}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-4 justify-between items-center">
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Team Lead
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Choose User"}
                    options={TLOptions}
                    value={TL}
                    defaultValue={TL}
                    handleChange={handleChangeTL}
                  />
                </div>
              </div>
              <div className="daterange">
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Pre Construction Start Date
                </p>
                <DateRangePicker
                  placement="topStart"
                  format="dd-MM-yyyy"
                  placeholder="Choose Date Range"
                  ranges={[
                    {
                      label: "today",
                      value: [startOfDay(new Date()), endOfDay(new Date())],
                      closeOverlay: false,
                    },
                    {
                      label: "Month",
                      value: [
                        endOfDay(new Date()),
                        startOfDay(addMonths(new Date(), 1)),
                      ],
                      closeOverlay: false,
                    },
                    {
                      label: "3 Months",
                      value: [
                        endOfDay(new Date()),
                        startOfDay(addMonths(new Date(), 3)),
                      ],
                      closeOverlay: false,
                    },
                    {
                      label: "6 Months",
                      value: [
                        endOfDay(new Date()),
                        startOfDay(addMonths(new Date(), 6)),
                      ],
                      closeOverlay: false,
                    },
                    {
                      label: "Year",
                      value: [
                        endOfDay(new Date()),
                        startOfDay(addYears(new Date(), 1)),
                      ],
                      closeOverlay: false,
                    },
                  ]}
                  onOk={handlestartDateChange}
                  className="w-[215px] mt-2 h-11"
                  cleanable={true}
                  defaultValue={(() => preDateRange as [Date, Date])()}
                />
              </div>
              <div className="daterange">
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Registration Date
                </p>
                <DateRangePicker
                  placement="topStart"
                  format="dd-MM-yyyy"
                  placeholder="Choose Date Range"
                  ranges={[
                    {
                      label: "today",
                      value: [endOfDay(new Date()), startOfDay(new Date())],
                      closeOverlay: false,
                    },
                    {
                      label: "Month",
                      value: [
                        endOfDay(subMonths(new Date(), 1)),
                        startOfDay(new Date()),
                      ],
                      closeOverlay: false,
                    },
                    {
                      label: "3 Months",
                      value: [
                        endOfDay(subMonths(new Date(), 3)),
                        startOfDay(new Date()),
                      ],
                      closeOverlay: false,
                    },
                    {
                      label: "6 Months",
                      value: [
                        endOfDay(subMonths(new Date(), 6)),
                        startOfDay(new Date()),
                      ],
                      closeOverlay: false,
                    },
                    {
                      label: "Year",
                      value: [
                        endOfDay(subYears(new Date(), 1)),
                        startOfDay(new Date()),
                      ],
                      closeOverlay: false,
                    },
                  ]}
                  onOk={handleRegistrationDateChange}
                  className="w-[215px] mt-2"
                  cleanable={true}
                  defaultValue={(() => registrationDateRange as [Date, Date])()}
                />
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Gated Community Name
                </p>
                <input
                  type="text"
                  className="ont-[Gm] text-[13px] font-medium text-[#0E0E0E] w-[215px] mt-2 h-11 border-[1px] border-solid rounded-md p-4 outline-none"
                  placeholder="Ex. Whitefield"
                  onChange={(e) => {
                    setGatedComm(e.target.value);
                  }}
                  defaultValue={gatedComm}
                />
              </div>
            </div>
            <div className="flex mt-4 justify-between items-center">
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Gated Community Approval
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Select Type"}
                    options={[
                      { value: "In Progress", label: "In Progress" },
                      { value: "Pending", label: "Pending" },
                      { value: "Done", label: "Done" },
                    ]}
                    value={gatedApproval}
                    defaultValue={gatedApproval}
                    handleChange={handleGatedApproval}
                  />
                </div>
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Electric Meter Approval
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Select Type"}
                    options={[
                      { value: "In Progress", label: "In Progress" },
                      { value: "Pending", label: "Pending" },
                      { value: "Done", label: "Done" },
                    ]}
                    value={electricApproval}
                    defaultValue={electricApproval}
                    handleChange={handleElectricApproval}
                  />
                </div>
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Government Approval
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Select Type"}
                    options={[
                      { value: "In Progress", label: "In Progress" },
                      { value: "Pending", label: "Pending" },
                      { value: "Done", label: "Done" },
                    ]}
                    value={govtApproval}
                    defaultValue={govtApproval}
                    handleChange={handleGovtApproval}
                  />
                </div>
              </div>
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Bank Approval
                </p>
                <div className="w-[215px] mt-2">
                  <MultiSelect
                    placeholder={"Select Type"}
                    options={[
                      { value: "In Progress", label: "In Progress" },
                      { value: "Pending", label: "Pending" },
                      { value: "Done", label: "Done" },
                    ]}
                    value={bankApproval}
                    defaultValue={bankApproval}
                    handleChange={handleBankApproval}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-4 gap-5 items-center">
              <div>
                <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                  Plot Location
                </p>
                <input
                  type="text"
                  className="font-[Gm] text-[13px] font-medium text-[#0E0E0E] w-[451px] mt-2 h-11 border-[1px] border-solid rounded-md p-4 outline-none"
                  placeholder="Ex. JP Nagar"
                  onChange={(e) => {
                    setPlotLocation(e.target.value);
                  }}
                  defaultValue={plotLocation}
                />
              </div>
              <div className="flex gap-2 items-center">
                <div>
                  <p className="font-[Gm] text-[13px] font-medium text-[#0E0E0E]">
                    Budget
                  </p>
                  <input
                    type="text"
                    className="font-[Gm] text-[13px] font-medium text-[#0E0E0E] w-[215px] mt-2 h-11 border-[1px] border-solid rounded-md p-4 outline-none"
                    placeholder="₹ 0"
                    onChange={(e) => {
                      setBudgetFrom(Number(e.target.value));
                    }}
                    defaultValue={budgetFrom || undefined}
                  />
                </div>
                <span className=" flex items-center justify-center h-11 mt-6">
                  -
                </span>
                <div>
                  <input
                    type="text"
                    className="font-[Gm] text-[13px] font-medium text-[#0E0E0E] w-[215px] mt-6 h-11 border-[1px] border-solid rounded-md p-4 outline-none"
                    placeholder="₹ 100"
                    onChange={(e) => {
                      setBudgetTo(Number(e.target.value));
                    }}
                    defaultValue={budgetTo || undefined}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-4 items-center gap-5">
              <button
                className="resetBtn"
                onClick={() => {
                  getAllClients("reset");
                }}
              >
                {resetLoader ? <SmallLoader color={""} /> : "Reset All"}
              </button>
              <button
                className="applyBtn"
                onClick={() => {
                  getAllClients();
                }}
              >
                {applyLoader ? <SmallLoader color={""} /> : "Apply FIlter"}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      {/* filter pop up ends */}
      <div className="mt-[12px] flex justify-between">
        <div className="relative">
          <input
            className="searchInputNew outline-none"
            type="text"
            style={{ position: "relative" }}
            placeholder="Search by name, ID, mobile number"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch(setClientSearch(search));
              }
            }}
          />
          {search?.length > 0 && (
            <ClearIcon
              sx={{
                position: "absolute",
                top: "8px",
                right: "11px",
              }}
              onClick={() => {
                setSearch("");
                dispatch(setClientSearch(""));
              }}
            />
          )}
          <div className="absolute top-3 left-3">
            <svg
              width="15"
              height="15"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="5.39301"
                cy="5.39319"
                r="4.11976"
                stroke="#667085"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.25836 8.47266L9.87354 10.0836"
                stroke="#667085"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="flex gap-5">
          <button
            className="flex filter_btn"
            onClick={() => {
              setOpenFilter(true);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.91866 4.25237C5.91866 5.49682 4.89274 6.50548 3.62608 6.50548C2.36016 6.50548 1.3335 5.49682 1.3335 4.25237C1.3335 3.00866 2.36016 2 3.62608 2C4.89274 2 5.91866 3.00866 5.91866 4.25237ZM13.6624 3.26555C14.2165 3.26555 14.6668 3.70802 14.6668 4.25237C14.6668 4.79746 14.2165 5.23992 13.6624 5.23992H9.27871C8.7239 5.23992 8.27353 4.79746 8.27353 4.25237C8.27353 3.70802 8.7239 3.26555 9.27871 3.26555H13.6624ZM2.33868 10.6386H6.72235C7.27717 10.6386 7.72753 11.0811 7.72753 11.6262C7.72753 12.1705 7.27717 12.6137 6.72235 12.6137H2.33868C1.78386 12.6137 1.3335 12.1705 1.3335 11.6262C1.3335 11.0811 1.78386 10.6386 2.33868 10.6386ZM12.3742 13.8519C13.6409 13.8519 14.6668 12.8432 14.6668 11.5995C14.6668 10.355 13.6409 9.34638 12.3742 9.34638C11.1083 9.34638 10.0817 10.355 10.0817 11.5995C10.0817 12.8432 11.1083 13.8519 12.3742 13.8519Z"
                fill="#344054"
              />
            </svg>
            Filter
          </button>
          {/* <button className="flex addColumn">+ Add Column</button> */}
        </div>
      </div>
      {loader ? (
        <Loader customHeight={"80vh"} />
      ) : (
        <>
          <div className="allTable bg-white mt-[15px]">
            <table className="w-full">
              <thead className="h-[50px] sticky top-0 z-10 bg-[#111111] font-[Gr] text-[14px] text-[#FFFFFF] font-medium">
                <tr className="h-[50px] top-0 z-10">
                  <th className="text-start px-6 whitespace-nowrap w-[69px]">
                    SL.No
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[180px]">
                    Client Name
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[110px]">
                    Client ID
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[150px]">
                    Mobile Number
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[120px]">
                    City
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[130px]">
                    Package
                  </th>
                  <th className="text-start  px-6 whitespace-nowrap w-[140px]">
                    Project Status
                  </th>

                  <th className="text-start px-6 whitespace-nowrap w-[178px]">
                    Customer Coordinator
                  </th>
                  <th className="text-start px-6 whitespace-nowrap">
                    Site Engineer
                  </th>
                  <th className="text-start px-6 whitespace-nowrap">
                    Project Manager
                  </th>
                  <th className="text-start px-6 whitespace-nowrap">
                    Team Lead
                  </th>
                  <th className="text-start px-6 whitespace-nowrap">Budget</th>
                </tr>
              </thead>
              <tbody>
                {clientList?.map((client, i) => (
                  <tr
                    key={i}
                    className="hover:opacity-[0.7] hover:bg-[#F6F6F6] border-b-[2px] border-[#F5F8FA] h-[66px] bg-white cursor-pointer"
                    onClick={() => {
                      // setClientID(client?._id);
                      console.log("hhi");
                      navigate("/Home/ConstructionClientDetails");
                      dispatch(setClientID(client?._id));
                      // Example navigation
                      // setClientScreens("clientDetails");
                    }}
                  >
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {(page! - 1) * perPage! + i + 1}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.fullname || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.client_id || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.mobile || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.city || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.package || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.project_status || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.customer_coordinator || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.site_engineer || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.project_manager || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.team_lead || "-"}
                    </td>
                    <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                      {client?.budget || "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              {clientList?.length === 0 && (
                <div
                  className="flex justify-center items-center bg-white mt-[2px] flex-col"
                  style={{ height: "72vh" }}
                >
                  <img
                    className="h-[250px] w-[220px]"
                    src={nodata}
                    alt="no-data"
                  />
                </div>
              )}
            </div>
          </div>
          {clientList?.length > 0 && (
            <div className="dealsPagination mt-10">
              <RsuitePagination
                handleChangePage={handleChangePage}
                limit={perPage}
                total={totalCount}
                currentPage={page}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllConstructionStage;
