import React from "react";
import OtherDocumentsCategory from "./OtherDocumentsCategory";
import Construction from "./Construction";
import PreConstruction from "./PreConstruction";

const DesignFile = () => {
  return (
    <div>
      <div>
        <div className="">
          <PreConstruction />
        </div>
        <div className=" mt-10">
          <Construction />
        </div>
        <div className=" mt-10">
          <OtherDocumentsCategory />
        </div>
      </div>
    </div>
  );
};

export default DesignFile;
