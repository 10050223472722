import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import ConstructionOverview from "./ConstructionOverview/ConstructionOverview";
import ProjectStages from "./ProjectStages/ProjectStages";
import ConstructionProjectStage from "./ConstructionProjectStage/ConstructionProjectStage";
import Activities from "./Activities/Activities";
import Notes from "./Notes/Notes";

const ConstructionTab = () => {
  const [value, setValue] = useState<string>("1");

  // theme for mui tab section
  const theme = createTheme({
    overrides: {
      MuiTab: {
        root: {
          textTransform: "capitalize",
          backgroundColor: "white",
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Gm",
          color: "#1d2939 !important",
          opacity: "9 !important",
          "&.MuiTab-textColorInherit.Mui-selected": {
            backgroundColor: "#F5F8FA",

            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Gsb",
          },
          "&.MuiButtonBase-root": {
            border: "1px solid #D0D5DD",
            borderTopLeftRadius: "8px",
            // minWidth: "200px !important",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Gsb",
          },
          // width: 400,
        },
      },
    },
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      {" "}
      <div className="my-[30px] mr-[20px] bg-white">
        <div className="tabcolor">
          <div className="mt-5 bg-white">
            <Box>
              <ThemeProvider theme={theme}>
                <TabContext value={value}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      TabIndicatorProps={{
                        style: { display: "none" },
                      }}
                    >
                      <Tab
                        className="con_tab"
                        label="Project Overview"
                        value="1"
                      />
                      <Tab
                        className="con_tab"
                        label="Project Stages"
                        value="2"
                      />
                      <Tab className="con_tab" label="Activities" value="3" />
                      <Tab className="con_tab" label="Note" value="4" />
                    </TabList>
                  </Box>
                  <TabPanel
                    className="min-h-[650px] p-10 bg-[#F5F8FA]"
                    value="1"
                  >
                    <ConstructionOverview />
                  </TabPanel>
                  <TabPanel
                    className="min-h-[650px] p-10 bg-[#F5F8FA]"
                    value="2"
                  >
                    <ConstructionProjectStage />
                  </TabPanel>
                  <TabPanel
                    className="min-h-[650px] p-10 bg-[#F5F8FA]"
                    value="3"
                  >
                    <Activities />
                  </TabPanel>
                  <TabPanel
                    className="min-h-[650px] p-10 bg-[#F5F8FA]"
                    value="4"
                  >
                    <Notes />
                  </TabPanel>
                </TabContext>
              </ThemeProvider>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConstructionTab;
