import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Dialog, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Dropzone from "react-dropzone";
import axiosClientInterceptors from "../../../../../../ApiConfig/axiosClientInterceptor";
import { selectClientID } from "../../../../../../Features/AllClients/AllClients";
import { useSelector } from "react-redux";
import LensIcon from "@mui/icons-material/Lens";
import {
  taskObject,
  userlisting,
  projectStatusType,
  DeletTasktype,
  deletdoc,
  FormValuesMarkCompleted,
  loaderObjTAsk,
} from "../../../../../../../Types";
import {
  addMonths,
  addYears,
  endOfDay,
  startOfDay,
  subMonths,
  subYears,
} from "date-fns";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { saveAs } from "file-saver";
import MultiSelect from "../../../../../../Tools/MultiSelect";
import nodata from "../../../../../Assets/nodata.jpg";
import { Toaster } from "../../../../../../Tools/Toaster";

const MUIstayle: any = {
  borderRadius: "2px",
  background: "linear-gradient(90deg, #FFD661 0%, #FABE2A 103.54%)",
  boxShadow: "none",
  textTransform: "none",
  padding: "10px",
  color: " #000",
  fontFamily: "gsb",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "24px" /* 171.429% */,
};
interface FormValues {
  TaskName?: string;
  Assignedtask?: string;
  TaskType?: string;
  Priority?: string;
  DueDate?: string;
  ReminderDate?: string;
  ReminderTime?: string;
  Description?: string;
}
const validationSchema = yup.object({
  TaskName: yup.string().required("TaskName is required"),
  Assignedtask: yup.string().required("Assignedtask is required"),
  TaskType: yup.string().required("TaskType is required"),
  Priority: yup.string().required("Priority is required"),
  DueDate: yup.string().required("DueDate is required"),
  // ReminderDate: yup.string().required("DueDate is required"),
  ReminderDate: yup
    .date()
    .required("ReminderDate is required")
    .test(
      "is-between",
      "ReminderDate should be between the current date and the DueDate",
      function (value) {
        const { DueDate } = this.parent;
        const now = new Date();
        return value >= now && value <= new Date(DueDate);
      }
    ),
  ReminderTime: yup.string().required("ReminderTime is required"),
  Description: yup.string().required("Description is required"),
});
const Task = ({ siglestageData }: any) => {
  const clientID = useSelector(selectClientID);
  const [MeetingOutcomeopen, setMeetingOutcomeopen] = useState<boolean>(false);
  const [openAddTask, setopenAddTask] = useState(false);
  const [openAddTaskEdit, setopenAddTaskEdit] = useState(false);
  const [uploadFileOpen, setuploadFileOpen] = useState<boolean>(false);
  const [Viewpopup, setViewpopup] = useState<boolean>(false);
  const [AllTask, setAllTask] = useState<taskObject[]>([]);
  const [taskDateRange, settaskDateRange] = useState<Date[]>([]);
  const [taskStartDate, settaskStartDate] = useState<string>("");
  const [taskEndDate, settaskEndDate] = useState<string>("");
  const [allocationUsers, setAllocationType] = useState<userlisting[]>([]);
  const [projectStatus, setProjectStatus] = useState<projectStatusType[]>([]);
  const [EditDataTask, setEditDataTask] = useState<any>({});
  const [Bluckfile, setBluckfile] = useState<File[]>([]);
  const [Loader, setLoader] = useState<loaderObjTAsk>({
    CreateNewTaskloader: false,
    editTaskLoader: false,
    TaskOutcomeloader: false,
    docsLoader: false,
  });
  const [taskTypevalue, settaskTypevalue] = useState<any>([]);

  // accepting file from dropzone and storing it to state
  const handleDrop = (acceptedFiles: any) => {
    setBluckfile((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };
  const deletFile = (i: any) => {
    let filesone = [...Bluckfile];
    filesone.splice(i, 1);
    setBluckfile(filesone);
  };

  const handleChangeTaskType = (data: any) => {
    settaskTypevalue(data);
  };

  const handlestartDateChange = (date: [Date, Date]) => {
    if (date) {
      console.log(date);

      settaskDateRange(date);
      const [startDate, endDate] = date;
      settaskStartDate(moment(startDate).format("YYYY-MM-DD"));
      settaskEndDate(moment(endDate).format("YYYY-MM-DD"));
    }
  };

  const getAllTask = () => {
    axiosClientInterceptors
      .post(
        `/clients/stages/tasks/get-tasks?client_id=${clientID}&section_name=Pre-Construction&stage_id=${siglestageData._id}
        `,
        {
          task_type: [],
          due_from: taskStartDate,
          due_to: taskEndDate,
        }
      )
      .then((response) => {
        const result = response.data.result;
        console.log("result", result);
        setAllTask(result);
        // setAllNotes(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUsers = () => {
    axiosClientInterceptors
      .get(`/general/users/getallusers`)
      .then((res) => {
        setAllocationType(res.data?.result);
        console.log("resresres", res.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getProjectStatus = () => {
    axiosClientInterceptors
      .get(`/general/config?heading=Task Type`)
      .then((res) => {
        setProjectStatus(res.data?.result);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    getProjectStatus();
    getUsers();
    getAllTask();
  }, []);

  // upload Doc
  const UploadDocs = () => {
    setLoader({
      CreateNewTaskloader: false,
      editTaskLoader: false,
      TaskOutcomeloader: false,
      docsLoader: true,
    });
    const formdata = new FormData();
    Bluckfile.map((value) => formdata.append("docs", value));

    const headers = {
      "content-type": "multipart/form-data",
    };
    axiosClientInterceptors
      .patch(`/clients/stages/tasks/?task_id=${EditDataTask._id}`, formdata, {
        headers,
      })

      .then((res) => {
        setLoader({
          CreateNewTaskloader: false,
          editTaskLoader: false,
          TaskOutcomeloader: false,
          docsLoader: false,
        });
        console.log(res);
        setuploadFileOpen(false);
        Toaster("success", res.data?.message);

        setBluckfile([]);
        getAllTask();
      })
      .catch((err) => {
        setLoader({
          CreateNewTaskloader: false,
          editTaskLoader: false,
          TaskOutcomeloader: false,
          docsLoader: false,
        });
        console.log(err);
        Toaster("error", err.response.data.message);
      });
  };

  // Add Task
  const formik = useFormik<FormValues>({
    initialValues: {
      TaskName: "",
      Assignedtask: "",
      TaskType: "",
      Priority: "",
      DueDate: "",
      ReminderDate: "",
      ReminderTime: "",
      Description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setLoader({
        CreateNewTaskloader: true,
      });

      axiosClientInterceptors
        .post(`/clients/stages/tasks/?client_id=${clientID}`, {
          task_type: values.TaskType,
          stage_id: siglestageData._id,
          assigned_to: values.Assignedtask,
          section_name: "Pre-Construction",
          task_name: values.TaskName,
          due_date: values.DueDate,
          priority: values.Priority,
          reminder_date: values.ReminderDate,
          reminder_time: values.ReminderTime,
          description: values.Description,
        })
        .then((response) => {
          setLoader({
            CreateNewTaskloader: false,
          });
          getAllTask();
          setopenAddTask(false);
          formik.resetForm({});
          Toaster("success", "Task Added Successfully");
        })
        .catch((err) => {
          setLoader({
            CreateNewTaskloader: false,
          });
          console.log(err);
        });
    },
  });
  // Edit Task
  const formikEdit = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      TaskName: EditDataTask.task_name,
      Assignedtask: EditDataTask.assigned_to,
      TaskType: EditDataTask.task_type_id,
      Priority: EditDataTask.priority,
      DueDate: EditDataTask.due_date,
      ReminderDate: EditDataTask.reminder_date,
      ReminderTime: EditDataTask.reminder_time,
      Description: EditDataTask.description,
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setLoader({
        CreateNewTaskloader: false,
        editTaskLoader: true,
        TaskOutcomeloader: false,
        docsLoader: false,
      });

      axiosClientInterceptors
        .put(`/clients/stages/tasks/?task_id=${EditDataTask._id}`, {
          task_type: values.TaskType,
          stage_id: siglestageData._id,
          section_name: "Pre-Construction",
          task_name: values.TaskName,
          due_date: values.DueDate,
          priority: values.Priority,
          reminder_date: values.ReminderDate,
          reminder_time: values.ReminderTime,
          description: values.Description,
        })
        .then((response) => {
          setLoader({
            CreateNewTaskloader: false,
            editTaskLoader: false,
            TaskOutcomeloader: false,
            docsLoader: false,
          });
          console.log(response);
          Toaster("success", "Task edited Successfully");
          getAllTask();
          setopenAddTaskEdit(false);
          formik.resetForm({});
        })
        .catch((err) => {
          setLoader({
            CreateNewTaskloader: false,
            editTaskLoader: false,
            TaskOutcomeloader: false,
            docsLoader: false,
          });
          console.log(err);
        });
    },
  });

  const fileNameSplit = (str: any) => {
    if (str) {
      return str.split("/").at(-1).split("-").slice(1).join("-");
    }
  };
  const DeletDocz = (doc: string) => {
    axiosClientInterceptors
      .delete(
        `/clients/stages/tasks/task-documents?task_id=${EditDataTask._id}&doc=${doc}`
      )
      .then((res) => {
        console.log(res);
        getAllTask();
        setViewpopup(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formikMarkCompleted = useFormik<FormValuesMarkCompleted>({
    initialValues: {
      task_outcome: "",
    },
    validationSchema: yup.object({
      task_outcome: yup.string().required("task_outcome is required"),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setLoader({
        CreateNewTaskloader: false,
        editTaskLoader: false,
        TaskOutcomeloader: true,
        docsLoader: false,
      });
      axiosClientInterceptors
        .put(`/clients/stages/tasks/?task_id=${EditDataTask._id}`, {
          completed: true,
          task_outcome: values.task_outcome,
        })
        .then((res) => {
          setLoader({
            CreateNewTaskloader: false,
            editTaskLoader: false,
            TaskOutcomeloader: false,
            docsLoader: false,
          });
          formikMarkCompleted.resetForm({});
          getAllTask();
          setMeetingOutcomeopen(false);
          Toaster("success", "Task Completed Successfully");
        })
        .catch((err) => {
          setLoader({
            CreateNewTaskloader: false,
            editTaskLoader: false,
            TaskOutcomeloader: false,
            docsLoader: false,
          });
          console.log(err);
        });
    },
  });
  return (
    <div>
      {/*Meeting Outcome pop */}
      <Dialog
        open={MeetingOutcomeopen}
        onClose={() => {
          setMeetingOutcomeopen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <form
          style={{ borderTop: "7px solid  #FCC200" }}
          className="p-4"
          onSubmit={formikMarkCompleted.handleSubmit}
        >
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">Task Outcome</p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMeetingOutcomeopen(false);
              }}
            />
          </div>
          <div className="mt-3">
            {/* <p className="Stage__FormTittle">Meeting Title</p> */}

            <textarea
              name="task_outcome"
              value={formikMarkCompleted.values.task_outcome}
              onChange={formikMarkCompleted.handleChange}
              cols={30}
              rows={10}
              className="ProjectStages___Stage__input w-full mt-2"
            />
            {formikMarkCompleted.touched.task_outcome &&
              formikMarkCompleted.errors.task_outcome && (
                <p className="required_message">
                  {formikMarkCompleted.errors.task_outcome}
                </p>
              )}
          </div>
          {Loader.TaskOutcomeloader ? (
            <div className="w-full flex justify-center items-center">
              <CircularProgress color="inherit" size={20} />
            </div>
          ) : (
            <button
              className="ProjectStages__activateButton mt-1"
              type="submit"
            >
              Submit & Mark as Completed
            </button>
          )}
        </form>
      </Dialog>
      {/* add Task */}
      <Dialog
        open={openAddTask}
        onClose={() => {
          setopenAddTask(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Create New Task
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setopenAddTask(false);
              }}
            />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-3">
              <p className="Stage__FormTittle">Task Name</p>
              <input
                name="TaskName"
                maxLength={35}
                value={formik.values.TaskName}
                onChange={formik.handleChange}
                type="text"
                placeholder="Enter Task Name"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formik.touched.TaskName && formik.errors.TaskName && (
                <p className="required_message">{formik.errors.TaskName}</p>
              )}
            </div>
            <div className=" grid grid-cols-2 gap-2">
              <div className="mt-3">
                <p
                  className="Stage__FormTittle"
                  onClick={() => {
                    console.log(allocationUsers);
                  }}
                >
                  Assigned task to
                </p>
                <select
                  name="Assignedtask"
                  value={formik.values.Assignedtask}
                  onChange={formik.handleChange}
                  className="ProjectStages___Stage__input w-full mt-2"
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  {allocationUsers.map((value: userlisting) => (
                    <option
                      value={value._id}
                    >{`${value.fullname}(${value.role})`}</option>
                  ))}
                </select>{" "}
                {formik.touched.Assignedtask && formik.errors.Assignedtask && (
                  <p className="required_message">
                    {formik.errors.Assignedtask}
                  </p>
                )}
              </div>
              <div className="mt-3">
                <p
                  className="Stage__FormTittle"
                  onClick={() => {
                    console.log("projectStatus", projectStatus);
                  }}
                >
                  Task Type
                </p>
                <select
                  name="TaskType"
                  value={formik.values.TaskType}
                  onChange={formik.handleChange}
                  className="ProjectStages___Stage__input w-full mt-2"
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  {projectStatus.map((value: projectStatusType) => (
                    <option value={value._id}>{value.section_name}</option>
                  ))}
                </select>{" "}
                {formik.touched.TaskType && formik.errors.TaskType && (
                  <p className="required_message">{formik.errors.TaskType}</p>
                )}
              </div>
            </div>
            <div className=" grid grid-cols-2 gap-2">
              <div className="mt-3">
                <p className="Stage__FormTittle">Priority</p>
                <select
                  name="Priority"
                  value={formik.values.Priority}
                  onChange={formik.handleChange}
                  className="ProjectStages___Stage__input w-full mt-2"
                >
                  <option value="" selected disabled>
                    select Priority{" "}
                  </option>
                  <option value="Low">Low</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                </select>{" "}
                {formik.touched.Priority && formik.errors.Priority && (
                  <p className="required_message">{formik.errors.Priority}</p>
                )}
              </div>
              <div className="mt-3">
                <p className="Stage__FormTittle">Due Date</p>
                <input
                  type="date"
                  name="DueDate"
                  min={moment().format("YYYY-MM-DD")}
                  value={formik.values.DueDate}
                  onChange={formik.handleChange}
                  className="ProjectStages___Stage__input w-full mt-2"
                />
                {formik.touched.DueDate && formik.errors.DueDate && (
                  <p className="required_message">{formik.errors.DueDate}</p>
                )}
              </div>
            </div>
            <div className=" grid grid-cols-2 gap-2">
              <div className="mt-3">
                <p className="Stage__FormTittle">Reminder Date</p>
                <input
                  type="date"
                  name="ReminderDate"
                  value={formik.values.ReminderDate}
                  onChange={formik.handleChange}
                  className="ProjectStages___Stage__input w-full mt-2"
                />
                {formik.touched.ReminderDate && formik.errors.ReminderDate && (
                  <p className="required_message">
                    {formik.errors.ReminderDate}
                  </p>
                )}
              </div>
              <div className="mt-3">
                <p className="Stage__FormTittle">Reminder Time</p>
                <input
                  type="time"
                  value={formik.values.ReminderTime}
                  onChange={formik.handleChange}
                  name="ReminderTime"
                  className="ProjectStages___Stage__input w-full mt-2"
                />
                {formik.touched.ReminderTime && formik.errors.ReminderTime && (
                  <p className="required_message">
                    {formik.errors.ReminderTime}
                  </p>
                )}
              </div>
            </div>
            <div className="mt-3">
              <p className="Stage__FormTittle">Description</p>
              <textarea
                name="Description"
                value={formik.values.Description}
                onChange={formik.handleChange}
                cols={30}
                rows={10}
                className="ProjectStages___Stage__input w-full mt-2"
              />{" "}
              {formik.touched.Description && formik.errors.Description && (
                <p className="required_message">{formik.errors.Description}</p>
              )}
            </div>
            {Loader.CreateNewTaskloader ? (
              <div className="w-full flex justify-center items-center">
                <CircularProgress color="inherit" size={20} />
              </div>
            ) : (
              <button
                className="ProjectStages__activateButton mt-1"
                type="submit"
              >
                Create Task
              </button>
            )}
          </form>
        </div>
      </Dialog>

      {/* edit task  */}
      <Dialog
        open={openAddTaskEdit}
        onClose={() => {
          setopenAddTaskEdit(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">Edit Task</p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setopenAddTaskEdit(false);
              }}
            />
          </div>
          <form onSubmit={formikEdit.handleSubmit}>
            <div className="mt-3">
              <p className="Stage__FormTittle">Task Name</p>

              <input
                name="TaskName"
                value={formikEdit.values.TaskName}
                onChange={formikEdit.handleChange}
                type="text"
                className="ProjectStages___Stage__input w-full mt-2"
              />
              {formikEdit.touched.TaskName && formikEdit.errors.TaskName && (
                <p className="required_message">{formikEdit.errors.TaskName}</p>
              )}
            </div>
            <div className=" grid grid-cols-2 gap-2">
              <div className="mt-3">
                <p
                  className="Stage__FormTittle"
                  onClick={() => {
                    console.log(allocationUsers);
                  }}
                >
                  Assigned task to
                </p>
                <input
                  disabled
                  name="TaskName"
                  value={EditDataTask.assigned_to}
                  type="text"
                  className="ProjectStages___Stage__input w-full mt-2"
                />
              </div>
              <div className="mt-3">
                <p
                  className="Stage__FormTittle"
                  onClick={() => {
                    console.log("projectStatus", projectStatus);
                  }}
                >
                  Task Type
                </p>
                <select
                  name="TaskType"
                  value={formikEdit.values.TaskType}
                  onChange={formikEdit.handleChange}
                  className="ProjectStages___Stage__input w-full mt-2"
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  {projectStatus.map((value: projectStatusType) => (
                    <option value={value._id}>{value.section_name}</option>
                  ))}
                </select>{" "}
                {formikEdit.touched.TaskType && formikEdit.errors.TaskType && (
                  <p className="required_message">
                    {formikEdit.errors.TaskType}
                  </p>
                )}
              </div>
            </div>
            <div className=" grid grid-cols-2 gap-2">
              <div className="mt-3">
                <p className="Stage__FormTittle">Priority</p>
                <select
                  name="Priority"
                  value={formikEdit.values.Priority}
                  onChange={formikEdit.handleChange}
                  className="ProjectStages___Stage__input w-full mt-2"
                >
                  <option value="" selected disabled>
                    Enter Details
                  </option>
                  <option value="Low">Low</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                </select>{" "}
                {formikEdit.touched.Priority && formikEdit.errors.Priority && (
                  <p className="required_message">
                    {formikEdit.errors.Priority}
                  </p>
                )}
              </div>
              <div className="mt-3">
                <p className="Stage__FormTittle">Due Date</p>
                <input
                  type="date"
                  name="DueDate"
                  min={moment().format("YYYY-MM-DD")}
                  value={formikEdit.values.DueDate}
                  onChange={formikEdit.handleChange}
                  className="ProjectStages___Stage__input w-full mt-2"
                />
                {formikEdit.touched.DueDate && formikEdit.errors.DueDate && (
                  <p className="required_message">
                    {formikEdit.errors.DueDate}
                  </p>
                )}
              </div>
            </div>
            <div className=" grid grid-cols-2 gap-2">
              <div className="mt-3">
                <p className="Stage__FormTittle">Reminder Date</p>
                <input
                  type="date"
                  name="ReminderDate"
                  value={formikEdit.values.ReminderDate}
                  onChange={formikEdit.handleChange}
                  className="ProjectStages___Stage__input w-full mt-2"
                />
                {formikEdit.touched.ReminderDate &&
                  formikEdit.errors.ReminderDate && (
                    <p className="required_message">
                      {formikEdit.errors.ReminderDate}
                    </p>
                  )}
              </div>
              <div className="mt-3">
                <p className="Stage__FormTittle">Reminder Time</p>
                <input
                  type="time"
                  value={formikEdit.values.ReminderTime}
                  onChange={formikEdit.handleChange}
                  name="ReminderTime"
                  className="ProjectStages___Stage__input w-full mt-2"
                />
                {formikEdit.touched.ReminderTime &&
                  formikEdit.errors.ReminderTime && (
                    <p className="required_message">
                      {formikEdit.errors.ReminderTime}
                    </p>
                  )}
              </div>
            </div>
            <div className="mt-3">
              <p className="Stage__FormTittle">Description</p>
              <textarea
                name="Description"
                value={formikEdit.values.Description}
                onChange={formikEdit.handleChange}
                cols={30}
                rows={10}
                className="ProjectStages___Stage__input w-full mt-2"
              />{" "}
              {formikEdit.touched.Description &&
                formikEdit.errors.Description && (
                  <p className="required_message">
                    {formikEdit.errors.Description}
                  </p>
                )}
            </div>
            {Loader.editTaskLoader ? (
              <div className="w-full flex justify-center items-center">
                <CircularProgress color="inherit" size={20} />
              </div>
            ) : (
              <button
                className="ProjectStages__activateButton mt-1"
                type="submit"
              >
                Edit Task
              </button>
            )}
          </form>
        </div>
      </Dialog>
      {/* dropzone */}
      <Dialog
        open={uploadFileOpen}
        onClose={() => {
          setuploadFileOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Upload Document
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setuploadFileOpen(false);
              }}
            />
          </div>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <>
                {Bluckfile?.length > 0 ? (
                  <div className="listingfile" style={{ overflowX: "scroll" }}>
                    <ul style={{ padding: "10px" }}>
                      {Bluckfile?.map((value, i: any) => (
                        <div className="flex items-center">
                          <LensIcon
                            className="me-1"
                            style={{
                              color: " #000000",
                              width: "10px",
                            }}
                          />
                          <li className="fileName">
                            {value?.name ? value?.name : value?.name}
                          </li>
                          <CloseIcon
                            fontSize="small"
                            className="ms-1"
                            onClick={() => {
                              // e.stopPropagation();
                              console.log(value);

                              deletFile(i);
                            }}
                            style={{ color: " #000000" }}
                          />
                        </div>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <div>
                      <div className="mb-3 flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="71"
                          height="51"
                          viewBox="0 0 71 51"
                          fill="none"
                        >
                          <path
                            d="M4.49219 5.3924C4.49219 2.41426 6.90645 0 9.88459 0H20.4238C21.1783 0 21.9293 0.158376 22.6154 0.472263C29.6513 3.69111 31.3036 5.38607 38.6491 6.66482C38.9417 6.71576 39.2427 6.74051 39.5397 6.74051H60.2137C63.1918 6.74051 65.6061 9.15477 65.6061 12.1329V27.8608C65.6061 30.8389 63.1919 33.2532 60.2137 33.2532H9.8846C6.90646 33.2532 4.49219 30.8389 4.49219 27.8608V5.3924Z"
                            fill="#FCC200"
                          />
                          <path
                            d="M0.732739 24.0042C0.346006 21.297 2.44663 18.875 5.18125 18.875H65.9635C68.6474 18.875 70.7329 21.2124 70.4281 23.8789L67.8603 46.3473C67.6009 48.6169 65.6801 50.3307 63.3957 50.3307H8.39101C6.15473 50.3307 4.25876 48.6863 3.9425 46.4725L0.732739 24.0042Z"
                            fill="url(#paint0_linear_1010_81020)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_1010_81020"
                              x1="35.5"
                              y1="11.0111"
                              x2="41.6543"
                              y2="54.5155"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#FFE185" />
                              <stop offset="1" stop-color="#F7BA00" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <input name="fileNames" {...getInputProps()} />
                      <p className="vendor_bulk_upload_text">
                        Drag & Drop Your File here <br /> or
                      </p>
                    </div>
                    <div>
                      <button className="browse_bulk_vendor">
                        Browse Files
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Dropzone>

          {Loader.docsLoader ? (
            <div className="w-full flex justify-center items-center">
              <CircularProgress color="inherit" size={20} />
            </div>
          ) : (
            <button
              className="ProjectStages__activateButton mt-1"
              onClick={() => {
                UploadDocs();
              }}
            >
              Upload Document
            </button>
          )}
        </div>
      </Dialog>
      {/* view All documents */}
      <Dialog
        open={Viewpopup}
        onClose={() => {
          setViewpopup(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center mb-4">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Uploaded Documents
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setViewpopup(false);
              }}
            />
          </div>
          {EditDataTask?.docs?.map((valuedoc: string) => (
            <div className="flex justify-start items-center gap-2 mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M15.9837 4.027C14.0519 2.09517 10.906 2.09517 8.97619 4.027L3.62365 9.37544C3.58879 9.4103 3.57033 9.45747 3.57033 9.50669C3.57033 9.55591 3.58879 9.60308 3.62365 9.63794L4.38039 10.3947C4.41498 10.4291 4.4618 10.4485 4.51061 10.4485C4.55943 10.4485 4.60625 10.4291 4.64084 10.3947L9.99338 5.04624C10.6578 4.38179 11.5417 4.01675 12.481 4.01675C13.4202 4.01675 14.3041 4.38179 14.9665 5.04624C15.631 5.71069 15.996 6.59458 15.996 7.53179C15.996 8.47105 15.631 9.35288 14.9665 10.0173L9.51145 15.4704L8.62756 16.3542C7.80109 17.1807 6.45783 17.1807 5.63137 16.3542C5.23146 15.9543 5.01203 15.4232 5.01203 14.8572C5.01203 14.2912 5.23146 13.76 5.63137 13.3601L11.0434 7.95015C11.1808 7.8148 11.3612 7.73892 11.554 7.73892H11.5561C11.7488 7.73892 11.9273 7.8148 12.0626 7.95015C12.2 8.08755 12.2738 8.26802 12.2738 8.46079C12.2738 8.65151 12.198 8.83198 12.0626 8.96733L7.63908 13.3868C7.60422 13.4216 7.58576 13.4688 7.58576 13.518C7.58576 13.5672 7.60422 13.6144 7.63908 13.6493L8.39582 14.406C8.43041 14.4404 8.47723 14.4598 8.52604 14.4598C8.57486 14.4598 8.62168 14.4404 8.65627 14.406L13.0778 9.98452C13.4859 9.57642 13.7094 9.03501 13.7094 8.45874C13.7094 7.88247 13.4838 7.33901 13.0778 6.93296C12.2349 6.09009 10.865 6.09214 10.0221 6.93296L9.49709 7.46001L4.61213 12.3429C4.28058 12.6725 4.01777 13.0647 3.83893 13.4966C3.6601 13.9286 3.5688 14.3917 3.57033 14.8592C3.57033 15.8087 3.94152 16.7008 4.61213 17.3714C5.30734 18.0646 6.21789 18.4112 7.12844 18.4112C8.03898 18.4112 8.94953 18.0646 9.64269 17.3714L15.9837 11.0345C16.9168 10.0994 17.4336 8.85454 17.4336 7.53179C17.4357 6.20698 16.9189 4.96216 15.9837 4.027Z"
                  fill="url(#paint0_linear_1010_81762)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1010_81762"
                    x1="4.16567"
                    y1="1.41703"
                    x2="19.9401"
                    y2="2.78853"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#626262" />
                    <stop offset="1" stop-color="#151414" />
                  </linearGradient>
                </defs>
              </svg>
              <p className="StageAccordionDetails__Agreement">
                {fileNameSplit(valuedoc)}
              </p>
              <p
                className="m-0 StageAccordionDetails__AgreementV cursor-pointer"
                onClick={() => {
                  window.open(`${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`);
                }}
              >
                View
              </p>
              <p
                className="m-0 StageAccordionDetails__AgreementV cursor-pointer"
                onClick={() => {
                  saveAs(
                    `${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`,
                    `${fileNameSplit(valuedoc)}`
                  );
                }}
              >
                Download
              </p>
              <p
                className="m-0 StageAccordionDetails__AgreementV "
                style={{ color: "#F05F5F", cursor: "pointer" }}
                onClick={() => {
                  DeletDocz(valuedoc);
                }}
              >
                Delete
              </p>
            </div>
          ))}

          <button
            className="ProjectStages__activateButton mt-3"
            onClick={() => {
              EditDataTask?.docs.map((value: any) => {
                saveAs(
                  `${process.env.REACT_APP_IMAGE_URL}/${value}`,
                  `${fileNameSplit(value)}`
                );
              });
            }}
          >
            Download All
          </button>
        </div>
      </Dialog>
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          {" "}
          {/* <select
            name="cars"
            id="cars"
            value={taskTypevalue}
            onChange={(e) => {
              settaskTypevalue(e.target.value);
            }}
            className="ProjectStages___Stage__input w-full mt-2"
          >
            <option value="" selected disabled>
              Select
            </option>
            {projectStatus.map((value: projectStatusType) => (
              <option value={value._id}>{value.section_name}</option>
            ))}{" "}
          </select>{" "} */}
          <div className="mt-2" style={{ width: "300px" }}>
            <MultiSelect
              placeholder={"Select Type"}
              options={taskTypevalue.map((value: any) => {
                return {
                  value: value.section_name,
                  label: value.section_name,
                  ID: value._id,
                };
              })}
              value={taskTypevalue}
              defaultValue={taskTypevalue}
              handleChange={handleChangeTaskType}
            />
          </div>
          <DateRangePicker
            placement="bottomStart"
            format="dd-MM-yyyy"
            placeholder="Choose Date Range"
            ranges={[
              {
                label: "today",
                value: [endOfDay(new Date()), startOfDay(new Date())],
                closeOverlay: false,
              },
              {
                label: "Month",
                value: [
                  endOfDay(subMonths(new Date(), 1)),
                  startOfDay(new Date()),
                ],
                closeOverlay: false,
              },
              {
                label: "3 Months",
                value: [
                  endOfDay(subMonths(new Date(), 3)),
                  startOfDay(new Date()),
                ],
                closeOverlay: false,
              },
              {
                label: "6 Months",
                value: [
                  endOfDay(subMonths(new Date(), 6)),
                  startOfDay(new Date()),
                ],
                closeOverlay: false,
              },
              {
                label: "Year",
                value: [
                  endOfDay(subYears(new Date(), 1)),
                  startOfDay(new Date()),
                ],
                closeOverlay: false,
              },
            ]}
            onOk={handlestartDateChange}
            className="mt-3 w-[400px]  focus:border-gray-400 border border-gray-300 "
            cleanable={true}
            defaultValue={(() => taskDateRange as [Date, Date])()}
          />
        </div>
        <Button
          onClick={() => {
            setopenAddTask(true);
          }}
          style={MUIstayle}
          variant="contained"
          startIcon={<AddIcon />}
        >
          Create New Task
        </Button>
      </div>
      {AllTask.length > 0 ? (
        <>
          {AllTask?.map((value: taskObject) => (
            <AccordionTransition
              value={value}
              setMeetingOutcomeopen={setMeetingOutcomeopen}
              setopenAddTask={setopenAddTask}
              setopenAddTaskEdit={setopenAddTaskEdit}
              setuploadFileOpen={setuploadFileOpen}
              setViewpopup={setViewpopup}
              setEditDataTask={setEditDataTask}
              getAllTask={getAllTask}
            />
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center mt-28">
          {/* <h3>No Data Found</h3> */}
          <img className="h-[250px] w-[220px]" src={nodata} alt="no-data" />
        </div>
      )}
    </div>
  );
};

export default Task;
type Propsvalue = {
  value?: taskObject;
  setMeetingOutcomeopen?: (value: boolean) => void;
  setopenAddTask?: (value: boolean) => void;
  setopenAddTaskEdit?: (value: boolean) => void;
  setuploadFileOpen?: (value: boolean) => void;
  setViewpopup?: (value: boolean) => void;
  setEditDataTask?: (value: any) => void;
  getAllTask?: any;
};

function AccordionTransition({
  value,
  setMeetingOutcomeopen,
  setopenAddTask,
  setopenAddTaskEdit,
  setuploadFileOpen,
  setViewpopup,
  setEditDataTask,
  getAllTask,
}: Propsvalue) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [EditView, setEditView] = useState<boolean>(false);
  const [finlefile, setfinlefile] = useState<boolean>(false);
  const [DeletID, setDeletID] = useState<any>("");

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const DeletTask = () => {
    axiosClientInterceptors
      .delete(`/clients/stages/tasks/?task_id=${DeletID}`)
      .then((res) => {
        console.log(res);
        getAllTask();
        // alert("Deleted !");
        Toaster("success", res.data.message);
      })
      .catch((err) => {
        Toaster("error", err.response.data.message);

        console.log(err);
      });
  };

  const fileNameSplit = (str: any) => {
    if (str) {
      return str.split("/").at(-1).split("-").slice(1).join("-");
    }
  };

  const DeletDocz = ({ doc, ID }: deletdoc) => {
    axiosClientInterceptors
      .delete(`/clients/stages/tasks/task-documents?task_id=${ID}&doc=${doc}`)
      .then((res) => {
        console.log(res);
        getAllTask();
        Toaster("success", res.data.message);
      })
      .catch((error) => {
        Toaster("success", error.response.data.message);

        console.log(error);
      });
  };

  return (
    <div className="mt-3">
      <Dialog
        open={finlefile}
        onClose={() => {
          setfinlefile(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle"></p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setfinlefile(false);
              }}
            />
          </div>
          <h3 className=" flex justify-center items-center my-8">
            Are you Sure !
          </h3>
          <div className="flex justify-between items-center gap-3">
            <button
              style={{ padding: "10px" }}
              className="w-full bg-red-600 text-white"
              onClick={() => {
                setfinlefile(false);
              }}
            >
              Cancle
            </button>{" "}
            <button
              className="w-full bg-green-500 text-white"
              style={{ padding: "10px" }}
              onClick={() => {
                setfinlefile(false);
                DeletTask();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Dialog>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          style={{ width: "100%" }}
          //   expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="flex justify-between items-center w-full px-3">
            <p
              className="ProjectStages__Card__Title"
              onClick={() => {
                console.log(value);
              }}
            >
              {value?.task_name || "-"}
            </p>
            {value?.task_status === "Completed" ? (
              <p
                className="ProjectStages__InProgresstask  p-2  text-green-500"
                style={{ color: "green" }}
              >
                {value?.task_status || "-"}
              </p>
            ) : (
              <p
                className="ProjectStages__InProgresstask  p-2 "
                onClick={() => {
                  console.log(value?.task_status);
                }}
              >
                {value?.task_status || "-"}
              </p>
            )}
            <p className="ProjectStages__UpdatedBy">
              Task Created on:{" "}
              {value?.createdAt
                ? moment(value?.createdAt).format("h:mm A, D MMMM YYYY")
                : "-"}
            </p>
            <p className="ProjectStages__UpdatedBy">
              Due Date:{" "}
              {value?.due_date
                ? moment(value?.due_date).format("h:mm A, D MMMM YYYY")
                : "-"}
            </p>
            <div className="flex justify-center items-start">
              {" "}
              <ExpandMoreIcon />{" "}
              {!expanded ? (
                <p className="ProjectStages__More">View More</p>
              ) : (
                <p className="ProjectStages__More">View Less</p>
              )}
            </div>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <div className=" grid grid-cols-7 mt-3">
            <div>
              <p className="StageAccordionDetails__title">Task created by</p>
              <p className="StageAccordionDetails__subtitle">
                {value?.created_by || "-"}
              </p>
            </div>
            <div>
              <p className="StageAccordionDetails__title">Assigned to</p>
              <p className="StageAccordionDetails__subtitle">
                {value?.assigned_to || "-"}
              </p>
            </div>
            <div>
              <p className="StageAccordionDetails__title">Task Type</p>
              <p className="StageAccordionDetails__subtitle">
                {value?.task_type || "-"}
              </p>
            </div>

            <div>
              <p className="StageAccordionDetails__title">Remind Date</p>
              <p className="StageAccordionDetails__subtitle">
                {value?.reminder_time || "-"},{value?.reminder_date || "-"}
              </p>
            </div>
            <div className="">
              <p className="StageAccordionDetails__title">Task Priority</p>
              <p
                className="StageAccordionDetails__subtitle"
                style={{ color: "#FF000" }}
              >
                {value?.priority || "-"}
              </p>
            </div>
            <div className="">
              <p className="StageAccordionDetails__title">Last Updated on</p>
              <p className="StageAccordionDetails__subtitle">
                {value?.updatedAt || "-"}
              </p>
            </div>
            <div className="">
              <p className="StageAccordionDetails__title">Submitted on</p>
              <p className="StageAccordionDetails__subtitle">
                {value?.submitted_on || "-"}
              </p>
            </div>
          </div>
          <div className="mt-4">
            <p className="StageAccordionDetails__title">Description</p>
            <p className="StageAccordionDetails__subtitle">
              {value?.description || "-"}
            </p>
          </div>{" "}
          <div className="mt-4">
            <p className="StageAccordionDetails__title">Task Outcome</p>
            <p className="StageAccordionDetails__subtitle">
              {value?.task_outcome || "-"}
            </p>
          </div>
          {/* <div className="mt-3">
            <p className="StageAccordionDetails__title">Attendees</p>
            <p className="StageAccordionDetails__Ssubtitle">@Abram</p>
          </div> */}
          <div className=" flex justify-start  gap-4 flex-wrap w-full">
            {value?.docs?.slice(0, 3)?.map((valuedoc: string) => (
              <div className="mt-2 flex  justify-start items-center gap-6 ">
                <div className="flex justify-start items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M15.9837 4.027C14.0519 2.09517 10.906 2.09517 8.97619 4.027L3.62365 9.37544C3.58879 9.4103 3.57033 9.45747 3.57033 9.50669C3.57033 9.55591 3.58879 9.60308 3.62365 9.63794L4.38039 10.3947C4.41498 10.4291 4.4618 10.4485 4.51061 10.4485C4.55943 10.4485 4.60625 10.4291 4.64084 10.3947L9.99338 5.04624C10.6578 4.38179 11.5417 4.01675 12.481 4.01675C13.4202 4.01675 14.3041 4.38179 14.9665 5.04624C15.631 5.71069 15.996 6.59458 15.996 7.53179C15.996 8.47105 15.631 9.35288 14.9665 10.0173L9.51145 15.4704L8.62756 16.3542C7.80109 17.1807 6.45783 17.1807 5.63137 16.3542C5.23146 15.9543 5.01203 15.4232 5.01203 14.8572C5.01203 14.2912 5.23146 13.76 5.63137 13.3601L11.0434 7.95015C11.1808 7.8148 11.3612 7.73892 11.554 7.73892H11.5561C11.7488 7.73892 11.9273 7.8148 12.0626 7.95015C12.2 8.08755 12.2738 8.26802 12.2738 8.46079C12.2738 8.65151 12.198 8.83198 12.0626 8.96733L7.63908 13.3868C7.60422 13.4216 7.58576 13.4688 7.58576 13.518C7.58576 13.5672 7.60422 13.6144 7.63908 13.6493L8.39582 14.406C8.43041 14.4404 8.47723 14.4598 8.52604 14.4598C8.57486 14.4598 8.62168 14.4404 8.65627 14.406L13.0778 9.98452C13.4859 9.57642 13.7094 9.03501 13.7094 8.45874C13.7094 7.88247 13.4838 7.33901 13.0778 6.93296C12.2349 6.09009 10.865 6.09214 10.0221 6.93296L9.49709 7.46001L4.61213 12.3429C4.28058 12.6725 4.01777 13.0647 3.83893 13.4966C3.6601 13.9286 3.5688 14.3917 3.57033 14.8592C3.57033 15.8087 3.94152 16.7008 4.61213 17.3714C5.30734 18.0646 6.21789 18.4112 7.12844 18.4112C8.03898 18.4112 8.94953 18.0646 9.64269 17.3714L15.9837 11.0345C16.9168 10.0994 17.4336 8.85454 17.4336 7.53179C17.4357 6.20698 16.9189 4.96216 15.9837 4.027Z"
                      fill="url(#paint0_linear_1010_81762)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1010_81762"
                        x1="4.16567"
                        y1="1.41703"
                        x2="19.9401"
                        y2="2.78853"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#626262" />
                        <stop offset="1" stop-color="#151414" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <p
                    className="StageAccordionDetails__Agreement"
                    onClick={() => {
                      console.log(value?.docs);
                    }}
                  >
                    {fileNameSplit(valuedoc)}
                  </p>
                  <p
                    className="m-0 StageAccordionDetails__AgreementV cursor-pointer"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`
                      );
                    }}
                  >
                    View
                  </p>
                  <a
                    className="m-0 StageAccordionDetails__AgreementV cursor-pointer"
                    onClick={() => {
                      saveAs(
                        `${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`,
                        `${fileNameSplit(valuedoc)}`
                      );
                      // console.log(
                      //   `${process.env.REACT_APP_IMAGE_URL}/${valuedoc}`,
                      //   `${fileNameSplit(valuedoc)}`
                      // );
                    }}
                  >
                    Download
                  </a>
                  {!value.isTaskCompleted && (
                    <p
                      onClick={() => {
                        DeletDocz({ doc: valuedoc, ID: value?._id });
                      }}
                      className="m-0 StageAccordionDetails__AgreementV "
                      style={{ color: "#F05F5F", cursor: "pointer" }}
                    >
                      Delete
                    </p>
                  )}
                </div>
              </div>
            ))}

            {value?.docs && value?.docs?.length > 0 && (
              <svg
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setViewpopup && setViewpopup(true);
                  setEditDataTask && setEditDataTask(value);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="25"
                viewBox="0 0 36 25"
                fill="none"
              >
                <path
                  d="M2.23438 5.3924C2.23438 2.41426 4.64864 0 7.62678 0H9.5719C10.3264 0 11.0743 0.154811 11.757 0.47617C14.7574 1.88865 15.6998 2.67382 18.9803 3.27128C19.2725 3.3245 19.5706 3.34811 19.8676 3.34811H27.1982C30.1763 3.34811 32.5906 5.76237 32.5906 8.74051V11.1249C32.5906 14.1031 30.1763 16.5173 27.1982 16.5173H7.62678C4.64863 16.5173 2.23438 14.1031 2.23438 11.1249V5.3924Z"
                  fill="#FCC200"
                />
                <path
                  d="M0.732739 14.5042C0.346005 11.797 2.44663 9.375 5.18125 9.375H30.2303C32.9142 9.375 34.9996 11.7124 34.6949 14.3789L33.9363 21.0161C33.677 23.2857 31.7561 24.9995 29.4717 24.9995H6.12941C3.89314 24.9995 1.99716 23.3551 1.68091 21.1413L0.732739 14.5042Z"
                  fill="url(#paint0_linear_1010_81784)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1010_81784"
                    x1="17.6334"
                    y1="5.46887"
                    x2="20.6903"
                    y2="27.0782"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFE185" />
                    <stop offset="1" stop-color="#F7BA00" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
          <div className="flex justify-start items-center gap-3 mt-5">
            {/* <button
              style={{ color: "#000000" }}
              className=" ProjectStages__Activate"
            >
              Share
            </button> */}
            {!value?.isTaskCompleted && (
              <button
                onClick={() => {
                  setopenAddTaskEdit && setopenAddTaskEdit(true);
                  setEditDataTask && setEditDataTask(value);
                }}
                style={{ color: "#1364C2" }}
                className=" ProjectStages__Activate"
              >
                Edit Task
              </button>
            )}
            {!value?.isTaskCompleted && (
              <button
                onClick={() => {
                  setuploadFileOpen && setuploadFileOpen(true);
                  setEditDataTask && setEditDataTask(value);
                }}
                style={{ color: "#000000" }}
                className=" ProjectStages__Activate"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 16V7.85L8.4 10.45L7 9L12 4L17 9L15.6 10.45L13 7.85V16H11ZM4 20V15H6V18H18V15H20V20H4Z"
                    fill="#6A6A6A"
                  />
                </svg>
              </button>
            )}
            {!value?.isTaskCompleted && (
              <button
                onClick={() => {
                  setMeetingOutcomeopen && setMeetingOutcomeopen(true);
                  setEditDataTask && setEditDataTask(value);
                }}
                style={{ color: "#009D06" }}
                className=" ProjectStages__Activate"
              >
                Mark as Completed
              </button>
            )}
            {!value?.isTaskCompleted && (
              <button
                onClick={() => {
                  setfinlefile(true);
                  setDeletID(value?._id);
                }}
                style={{ color: "#FF0000" }}
                className=" ProjectStages__Activate"
              >
                Delete
              </button>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
