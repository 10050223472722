import React, { useEffect, useState } from "react";
import {
  otherFiles,
  otherFilesData,
  preOtherFilesFormik,
  resType,
} from "../../../../../Types";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "../../../Assets/svg/deletelogo.svg";
import { useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import SmallLoader from "../../../../Tools/SmallLoader";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Loader from "../../../../Tools/Loader";
import { Toaster } from "../../../../Tools/Toaster";
import axiosClientInterceptors from "../../../../ApiConfig/axiosClientInterceptor";
import { AxiosResponse } from "axios";
import { validateOtherFiles } from "../../../../Tools/Validation";

const OtherDocumentsCategory = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [otherFiles, setOtherFiles] = useState<otherFiles[]>([]);
  const [addLoader, setAddLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDel, setOpenDel] = useState<boolean>(false);
  const [editLoader, setEditLoader] = useState<boolean>(false);
  const [delLoader, setDelLoader] = useState<boolean>(false);
  const [otherFilesData, setOtherFilesData] = useState<otherFilesData>({
    section_name: "",
    _id: "",
  });
  const [otherFilesID, setOtherFilesID] = useState<string>("");

  const addOtherFilesFormik = useFormik<preOtherFilesFormik>({
    initialValues: {
      otherFiles: "",
    },
    validationSchema: validateOtherFiles,
    onSubmit: (val) => {
      setAddLoader(true);
      axiosClientInterceptors
        .post(`/config/general`, {
          section_heading: "Other File Category",
          section_name: val.otherFiles,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getOtherFilesData();
            setOpen(false);
            addOtherFilesFormik.resetForm({});
            setAddLoader(false);
            Toaster("success", data?.message);
          } else {
            Toaster("error", data?.message);
            setAddLoader(false);
          }
        })
        .catch((err: AxiosResponse) => {
          console.log(err);
          setAddLoader(false);
        });
    },
  });

  const editOtherFilesFormik = useFormik<preOtherFilesFormik>({
    enableReinitialize: true,
    initialValues: {
      otherFiles: otherFilesData?.section_name,
    },
    validationSchema: validateOtherFiles,
    onSubmit: (val) => {
      setEditLoader(true);
      axiosClientInterceptors
        .put(`/config/general/${otherFilesData._id}`, {
          section_name: val.otherFiles,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getOtherFilesData();
            setOpenEdit(false);
            setEditLoader(false);
            Toaster("success", data?.message);
            editOtherFilesFormik.resetForm({});
          } else {
            Toaster("error", data?.message);
            setEditLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setEditLoader(false);
        });
    },
  });

  const deleteSection = () => {
    setDelLoader(true);
    axiosClientInterceptors
      .delete(`/config/general/${otherFilesID}`)
      .then((res: AxiosResponse) => {
        const data: resType = res.data;
        if (data.success) {
          getOtherFilesData();
          setOpenDel(false);
          Toaster("success", data?.message);
          setDelLoader(false);
        } else {
          Toaster("error", data?.message);
          setDelLoader(false);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
        setDelLoader(false);
      });
  };

  const getOtherFilesData = () => {
    setLoader(true);
    axiosClientInterceptors
      .get(`/config/general/Other File Category`)
      .then((res: AxiosResponse) => {
        console.log(res);
        setOtherFiles(res.data?.result);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getOtherFilesData();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center h-[68px] p-6 bg-white">
        <p className="project_Heading">Other Documents Category</p>
        <button
          className="addstatus_btn"
          onClick={() => {
            setOpen(true);
          }}
        >
          {" "}
          <AddIcon sx={{ fontSize: "18px", marginBottom: "2px" }} /> File
          Category
        </button>
      </div>
      <div className="mt-4 py-4 px-6 dnd_top flex w-full gap-16 items-center h-[49px]">
        <p className="font-[Gm] font-semibold w-1/3 text-[14px] text-[#FFFFFF]">
          S.No.
        </p>
        <p className="font-[Gm] mt-0 font-semibold w-2/3 text-[14px] text-[#FFFFFF]">
          File Category{" "}
        </p>
        <p className="font-[Gm] mt-0 font-semibold w-1/5 text-[14px] text-[#FFFFFF]">
          Actions
        </p>
      </div>
      {loader ? (
        <Loader customHeight={"30vh"} />
      ) : (
        <>
          {otherFiles?.map((item, i) => (
            <div
              key={i}
              className="flex gap-16 items-center py-4 px-6 h-[55px] border-t-[2px] border-t-[#F5F8FA] bg-white"
            >
              <p className="content_title w-1/3">{i + 1}</p>
              <p className="content_title w-2/3 mt-0">{item?.section_name}</p>
              <div className="flex gap-5">
                <button
                  className="edit_stuts"
                  onClick={() => {
                    setOtherFilesData(item);
                    setOpenEdit(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="delete_status"
                  onClick={() => {
                    setOtherFilesID(item?._id);
                    setOpenDel(true);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </>
      )}

      {/* Add preConstruciton type pop starts */}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          addOtherFilesFormik.resetForm({});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "386px",
              minHeight: "230px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-between">
            <p className="font-[Gm] font-semibold text-[18px] text-[#000000]">
              Add File Category
            </p>
            <span
              onClick={() => {
                setOpen(false);
                addOtherFilesFormik.resetForm({});
              }}
            >
              <CloseRoundedIcon />
            </span>
          </div>
          <form action="" onSubmit={addOtherFilesFormik.handleSubmit}>
            <div className="mt-4">
              <div>
                <label
                  htmlFor=""
                  className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                >
                  File Category Name
                </label>
                <input
                  type="text"
                  name="otherFiles"
                  placeholder="Enter File Category Name"
                  className="add_input mt-3 w-full"
                  onChange={addOtherFilesFormik.handleChange}
                />
                {addOtherFilesFormik.touched.otherFiles &&
                  addOtherFilesFormik.errors.otherFiles && (
                    <p className="required_message">
                      {addOtherFilesFormik.errors.otherFiles}
                    </p>
                  )}
              </div>
              <button type="submit" className="input_btn mt-4 w-full">
                {addLoader ? <SmallLoader color="" /> : "Add"}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      {/* Add preConstruciton type pop ends */}

      {/* Edit preConstruciton pop ends */}
      <Dialog
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
          editOtherFilesFormik.resetForm({});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "386px",
              minHeight: "230px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-between">
            <p className="font-[Gm] font-semibold text-[18px] text-[#000000]">
              Edit File Category
            </p>
            <CloseRoundedIcon
              onClick={() => {
                setOpenEdit(false);
                editOtherFilesFormik.resetForm({});
              }}
            />
          </div>
          <form onSubmit={editOtherFilesFormik.handleSubmit}>
            <div className="mt-4">
              <div>
                <label
                  htmlFor=""
                  className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                >
                  File Category Name
                </label>
                <input
                  name="otherFiles"
                  type="text"
                  placeholder="Enter File Category Name"
                  className="add_input mt-3 w-full"
                  defaultValue={otherFilesData.section_name}
                  onChange={editOtherFilesFormik.handleChange}
                />
                {editOtherFilesFormik.touched.otherFiles &&
                  editOtherFilesFormik.errors.otherFiles && (
                    <p className="required_message">
                      {editOtherFilesFormik.errors.otherFiles}
                    </p>
                  )}
              </div>
              <button type="submit" className="input_btn mt-4 w-full">
                {editLoader ? <SmallLoader color="" /> : "Save"}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      {/* Edit preConstruciton pop starts */}

      {/* delete pop starts */}
      <Dialog
        open={openDel}
        onClose={() => {
          setOpenDel(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "404px",
              minHeight: "265px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-center items-center">
            <img
              style={{
                width: "80px",
              }}
              src={DeleteIcon}
              alt=""
            />
          </div>
          <div className="deleteText mt-6">
            Are you sure want to Delete this File Category?
          </div>
          <div className="flex justify-between items-center mt-9">
            <button
              className="cancelDelUser"
              onClick={() => {
                setOpenDel(false);
              }}
            >
              Cancel
            </button>
            <button
              className="delUser"
              onClick={() => {
                deleteSection();
              }}
            >
              {delLoader ? <SmallLoader color="" /> : "Delete"}
            </button>
          </div>
        </div>
      </Dialog>
      {/* delete pop ends */}
    </div>
  );
};

export default OtherDocumentsCategory;
