import React from "react";
import { Outlet } from "react-router-dom";
import AdminSideBar from "./AdminSideBar";

const Container = () => {
  return (
    <div className=" bg-[#FFFFFF]">
      <div className="">
        <div className="bg-[#FFFFFF] pl-[30px] py-[30px] sidebarnav">
          <AdminSideBar />
        </div>
        <div className="bg-[#FFFFFF]   ml-[253px] overflow-hidden">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Container;
