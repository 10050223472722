import React, { KeyboardEvent, useEffect, useState } from "react";
import "./Manageuser.css";
import { useFormik } from "formik";
import ValueType from "react-select";
import AddIcon from "@mui/icons-material/Add";
import axiosClientInterceptors from "../../../ApiConfig/axiosClientInterceptor";
import axios, { AxiosResponse } from "axios";
import {
  cities,
  option,
  resType,
  userFormikValue,
  userList,
  userType,
} from "../../../../Types";
import RsuitePagination from "../../../Tools/RsuitePagination";
import { PaginationProps } from "antd";
import Dialog from "@mui/material/Dialog";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Toaster } from "../../../Tools/Toaster";
import nodata from "../../Assets/nodata.jpg";
import {
  LastActivityDateLead,
  // LastActivityDateLead,
  validateAddUser,
} from "../../../Tools/Validation";
import DeleteIcon from "../../Assets/svg/deletelogo.svg";
import UserDetails from "./UserDetails";
import CloseIcon from "@mui/icons-material/Close";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import MultiSelect from "../../../Tools/MultiSelect";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { endOfDay, startOfDay, subMonths, subYears } from "date-fns";
import moment from "moment";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../App/store";
import {
  setUserStatus,
  setJoiningDateRange,
  setUsersRole,
  setJoiningStartDate,
  setJoiningEndDate,
  selectRole,
  selectJoiningDateRange,
  selectJoiningStartDate,
  selectJoiningEndDate,
  selectUserStatus,
  setUserPerPage,
  setUserPage,
  selectuserPage,
  selectuserTotalCount,
  selectuserPerPage,
  setUserTotalCount,
  setUserSearch,
  selectusersearch,
} from "../../../Features/ManageUsers/ManageUsersSlice";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "../../../Tools/Loader";
import SmallLoader from "../../../Tools/SmallLoader";

const ManageUser = () => {
  const dispatch = useDispatch<AppDispatch>();
  const defUserStatus = useAppSelector(selectUserStatus);
  const defUserRole = useAppSelector(selectRole);
  const page = useAppSelector(selectuserPage);
  const perPage = useSelector(selectuserPerPage);
  const totalCount = useSelector(selectuserTotalCount);
  // const searchedUser = useSelector(selectusersearch);

  const defJoiningDateRange = useAppSelector(selectJoiningDateRange);
  const defJoiningStartDate = useAppSelector(selectJoiningStartDate);
  const defJoiningEndDate = useAppSelector(selectJoiningEndDate);
  const defSearch = useAppSelector(selectusersearch);

  const [loader, setLoader] = useState<boolean>(false);
  const [applyLoader, setApplyLoader] = useState<boolean>(false);
  const [resetLoader, setResetLoader] = useState<boolean>(false);
  const [addUserLoader, setAddUserLoader] = useState<boolean>(false);
  const [delUserLoader, setDelUserLoader] = useState<boolean>(false);

  const [userRole, setUserRole] = useState<userType[]>([]);
  const [users, setUsers] = useState<userList[]>([]);
  const [openAddUser, setOpenAddUser] = useState<boolean>(false);
  const [showPasswords, setShowPassword] = useState<boolean>(false);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [openDel, setOpenDel] = useState<boolean>(false);
  const [SwitchScreen, setSwitchScreen] = useState<string>("userListing");
  const [userID, setUserID] = useState<string>("");
  const [city, setCity] = useState<cities[]>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(defSearch || "");

  const [status, setStatus] = useState<string>(defUserStatus);
  const [role, setRole] = useState<option[]>(defUserRole || []);
  const [joiningDate, setJoiningDate] =
    useState<[(Date | null | undefined)?, (Date | null | undefined)?]>(
      defJoiningDateRange
    );
  const [joiningStartDate, setjoiningStartDate] =
    useState<string>(defJoiningStartDate);
  const [joiningEndDate, setjoiningEndDate] =
    useState<string>(defJoiningEndDate);

  const addUserFormik = useFormik<userFormikValue>({
    initialValues: {
      fullName: "",
      empID: "",
      mobileNumber: "",
      userID: "",
      password: "",
      joiningDate: "",
      city: "",
      userType: "",
    },
    validationSchema: validateAddUser,
    onSubmit: (value) => {
      setAddUserLoader(true);
      axiosClientInterceptors
        .post(`/users/signup`, {
          fullname: addUserFormik.values.fullName,
          emp_ID: addUserFormik.values.empID,
          mobile: addUserFormik.values.mobileNumber,
          userID: addUserFormik.values.userID,
          password: addUserFormik.values.password,
          doj: addUserFormik.values.joiningDate,
          role: addUserFormik.values.userType,
          city: addUserFormik.values.city,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getAllUsers();
            addUserFormik.resetForm({});
            setOpenAddUser(false);
            Toaster("success", data?.message);
            setAddUserLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Toaster("error", err.response?.data?.message);
          setAddUserLoader(false);
        });
    },
  });

  const getUserType = () => {
    axiosClientInterceptors
      .get(`/users/type`)
      .then((res: AxiosResponse) => {
        console.log(res, "rse");
        setUserRole(res.data?.result);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const roleOptions = (userRole ?? [])?.map((x) => {
    return {
      value: x.role || "",
      label: x.role || "",
    };
  });

  const handleChangeRole = (value: any) => {
    setRole(value);
  };

  const onChangeDate = (date: [Date, Date]) => {
    // console.log(date);
    if (date) {
      setJoiningDate(date);
      const [startDate, endDate] = date;
      setjoiningStartDate(moment(startDate).format("YYYY-MM-DD"));
      setjoiningEndDate(moment(endDate).format("YYYY-MM-DD"));
    }
  };

  const getAllCity = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_CRM}/get-city`)
      .then((res: AxiosResponse) => {
        setCity(res.data.result);
      });
  };

  const selectAllCheckbox = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(users?.map((item) => item?._id));
    if (isCheckAll) setIsCheck([]);
  };

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const deleteUser = () => {
    setDelUserLoader(true);
    axiosClientInterceptors
      .delete(`/users`, {
        data: { users: isCheck },
      })
      .then((res: AxiosResponse) => {
        const data: resType = res.data;
        if (data?.success) {
          setIsCheck([]);
          setIsCheckAll(false);
          getAllUsers();
          setOpenDel(false);
          setDelUserLoader(false);
          Toaster("success", data?.message);
        } else {
          setDelUserLoader(false);
          Toaster("error", data?.message);
        }
      })
      .catch((err: AxiosResponse) => {
        setDelUserLoader(false);
        console.log(err);
      });
  };

  const getAllUsers = (reset?: string) => {
    setLoader(true);
    {
      reset ? setResetLoader(true) : setApplyLoader(true);
    }
    if (reset === "reset") {
      setRole([]);
      setJoiningDate([]);
      setjoiningStartDate("");
      setjoiningEndDate("");
      setStatus("");
    }

    dispatch(setUserStatus(reset === "reset" ? "" : status));
    dispatch(setUsersRole(reset === "reset" ? [] : role));
    dispatch(setJoiningDateRange(reset === "reset" ? [] : joiningDate));
    dispatch(setJoiningStartDate(reset === "reset" ? "" : joiningStartDate));
    dispatch(setJoiningEndDate(reset === "reset" ? "" : joiningEndDate));

    axiosClientInterceptors
      .post(`/users?page=${page}&limit=${perPage}&search=${search}`, {
        role: reset === "reset" ? [] : role?.map((x) => x.value) || [],
        doj_from: reset === "reset" ? "" : joiningStartDate || "",
        doj_to: reset === "reset" ? "" : joiningEndDate || "",
        status: reset === "reset" ? "" : status || "",
      })
      .then((res: AxiosResponse) => {
        setUsers(res.data?.result?.data);
        setOpenFilter(false);
        dispatch(setUserTotalCount(res.data?.result?.totalCount));
        dispatch(setUserPage(res.data?.result?.currentPage));
        setLoader(false);
        setResetLoader(false);
        setApplyLoader(false);
      })
      .then((err) => {
        console.log(err);
        setLoader(false);
        setResetLoader(false);
        setApplyLoader(false);
      });
  };

  const togglePassword = () => {
    setShowPassword(!showPasswords);
  };

  const handleChangePage: PaginationProps["onChange"] = (page, perPage) => {
    dispatch(setUserPage(page));
    dispatch(setUserPerPage(perPage));
  };

  useEffect(() => {
    getUserType();
    getAllCity();
  }, []);

  useEffect(() => {
    getAllUsers();
  }, [page, perPage, defSearch, SwitchScreen]);

  return (
    <div className="my-[30px] mr-[20px] bg-[#F5F8FA] border-[0.5px] border-[#DEDEDE] rounded-[2px]">
      {SwitchScreen === "userListing" && (
        <>
          <div className="p-6">
            <>
              {loader ? (
                <Loader customHeight={"85vh"} />
              ) : (
                <>
                  <div>
                    <div className="flex justify-between items-center pb-[21px] border-b-[1px] border-[#DEDEDE]">
                      <p className="manageuser_info">
                        Manage Users ({totalCount || 0})
                      </p>
                      <div className="flex gap-4">
                        <div className="relative">
                          <input
                            className="searchuser outline-none"
                            type="text"
                            style={{ position: "relative" }}
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                dispatch(setUserSearch(search));
                              }
                            }}
                          />
                          {search?.length > 0 && (
                            <ClearIcon
                              sx={{
                                position: "absolute",
                                top: "8px",
                                right: "11px",
                              }}
                              onClick={() => {
                                setSearch("");
                                dispatch(setUserSearch(""));
                              }}
                            />
                          )}
                          <div className="absolute top-3 left-3">
                            <svg
                              //   className="top-[29%] left-[3%]"
                              width="15"
                              height="15"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="5.39301"
                                cy="5.39319"
                                r="4.11976"
                                stroke="#667085"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.25836 8.47266L9.87354 10.0836"
                                stroke="#667085"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <button
                          className="manageUser_filterBtn"
                          onClick={() => {
                            setOpenFilter(true);
                          }}
                        >
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.43887 3.4218C4.43887 4.40699 3.66943 5.20551 2.71944 5.20551C1.77 5.20551 1 4.40699 1 3.4218C1 2.43719 1.77 1.63867 2.71944 1.63867C3.66943 1.63867 4.43887 2.43719 4.43887 3.4218ZM10.2467 2.64057C10.6622 2.64057 11 2.99085 11 3.4218C11 3.85332 10.6622 4.20361 10.2467 4.20361H6.95891C6.5428 4.20361 6.20503 3.85332 6.20503 3.4218C6.20503 2.99085 6.5428 2.64057 6.95891 2.64057H10.2467ZM1.75388 8.47759H5.04164C5.45775 8.47759 5.79553 8.82788 5.79553 9.2594C5.79553 9.69035 5.45775 10.0412 5.04164 10.0412H1.75388C1.33778 10.0412 1 9.69035 1 9.2594C1 8.82788 1.33778 8.47759 1.75388 8.47759ZM9.28057 11.0214C10.2306 11.0214 11 10.2229 11 9.23826C11 8.25307 10.2306 7.45455 9.28057 7.45455C8.33113 7.45455 7.56113 8.25307 7.56113 9.23826C7.56113 10.2229 8.33113 11.0214 9.28057 11.0214Z"
                              fill="#7D7D7D"
                            />
                          </svg>
                          Filter
                        </button>
                        <button
                          className="adduser_btn"
                          onClick={() => {
                            setOpenAddUser(true);
                          }}
                        >
                          <AddIcon
                            sx={{ fontSize: "15px", paddingBottom: "3px" }}
                          />
                          Add User
                        </button>
                        <div
                          className="del_btn"
                          onClick={() => {
                            if (isCheck.length > 0) {
                              setOpenDel(true);
                            } else {
                              Toaster("error", "Select user to delete!");
                            }
                          }}
                        >
                          <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.3342 3.05775C12.5611 3.05775 12.75 3.24616 12.75 3.48591V3.70757C12.75 3.94149 12.5611 4.13574 12.3342 4.13574H2.66641C2.43892 4.13574 2.25 3.94149 2.25 3.70757V3.48591C2.25 3.24616 2.43892 3.05775 2.66641 3.05775H4.36725C4.71275 3.05775 5.01343 2.81217 5.09115 2.46567L5.18022 2.06784C5.31865 1.52593 5.77421 1.16602 6.29558 1.16602H8.70443C9.22012 1.16602 9.68079 1.52593 9.81411 2.03926L9.90942 2.46509C9.98657 2.81217 10.2873 3.05775 10.6333 3.05775H12.3342ZM11.4702 11.1607C11.6477 9.50575 11.9586 5.57413 11.9586 5.53446C11.97 5.41429 11.9308 5.30054 11.8531 5.20896C11.7697 5.12321 11.6642 5.07246 11.5479 5.07246H3.45673C3.33986 5.07246 3.22867 5.12321 3.15151 5.20896C3.07322 5.30054 3.03464 5.41429 3.04032 5.53446C3.04136 5.54175 3.05251 5.68024 3.07117 5.91177C3.15402 6.94033 3.38478 9.80507 3.53389 11.1607C3.63941 12.1593 4.29467 12.787 5.2438 12.8097C5.97621 12.8266 6.73075 12.8325 7.50231 12.8325C8.22905 12.8325 8.96713 12.8266 9.72224 12.8097C10.7043 12.7928 11.359 12.1762 11.4702 11.1607Z"
                              fill="#FE3E3E"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="manageUser_table bg-white mt-[15px]">
                    <table className="w-full border-[1.2px] border-solid border-[#E6E6E6]">
                      <thead className="h-[50px] sticky top-0 z-10 bg-[#111111] font-[Gr] text-[14px] text-[#FFFFFF] font-medium">
                        <tr className="h-[45px] top-0 z-10">
                          <th className="w-[60px] px-[20px]">
                            <input
                              type="checkbox"
                              // id="selectAll"
                              onChange={selectAllCheckbox}
                              checked={isCheckAll}
                              className="styled-checkbox"
                              id="styled-checkbox-1"
                            />
                            <label htmlFor="styled-checkbox-1"></label>
                          </th>
                          <th className="text-start font-[Gm] font-medium text-[14px]  px-6 whitespace-nowrap">
                            SL.No
                          </th>
                          <th className="text-start font-[Gm] font-medium text-[14px]  px-6 whitespace-nowrap">
                            Full Name
                          </th>
                          <th className="text-start font-[Gm] font-medium text-[14px]  px-6 whitespace-nowrap">
                            User Code
                          </th>
                          <th className="text-start font-[Gm] font-medium text-[14px]  px-6 whitespace-nowrap">
                            Role
                          </th>
                          <th className="text-start font-[Gm] font-medium text-[14px]  px-6 whitespace-nowrap">
                            User ID
                          </th>
                          <th className="text-start font-[Gm] font-medium text-[14px] px-6 whitespace-nowrap">
                            Action
                          </th>
                        </tr>
                      </thead>
                      {users?.map((user, i) => (
                        <tbody key={i}>
                          <tr
                            className="hover:opacity-[0.7] hover:bg-[#F6F6F6] border-b-[2px] border-[#F5F8FA] h-[66px] bg-white cursor-pointer"
                            onClick={() => {
                              setUserID(user._id);
                              setSwitchScreen("userDetails");
                            }}
                          >
                            <td className="w-[60px] px-[20px]">
                              <input
                                type="checkbox"
                                id={user._id}
                                onChange={handleClick}
                                checked={isCheck.includes(user?._id)}
                                className="styled-checkbox"
                                onClick={(
                                  e: React.MouseEvent<
                                    HTMLInputElement,
                                    MouseEvent
                                  >
                                ) => {
                                  e.stopPropagation();
                                }}
                              />
                              <label
                                onClick={(
                                  e: React.MouseEvent<
                                    HTMLLabelElement,
                                    MouseEvent
                                  >
                                ) => {
                                  e.stopPropagation();
                                }}
                                htmlFor={user?._id}
                              ></label>
                            </td>
                            <td className="text-start font-[Gm] font-medium text-[14px] px-6">
                              {" "}
                              {(page - 1) * perPage + i + 1}
                            </td>
                            <td className="text-start font-[Gm] font-medium text-[14px] px-6">
                              {user.fullname}
                            </td>
                            <td className="text-start font-[Gm] font-medium text-[14px] px-6">
                              {user.user_code}
                            </td>
                            <td className="text-start font-[Gm] font-medium text-[14px] px-6">
                              {user.role}
                            </td>
                            <td className="text-start font-[Gm] font-medium text-[14px] px-6">
                              {user.userID}
                            </td>
                            <td className="text-start font-[Gm] font-medium text-[14px] px-6">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M17.7087 13.6123V6.38815C17.7087 3.87148 15.9262 2.29232 13.4037 2.29232H6.59699C4.07449 2.29232 2.29199 3.86315 2.29199 6.38815L2.29199 13.6123C2.29199 16.1373 4.07449 17.709 6.59699 17.709H13.4037C15.9262 17.709 17.7087 16.1373 17.7087 13.6123Z"
                                  stroke="#130F26"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.4057 10.0013H6.5957"
                                  stroke="#130F26"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.2686 6.87638L13.4052 9.99971L10.2686 13.123"
                                  stroke="#130F26"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <div>
                      {users?.length === 0 && (
                        <div
                          className="flex justify-center items-center bg-white mt-[2px] flex-col"
                          style={{ height: "50vh" }}
                        >
                          <img
                            className="h-[200px] w-[170px]"
                            src={nodata}
                            alt="no-data"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {users?.length > 0 && (
                    <div className="dealsPagination mt-10">
                      <RsuitePagination
                        handleChangePage={handleChangePage}
                        limit={perPage}
                        total={totalCount}
                        currentPage={page}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          </div>

          {/* Add User Pop up starts */}
          <Dialog
            open={openAddUser}
            onClose={() => {
              setOpenAddUser(false);
              addUserFormik.resetForm({});
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "458px",
                  minHeight: "594px",
                },
              },
            }}
          >
            <div className="border-t-[5px] border-[#FCC200]">
              <div className="p-5">
                <div className="flex justify-between items-center">
                  <span className="font-[Gr] font-semibold text-[18px] text-[#0E0E0E]">
                    Add User
                  </span>
                  <div
                    onClick={() => {
                      setOpenAddUser(false);
                      addUserFormik.resetForm({});
                    }}
                  >
                    <CloseRoundedIcon
                      sx={{ color: "black", cursor: "pointer" }}
                    />
                  </div>
                </div>
                <form onSubmit={addUserFormik.handleSubmit}>
                  <div className="mt-8">
                    <div className="">
                      <div>
                        <p className="font-[Gm] font-medium text-[13px] text-[#0E0E0E] mb-2">
                          Full Name
                        </p>
                        <input
                          name="fullName"
                          type="text"
                          className="w-full addUsr_input"
                          placeholder="eg. sample user"
                          onChange={addUserFormik.handleChange}
                        />
                        {addUserFormik.touched.fullName &&
                          addUserFormik.errors.fullName && (
                            <p className="required_message">
                              {addUserFormik.errors.fullName}
                            </p>
                          )}
                      </div>
                      <div className="flex gap-3 mt-6">
                        <div className="w-1/2">
                          <p className="font-[Gm] font-medium text-[13px] text-[#0E0E0E] mb-2">
                            Employee ID
                          </p>
                          <input
                            name="empID"
                            type="text"
                            className="w-full small_input"
                            placeholder="eg. CEN01XX"
                            onChange={addUserFormik.handleChange}
                            onKeyDown={(e: KeyboardEvent) => {
                              let element = e.target as HTMLInputElement;
                              let value = element.value;
                              // let num = e.target.value;
                              // if (e.keyCode === 69 || e.key == "." || e.key === "-" || e.key === "+" || num.length >= 10 && e.keyCode !== 8) {
                              //   e.preventDefault();
                              // }
                              if (value?.length >= 6 && e.keyCode !== 8) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {addUserFormik.touched.empID &&
                            addUserFormik.errors.empID && (
                              <p className="required_message">
                                {addUserFormik.errors.empID}
                              </p>
                            )}
                        </div>
                        <div className="w-1/2">
                          <p className="font-[Gm] font-medium text-[13px] text-[#0E0E0E] mb-2">
                            Mobile Number
                          </p>
                          <div className="flex">
                            <div className="num91">+91</div>
                            <div className="w-full">
                              <input
                                name="mobileNumber"
                                type="text"
                                className="w-full numInput"
                                placeholder="eg. 8451256526"
                                onChange={addUserFormik.handleChange}
                                onKeyDown={(e: KeyboardEvent) => {
                                  let element = e.target as HTMLInputElement;
                                  let value = element.value;
                                  // let num = e.target.value;
                                  // if (e.keyCode === 69 || e.key == "." || e.key === "-" || e.key === "+" || num.length >= 10 && e.keyCode !== 8) {
                                  //   e.preventDefault();
                                  // }
                                  if (value?.length >= 10 && e.keyCode !== 8) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {addUserFormik.touched.mobileNumber &&
                            addUserFormik.errors.mobileNumber && (
                              <p className="required_message">
                                {addUserFormik.errors.mobileNumber}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="flex gap-3 mt-6">
                        <div className="w-1/2">
                          <p className="font-[Gm] font-medium text-[13px] text-[#0E0E0E] mb-2">
                            User ID
                          </p>
                          <input
                            name="userID"
                            type="text"
                            className="w-full small_input"
                            placeholder="eg. user@gmail.com"
                            onChange={addUserFormik.handleChange}
                          />
                          {addUserFormik.touched.userID &&
                            addUserFormik.errors.userID && (
                              <p className="required_message">
                                {addUserFormik.errors.userID}
                              </p>
                            )}
                        </div>
                        <div className="w-1/2 relative">
                          <p className="font-[Gm] font-medium text-[13px] text-[#0E0E0E] mb-2">
                            Password
                          </p>
                          <div className="flex items-center">
                            <div className="w-5/6">
                              <input
                                name="password"
                                type={showPasswords ? "text" : "password"}
                                className="pwdInput"
                                onChange={addUserFormik.handleChange}
                                placeholder="eg. XXXX"
                              />
                            </div>
                            <div
                              className="hide w-1/6"
                              onClick={togglePassword}
                            >
                              {showPasswords ? (
                                <VisibilityIcon className="userpassword_eye" />
                              ) : (
                                <VisibilityOff className="userpassword_eye" />
                              )}
                            </div>
                          </div>
                          {addUserFormik.touched.password &&
                            addUserFormik.errors.password && (
                              <p className="required_message">
                                {addUserFormik.errors.password}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="flex gap-3 mt-6">
                        <div className="w-1/2">
                          <p className="font-[Gm] font-medium text-[13px] text-[#0E0E0E] mb-2">
                            Joining Date
                          </p>
                          <input
                            name="joiningDate"
                            type="date"
                            // min={new Date().toISOString().split("T")[0]}
                            className="w-full small_input"
                            placeholder="dd/mm/yyyy"
                            onChange={addUserFormik.handleChange}
                          />
                          {addUserFormik.touched.joiningDate &&
                            addUserFormik.errors.joiningDate && (
                              <p className="required_message">
                                {addUserFormik.errors.joiningDate}
                              </p>
                            )}
                        </div>
                        <div className="w-1/2">
                          <p className="font-[Gm] font-medium text-[13px] text-[#0E0E0E] mb-2">
                            User Type
                          </p>
                          <select
                            className="w-full small_input"
                            name="userType"
                            onChange={addUserFormik.handleChange}
                          >
                            <option hidden>Select Type</option>
                            {userRole?.map((item, index) => (
                              <option value={item.role} key={index}>
                                {item?.user_group}
                              </option>
                            ))}
                          </select>
                          {addUserFormik.touched.userType &&
                            addUserFormik.errors.userType && (
                              <p className="required_message">
                                {addUserFormik.errors.userType}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="w-full mt-6">
                        <p className="font-[Gm] font-medium text-[13px] text-[#0E0E0E] mb-2">
                          City
                        </p>
                        <select
                          name="city"
                          className="addUsr_input"
                          onChange={addUserFormik.handleChange}
                        >
                          <option value="" hidden>
                            Select City
                          </option>
                          <option value="Bangalore">Bangalore</option>
                        </select>
                        {addUserFormik.touched.city &&
                          addUserFormik.errors.city && (
                            <p className="required_message">
                              {addUserFormik.errors.city}
                            </p>
                          )}
                      </div>
                      <button type="submit" className="w-full add_btn mt-6">
                        <AddIcon
                          sx={{ fontSize: "18px", paddingBottom: "2px" }}
                        />{" "}
                        {addUserLoader ? (
                          <SmallLoader color={""} />
                        ) : (
                          "Add User"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Dialog>
          {/* Add User Pop up ends */}

          {/* Delete Pop up starts */}
          <Dialog
            open={openDel}
            onClose={() => {
              setOpenDel(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "404px",
                  height: "226px",
                },
              },
            }}
          >
            <div className="p-5">
              <div className="flex justify-center items-center">
                <img
                  style={{
                    width: "50px",
                  }}
                  src={DeleteIcon}
                  alt=""
                />
              </div>
              <div className="deleteText mt-6">
                Are you sure want to Delete {`${isCheck.length}`}
                {isCheck.length > 1 ? " Users" : " User"} ?
              </div>
              <div className="flex justify-between items-center mt-9">
                <button
                  className="cancelDelUser"
                  onClick={() => {
                    setOpenDel(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="delUser"
                  onClick={() => {
                    deleteUser();
                  }}
                >
                  {delUserLoader ? <SmallLoader color={""} /> : "Delete"}
                </button>
              </div>
            </div>
          </Dialog>
          {/* Delete Pop up ends */}
        </>
      )}

      {SwitchScreen === "userDetails" && (
        <UserDetails
          userID={userID}
          setSwitchScreen={setSwitchScreen}
          city={city}
        />
      )}

      {/* filter pop up starts */}
      <Dialog
        open={openFilter}
        onClose={() => {
          setOpenFilter(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "480px",
              minHeight: "390px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-between items-center mb-7">
            <p className="filter_title">Apply Filters</p>
            <CloseIcon
              onClick={() => {
                setOpenFilter(false);
              }}
              sx={{ cursor: "pointer" }}
            />
          </div>
          {/* <form onSubmit={filterFormik.handleSubmit}> */}
          <div>
            <p className="mb-2 sub_heading">Choose Status</p>
            <Select
              onValueChange={(value) => {
                setStatus(value);
              }}
              defaultValue={status}
            >
              <SelectTrigger id="framework" className="w-full">
                <SelectValue placeholder="Select Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="active">Active</SelectItem>
                <SelectItem value="inactive">Inactive</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="mt-4 ">
            <p className="mb-2 sub_heading">Choose Role</p>
            <MultiSelect
              placeholder={"Choose Role"}
              options={roleOptions}
              value={role}
              defaultValue={role}
              handleChange={handleChangeRole}
            />
          </div>
          <div className="mt-4 daterange">
            <p className="mb-2 sub_heading">Joining Date</p>
            <DateRangePicker
              placement="topStart"
              format="dd-MM-yyyy"
              placeholder="Choose Date Range"
              ranges={[
                {
                  label: "today",
                  value: [endOfDay(new Date()), startOfDay(new Date())],
                  closeOverlay: false,
                },
                {
                  label: "Month",
                  value: [
                    endOfDay(subMonths(new Date(), 1)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "3 Months",
                  value: [
                    endOfDay(subMonths(new Date(), 3)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "6 Months",
                  value: [
                    endOfDay(subMonths(new Date(), 6)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
                {
                  label: "Year",
                  value: [
                    endOfDay(subYears(new Date(), 1)),
                    startOfDay(new Date()),
                  ],
                  closeOverlay: false,
                },
              ]}
              onOk={onChangeDate}
              style={{ width: "100%" }}
              cleanable={true}
              defaultValue={(() => joiningDate as [Date, Date])()}
            />
          </div>
          <div className="flex gap-4 mt-6">
            <button
              className="resetFitler"
              type="reset"
              onClick={() => {
                getAllUsers("reset");
                // setOpenFilter(false);
              }}
            >
              {resetLoader ? <SmallLoader color={""} /> : "Reset All"}
            </button>
            <button
              className="applyFilter"
              type="submit"
              onClick={() => {
                getAllUsers();
              }}
            >
              {applyLoader ? <SmallLoader color={""} /> : " Apply Filter"}
            </button>
          </div>
          {/* </form> */}
        </div>
      </Dialog>
      {/* filter pop up ends */}
    </div>
  );
};

export default ManageUser;
