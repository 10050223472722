import { Dialog, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import StageTabs from "./StageTabs";
import axiosClientInterceptors from "../../../../../ApiConfig/axiosClientInterceptor";
import { useSelector } from "react-redux";
import {
  selectClientID,
  selectindividualStageData,
  setindividualStageData,
} from "../../../../../Features/AllClients/AllClients";
import { StageObject, sageLoader } from "../../../../../../Types";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../App/store";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import moment from "moment";
import { Toaster } from "../../../../../Tools/Toaster";
interface FormValues {
  handled_by?: string;
  start_date?: string;
  estimated_end_date?: string;
}
const validationSchema = yup.object({
  handled_by: yup.string().required("Stage Handled By is required"),
  start_date: yup.string().required("start_date is required"),
  estimated_end_date: yup
    .string()
    .required("estimated_end_date is required")
    .test(
      "is-greater",
      "End date must be greater than start date",
      function (estimatedEndDate) {
        const startDate = this.parent.start_date; // Accessing the value of start_date
        if (!startDate || !estimatedEndDate) {
          return true; // Skip validation if either date is not provided
        }
        return new Date(estimatedEndDate) >= new Date(startDate); // Compare dates
      }
    ),
});

const ProjectStages = () => {
  const dispatch = useDispatch<AppDispatch>();
  const siglestageData = useSelector(selectindividualStageData);

  const [Activate, SetActivate] = useState(false);
  const [ViewStage, setViewStage] = useState<boolean>(false);
  const ClientID = useSelector(selectClientID);
  const [StageData, setStageData] = useState<StageObject[]>([]);
  const [StageID, setStageID] = useState("");
  const [loader, setloader] = useState<sageLoader>({
    activatebotton: false,
    pageloader: false,
  });

  const getStage = () => {
    setloader({
      activatebotton: false,
      pageloader: true,
    });
    axiosClientInterceptors
      .get(`/clients/stages/${ClientID}?section_name=Pre-Construction`)
      .then((res) => {
        const result = res.data.result;
        console.log("resukk", res);
        setStageData(result);
        setloader({
          activatebotton: false,
          pageloader: false,
        });
      })
      .catch((err) => {
        setloader({
          activatebotton: false,
          pageloader: false,
        });
        // console.log(err)
      });
  };

  const formikActivestage = useFormik<FormValues>({
    initialValues: {
      handled_by: "",
      start_date: "",
      estimated_end_date: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setloader({
        activatebotton: true,
        pageloader: false,
      });
      axiosClientInterceptors
        .put(`/clients/stages/${StageID}`, { ...values, activate: true })
        .then((res) => {
          const result = res.data.result;
          // alert(res.data.message);
          getStage && getStage();
          formikActivestage.resetForm({});
          SetActivate(false);
          // setStageData(result);
          setloader({
            activatebotton: false,
            pageloader: false,
          });
          Toaster("success", "Stage Activated Successfully");
        })
        .catch((err) => {
          setloader({
            activatebotton: false,
            pageloader: false,
          });
          Toaster("error", "Stage is Not Activated ");
          // console.log(err)
        });
    },
  });

  useEffect(() => {
    getStage();
  }, []);
  return (
    <>
      {ViewStage ? (
        <div className="w-full">
          <div className="flex justify-between items-center cursor-pointer">
            <p
              className="Single__StageName"
              onClick={() => {
                console.log(StageData);
              }}
            >
              {siglestageData.stage_name}
            </p>
            <div
              className="backbutton flex  items-center"
              onClick={() => {
                setViewStage(false);
              }}
            >
              <KeyboardBackspaceIcon />
              Back
            </div>
          </div>
          <StageTabs />
        </div>
      ) : (
        <div>
          <Dialog
            open={Activate}
            onClose={() => {
              SetActivate(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              zIndex: 30,
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  minWidth: "458px",
                  //   height: "246px",
                },
              },
            }}
          >
            <form
              onSubmit={formikActivestage.handleSubmit}
              style={{ borderTop: "7px solid  #FCC200" }}
              className="p-4"
            >
              <div className="flex justify-between items-center">
                <p className="ProjectStagesPopFill__Stage__Tittle">
                  Fill in the stage details
                </p>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    SetActivate(false);
                  }}
                />
              </div>
              <div className="mt-3">
                <p className="Stage__FormTittle">Stage Handled By</p>
                <select
                  value={formikActivestage.values.handled_by}
                  onChange={formikActivestage.handleChange}
                  name="handled_by"
                  id="cars"
                  className="ProjectStages___Stage__input mt-3 w-full"
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  <option value="Cendrol">Cendrol</option>
                  <option value="Outsourced">Outsourced</option>
                  <option value="Client">Client</option>
                </select>
                {formikActivestage.errors.handled_by &&
                  formikActivestage.touched.handled_by && (
                    <div className=" text-red-500">
                      {formikActivestage.errors.handled_by}
                    </div>
                  )}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-3">
                <div>
                  <p className="Stage__FormTittle">Start Date </p>
                  <input
                    name="start_date"
                    type="date"
                    value={formikActivestage.values.start_date}
                    onChange={formikActivestage.handleChange}
                    className="ProjectStages___Stage__input mt-3 w-full"
                  />
                  {formikActivestage.errors.start_date &&
                    formikActivestage.touched.start_date && (
                      <div className=" text-red-500">
                        {formikActivestage.errors.start_date}
                      </div>
                    )}
                </div>
                <div>
                  <p className="Stage__FormTittle">Estimated End Date</p>
                  <input
                    value={formikActivestage.values.estimated_end_date}
                    onChange={formikActivestage.handleChange}
                    name="estimated_end_date"
                    type="date"
                    className="ProjectStages___Stage__input mt-3 w-full"
                  />
                  {formikActivestage.errors.estimated_end_date &&
                    formikActivestage.touched.estimated_end_date && (
                      <div className=" text-red-500">
                        {formikActivestage.errors.estimated_end_date}
                      </div>
                    )}
                </div>
              </div>
              {loader.activatebotton ? (
                <div className=" flex justify-center items-center w-full mt-4">
                  {" "}
                  <CircularProgress color="inherit" size={20} />
                </div>
              ) : (
                <button
                  className="ProjectStages__activateButton mt-3"
                  type="submit"
                >
                  Activate
                </button>
              )}
            </form>
          </Dialog>

          <>
            {loader.pageloader ? (
              <div className=" flex justify-center items-center w-full mt-4">
                {" "}
                <CircularProgress color="inherit" size={20} />
              </div>
            ) : (
              <>
                {StageData?.map((value, index) => (
                  <>
                    {value.isActive ? (
                      <div className="mt-5">
                        <AccordionTransition
                          setViewStage={setViewStage}
                          stageData={value}
                          getStage={getStage}
                        />
                      </div>
                    ) : (
                      <div
                        className="p-3 bg-[#FFF] grid grid-cols-5  items-center mt-4"
                        key={value._id}
                      >
                        <p className="ProjectStages__Card__Title  w-fit">
                          {value?.stage_name || "-"}
                        </p>
                        <p className="ProjectStages__Yettostart w-fit">
                          {value?.status || "-"}
                        </p>
                        <p className="ProjectStages__UpdatedBy w-fit">
                          Updated By: {value?.updated_by || "-"}
                        </p>
                        <p className="ProjectStages__UpdatedBy w-fit">
                          Activity Date & Time: {value?.updatedAt || "-"}
                        </p>
                        <button
                          className="ProjectStages__Activate  w-fit    ms-32"
                          onClick={() => {
                            setStageID(value._id);
                            SetActivate(true);
                          }}
                        >
                          Activate
                        </button>
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
          </>

          {/* */}
        </div>
      )}
    </>
  );
};

export default ProjectStages;
type propsData = {
  setViewStage?: (value: boolean) => void;
  stageData?: StageObject;
  getStage?: () => void;
};
function AccordionTransition({ setViewStage, stageData, getStage }: propsData) {
  const [expanded, setExpanded] = React.useState(false);
  const [EditView, setEditView] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      handled_by: stageData?.handled_by || "",
      start_date: stageData?.start_date
        ? moment(stageData?.start_date).format("YYYY-MM-DD")
        : "-",
      estimated_end_date: stageData?.estimated_end_date
        ? moment(stageData?.estimated_end_date).format("YYYY-MM-DD")
        : "-",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      axiosClientInterceptors
        .put(`/clients/stages/${stageData?._id}`, values)
        .then((res) => {
          const result = res.data.result;
          console.log("resukk", result);
          setEditView(false);
          // alert(res.data.message);
          Toaster("success", res.data.message);
          getStage && getStage();
          formik.resetForm({});
          // setStageData(result);
        })
        .catch((err) => {
          // console.log(err)
        });
    },
  });

  const iscompelt = () => {
    axiosClientInterceptors
      .put(`/clients/stages/${stageData?._id}`, { completed: true })
      .then((res) => {
        // alert(res.data.message);
        Toaster("success", res.data.message);
        getStage && getStage();
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  const reopentheStage = () => {
    axiosClientInterceptors
      .put(`/clients/stages/${stageData?._id}`, { reopen: true })
      .then((res) => {
        // alert(res.data.message);
        Toaster("success", res.data.message);

        getStage && getStage();
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          style={{ width: "100%" }}
          //   expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="grid grid-cols-5 items-center w-full px-3">
            <p className="ProjectStages__Card__Title w-fit">
              {" "}
              {stageData?.stage_name || "-"}
            </p>
            {/* Completed */}
            {stageData?.status === "Completed" ? (
              <p
                style={{
                  color: "#38B000",
                  background: "#DBF1D1",
                }}
                className="ProjectStages__InProgress p-3 w-fit  text-green-800 bg-green-300"
                onClick={() =>
                  console.log("stageData?.status=====>", stageData?.status)
                }
              >
                {" "}
                {stageData?.status || "-"}
              </p>
            ) : (
              <p
                className="ProjectStages__InProgress p-3 w-fit "
                onClick={() =>
                  console.log("stageData?.status=====>", stageData?.status)
                }
              >
                {" "}
                {stageData?.status || "-"}
              </p>
            )}

            <p className="ProjectStages__UpdatedBy w-fit">
              Updated By: {stageData?.updated_by || "-"}
            </p>
            <p className="ProjectStages__UpdatedBy w-fit">
              Activity Date & Time: {stageData?.updatedAt || "-"}
            </p>
            <div className="flex justify-center items-start w-fit ms-32">
              {" "}
              <ExpandMoreIcon />{" "}
              {!expanded ? (
                <p className="ProjectStages__More">View More</p>
              ) : (
                <p className="ProjectStages__More">View Less</p>
              )}
            </div>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <form onSubmit={formik.handleSubmit}>
            <div className=" grid grid-cols-5 mt-3">
              <div>
                <p className="StageAccordionDetails__title">Stage Handled by</p>
                {EditView ? (
                  <>
                    <select
                      value={formik.values.handled_by}
                      onChange={formik.handleChange}
                      name="handled_by"
                      id="cars"
                      className="ProjectStages___Stage__input mt-3"
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="Cendrol">Cendrol</option>
                      <option value="Outsourced">Outsourced</option>
                      <option value="Client">Client</option>
                    </select>
                    {formik.errors.handled_by && formik.touched.handled_by && (
                      <div className=" text-red-500">
                        {formik.errors.handled_by}
                      </div>
                    )}
                  </>
                ) : (
                  <p className="StageAccordionDetails__subtitle">
                    {stageData?.handled_by}
                  </p>
                )}
              </div>
              <div>
                <p className="StageAccordionDetails__title">
                  Start Date<span className=" text-red-800">*</span>
                </p>
                {EditView ? (
                  <>
                    <input
                      name="start_date"
                      type="date"
                      value={formik.values.start_date}
                      onChange={formik.handleChange}
                      className="ProjectStages___Stage__input mt-3"
                    />
                    {formik.errors.start_date && formik.touched.start_date && (
                      <div className=" text-red-500">
                        {formik.errors.start_date}
                      </div>
                    )}
                  </>
                ) : (
                  <p className="StageAccordionDetails__subtitle">
                    {stageData?.start_date
                      ? moment(stageData?.start_date).format("DD/MM/YYYY")
                      : "-"}
                  </p>
                )}
              </div>
              <div>
                <p className="StageAccordionDetails__title">
                  Estimated End Date<span className=" text-red-800">*</span>
                </p>
                {EditView ? (
                  <>
                    <input
                      value={formik.values.estimated_end_date}
                      onChange={formik.handleChange}
                      name="estimated_end_date"
                      type="date"
                      className="ProjectStages___Stage__input mt-3"
                    />
                    {formik.errors.estimated_end_date &&
                      formik.touched.estimated_end_date && (
                        <div className=" text-red-500">
                          {formik.errors.estimated_end_date}
                        </div>
                      )}
                  </>
                ) : (
                  <p className="StageAccordionDetails__subtitle">
                    {stageData?.estimated_end_date
                      ? moment(stageData?.estimated_end_date).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </p>
                )}
              </div>
              <div>
                <p className="StageAccordionDetails__title">End Date</p>
                <p className="StageAccordionDetails__subtitle">
                  {stageData?.end_date}
                </p>
              </div>
              <div>
                <p className="StageAccordionDetails__title">
                  No. of Days Taken
                </p>
                <p className="StageAccordionDetails__subtitle">
                  {stageData?.days_taken}
                </p>
              </div>
            </div>
            <div className="flex justify-start items-center gap-3 mt-3">
              {stageData?.status === "Completed" ? (
                <div
                  onClick={() => {
                    reopentheStage();
                  }}
                  // type=""
                  style={{ color: "#B00000", cursor: "pointer" }}
                  className=" ProjectStages__Activate"
                >
                  Re-open Stage
                </div>
              ) : (
                <>
                  {EditView ? (
                    <div className="mt-3 flex justify-start items-center gap-3">
                      <button
                        type="submit"
                        style={{ color: "#38B000", cursor: "pointer" }}
                        className=" ProjectStages__Activate "
                      >
                        Save Changes
                      </button>
                      <div
                        onClick={() => {
                          setEditView(false);
                        }}
                        style={{ color: "#F00", cursor: "pointer" }}
                        className=" ProjectStages__Activate"
                      >
                        Discard Change
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ color: "#1364C2", cursor: "pointer" }}
                      onClick={() => {
                        setEditView(true);
                      }}
                      className=" ProjectStages__Activate"
                    >
                      Edit
                    </div>
                  )}
                </>
              )}
              {!EditView && (
                <>
                  {!stageData?.isStageCompleted && (
                    <div
                      onClick={() => {
                        iscompelt && iscompelt();
                      }}
                      style={{ color: "#38B000", cursor: "pointer" }}
                      className=" ProjectStages__Activate"
                    >
                      Mark as Completed
                    </div>
                  )}
                  <button
                    onClick={() => {
                      setViewStage && setViewStage(true);
                      // setStageID(stageData._id);
                      console.log("stageData", stageData);
                      dispatch(setindividualStageData(stageData));
                    }}
                    style={{ color: "#000000" }}
                    className=" ProjectStages__Activate"
                  >
                    View Stage
                  </button>
                </>
              )}
            </div>
          </form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
