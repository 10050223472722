import React, { KeyboardEvent, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import doc from "../../../Assets/svg/Doc.svg";
import haltIcon from "../../../Assets/svg/haltIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import note from "../../../Assets/svg/NoteIcon.svg";
import email from "../../../Assets/svg/Email.svg";
import call from "../../../Assets/svg/Call.svg";
import whatsapp from "../../../Assets/svg/whatsapp.svg";
import deleteIcon from "../../../Assets/svg/del.svg";
import delIcon from "../../../Assets/svg/deletelogo.svg";
import Dialog from "@mui/material/Dialog";
import ErrorIcon from "@mui/icons-material/Error";
import {
  cities,
  clientDataType,
  clientFormik,
  haltReasonFormik,
  projectStatusType,
  resType,
} from "../../../../../Types";
import axios, { AxiosResponse } from "axios";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import axiosClientInterceptors from "../../../../ApiConfig/axiosClientInterceptor";
import { useFormik } from "formik";
import Tabs from "./PreconstructionTabs";
import SmallLoader from "../../../../Tools/SmallLoader";
import { Toaster } from "../../../../Tools/Toaster";
import Loader from "../../../../Tools/Loader";
import { validateClientDetails } from "../../../../Tools/Validation";
import { selectClientID } from "../../../../Features/AllClients/AllClients";
import { useAppSelector } from "../../../App/store";
import { useNavigate } from "react-router-dom";
import ConstructionTab from "./ConstructionTab";

const ConstructionClientDetails = () => {
  const navigate = useNavigate();
  const [actionMenu, setActionMenu] = useState<boolean>(false);

  return (
    <>
      {" "}
      <div className="py-4 px-3">
        <div className="flex items-center border-b-[1px] pb-[18px]">
          <span
            className="text-[16px] text-[#667085] font-medium cursor-pointer"
            onClick={() => {
              navigate("/Home/AllClients"); // Example navigation
            }}
          >
            All Clients
          </span>
          <div className="p-1">
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 0.5L5.5 5L1 9.5" stroke="#667085" />
            </svg>
          </div>
          <span className="text-[16px] text-[#667085] font-medium">
            Clients Details
          </span>
        </div>

        <>
          {/* {clientsData?.status === "inactive" && (
            <div className="p-3 h-[113px] bg-[#FFF4F4]">
              <p className="flex items-center font-sans text-[13px] font-normal text-[#E92323]">
                <ErrorIcon
                  sx={{
                    fontSize: "16px",
                    color: "#E92323",
                    paddingRight: "2px",
                  }}
                />{" "}
                Halt Reason
              </p>
              <p className="font-sans font-normal text-[13px] text-black">
                {clientsData?.status_reason || "-"}
              </p>
            </div>
          )} */}

          <div className="p-3">
            <div className="flex items-center justify-between">
              <span className="font-[Gm] text-[18px] font-medium">
                Client Details
              </span>
              <div className="flex gap-3">
                <button
                  disabled={true}
                  className="action_btn"
                  //   onClick={() => {
                  //     setActionMenu(!actionMenu);
                  //   }}
                >
                  Action <KeyboardArrowDownIcon sx={{ marginLeft: "3px" }} />
                  {actionMenu && (
                    <ClickAwayListener
                      onClickAway={() => {
                        setActionMenu(false);
                      }}
                    >
                      <div className="actionList">
                        <div
                          className="hover:opacity-[0.7] hover:bg-[#F6F6F6]  menuItem border-solid"
                          //   onClick={() => {
                          //     setOpenHalt(true);
                          //   }}
                        >
                          <img src={haltIcon} /> Make Project Halt
                        </div>
                        <div
                          className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid"
                          onClick={() => {
                            // setImportDataOpen(true);
                          }}
                        >
                          <img src={note} /> Create Note
                        </div>
                        <div
                          className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid"
                          onClick={() => {
                            // setImportDataOpen(true);
                          }}
                        >
                          <img src={email} /> Send Email
                        </div>
                        <div
                          className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid"
                          onClick={() => {
                            // setImportDataOpen(true);
                          }}
                        >
                          <img src={call} /> Call
                        </div>
                        <div
                          className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid"
                          onClick={() => {
                            // setImportDataOpen(true);
                          }}
                        >
                          <img src={whatsapp} /> WhatsApp Call
                        </div>
                        {/* <div
                        className="hover:opacity-[0.7] hover:bg-[#F6F6F6] menuItem border-solid text-[#FE3E3E]"
                        onClick={() => {
                          setOpenDel(true);
                        }}
                      >
                        <img src={deleteIcon} /> Delete
                      </div> */}
                      </div>
                    </ClickAwayListener>
                  )}
                </button>
              </div>
            </div>
            <div>
              <p className=" mt-4 font-[Gm] font-medium text-[15px] text-[#969696]">
                Client Name
              </p>
              <p className="font-[Gm] font-semibold text-[24px] text-[#252C32]">
                {"-"}
              </p>
            </div>
            <div className="mt-4 flex items-center gap-20">
              <div className="w-full">
                <p className="user_heading">Mobile Number </p>
                <p className="user_data">+91 {"-"}</p>
              </div>
              <div className="w-full">
                <p className="user_heading">Alt Mobile Number</p>
                <p className="user_data">+91 {"-"}</p>
              </div>
              <div className="w-full">
                <p className="user_heading">Email ID</p>
                <p className="user_data">{"-"}</p>
              </div>
              <div className="w-full">
                <p className="user_heading">Client ID</p>
                <p className="user_data">{"-"}</p>
              </div>
              <div className="w-full">
                <p className="user_heading">Project ID</p>
                <p className="user_data">{"-"}</p>
              </div>
            </div>
            <div className="mt-8 flex items-center gap-20">
              <div className="w-full">
                <p className="user_heading">City</p>
                <p className="user_data">{"-"}</p>
              </div>

              <div className="w-full">
                <p className="user_heading">Package</p>
                <p className="user_data"> {"-"}</p>
              </div>
              <div className="w-full">
                <p className="user_heading">Agreement Status</p>
                <p className="user_data">{"-"}</p>
              </div>
              <div className="w-full">
                <p className="user_heading">Agreement Date</p>
                <p className="user_data">{"-"}</p>
              </div>
              <div className="w-full">
                <p className="user_heading">Project Status</p>
                <p className="user_data">{"-"}</p>
              </div>
            </div>
          </div>
          <div>
            {/* {loader ? (
          <Loader customHeight={"70vh"} />
        ) : ( */}
            <ConstructionTab />
            {/* )} */}
          </div>
        </>
      </div>
    </>
  );
};

export default ConstructionClientDetails;
