import React from "react";
import AllClients from "../AllPreConstructionList";

const ListingPreConstructionTab = () => {
  return (
    <div>
      <AllClients />
    </div>
  );
};

export default ListingPreConstructionTab;
