import Container from "./AllComponents/Admin/Container";
import { ToastContainer } from "react-toastify";
// import AllClients from "./AllComponents/Helpers/AllClients/AllClients";
import Configuration from "./AllComponents/Helpers/Configuration/Configuration";
import ManageUser from "./AllComponents/Helpers/ManageUsers/ManageUser";
import Task from "./AllComponents/Helpers/Task/Task";
import Login from "./AllComponents/Login";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./AllComponents/Helpers/Dashboard/Dashboard";
import MeetingCalender from "./AllComponents/Helpers/MeetingCalender/MeetingCalender";
import PersonalDetails from "./AllComponents/Admin/PersonalDetails";
import ListingPreConstructionTab from "./AllComponents/Helpers/AllClients/Pre-ConstructionStage/ListingPreConstructionTab";
import ConstructionClientDetails from "./AllComponents/Helpers/AllClients/ClientDetails/ConstructionClientDetails";
import ClientDetails from "./AllComponents/Helpers/AllClients/ClientDetails/PreConstructionClientDetails";
import ActionDoc from "./AllComponents/Helpers/AllClients/DoCAction/ActionDoc";
import Documents from "./AllComponents/Helpers/Documents/Documents";

const NotFound = () => {
  return <div>Not Found</div>;
};
function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Home" element={<Container />}>
          <Route path="AllClients" element={<ListingPreConstructionTab />} />
          <Route path="ClientDetailsPage" element={<ClientDetails />} />
          <Route path="ActionDOC" element={<Documents />} />
          <Route
            path="ConstructionClientDetails"
            element={<ConstructionClientDetails />}
          />
          <Route path="Task" element={<Task />} />
          <Route path="ManageUsers" element={<ManageUser />} />
          <Route path="Configuration" element={<Configuration />} />
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="MeetingCalender" element={<MeetingCalender />} />
          <Route path="Profile" element={<PersonalDetails />} />
          <Route path="Documents" element={<Documents />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
