import React, { useEffect, useState } from "react";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axiosClientInterceptors from "../../../../../ApiConfig/axiosClientInterceptor";
import { selectClientID } from "../../../../../Features/AllClients/AllClients";
import { useAppSelector } from "../../../../App/store";
import {
  notesobject,
  note_type,
  stagenameobject,
  PinApiperams,
  loaderObj,
} from "../../../../../../Types";
import { useFormik } from "formik";
import * as yup from "yup";
import PushPinIcon from "@mui/icons-material/PushPin";
import MultiSelect from "../../../../../Tools/MultiSelect";
import CircularProgress from "@mui/material/CircularProgress";
import { DateRangePicker } from "rsuite";
import {
  addMonths,
  addYears,
  endOfDay,
  startOfDay,
  subMonths,
  subYears,
} from "date-fns";
import moment from "moment";
import { Toaster } from "../../../../../Tools/Toaster";
interface FormValues {
  Stage_Name?: string;
  Note_type?: string;
  Description?: string;
}
const validationSchema = yup.object({
  // Stage_Name: yup.string().required("Stage_Name is required"),
  Note_type: yup.string().required("Note_type is required"),
  Description: yup.string().required("Description is required"),
});
// update Note

const validationSchemaupdate = yup.object({
  Note_type: yup.string().required("Note_type is required"),
  Description: yup.string().required("Description is required"),
});

const Notes = () => {
  const [noteOpen, setnoteOpen] = useState(false);
  const [noteupdateOpen, setnoteupdateOpen] = useState(false);
  const clientID = useAppSelector(selectClientID);
  const [AllNotes, setAllNotes] = useState<notesobject[]>([]);
  const [NoteTypeListing, setNoteTypeListing] = useState<note_type[]>([]);
  const [stageListing, setstageListing] = useState<stagenameobject[]>([]);
  const [NoteIDEDit, setNoteIDEDit] = useState<string>("");
  const [Search, setSearch] = useState<string>("");
  const [SelectStageNameFilter, setSelectStageNameFilter] =
    useState<string>("");
  const [SelectNotetypeFilter, setSelectNotetypeFilter] = useState<any>([]);
  const handleChangeNotetype = (value: any) => {
    setSelectNotetypeFilter(value);
  };
  const [notetypeMultiselect, setnotetypeMultiselect] = useState<any>([]);
  const [Loader, setLoader] = useState<loaderObj>({
    addLoader: false,
    editLoader: false,
    pageloader: false,
  });
  const [meetingDateRange, setmeetingDateRange] = useState<Date[]>([]);
  const [meetingStartDate, setmeetingStartDate] = useState<string>("");
  const [meetingEndDate, setmeetingEndDate] = useState<string>("");
  const [NoteEditData, setNoteEditData] = useState<any>(null);

  const handlestartDateChange = (date: [Date, Date]) => {
    if (date) {
      setmeetingDateRange(date);
      const [startDate, endDate] = date;
      setmeetingStartDate(moment(startDate).format("YYYY-MM-DD"));
      setmeetingEndDate(moment(endDate).format("YYYY-MM-DD"));
    }
  };
  const formik = useFormik<FormValues>({
    initialValues: {
      Stage_Name: "",
      Note_type: "",
      Description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader({
        addLoader: true,
        editLoader: false,
        pageloader: false,
      });
      axiosClientInterceptors
        .post(
          `/clients/notes/${clientID}`,

          {
            section_name: "Pre-Construction",
            note_type: values.Note_type,
            stage_id: values.Stage_Name,
            description: values.Description,
            note_origin: "CRM",
          }
        )
        .then((response) => {
          setLoader({
            addLoader: false,
            editLoader: false,
            pageloader: false,
          });
          formik.resetForm({});
          getNotes();
          setnoteOpen(false);
          Toaster("success", response.data.message);
        })
        .catch((err) => {
          console.log(err);
          setLoader({
            addLoader: false,
            editLoader: false,
            pageloader: false,
          });
        });
    },
  });

  const formikupdateNote = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      Stage_Name: NoteEditData?.stage_id || "",

      Note_type: NoteEditData?.note_type || "",
      Description: NoteEditData?.description || "",
    },
    validationSchema: validationSchemaupdate,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setLoader({
        addLoader: false,
        editLoader: true,
        pageloader: false,
      });
      axiosClientInterceptors
        .put(
          `/clients/notes/${NoteIDEDit}`,

          {
            section_name: "Pre-Construction",
            note_type: values.Note_type,
            description: values.Description,
            stage_id: values.Stage_Name,

            note_origin: "CRM",
          }
        )
        .then((response) => {
          setLoader({
            addLoader: false,
            editLoader: false,
            pageloader: false,
          });
          getNotes();
          Toaster("success", response.data.message);

          setnoteupdateOpen(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader({
            addLoader: false,
            editLoader: false,
            pageloader: false,
          });
        });
    },
  });

  const getNoteType = () => {
    axiosClientInterceptors
      .get(`/general/config?heading=Note Type`)
      .then((res) => {
        const result = res.data.result;
        // [
        //   { value: "In Progress", label: "In Progress" },
        //   { value: "Pending", label: "Pending" },
        //   { value: "Done", label: "Done" },
        // ];
        setNoteTypeListing(result);
        setnotetypeMultiselect(
          result.map((valu: any) => {
            return {
              value: valu.section_name,
              label: valu.section_name,
              ID: valu._id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSTageListing = () => {
    axiosClientInterceptors
      .get(
        `/general/stages-list?lead_id=${clientID}&section_name=Pre-Construction`
      )
      .then((res) => {
        const result = res.data.result;
        console.log("data", result);
        // setNoteTypeListing(result);
        setstageListing(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNotes = () => {
    setLoader({
      addLoader: false,
      editLoader: false,
      pageloader: true,
    });
    axiosClientInterceptors
      .post(`/clients/notes?search=${Search}`, {
        section_name: "Pre-Construction",
        stage_id: SelectStageNameFilter,
        clientID: clientID,
        note_type: SelectNotetypeFilter.map((val: any) => val.ID),
        created_from_date: meetingStartDate,
        created_to_date: meetingEndDate,
      })
      .then((response) => {
        const result = response.data.result;

        setAllNotes(result);
        setLoader({
          addLoader: false,
          editLoader: false,
          pageloader: false,
        });
      })
      .catch((err) => {
        setLoader({
          addLoader: false,
          editLoader: false,
          pageloader: false,
        });
        console.log(err);
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getNotes();
    }, 500); // Adjust the debounce delay time as needed

    return () => clearTimeout(delayDebounceFn);
  }, [Search, SelectNotetypeFilter, SelectStageNameFilter, meetingDateRange]);

  const deletNote = (ID: string) => {
    axiosClientInterceptors
      .delete(`/clients/notes/${ID}`)
      .then((response) => {
        Toaster("success", response.data.message);

        getNotes();
      })
      .catch((err) => {
        Toaster("error", err.response.data.message);

        console.log(err);
      });
  };

  const pinUnPinNote = ({ ID, value }: PinApiperams) => {
    axiosClientInterceptors
      .patch(`/clients/notes/${ID}`, {
        isPinned: value,
      })
      .then((response) => {
        console.log(response);
        Toaster("success", "Note Pinned Successfully");

        getNotes();
      })
      .catch((err) => {
        Toaster("error", err.response.data.message);

        console.log(err);
      });
  };

  useEffect(() => {
    getSTageListing();
    getNoteType();
    getNotes();
  }, []);
  return (
    <div>
      <Dialog
        open={noteOpen}
        onClose={() => {
          setnoteOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "458px",
              //   height: "246px",
            },
          },
        }}
      >
        <form
          style={{ borderTop: "7px solid  #FCC200" }}
          className="p-4"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">Create Note </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setnoteOpen(false);
              }}
            />
          </div>
          <div className="mt-3">
            <p className="Stage__FormTittle">Stage Name</p>

            <select
              name="Stage_Name"
              value={formik.values.Stage_Name}
              onChange={formik.handleChange}
              id="cars"
              className="ProjectStages___Stage__input w-full mt-3"
            >
              <option value="" disabled selected>
                Select Stage Name
              </option>
              {stageListing.map((value) => (
                <option value={value._id}>{value.name}</option>
              ))}
            </select>
            {/* {formik.errors.Stage_Name && formik.touched.Stage_Name && (
              <div className="text-red-500">{formik.errors.Stage_Name}</div>
            )} */}
          </div>
          <div className="mt-3">
            <p className="Stage__FormTittle">Note type*</p>

            <select
              name="Note_type"
              id="cars"
              value={formik.values.Note_type}
              onChange={formik.handleChange}
              className="ProjectStages___Stage__input w-full mt-3"
            >
              <option value="" disabled selected>
                Select Note type
              </option>
              {NoteTypeListing.map((value) => (
                <option value={value._id}>{value.section_name}</option>
              ))}
            </select>
            {formik.errors.Note_type && formik.touched.Note_type && (
              <div className="text-red-500">{formik.errors.Note_type}</div>
            )}
          </div>

          <div className="mt-3">
            <p className="Stage__FormTittle">Description*</p>

            <textarea
              name="Description"
              id=""
              value={formik.values.Description}
              onChange={formik.handleChange}
              cols={30}
              rows={10}
              className="ProjectStages___Stage__input w-full mt-2"
            />
            {formik.errors.Description && formik.touched.Description && (
              <div className="text-red-500">{formik.errors.Description}</div>
            )}
          </div>

          {Loader.addLoader ? (
            <div className="flex justify-center items-center mt-2">
              <CircularProgress color="inherit" size={25} />
            </div>
          ) : (
            <button
              className="ProjectStages__activateButton mt-1"
              type="submit"
            >
              Create Note
            </button>
          )}
        </form>
      </Dialog>
      <Dialog
        open={noteupdateOpen}
        onClose={() => {
          setnoteupdateOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "458px",
              //   height: "246px",
            },
          },
        }}
      >
        <form
          style={{ borderTop: "7px solid  #FCC200" }}
          className="p-4"
          onSubmit={formikupdateNote.handleSubmit}
        >
          <div className="flex justify-between items-center">
            <p
              className="ProjectStagesPopFill__Stage__Tittle"
              onClick={() => {
                console.log(NoteEditData);
              }}
            >
              Edit Note
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setnoteupdateOpen(false);
              }}
            />
          </div>
          <div className="mt-3">
            <p className="Stage__FormTittle">Stage Name</p>

            <select
              name="Stage_Name"
              value={formikupdateNote.values.Stage_Name}
              onChange={formikupdateNote.handleChange}
              id="cars"
              className="ProjectStages___Stage__input w-full mt-3"
            >
              <option value="" disabled selected>
                Select Stage Name
              </option>
              {stageListing.map((value) => (
                <option value={value._id}>{value.name}</option>
              ))}
            </select>
            {/* {formik.errors.Stage_Name && formik.touched.Stage_Name && (
              <div className="text-red-500">{formik.errors.Stage_Name}</div>
            )} */}
          </div>

          <div className="mt-3">
            <p
              className="Stage__FormTittle"
              onClick={() => {
                console.log(NoteEditData);
              }}
            >
              Note type*
            </p>

            <select
              name="Note_type"
              id="cars"
              value={formikupdateNote.values.Note_type}
              onChange={formikupdateNote.handleChange}
              className="ProjectStages___Stage__input w-full mt-3"
            >
              <option value="" disabled selected>
                Select Note type
              </option>
              {NoteTypeListing.map((value) => (
                <option value={value._id}>{value.section_name}</option>
              ))}
            </select>
            {formikupdateNote.errors.Note_type &&
              formikupdateNote.touched.Note_type && (
                <div className="text-red-500">
                  {formikupdateNote.errors.Note_type}
                </div>
              )}
          </div>

          <div className="mt-3">
            <p className="Stage__FormTittle">Description*</p>

            <textarea
              name="Description"
              value={formikupdateNote.values.Description}
              onChange={formikupdateNote.handleChange}
              cols={30}
              rows={10}
              className="ProjectStages___Stage__input w-full mt-2"
            />
            {formikupdateNote.errors.Description &&
              formikupdateNote.touched.Description && (
                <div className="text-red-500">
                  {formikupdateNote.errors.Description}
                </div>
              )}
          </div>

          {Loader.editLoader ? (
            <div className="flex justify-center items-center mt-2">
              <CircularProgress color="inherit" size={25} />
            </div>
          ) : (
            <button
              className="ProjectStages__activateButton mt-1"
              type="submit"
            >
              Update Note
            </button>
          )}
        </form>
      </Dialog>
      <div className="flex justify-between items-center mt-3">
        <div className="flex justify-start items-center gap-6">
          <div className="group" style={{ width: "400px" }}>
            <svg
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              style={{ left: "1rem" }}
            >
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              value={Search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              className="input"
              type="search"
              placeholder="Search By User"
            />
          </div>
          <select
            style={{ cursor: "pointer" }}
            name="cars"
            id="cars"
            value={SelectStageNameFilter}
            onChange={(e) => {
              setSelectStageNameFilter(e.target.value);
            }}
            className="ProjectStages___Stage__input"
          >
            <option value="" disabled selected>
              Select Stage Name
            </option>
            {stageListing.map((value) => (
              <option value={value._id}>{value.name}</option>
            ))}
            <option value="">All</option>
          </select>
          <MultiSelect
            placeholder={" Select Note Type"}
            options={notetypeMultiselect}
            value={SelectNotetypeFilter}
            defaultValue={SelectNotetypeFilter}
            handleChange={handleChangeNotetype}
          />

          <DateRangePicker
            style={{ cursor: "pointer" }}
            placement="bottomStart"
            format="dd-MM-yyyy"
            placeholder="Choose Date Range"
            ranges={[
              {
                label: "today",
                value: [endOfDay(new Date()), startOfDay(new Date())],
                closeOverlay: false,
              },
              {
                label: "Month",
                value: [
                  endOfDay(subMonths(new Date(), 1)),
                  startOfDay(new Date()),
                ],
                closeOverlay: false,
              },
              {
                label: "3 Months",
                value: [
                  endOfDay(subMonths(new Date(), 3)),
                  startOfDay(new Date()),
                ],
                closeOverlay: false,
              },
              {
                label: "6 Months",
                value: [
                  endOfDay(subMonths(new Date(), 6)),
                  startOfDay(new Date()),
                ],
                closeOverlay: false,
              },
              {
                label: "Year",
                value: [
                  endOfDay(subYears(new Date(), 1)),
                  startOfDay(new Date()),
                ],
                closeOverlay: false,
              },
            ]}
            onClean={() => {
              setmeetingDateRange([]);

              setmeetingStartDate("");
              setmeetingEndDate("");
            }}
            onOk={handlestartDateChange}
            className=" w-[400px]  focus:border-gray-400  border-gray-300 "
            cleanable={true}
            defaultValue={(() => meetingDateRange as [Date, Date])()}
          />
        </div>

        <button
          onClick={() => {
            setnoteOpen(true);
          }}
          className="ProjectStages__activateButton "
          style={{ width: "200px" }}
        >
          Create Note
        </button>
      </div>
      <>
        {" "}
        {Loader.pageloader ? (
          <div
            className="flex justify-center items-center  flex-col "
            style={{ height: "200px" }}
          >
            <CircularProgress color="inherit" size={25} />
          </div>
        ) : (
          <>
            {" "}
            {AllNotes.map((value) => (
              <div className="p-5 bg-[#FFF] mt-3" key={value?._id}>
                <div className="flex justify-between items-center ">
                  <p className="note__title">Note by {value?.note_by || "-"}</p>
                  <p className="ProjectStages__UpdatedBy">
                    Note type: {value?.note_type || "-"} •{" "}
                    {value?.createdAt
                      ? moment(value?.createdAt).format("h:mm A, D MMMM YYYY")
                      : "-"}
                  </p>
                </div>
                <p className="note__subtitle my-2">
                  {value?.description || "-"}
                </p>
                <div className=" flex justify-start items-center gap-3 mt-3">
                  {value.isPinned ? (
                    <PushPinIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        pinUnPinNote({ ID: value?._id, value: false });
                      }}
                    />
                  ) : (
                    <PushPinOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        pinUnPinNote({ ID: value?._id, value: true });
                      }}
                    />
                  )}
                  <button
                    className="edit_allocation"
                    onClick={() => {
                      setnoteupdateOpen(true);
                      setNoteIDEDit(value._id);
                      setNoteEditData(value);
                    }}
                  >
                    Edit Note
                  </button>

                  <button
                    className="edit_allocation "
                    style={{ color: "#FF0000" }}
                    onClick={() => {
                      deletNote(value._id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </>
        )}
      </>
    </div>
  );
};

export default Notes;
