import { allClientFilter } from "../../../Types";
import { RootState } from "./../../AllComponents/App/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: allClientFilter = {
  ClientID: "",
  clientPage: 1,
  clientPerPage: 10,
  clientTotalCount: 0,
  clientSearch: "",
  advFilter: {},
  advFilterConstruction: {},
  individualStageData: {},
  currentPageDocument: 1,
};

export const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClientID: (state, action: PayloadAction<string>) => {
      state.ClientID = action.payload;
    },
    setAdvFilter: (state, action: PayloadAction<{}>) => {
      state.advFilter = action.payload;
    },
    setadvFilterConstruction: (state, action: PayloadAction<{}>) => {
      state.advFilterConstruction = action.payload;
    },
    setClientPage: (state, action: PayloadAction<number>) => {
      state.clientPage = action.payload;
    },
    setClientPerPage: (state, action: PayloadAction<number>) => {
      state.clientPerPage = action.payload;
    },
    setClientTotalCount: (state, action: PayloadAction<number>) => {
      state.clientTotalCount = action.payload;
    },
    setClientSearch: (state, action: PayloadAction<string>) => {
      state.clientSearch = action.payload;
    },
    setindividualStageData: (state, action: PayloadAction<any>) => {
      state.individualStageData = action.payload;
    },
    setcurrentPageDocument: (state, action: PayloadAction<any>) => {
      state.currentPageDocument = action.payload;
    },
    clearClientState: (state) => {
      return initialState; // Reset state to initial state
    },
  },
});

export const {
  setClientID,
  setAdvFilter,
  setadvFilterConstruction,
  setClientPage,
  setClientPerPage,
  setClientTotalCount,
  setClientSearch,
  setindividualStageData,
  setcurrentPageDocument,
  clearClientState, // Export clearClientState action
} = clientSlice.actions;

export const selectClientID = (state: RootState) => state.clients.ClientID;
export const selectAdvFilter = (state: RootState) => state.clients.advFilter;
export const selectadvFilterConstruction = (state: RootState) =>
  state.clients.advFilterConstruction;

export const selectClientPage = (state: RootState) => state.clients.clientPage;
export const selectClientPerPage = (state: RootState) =>
  state.clients.clientPerPage;
export const selectClientTotalCount = (state: RootState) =>
  state.clients.clientTotalCount;
export const selectClientSearch = (state: RootState) =>
  state.clients.clientSearch;
export const selectindividualStageData = (state: RootState) =>
  state.clients.individualStageData;
export const selectcurrentPageDocument = (state: RootState) =>
  state.clients.currentPageDocument;

export default clientSlice.reducer;
