import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteIcon from "../../../Assets/svg/deletelogo.svg";
import axiosClientInterceptors from "../../../../ApiConfig/axiosClientInterceptor";
import { AxiosError, AxiosResponse } from "axios";
import {
  resType,
  status,
  statusData,
  statusFormik,
} from "../../../../../Types";
import { useFormik } from "formik";
import { Toaster } from "../../../../Tools/Toaster";
import Loader from "../../../../Tools/Loader";
import SmallLoader from "../../../../Tools/SmallLoader";
import { validateProjectStatus } from "../../../../Tools/Validation";

const ProjectStatus = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [addStatusLoader, setAddStatusLoader] = useState<boolean>(false);
  const [editStatusLoader, setEditStatusLoader] = useState<boolean>(false);
  const [delLoader, setDelLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openDel, setOpenDel] = useState<boolean>(false);
  const [projectStatus, SetProjectStatus] = useState<status[]>([]);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [sectionData, setSectionData] = useState<statusData>({
    section_name: "",
    _id: "",
  });
  const [sectionID, setSectionID] = useState<string>("");

  const addStatusFormik = useFormik<statusFormik>({
    initialValues: {
      projectStatus: "",
    },
    validationSchema: validateProjectStatus,
    onSubmit: (val) => {
      setAddStatusLoader(true);
      axiosClientInterceptors
        .post(`/config/general`, {
          section_heading: "Project Status",
          section_name: val.projectStatus,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getProjectStatus();
            setOpen(false);
            Toaster("success", data?.message);
            setAddStatusLoader(false);
            addStatusFormik.resetForm({});
          }
        })
        .catch((err) => {
          console.log(err, "err");
          Toaster("error", err?.response?.data?.message);
          setAddStatusLoader(false);
        });
    },
  });

  const editStatusFormik = useFormik<statusFormik>({
    enableReinitialize: true,
    initialValues: {
      projectStatus: sectionData.section_name,
    },
    validationSchema: validateProjectStatus,
    onSubmit: (val) => {
      setEditStatusLoader(true);
      axiosClientInterceptors
        .put(`/config/general/${sectionData._id}`, {
          section_name: val.projectStatus,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getProjectStatus();
            setOpenEdit(false);
            Toaster("success", data?.message);
            setEditStatusLoader(false);
            editStatusFormik.resetForm({});
          }
        })
        .catch((err) => {
          console.log(err, "err");
          Toaster("error", err?.response?.data?.message);
          setAddStatusLoader(false);
        });
    },
  });

  const deleteSection = () => {
    setDelLoader(true);
    axiosClientInterceptors
      .delete(`/config/general/${sectionID}`)
      .then((res: AxiosResponse) => {
        const data: resType = res.data;
        if (data?.success) {
          getProjectStatus();
          setOpenDel(false);
          Toaster("success", data?.message);
          setDelLoader(false);
        } else {
          Toaster("error", data?.message);
          setDelLoader(false);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
        setDelLoader(false);
      });
  };

  const getProjectStatus = () => {
    setLoader(true);
    axiosClientInterceptors
      .get(`/config/general/Project Status`)
      .then((res: AxiosResponse) => {
        SetProjectStatus(res.data?.result);
        setLoader(false);
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getProjectStatus();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center h-[68px] p-6">
        <p className="project_Heading">Project Status</p>
        <button
          className="addstatus_btn"
          onClick={() => {
            setOpen(true);
          }}
        >
          {" "}
          <AddIcon sx={{ fontSize: "18px", marginBottom: "2px" }} /> Add Project
          Status
        </button>
      </div>
      {loader ? (
        <Loader customHeight={"30vh"} />
      ) : (
        <>
          {projectStatus?.map((item, i) => (
            <>
              <div
                key={i}
                className="flex justify-between items-center p-6 h-[55px] border-t-[2px] border-t-[#F5F8FA]"
              >
                <p className="content_title">{item?.section_name}</p>
                <div className="flex gap-5">
                  <button
                    className="edit_stuts"
                    onClick={() => {
                      setSectionData(item);
                      setOpenEdit(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="delete_status"
                    onClick={() => {
                      setSectionID(item?._id);
                      setOpenDel(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </>
          ))}
        </>
      )}

      {/* Add project Status pop up starts */}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          addStatusFormik.resetForm({});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "386px",
              minHeight: "230px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-between">
            <p className="font-[Gm] font-semibold text-[18px] text-[#000000]">
              Add Project Status
            </p>
            <CloseRoundedIcon
              className="cursor-pointer"
              onClick={() => {
                setOpen(false);
                addStatusFormik.resetForm({});
              }}
            />
          </div>
          <form onSubmit={addStatusFormik.handleSubmit}>
            <div className="mt-4">
              <div>
                <label
                  htmlFor=""
                  className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                >
                  Project Status
                </label>
                <input
                  type="text"
                  name="projectStatus"
                  placeholder="Enter Project Status Name"
                  className="add_input mt-3 w-full"
                  onChange={addStatusFormik.handleChange}
                />
                {addStatusFormik.touched.projectStatus &&
                  addStatusFormik.errors.projectStatus && (
                    <p className="required_message">
                      {addStatusFormik.errors.projectStatus}
                    </p>
                  )}
              </div>
              <button type="submit" className="input_btn mt-4 w-full">
                {addStatusLoader ? <SmallLoader color={""} /> : "Add"}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      {/* Add project Status pop up ends */}

      {/* Edit project Status pop up starts */}
      <Dialog
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
          editStatusFormik.resetForm({});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "386px",
              minHeight: "230px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-between">
            <p className="font-[Gm] font-semibold text-[18px] text-[#000000]">
              Edit Status
            </p>
            <CloseRoundedIcon
              className="cursor-pointer"
              onClick={() => {
                setOpenEdit(false);
                editStatusFormik.resetForm({});
              }}
            />
          </div>
          <form onSubmit={editStatusFormik.handleSubmit}>
            <div className="mt-4">
              <div>
                <label
                  htmlFor=""
                  className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                >
                  Project Status
                </label>
                <input
                  type="text"
                  name="projectStatus"
                  placeholder="Enter Project Status Name"
                  className="add_input mt-3 w-full"
                  defaultValue={sectionData.section_name}
                  onChange={editStatusFormik.handleChange}
                />
                {editStatusFormik.touched.projectStatus &&
                  editStatusFormik.errors.projectStatus && (
                    <p className="required_message">
                      {editStatusFormik.errors.projectStatus}
                    </p>
                  )}
              </div>
              <button className="input_btn mt-4 w-full" type="submit">
                {editStatusLoader ? <SmallLoader color={""} /> : "Save"}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      {/* Edit project Status pop up ends */}

      {/* delete pop starts */}
      <Dialog
        open={openDel}
        onClose={() => {
          setOpenDel(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "404px",
              height: "265px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-center items-center">
            <img
              style={{
                width: "80px",
              }}
              src={DeleteIcon}
              alt=""
            />
          </div>
          <div className="deleteText mt-6">
            Are you sure want to Delete this Status
          </div>
          <div className="flex justify-between items-center mt-9">
            <button
              className="cancelDelUser"
              onClick={() => {
                setOpenDel(false);
              }}
            >
              Cancel
            </button>
            <button
              className="delUser"
              onClick={() => {
                deleteSection();
              }}
            >
              {delLoader ? <SmallLoader color={""} /> : "Delete"}
            </button>
          </div>
        </div>
      </Dialog>
      {/* delete pop ends */}
    </div>
  );
};

export default ProjectStatus;
