import { overviewProps, resType } from "../../../../../../Types";
import React, { useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import axiosClientInterceptors from "../../../../../ApiConfig/axiosClientInterceptor";
import { AxiosResponse } from "axios";
import SmallLoader from "../../../../../Tools/SmallLoader";
import { Toaster } from "../../../../../Tools/Toaster";

const ApprovalStatus = ({ clientsData, getUserDetails }: overviewProps) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [sLoader, setSLoader] = useState<boolean>(false);
  const [gatedApproval, setGatedApproval] = useState<string>("");
  const [electricApproval, setElectricApproval] = useState<string>("");
  const [govtApproval, setGovtpproval] = useState<string>("");
  const [bankApproval, setBankApproval] = useState<string>("");

  const updateApprovalStatus = () => {
    setSLoader(true);
    axiosClientInterceptors
      .put(`/clients/${clientsData?._id}`, {
        gated_community_approval:
          gatedApproval || clientsData?.gated_community_approval,
        electric_meter_approval:
          electricApproval || clientsData?.electric_meter_approval,
        govt_approval: govtApproval || clientsData?.govt_approval,
        bank_approval: bankApproval || clientsData?.bank_approval,
      })
      .then((res: AxiosResponse) => {
        const data: resType = res.data;
        if (data.success) {
          getUserDetails();
          setToggle(false);
          setSLoader(false);
          Toaster("success", data?.message);
        } else {
          Toaster("error", data?.message);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
        setSLoader(false);
      });
  };

  return (
    <div className="mt-5">
      <div className="bg-white border-solid border-[1px]">
        {!toggle ? (
          <>
            <div className="flex justify-between items-center p-4 border-b-[1px]">
              <p className="font-[Gm] font-medium text-[20px]">
                Approval Status
              </p>
              <button
                className="edit_allocation"
                onClick={() => {
                  setToggle(true);
                }}
              >
                Edit
              </button>
            </div>
            <div className="p-4 flex justify-between items-center">
              <div>
                <p className="allo_heading">Gated Community Approval</p>
                <p className="allo_content">
                  {clientsData?.gated_community_approval || "-"}
                </p>
              </div>
              <div>
                <p className="allo_heading">Electric Meter Approval</p>
                <p className="allo_content">
                  {clientsData?.electric_meter_approval || "-"}
                </p>
              </div>
              <div>
                <p className="allo_heading">Government Approval</p>
                <p className="allo_content">
                  {clientsData?.govt_approval || "-"}
                </p>
              </div>
              <div>
                <p className="allo_heading">Bank Approval</p>
                <p className="allo_content">
                  {clientsData?.bank_approval || "-"}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between items-center p-4 border-b-[1px]">
              <p className="font-[Gm] font-medium text-[20px]">
                Approval Status
              </p>
              <div className="flex gap-3">
                <button
                  className="allo_discard"
                  onClick={() => {
                    setToggle(false);
                    setElectricApproval("");
                    setGatedApproval("");
                    setGovtpproval("");
                    setBankApproval("");
                  }}
                >
                  Discard
                </button>
                <button
                  className="allo_save"
                  onClick={() => {
                    updateApprovalStatus();
                  }}
                >
                  {sLoader ? <SmallLoader color={""} /> : "Save"}
                </button>
              </div>
            </div>
            <div className="p-4 flex justify-between items-center">
              <div>
                <p className="allo_heading mb-3">Gated Community Approval</p>
                <Select
                  name="customerCoordinator"
                  onValueChange={(val) => {
                    setGatedApproval(val);
                  }}
                  defaultValue={clientsData?.gated_community_approval}
                >
                  <SelectTrigger className="w-[155px]">
                    <SelectValue
                      placeholder={`${
                        clientsData?.gated_community_approval || "select"
                      }`}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="In Progress">In Progress</SelectItem>
                      <SelectItem value="Pending">Pending</SelectItem>
                      <SelectItem value="Done">Done</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <p className="allo_heading mb-3">Electric Meter Approval</p>
                <Select
                  name="customerCoordinator"
                  onValueChange={(val) => {
                    setElectricApproval(val);
                  }}
                  defaultValue={clientsData?.electric_meter_approval}
                >
                  <SelectTrigger className="w-[155px]">
                    <SelectValue
                      placeholder={`${
                        clientsData?.electric_meter_approval || "select"
                      }`}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="In Progress">In Progress</SelectItem>
                      <SelectItem value="Pending">Pending</SelectItem>
                      <SelectItem value="Done">Done</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <p className="allo_heading mb-3">Government Approvall</p>
                <Select
                  name="customerCoordinator"
                  onValueChange={(val) => {
                    setGovtpproval(val);
                  }}
                  defaultValue={clientsData?.govt_approval}
                >
                  <SelectTrigger className="w-[155px]">
                    <SelectValue
                      placeholder={`${clientsData?.govt_approval || "select"}`}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="In Progress">In Progress</SelectItem>
                      <SelectItem value="Pending">Pending</SelectItem>
                      <SelectItem value="Done">Done</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <p className="allo_heading mb-3">Bank Approval</p>
                <Select
                  name="customerCoordinator"
                  onValueChange={(val) => {
                    setBankApproval(val);
                  }}
                  defaultValue={clientsData?.bank_approval}
                >
                  <SelectTrigger className="w-[155px]">
                    <SelectValue
                      placeholder={`${clientsData?.bank_approval || "select"}`}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="In Progress">In Progress</SelectItem>
                      <SelectItem value="Pending">Pending</SelectItem>
                      <SelectItem value="Done">Done</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApprovalStatus;
