import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

const SERVER_URL: string | undefined = process.env.REACT_APP_BASE_URL;

const timeout: number = 10000; // milliseconds

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  metadata?: {
    controller: AbortController;
  };
}

const axiosClientInterceptors: AxiosInstance = axios.create({
  baseURL: SERVER_URL,
  // timeout: timeout,
});

const requestHandler = (
  request: ExtendedAxiosRequestConfig
): ExtendedAxiosRequestConfig => {
  const controller = new AbortController();
  request.signal = controller.signal;
  // console.log(request, "request");
  if (localStorage.getItem("token")) {
    request.headers = {
      ...request.headers,
      authorization: localStorage.getItem("token"),
    };
  }

  // Add the controller to the request's metadata for potential later use
  request.metadata = { controller };

  return request;
};

const responseHandler = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const errorHandler = async (error: any) => {
  const status = error.response ? error.response.status : null;
  const refreshToken = localStorage.getItem("token");

  if (refreshToken === null || status === 401) {
    window.location.href = "/";
  }

  return Promise.reject(error);
};

axiosClientInterceptors.interceptors.request.use(
  (request) => requestHandler(request) as InternalAxiosRequestConfig<any>,
  (error) => errorHandler(error)
);

axiosClientInterceptors.interceptors.response.use(
  (response: AxiosResponse) => responseHandler(response),
  (error) => errorHandler(error)
);

// Export the configured axios instance
export default axiosClientInterceptors;
