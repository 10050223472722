import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import PreConstructionCard from "./PreConstructionCard";
import axiosClientInterceptors from "../../../../ApiConfig/axiosClientInterceptor";
import { AxiosResponse } from "axios";
import {
  option,
  projectStageFormik,
  resType,
  stages,
  userType,
} from "../../../../../Types";
import Dialog from "@mui/material/Dialog";
import { useFormik } from "formik";
import MultiSelect from "../../../../Tools/MultiSelect";
import { Toaster } from "../../../../Tools/Toaster";
import Loader from "../../../../Tools/Loader";
import SmallLoader from "../../../../Tools/SmallLoader";
import { validateProjectStage } from "../../../../Tools/Validation";

const PreConstruction = () => {
  // const classes = useStyles();
  const [stagesData, setStagesData] = useState<stages[]>([]);
  const [toggle, setToggle] = useState<boolean>(false);
  const [openStage, setOpenStage] = useState<boolean>(false);
  const [userType, setUserType] = useState<userType[]>([]);
  const [user, setUser] = useState<option[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [addStageLoader, setAddStageLoader] = useState<boolean>(false);
  const [editLoader, setEditLoader] = useState<Boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const addProjectStatus = useFormik<projectStageFormik>({
    initialValues: {
      stage_name: "",
      display_payment: false,
      display_design: false,
    },
    validationSchema: validateProjectStage,
    onSubmit: (val) => {
      if (user?.length > 0) {
        setAddStageLoader(true);
        axiosClientInterceptors
          .post(`/config/stages`, {
            stage_name: val.stage_name,
            assigned_to: user?.map((x) => x.value) || [],
            display_payment: val.display_payment,
            display_design: val.display_design,
          })
          .then((res: AxiosResponse) => {
            const data: resType = res.data;
            if (data.success) {
              getProjectStages();
              setOpenStage(false);
              setUser([]);
              addProjectStatus.resetForm({});
              Toaster("success", data?.message);
              setAddStageLoader(false);
            }
          })
          .catch((err) => {
            console.log(err);
            Toaster("error", err.response?.data?.message);
            setAddStageLoader(false);
          });
      }
    },
  });

  const validateStage = () => {
    if (user?.length === 0) {
      setErrorMsg("Stage Assigned to is required");
    } else {
      setErrorMsg("");
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    console.log(stagesData, "stagesData");
    console.log(event, "event");
    // console.log("drag end!");
    if (over && active.id !== over.id) {
      const activeIndex = stagesData.findIndex((item) => item.id === active.id);
      const overIndex = stagesData.findIndex((item) => item.id === over.id);
      setStagesData((prev) => arrayMove<stages>(prev, activeIndex, overIndex));
    }
  }

  const getUserType = () => {
    axiosClientInterceptors
      .get(`/users/type`)
      .then((res: AxiosResponse) => {
        setUserType(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getProjectStages = () => {
    setLoader(true);
    axiosClientInterceptors
      .get(`/config/stages`)
      .then((res: AxiosResponse) => {
        let data = res.data?.result?.map((val: stages) => {
          val.id = val._id;
          return val;
        });
        setStagesData(data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const roleOptions = (userType ?? [])?.map((x) => {
    return {
      value: x.role || "",
      label: x.role || "",
    };
  });

  const handleChangeUser = (value: any) => {
    setUser(value);
    setErrorMsg("");
  };

  const updateSequence = () => {
    setEditLoader(true);
    axiosClientInterceptors
      .put(`/config/stages`, {
        sequence: stagesData?.map((x) => {
          return {
            _id: x._id,
          };
        }),
      })
      .then((res: AxiosResponse) => {
        const data: resType = res.data;
        if (data.success) {
          getProjectStages();
          setToggle(false);
          setEditLoader(false);
          Toaster("success", data?.message);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
        setEditLoader(false);
      });
  };

  useEffect(() => {
    getUserType();
    getProjectStages();
  }, []);

  return (
    <>
      <div className="p-6">
        <div className="flex justify-between items-center h-[30px]">
          <p className="project_Heading">Pre Construction</p>
          {!toggle ? (
            <div className="flex gap-4">
              <button
                className="Carouselsequence"
                onClick={() => {
                  setToggle(true);
                }}
              >
                Edit Sqeuence
              </button>
              <button
                className="addstatus_btn"
                onClick={() => {
                  setOpenStage(true);
                }}
              >
                {" "}
                <AddIcon sx={{ fontSize: "18px", marginBottom: "2px" }} />{" "}
                Stages
              </button>
            </div>
          ) : (
            <button
              className="dnd_save"
              onClick={() => {
                updateSequence();
              }}
            >
              {editLoader ? <SmallLoader color="" /> : "Save"}
            </button>
          )}
        </div>
      </div>

      <div className="min-h-[300px] overflow-y-auto overflow-x-hidden">
        <div className="p-6 flex w-full items-center gap-16 dnd_top bg-white c_text">
          <div className="text-start w-1/5 font-[Gm] font-normal">SL.No</div>
          <div className="text-start w-3/5 font-[Gm] font-normal">
            Project Stage
          </div>
          <div className="text-start w-1/5 font-[Gm] font-normal">
            Design Files
          </div>
          <div className="text-start w-1/5 font-[Gm] font-normal">
            View Payments
          </div>
          <div className="flex text-start w-1/5">Action</div>
        </div>
        {loader ? (
          <Loader customHeight="" />
        ) : (
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={stagesData}>
              {stagesData?.map((item, i) => (
                <PreConstructionCard
                  key={item?.id}
                  item={item}
                  index={i}
                  toggle={toggle}
                  roleOptions={roleOptions}
                  user={user}
                  getProjectStages={getProjectStages}
                />
              ))}
            </SortableContext>
          </DndContext>
        )}
      </div>

      {/* Add Stage pop up starts */}
      <Dialog
        open={openStage}
        onClose={() => {
          setOpenStage(false);
          addProjectStatus.resetForm({});
          setErrorMsg("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "386px",
              minHeight: "262px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-between items-center">
            <p className="font-[Gm] font-semibold text-[18px] text-[#000000]">
              Add Stage
            </p>
            <CloseRoundedIcon
              className="cursor-pointer"
              onClick={() => {
                addProjectStatus.resetForm({});
                setOpenStage(false);
                setErrorMsg("");
              }}
            />
          </div>
          <form onSubmit={addProjectStatus.handleSubmit}>
            <div className="mt-4">
              <div className="stageTop py-3 px-4">
                <div>
                  <label
                    htmlFor=""
                    className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                  >
                    Stage Name
                  </label>
                  <input
                    name="stage_name"
                    type="text"
                    placeholder="Enter Stage Name"
                    className="add_input mt-3 w-full"
                    onChange={addProjectStatus.handleChange}
                  />{" "}
                  {addProjectStatus.touched.stage_name &&
                    addProjectStatus.errors.stage_name && (
                      <p className="required_message">
                        {addProjectStatus.errors.stage_name}
                      </p>
                    )}
                </div>

                <div className="mt-3">
                  <p className="font-[Gm] font-medium text-[13px] text-[#221E1F]">
                    Features
                  </p>
                  <div className="flex gap-5 items-center mt-3 checkbox-wrapper">
                    <span>Payment</span>
                    <input
                      name="display_payment"
                      type="checkbox"
                      id="check1"
                      onChange={addProjectStatus.handleChange}
                    />
                    <label htmlFor="check1" />
                    <span>Design Files</span>
                    <input
                      name="display_design"
                      type="checkbox"
                      id="check2"
                      onChange={addProjectStatus.handleChange}
                    />
                    <label htmlFor="check2" />
                  </div>
                </div>
              </div>
              <div className="stageTop py-3 px-4 mt-3">
                <label
                  htmlFor=""
                  className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                >
                  Stage Assigned To
                </label>
                <div className="mt-3">
                  <MultiSelect
                    placeholder={"Choose Role"}
                    options={roleOptions}
                    value={user}
                    defaultValue={user}
                    handleChange={handleChangeUser}
                  />
                  <p>
                    {errorMsg && <p className="required_message">{errorMsg}</p>}
                  </p>
                </div>
              </div>
              <button
                type="submit"
                className="addstage_btn"
                onClick={() => {
                  validateStage();
                }}
              >
                {addStageLoader ? <SmallLoader color={""} /> : "Add"}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      {/* Add Stage pop up ends */}
    </>
  );
};

export default PreConstruction;
