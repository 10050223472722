import React from "react";
import Allocation from "./Allocation";
import { overviewProps } from "../../../../../../Types";
import SalesInfo from "./SalesInfo";
import ProjectInfo from "./ProjectInfo";
import ApprovalStatus from "./ApprovalStatus";
import Document from "./Document";
import FinalQuotation from "./FinalQuotation";
import ProgressBar from "./ProgressBar";

const Overview = ({ clientsData, getUserDetails }: overviewProps) => {
  return (
    <>
      <Allocation clientsData={clientsData} getUserDetails={getUserDetails} />
      <ProgressBar clientsData={clientsData} getUserDetails={getUserDetails} />
      <SalesInfo clientsData={clientsData} getUserDetails={getUserDetails} />
      <FinalQuotation
        clientsData={clientsData}
        getUserDetails={getUserDetails}
      />
      <ProjectInfo clientsData={clientsData} getUserDetails={getUserDetails} />
      <ApprovalStatus
        clientsData={clientsData}
        getUserDetails={getUserDetails}
      />

      {/* <Document clientsData={clientsData} getUserDetails={getUserDetails} /> */}
    </>
  );
};

export default Overview;
