import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";
import General from "./General/General";
import Activity from "./Activity/Activity";
import DesignFile from "./DesignFile/DesignFile";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
});

const AllClients = () => {
  const themeNested = createTheme({
    overrides: {
      MuiTab: {
        root: {
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Gm",
          color: "#344054 !important",
          opacity: "9 !important",
          "&.MuiTab-textColorInherit.Mui-selected": {
            backgroundColor: "#F5F8FA",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Gm",
            color: "#1D2939",
          },
        },
      },
    },
  });

  const classes = useStyles();
  const [value, setValue] = useState("1");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          //  typography: "body1"
        }}
      >
        <ThemeProvider theme={themeNested}>
          <TabContext value={value}>
            <Box sx={{ width: "100%", borderBottom: " 1.px solid #D8D8D8" }}>
              <div>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{
                    style: {
                      background: "#F6BA00",
                      height: "2px",
                      borderRadius: "3px",
                      // width: "10px",
                    },
                  }}
                  className="tab_border"
                >
                  <Tab
                    className="tab_nav tab_color"
                    label="General"
                    value="1"
                  />
                  <Tab
                    className="tab_nav tab_color"
                    label="Activity"
                    value="2"
                  />
                  <Tab
                    className="tab_nav tab_color"
                    label="Design File Categories"
                    value="3"
                  />
                </TabList>
              </div>
            </Box>
            {/* <div className="tab_border"></div> */}
            <TabPanel value="1" style={{ padding: "20px", minHeight: "50vh" }}>
              <General />
            </TabPanel>
            <TabPanel value="2" style={{ padding: "20px", minHeight: "50vh" }}>
              <Activity />
            </TabPanel>
            <TabPanel value="3" style={{ padding: "20px", minHeight: "50vh" }}>
              <DesignFile />
            </TabPanel>
          </TabContext>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default AllClients;
