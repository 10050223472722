import React, { useEffect, useState } from "react";
import { Button, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Fade from "@mui/material/Fade";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dropzone from "react-dropzone";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axiosClientInterceptors from "../../../../../../ApiConfig/axiosClientInterceptor";
import { selectClientID } from "../../../../../../Features/AllClients/AllClients";
import { useSelector } from "react-redux";
import {
  DesignLoader,
  designgetobj,
  meetingIDobj,
  addITTparm,
} from "../../../../../../../Types";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import LensIcon from "@mui/icons-material/Lens";
import { saveAs } from "file-saver";
import { Toaster } from "../../../../../../Tools/Toaster";
import nodata from "../../../../../Assets/nodata.jpg";

const fileNameSplit = (str: any) => {
  if (str) {
    return str?.split("/").at(-1).split("-").slice(1).join("-");
  }
};

const MUIstayle: any = {
  borderRadius: "2px",
  background: "linear-gradient(90deg, #FFD661 0%, #FABE2A 103.54%)",
  boxShadow: "none",
  textTransform: "none",
  padding: "10px",
  color: " #000",
  fontFamily: "gsb",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "24px" /* 171.429% */,
};
const Design = ({ siglestageData }: any) => {
  const clientID = useSelector(selectClientID);
  const [uploadFileOpen, setuploadFileOpen] = useState<boolean>(false);
  const [loader, setloader] = useState<DesignLoader>({
    createDesign: false,
    docsLoader: false,
  });
  const [AllDesign, setAllDesign] = useState<designgetobj[]>([]);
  const [meetingID, setMeetingID] = useState<meetingIDobj[]>([]);
  const [Bluckfile, setBluckfile] = useState<File[]>([]);
  const [editData, seteditData] = useState<any>(null);
  const [interatalID, setinteratalID] = useState<any>(null);
  const [Filetypelist, setFiletypelist] = useState<any>([]);
  const [fileState, setfileState] = useState<string>("");
  const [Viewpopup, setViewpopup] = useState<boolean>(false);

  // accepting file from dropzone and storing it to state
  const handleDrop = (acceptedFiles: any) => {
    setBluckfile((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };
  const deletFile = (i: any) => {
    let filesone = [...Bluckfile];
    filesone.splice(i, 1);
    setBluckfile(filesone);
  };

  const AddCreateOption = () => {
    setloader({ createDesign: true, docsLoader: false });
    axiosClientInterceptors
      .post(`/clients/stages/designfiles`, {
        iterations: [
          {
            status: "Pending",
          },
        ],
        client_id: clientID,
        stage_id: siglestageData._id,
      })
      .then((response) => {
        setloader({ createDesign: false, docsLoader: false });
        getAllDesign();
        // console.log("AddCreateOption ==>", response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllDesign = () => {
    axiosClientInterceptors
      .get(
        `/clients/stages/designfiles?client_id=${clientID}&stage_id=${siglestageData._id}`
      )
      .then((response) => {
        const result = response.data.result;
        console.log("responsegetAllDesign===>", result);
        setAllDesign(result);
      })
      .catch((err) => {
        console.log("err=>>>>>>", err);
      });
  };

  const getMettingID = () => {
    axiosClientInterceptors
      .get(`/clients/stages/designfiles/meeting-ids?client_id=${clientID}`)
      .then((res) => {
        const result = res.data.result;
        console.log("result", result);
        setMeetingID(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // upload Doc
  const UploadDocs = () => {
    if (fileState !== "" && Bluckfile.length > 0) {
      setloader({
        createDesign: false,
        docsLoader: true,
      });
      const formdata = new FormData();
      Bluckfile.map((value) => formdata.append("docs", value));
      formdata.append("design_id", editData?._id);
      formdata.append("iteration_id", interatalID._id);
      formdata.append("client_id", clientID);
      formdata.append("stage_id", siglestageData._id);
      formdata.append("doc_type", fileState);

      const headers = {
        "content-type": "multipart/form-data",
      };
      axiosClientInterceptors
        .patch(`/clients/stages/designfiles`, formdata, {
          headers,
        })

        .then((res) => {
          setloader({
            createDesign: false,
            docsLoader: false,
          });
          console.log(res);
          setuploadFileOpen(false);
          setBluckfile([]);
          getAllDesign();
        })
        .catch((err) => {
          setloader({
            createDesign: false,
            docsLoader: false,
          });
          console.log(err);
        });
    } else {
      alert("select file type");
    }
  };
  const getDoc = () => {
    axiosClientInterceptors
      .get(`/general/config?heading=Pre-Construction File Category`)
      .then((res) => {
        // setProjectStatus(res.data?.result);
        setFiletypelist(res.data.result);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  const deletDocz = ({ design_id, itr_id, doc_id, file }: any) => {
    axiosClientInterceptors
      .delete(
        `/clients/stages/designfiles/?design_id=${design_id}&itr_id=${itr_id}&doc_id=${doc_id}&file=${file}`
      )
      .then((res) => {
        console.log(res);
        Toaster("success", res.data.message);
        getAllDesign();
        setViewpopup(false);
      })
      .catch((err) => {
        Toaster("success", err.response.data.message);

        console.log(err);
      });
  };

  useEffect(() => {
    getDoc();
    getMettingID();
    getAllDesign();
  }, []);
  return (
    <div>
      <Dialog
        open={uploadFileOpen}
        onClose={() => {
          setuploadFileOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Upload Document
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setuploadFileOpen(false);
              }}
            />
          </div>
          <select
            name="DOCTYPE"
            id="DOCTYPE"
            className="ProjectStages___Stage__input w-full mt-2"
            value={fileState}
            onChange={(e) => setfileState(e.target.value)}
          >
            <option value="" selected>
              Select
            </option>
            {Filetypelist?.map((val: any) => (
              <option value={val._id}>{val.section_name}</option>
            ))}
          </select>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <>
                {Bluckfile?.length > 0 ? (
                  <div className="listingfile" style={{ overflowX: "scroll" }}>
                    <ul style={{ padding: "10px" }}>
                      {Bluckfile?.map((value, i: any) => (
                        <div className="flex items-center">
                          <LensIcon
                            className="me-1"
                            style={{
                              color: " #000000",
                              width: "10px",
                            }}
                          />
                          <li className="fileName">
                            {value?.name ? value?.name : value?.name}
                          </li>
                          <CloseIcon
                            fontSize="small"
                            className="ms-1"
                            onClick={() => {
                              // e.stopPropagation();
                              console.log(value);

                              deletFile(i);
                            }}
                            style={{ color: " #000000" }}
                          />
                        </div>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <div>
                      <div className="mb-3 flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="71"
                          height="51"
                          viewBox="0 0 71 51"
                          fill="none"
                        >
                          <path
                            d="M4.49219 5.3924C4.49219 2.41426 6.90645 0 9.88459 0H20.4238C21.1783 0 21.9293 0.158376 22.6154 0.472263C29.6513 3.69111 31.3036 5.38607 38.6491 6.66482C38.9417 6.71576 39.2427 6.74051 39.5397 6.74051H60.2137C63.1918 6.74051 65.6061 9.15477 65.6061 12.1329V27.8608C65.6061 30.8389 63.1919 33.2532 60.2137 33.2532H9.8846C6.90646 33.2532 4.49219 30.8389 4.49219 27.8608V5.3924Z"
                            fill="#FCC200"
                          />
                          <path
                            d="M0.732739 24.0042C0.346006 21.297 2.44663 18.875 5.18125 18.875H65.9635C68.6474 18.875 70.7329 21.2124 70.4281 23.8789L67.8603 46.3473C67.6009 48.6169 65.6801 50.3307 63.3957 50.3307H8.39101C6.15473 50.3307 4.25876 48.6863 3.9425 46.4725L0.732739 24.0042Z"
                            fill="url(#paint0_linear_1010_81020)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_1010_81020"
                              x1="35.5"
                              y1="11.0111"
                              x2="41.6543"
                              y2="54.5155"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#FFE185" />
                              <stop offset="1" stop-color="#F7BA00" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <input name="fileNames" {...getInputProps()} />
                      <p className="vendor_bulk_upload_text">
                        Drag & Drop Your File here <br /> or
                      </p>
                    </div>
                    <div>
                      <button className="browse_bulk_vendor">
                        Browse Files
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Dropzone>

          {loader.docsLoader ? (
            <div className="w-full flex justify-center items-center">
              <CircularProgress color="inherit" size={20} />
            </div>
          ) : (
            <button
              className="ProjectStages__activateButton mt-1"
              onClick={() => {
                UploadDocs();
              }}
            >
              Upload Document
            </button>
          )}
        </div>
      </Dialog>
      {/* view All documents */}
      <Dialog
        open={Viewpopup}
        onClose={() => {
          setViewpopup(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center mb-4">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Uploaded Documents
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setViewpopup(false);
              }}
            />
          </div>
          {interatalID?.docs?.map((valuedoc: any) => (
            <div className="flex justify-start items-center gap-2 mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M15.9837 4.027C14.0519 2.09517 10.906 2.09517 8.97619 4.027L3.62365 9.37544C3.58879 9.4103 3.57033 9.45747 3.57033 9.50669C3.57033 9.55591 3.58879 9.60308 3.62365 9.63794L4.38039 10.3947C4.41498 10.4291 4.4618 10.4485 4.51061 10.4485C4.55943 10.4485 4.60625 10.4291 4.64084 10.3947L9.99338 5.04624C10.6578 4.38179 11.5417 4.01675 12.481 4.01675C13.4202 4.01675 14.3041 4.38179 14.9665 5.04624C15.631 5.71069 15.996 6.59458 15.996 7.53179C15.996 8.47105 15.631 9.35288 14.9665 10.0173L9.51145 15.4704L8.62756 16.3542C7.80109 17.1807 6.45783 17.1807 5.63137 16.3542C5.23146 15.9543 5.01203 15.4232 5.01203 14.8572C5.01203 14.2912 5.23146 13.76 5.63137 13.3601L11.0434 7.95015C11.1808 7.8148 11.3612 7.73892 11.554 7.73892H11.5561C11.7488 7.73892 11.9273 7.8148 12.0626 7.95015C12.2 8.08755 12.2738 8.26802 12.2738 8.46079C12.2738 8.65151 12.198 8.83198 12.0626 8.96733L7.63908 13.3868C7.60422 13.4216 7.58576 13.4688 7.58576 13.518C7.58576 13.5672 7.60422 13.6144 7.63908 13.6493L8.39582 14.406C8.43041 14.4404 8.47723 14.4598 8.52604 14.4598C8.57486 14.4598 8.62168 14.4404 8.65627 14.406L13.0778 9.98452C13.4859 9.57642 13.7094 9.03501 13.7094 8.45874C13.7094 7.88247 13.4838 7.33901 13.0778 6.93296C12.2349 6.09009 10.865 6.09214 10.0221 6.93296L9.49709 7.46001L4.61213 12.3429C4.28058 12.6725 4.01777 13.0647 3.83893 13.4966C3.6601 13.9286 3.5688 14.3917 3.57033 14.8592C3.57033 15.8087 3.94152 16.7008 4.61213 17.3714C5.30734 18.0646 6.21789 18.4112 7.12844 18.4112C8.03898 18.4112 8.94953 18.0646 9.64269 17.3714L15.9837 11.0345C16.9168 10.0994 17.4336 8.85454 17.4336 7.53179C17.4357 6.20698 16.9189 4.96216 15.9837 4.027Z"
                  fill="url(#paint0_linear_1010_81762)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1010_81762"
                    x1="4.16567"
                    y1="1.41703"
                    x2="19.9401"
                    y2="2.78853"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#626262" />
                    <stop offset="1" stop-color="#151414" />
                  </linearGradient>
                </defs>
              </svg>
              <p className="StageAccordionDetails__Agreement">
                {fileNameSplit(valuedoc.file)}
              </p>
              <p
                className="m-0 StageAccordionDetails__AgreementV cursor-pointer"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_IMAGE_URL}/${valuedoc?.file}`
                  );
                }}
              >
                View
              </p>
              <p
                className="m-0 StageAccordionDetails__AgreementV  cursor-pointer"
                onClick={() => {
                  saveAs(
                    `${process.env.REACT_APP_IMAGE_URL}/${valuedoc?.file}`,
                    `${fileNameSplit(valuedoc?.file)}`
                  );
                }}
              >
                Download
              </p>
              <p
                className="m-0 StageAccordionDetails__AgreementV  "
                style={{ color: "#F05F5F", cursor: "pointer" }}
                onClick={() => {
                  // DeletDocz(valuedoc);
                  console.log(interatalID);

                  deletDocz({
                    design_id: editData?._id,
                    itr_id: interatalID._id,
                    doc_id: valuedoc._id,
                    file: valuedoc?.file,
                  });
                }}
              >
                Delete
              </p>
            </div>
          ))}

          <button
            className="ProjectStages__activateButton mt-3"
            onClick={() => {
              interatalID?.docs.map((value: any) => {
                saveAs(
                  `${process.env.REACT_APP_IMAGE_URL}/${value.file}`,
                  `${fileNameSplit(value.file)}`
                );
              });
            }}
          >
            Download All
          </button>
        </div>
      </Dialog>
      <div className=" flex justify-end items-center">
        {loader.createDesign ? (
          <div className="w-full flex justify-end items-center">
            <CircularProgress color="inherit" size={20} />
          </div>
        ) : (
          <Button
            style={MUIstayle}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              AddCreateOption();
            }}
          >
            Create Option
          </Button>
        )}
      </div>
      {AllDesign.length > 0 ? (
        <>
          {" "}
          {AllDesign?.map((value: designgetobj) => (
            <div className="mt-4" key={value._id}>
              <AccordionTransition
                value={value}
                setuploadFileOpen={setuploadFileOpen}
                AllDesign={AllDesign}
                meetingID={meetingID}
                getAllDesign={getAllDesign}
                editData={editData}
                seteditData={seteditData}
                interatalID={interatalID}
                setinteratalID={setinteratalID}
                getDoc={getDoc}
                setViewpopup={setViewpopup}
                deletDocz={deletDocz}
              />
            </div>
          ))}
        </>
      ) : (
        <div className="flex justify-center items-center mt-28">
          {/* <h3>No Data Found</h3> */}
          <img className="h-[250px] w-[220px]" src={nodata} alt="no-data" />
        </div>
      )}
    </div>
  );
};

export default Design;

// ==================================================================================================================================================================

const validationSchema = yup.object({
  meetingID: yup.string().required("meetingID is required"),
  Initiatedon: yup.string().required("Initiatedon is required"),
  EstimatedDueDate: yup
    .date()
    .required("EstimatedDueDate is required")
    .test(
      "is-future-date",
      "EstimatedDueDate should be a future date relative to Initiatedon",
      function (value) {
        const { Initiatedon } = this.parent;
        return moment(value).isAfter(moment(Initiatedon));
      }
    ),
  Status: yup.string().required("Status is required"),
  Description: yup.string().required("Description is required"),
});

type propsData = {
  value: designgetobj;
  setuploadFileOpen?: (value: boolean) => void;
  AllDesign?: designgetobj[];
  meetingID?: meetingIDobj[];
  getAllDesign?: any;
  editData: any;
  seteditData: any;
  interatalID: any;
  setinteratalID: any;
  getDoc: any;
  setViewpopup?: (value: boolean) => void;
  deletDocz: any;
};
interface FormValues {
  meetingID: string;
  Initiatedon: string;
  EstimatedDueDate: string;
  Status: string;
  Description: string;
}

function AccordionTransition({
  value,
  setuploadFileOpen,
  AllDesign,
  meetingID,
  getAllDesign,
  editData,
  seteditData,
  interatalID,
  setinteratalID,
  setViewpopup,
  getDoc,
  deletDocz,
}: propsData) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const addITT = ({ ID }: addITTparm) => {
    axiosClientInterceptors
      .put(`/clients/stages/designfiles`, {
        design_id: ID,
      })
      .then((res) => {
        console.log(res);
        getAllDesign();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="mt-3">
        <Accordion
          expanded={expanded}
          onChange={handleExpansion}
          slots={{ transition: Fade as AccordionSlots["transition"] }}
          slotProps={{ transition: { timeout: 400 } }}
          sx={{
            "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
            "& .MuiAccordionDetails-root": {
              display: expanded ? "block" : "none",
            },
            // marginTop: 2,
          }}
        >
          <AccordionSummary
            style={{ width: "100%" }}
            //   expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${value._id}-content`}
            id={`panel${value._id}-content`}
          >
            <div className="flex justify-between items-center w-full px-3">
              <p className="ProjectStages__Card__Title">{value?.name}</p>

              <p className="ProjectStages__UpdatedBy">
                Activity Date:{" "}
                {value?.updatedAt
                  ? moment(value?.updatedAt).format("hh:mm A DD, MMMM YYYY")
                  : "-"}
              </p>
              <p
                className="ProjectStages__UpdatedBy"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Total Iterations: {value?.iterations?.length}
              </p>
              <div className="flex justify-center items-start">
                {" "}
                <ExpandMoreIcon />{" "}
                {!expanded ? (
                  <p className="ProjectStages__More">View More</p>
                ) : (
                  <p className="ProjectStages__More">View Less</p>
                )}
              </div>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            {value?.iterations?.map((val, index) => (
              <NestedAcco
                val={val}
                value={value}
                index={index}
                interatalID={interatalID}
                editData={editData}
                setinteratalID={setinteratalID}
                getAllDesign={getAllDesign}
                meetingID={meetingID}
                seteditData={seteditData}
                setuploadFileOpen={setuploadFileOpen}
                getDoc={getDoc}
                setViewpopup={setViewpopup}
                deletDocz={deletDocz}
              />
            ))}

            <div className="my-10  relative">
              <Divider />
              <Button
                onClick={() => {
                  if (value?.iterations?.at(-1)?.status === "Completed") {
                    addITT({ ID: value?._id });
                  } else {
                    Toaster("info", "In-Complete Iteration");
                  }
                }}
                className=" absolute"
                style={{
                  ...MUIstayle,
                  color: "#FF26A8",
                  position: "absolute",
                  right: "50%",
                  top: "-22px",
                  background: "#FFF",
                  border: "1.333px solid var(--Gray-300, #D0D5DD)",
                }}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add New Iteration
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
type props = {
  val: any;
  index: number;
  interatalID: any;
  editData: any;
  setinteratalID: any;
  getAllDesign: any;
  meetingID: any;
  seteditData: any;
  value: any;
  setuploadFileOpen: any;
  getDoc: any;
  setViewpopup: any;
  deletDocz: any;
};

function NestedAcco({
  val,
  index,
  interatalID,
  editData,
  setinteratalID,
  getAllDesign,
  meetingID,
  seteditData,
  value,
  setuploadFileOpen,
  getDoc,
  setViewpopup,
  deletDocz,
}: props) {
  const [expandedNested, setexpandedNested] = React.useState(false);
  const [EditView, setEditView] = useState<any>("");

  const handleExpansionNested = () => {
    setexpandedNested((prevExpanded) => !prevExpanded);
  };

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      meetingID: interatalID?.meeting_ID || "",
      Initiatedon: interatalID?.initiated_on
        ? moment(interatalID?.initiated_on).format("YYYY-MM-DD")
        : "",
      EstimatedDueDate: interatalID?.est_due_date
        ? moment(interatalID?.est_due_date).format("YYYY-MM-DD")
        : "",
      Status: interatalID?.status || "",
      Description: interatalID?.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      axiosClientInterceptors
        .put(`/clients/stages/designfiles`, {
          design_id: editData?._id,
          iteration_id: interatalID?._id,
          meeting_id: values.meetingID,
          initiated_on: values.Initiatedon,
          est_due_date: values.EstimatedDueDate,
          status: values.Status,
          description: values.Description,
        })
        .then((res) => {
          console.log(res);
          formik.resetForm({});
          // Toaster("success" ,"Upad")
          setEditView("");
          getAllDesign();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  return (
    <>
      <Accordion
        key={val._id}
        expanded={expandedNested}
        onChange={handleExpansionNested}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          boxShadow: "none",
          marginTop: "10px",
          background: "#F5F8FA",
          "& .MuiAccordion-region": {
            height: expandedNested ? "auto" : 0,
          },
          "& .MuiAccordionDetails-root": {
            display: expandedNested ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          style={{ width: "100%" }}
          //   expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${val._id}-content`}
          id={`panel${val._id}-content`}
        >
          <div className="flex justify-between items-center w-full px-3">
            <p className="ProjectStages__Card__Title">Iteration {index}</p>

            <p className="ProjectStages__UpdatedBy">
              Activity Date:{" "}
              {val?.activity_date
                ? moment(val?.activity_date).format("hh:mm A DD, MMMM YYYY")
                : "-"}
            </p>
            {/* <p className="ProjectStages__UpdatedBy">
                      Total Iterations: "2"
                    </p> */}
            <div className="flex justify-center items-start">
              {" "}
              <ExpandMoreIcon />{" "}
              {!expandedNested ? (
                <p className="ProjectStages__More">View More</p>
              ) : (
                <p className="ProjectStages__More">View Less</p>
              )}
            </div>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex justify-between items-center">
              {" "}
              {val._id === EditView ? (
                <div>
                  {" "}
                  <select
                    value={formik.values.meetingID}
                    onChange={formik.handleChange}
                    name="meetingID"
                    id="meetingID"
                    className="ProjectStages___Stage__input w-40 mt-2"
                  >
                    <option value="" selected>
                      Enter MeetingID
                    </option>
                    {meetingID?.map((val: any) => (
                      <option value={val.meeting_ID}>{val.meeting_ID}</option>
                    ))}
                  </select>
                  {formik.touched.meetingID && formik.errors.meetingID && (
                    <p className="required_message">
                      {formik.errors.meetingID}
                    </p>
                  )}
                </div>
              ) : (
                <p className="Stage__SubFormTittle">{val?.meeting_ID || "-"}</p>
              )}
              <div className="flex justify-start items-center gap-3 mt-3">
                {val._id === EditView ? (
                  <button
                    type="submit"
                    style={{ color: "#1364C2" }}
                    // onClick={() => {
                    //   setEditView(false);
                    // }}
                    className=" ProjectStages__Activate"
                  >
                    Save
                  </button>
                ) : (
                  <div
                    style={{ color: "#1364C2", cursor: "pointer" }}
                    onClick={() => {
                      setEditView(val._id);
                      seteditData(value);
                      setinteratalID(val);
                    }}
                    className=" ProjectStages__Activate"
                  >
                    Edit
                  </div>
                )}
                <div
                  style={{ color: "#000000", cursor: "pointer" }}
                  className=" ProjectStages__Activate"
                  onClick={() => {
                    getDoc();
                    seteditData(value);
                    setinteratalID(val);
                    setuploadFileOpen && setuploadFileOpen(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 16V7.85L8.4 10.45L7 9L12 4L17 9L15.6 10.45L13 7.85V16H11ZM4 20V15H6V18H18V15H20V20H4Z"
                      fill="#6A6A6A"
                    />
                  </svg>
                </div>
                <button
                  style={{ color: "#000000" }}
                  className=" ProjectStages__Activate"
                >
                  Send Mail
                </button>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-4 mt-4">
              <div>
                <p className="StageAccordionDetails__title">Initiated on</p>
                {val._id === EditView ? (
                  <>
                    <input
                      type="date"
                      name="Initiatedon"
                      id="Initiatedon"
                      value={formik.values.Initiatedon}
                      onChange={formik.handleChange}
                      className="ProjectStages___Stage__input w-full mt-2"
                    />
                    {formik.touched.Initiatedon &&
                      formik.errors.Initiatedon && (
                        <p className="required_message">
                          {formik.errors.Initiatedon}
                        </p>
                      )}
                  </>
                ) : (
                  <p className="Stage__SubFormTittle">
                    {val?.initiated_on
                      ? moment(val?.initiated_on).format("DD/MM/YYYY")
                      : "-"}
                  </p>
                )}
              </div>
              <div>
                <p className="StageAccordionDetails__title">
                  Estimated Due Date
                </p>
                {val._id === EditView ? (
                  <>
                    <input
                      type="date"
                      name="EstimatedDueDate"
                      id="EstimatedDueDate"
                      value={formik.values.EstimatedDueDate}
                      onChange={formik.handleChange}
                      className="ProjectStages___Stage__input w-full mt-2"
                    />
                    {formik.touched.EstimatedDueDate &&
                      formik.errors.EstimatedDueDate && (
                        <p className="required_message">
                          {formik.errors.EstimatedDueDate}
                        </p>
                      )}
                  </>
                ) : (
                  <p className="Stage__SubFormTittle">
                    {val.est_due_date
                      ? moment(val.est_due_date).format("DD/MM/YYYY")
                      : "-"}
                  </p>
                )}
              </div>
              <div>
                <p className="StageAccordionDetails__title">Status</p>

                {val._id === EditView ? (
                  <>
                    {" "}
                    <select
                      name="Status"
                      id="Status"
                      value={formik.values.Status}
                      onChange={formik.handleChange}
                      className="ProjectStages___Stage__input w-full mt-2"
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option value="Pending">Pending</option>
                      <option value="Completed">Completed</option>
                    </select>
                    {formik.touched.Status && formik.errors.Status && (
                      <p className="required_message">{formik.errors.Status}</p>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    {val.status === "Pending" ? (
                      <p
                        className="Stage__SubFormTittle"
                        style={{ color: "#C21313" }}
                      >
                        {val.status || "-"}
                      </p>
                    ) : (
                      <p
                        className="Stage__SubFormTittle"
                        style={{ color: "#38B000" }}
                      >
                        {val.status || "-"}
                      </p>
                    )}
                  </>
                )}
              </div>
              <div>
                <p className="StageAccordionDetails__title">End Date & Time:</p>
                <p className="Stage__SubFormTittle">{val.end_date || "-"}</p>
                {/* <input
                        type="date"
                        name=""
                        id=""
                        className="ProjectStages___Stage__input w-full mt-2"
                      /> */}
              </div>
            </div>
            <div>
              <p className="StageAccordionDetails__title mt-4">Description</p>

              {val._id === EditView ? (
                <>
                  <input
                    type="text"
                    name="Description"
                    id="Description"
                    value={formik.values.Description}
                    onChange={formik.handleChange}
                    placeholder="Enter Description"
                    className="ProjectStages___Stage__input w-full mt-2"
                  />
                  {formik.touched.Description && formik.errors.Description && (
                    <p className="required_message">
                      {formik.errors.Description}
                    </p>
                  )}
                </>
              ) : (
                <p className="Stage__SubFormTittle">{val.description || "-"}</p>
              )}
            </div>
            <div className=" flex justify-start mt-4  gap-4 flex-wrap w-full">
              {val?.docs?.slice(0, 3)?.map((valuedoc: any) => (
                <div className="mt-2 flex  justify-start items-center gap-6 ">
                  <div className="flex justify-start items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        d="M15.9837 4.027C14.0519 2.09517 10.906 2.09517 8.97619 4.027L3.62365 9.37544C3.58879 9.4103 3.57033 9.45747 3.57033 9.50669C3.57033 9.55591 3.58879 9.60308 3.62365 9.63794L4.38039 10.3947C4.41498 10.4291 4.4618 10.4485 4.51061 10.4485C4.55943 10.4485 4.60625 10.4291 4.64084 10.3947L9.99338 5.04624C10.6578 4.38179 11.5417 4.01675 12.481 4.01675C13.4202 4.01675 14.3041 4.38179 14.9665 5.04624C15.631 5.71069 15.996 6.59458 15.996 7.53179C15.996 8.47105 15.631 9.35288 14.9665 10.0173L9.51145 15.4704L8.62756 16.3542C7.80109 17.1807 6.45783 17.1807 5.63137 16.3542C5.23146 15.9543 5.01203 15.4232 5.01203 14.8572C5.01203 14.2912 5.23146 13.76 5.63137 13.3601L11.0434 7.95015C11.1808 7.8148 11.3612 7.73892 11.554 7.73892H11.5561C11.7488 7.73892 11.9273 7.8148 12.0626 7.95015C12.2 8.08755 12.2738 8.26802 12.2738 8.46079C12.2738 8.65151 12.198 8.83198 12.0626 8.96733L7.63908 13.3868C7.60422 13.4216 7.58576 13.4688 7.58576 13.518C7.58576 13.5672 7.60422 13.6144 7.63908 13.6493L8.39582 14.406C8.43041 14.4404 8.47723 14.4598 8.52604 14.4598C8.57486 14.4598 8.62168 14.4404 8.65627 14.406L13.0778 9.98452C13.4859 9.57642 13.7094 9.03501 13.7094 8.45874C13.7094 7.88247 13.4838 7.33901 13.0778 6.93296C12.2349 6.09009 10.865 6.09214 10.0221 6.93296L9.49709 7.46001L4.61213 12.3429C4.28058 12.6725 4.01777 13.0647 3.83893 13.4966C3.6601 13.9286 3.5688 14.3917 3.57033 14.8592C3.57033 15.8087 3.94152 16.7008 4.61213 17.3714C5.30734 18.0646 6.21789 18.4112 7.12844 18.4112C8.03898 18.4112 8.94953 18.0646 9.64269 17.3714L15.9837 11.0345C16.9168 10.0994 17.4336 8.85454 17.4336 7.53179C17.4357 6.20698 16.9189 4.96216 15.9837 4.027Z"
                        fill="url(#paint0_linear_1010_81762)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1010_81762"
                          x1="4.16567"
                          y1="1.41703"
                          x2="19.9401"
                          y2="2.78853"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#626262" />
                          <stop offset="1" stop-color="#151414" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <p
                      className="StageAccordionDetails__Agreement"
                      onClick={() => {
                        console.log(val?.docs);
                      }}
                    >
                      {fileNameSplit(valuedoc.file)}
                    </p>
                    <p
                      className="m-0 StageAccordionDetails__AgreementV cursor-pointer"
                      onClick={() => {
                        console.log("valuedoc", valuedoc);

                        window.open(
                          `${process.env.REACT_APP_IMAGE_URL}/${valuedoc.file}`
                        );
                      }}
                    >
                      View
                    </p>
                    <a
                      className="m-0 StageAccordionDetails__AgreementV cursor-pointer"
                      onClick={() => {
                        saveAs(
                          `${process.env.REACT_APP_IMAGE_URL}/${valuedoc.file}`,
                          `${fileNameSplit(valuedoc.file)}`
                        );
                      }}
                    >
                      Download
                    </a>

                    <p
                      onClick={() => {
                        // DeletDocz({ doc: valuedoc, ID: value?._id });
                        deletDocz({
                          design_id: value?._id,
                          itr_id: val._id,
                          doc_id: valuedoc._id,
                          file: valuedoc?.file,
                        });
                      }}
                      className="m-0 StageAccordionDetails__AgreementV  cursor-pointer"
                      style={{ color: "#F05F5F" }}
                    >
                      Delete
                    </p>
                  </div>
                </div>
              ))}

              {val?.docs?.length > 3 ? (
                <svg
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setViewpopup && setViewpopup(true);
                    seteditData(value);
                    setinteratalID(val);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="25"
                  viewBox="0 0 36 25"
                  fill="none"
                >
                  <path
                    d="M2.23438 5.3924C2.23438 2.41426 4.64864 0 7.62678 0H9.5719C10.3264 0 11.0743 0.154811 11.757 0.47617C14.7574 1.88865 15.6998 2.67382 18.9803 3.27128C19.2725 3.3245 19.5706 3.34811 19.8676 3.34811H27.1982C30.1763 3.34811 32.5906 5.76237 32.5906 8.74051V11.1249C32.5906 14.1031 30.1763 16.5173 27.1982 16.5173H7.62678C4.64863 16.5173 2.23438 14.1031 2.23438 11.1249V5.3924Z"
                    fill="#FCC200"
                  />
                  <path
                    d="M0.732739 14.5042C0.346005 11.797 2.44663 9.375 5.18125 9.375H30.2303C32.9142 9.375 34.9996 11.7124 34.6949 14.3789L33.9363 21.0161C33.677 23.2857 31.7561 24.9995 29.4717 24.9995H6.12941C3.89314 24.9995 1.99716 23.3551 1.68091 21.1413L0.732739 14.5042Z"
                    fill="url(#paint0_linear_1010_81784)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1010_81784"
                      x1="17.6334"
                      y1="5.46887"
                      x2="20.6903"
                      y2="27.0782"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFE185" />
                      <stop offset="1" stop-color="#F7BA00" />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
            </div>
          </form>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
