import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteIcon from "../../../Assets/svg/deletelogo.svg";
import axiosClientInterceptors from "../../../../ApiConfig/axiosClientInterceptor";
import { AxiosResponse } from "axios";
import {
  meeting,
  meetingData,
  meetingFormik,
  resType,
} from "../../../../../Types";
import { useFormik } from "formik";
import { Toaster } from "../../../../Tools/Toaster";
import Loader from "../../../../Tools/Loader";
import SmallLoader from "../../../../Tools/SmallLoader";
import { validateActivityStatus } from "../../../../Tools/Validation";

const MeetingType = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openDel, setOpenDel] = useState<boolean>(false);
  const [meeting, setMeeting] = useState<meeting[]>([]);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [meetingData, setMeetingData] = useState<meetingData>({
    section_name: "",
    _id: "",
  });
  const [meetingID, setMeetingID] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [addLoader, setAddLoader] = useState<boolean>(false);
  const [editLoader, setEditLoader] = useState<boolean>(false);
  const [delLoader, setDelLoader] = useState<boolean>(false);

  const addMeetingFormik = useFormik<meetingFormik>({
    initialValues: {
      projectStatus: "",
    },
    validationSchema: validateActivityStatus,
    onSubmit: (val) => {
      setAddLoader(true);
      axiosClientInterceptors
        .post(`/config/general`, {
          section_heading: "Meeting Type",
          section_name: val.projectStatus,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getMeetingType();
            setOpen(false);
            addMeetingFormik.resetForm({});
            Toaster("success", data?.message);
            setAddLoader(false);
            setOpen(false);
          } else {
            Toaster("error", data?.message);
            setAddLoader(false);
          }
        })
        .catch((err: AxiosResponse) => {
          console.log(err);
          setAddLoader(false);
        });
    },
  });

  const editMeetingFormik = useFormik<meetingFormik>({
    enableReinitialize: true,
    initialValues: {
      projectStatus: meetingData.section_name,
    },
    validationSchema: validateActivityStatus,
    onSubmit: (val) => {
      setEditLoader(true);
      axiosClientInterceptors
        .put(`/config/general/${meetingData._id}`, {
          section_name: val.projectStatus,
        })
        .then((res: AxiosResponse) => {
          const data: resType = res.data;
          if (data?.success) {
            getMeetingType();
            setOpenEdit(false);
            Toaster("success", data?.message);
            setEditLoader(false);
            editMeetingFormik.resetForm({});
          } else {
            Toaster("error", data?.message);
            setEditLoader(false);
          }
        })
        .catch((err: AxiosResponse) => {
          console.log(err);
          setEditLoader(false);
        });
    },
  });

  const deleteSection = () => {
    setDelLoader(true);
    axiosClientInterceptors
      .delete(`/config/general/${meetingID}`)
      .then((res: AxiosResponse) => {
        const data: resType = res.data;
        if (data.success) {
          getMeetingType();
          setOpenDel(false);
          Toaster("success", data?.message);
          setDelLoader(false);
        } else {
          Toaster("error", data?.message);
          setDelLoader(false);
        }
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
        setDelLoader(false);
      });
  };

  const getMeetingType = () => {
    setLoader(true);
    axiosClientInterceptors
      .get(`/config/general/Meeting Type`)
      .then((res: AxiosResponse) => {
        setMeeting(res.data?.result);
        setLoader(false);
      })
      .catch((err: AxiosResponse) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getMeetingType();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center h-[68px] p-6">
        <p className="project_Heading">Meeting Type</p>
        <button
          className="addstatus_btn min-w-[150px]"
          onClick={() => {
            setOpen(true);
          }}
        >
          {" "}
          <AddIcon sx={{ fontSize: "18px", marginBottom: "2px" }} /> Add Meeting
          Type
        </button>
      </div>
      {loader ? (
        <Loader customHeight={"30vh"} />
      ) : (
        <>
          {meeting?.map((item, i) => (
            <>
              <div className="flex justify-between items-center p-6 h-[55px] border-t-[2px] border-t-[#F5F8FA]">
                <p className="content_title">{item?.section_name}</p>
                <div className="flex gap-5">
                  <button
                    className="edit_stuts"
                    onClick={() => {
                      setMeetingData(item);
                      setOpenEdit(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="delete_status"
                    onClick={() => {
                      setMeetingID(item?._id);
                      setOpenDel(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </>
          ))}
        </>
      )}

      {/* Add Meeting type pop starts */}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          addMeetingFormik.resetForm({});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "386px",
              minHeight: "230px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-between">
            <p className="font-[Gm] font-semibold text-[18px] text-[#000000]">
              Add New Meeting
            </p>
            <CloseRoundedIcon
              className="cursor-pointer"
              onClick={() => {
                setOpen(false);
                addMeetingFormik.resetForm({});
              }}
            />
          </div>
          <form onSubmit={addMeetingFormik.handleSubmit}>
            <div className="mt-4">
              <div>
                <label
                  htmlFor=""
                  className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                >
                  Meeting Name
                </label>
                <input
                  name="projectStatus"
                  type="text"
                  placeholder="Enter Meeting Name"
                  className="add_input mt-3 w-full"
                  onChange={addMeetingFormik.handleChange}
                />
                {addMeetingFormik.touched.projectStatus &&
                  addMeetingFormik.errors.projectStatus && (
                    <p className="required_message">
                      {addMeetingFormik.errors.projectStatus}
                    </p>
                  )}
              </div>
              <button
                disabled={addLoader}
                type="submit"
                className="input_btn mt-4 w-full"
              >
                {addLoader ? <SmallLoader color="" /> : "Add"}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      {/* Add Meeting type pop ends */}

      {/* Edit Meeting type pop starts */}
      <Dialog
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
          editMeetingFormik.resetForm({});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "386px",
              height: "230px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-between">
            <p className="font-[Gm] font-semibold text-[18px] text-[#000000]">
              Edit Meeting Type
            </p>
            <CloseRoundedIcon
              className="cursor-pointer"
              onClick={() => {
                setOpenEdit(false);
                editMeetingFormik.resetForm({});
              }}
            />
          </div>
          <form onSubmit={editMeetingFormik.handleSubmit}>
            <div className="mt-4">
              <div>
                <label
                  htmlFor=""
                  className="font-[Gm] font-medium text-[13px] text-[#221E1F]"
                >
                  Meeting Name
                </label>
                <input
                  name="projectStatus"
                  type="text"
                  placeholder="Enter Meeting Name"
                  className="add_input mt-3 w-full"
                  defaultValue={meetingData.section_name}
                  onChange={editMeetingFormik.handleChange}
                />
                {editMeetingFormik.touched.projectStatus &&
                  editMeetingFormik.errors.projectStatus && (
                    <p className="required_message">
                      {editMeetingFormik.errors.projectStatus}
                    </p>
                  )}
              </div>
              <button
                disabled={editLoader}
                type="submit"
                className="input_btn mt-4 w-full"
              >
                {editLoader ? <SmallLoader color="" /> : "Save"}
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      {/* Edit Meeting type pop ends */}

      {/* delete pop starts */}
      <Dialog
        open={openDel}
        onClose={() => {
          setOpenDel(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "404px",
              height: "265px",
            },
          },
        }}
      >
        <div className="p-5">
          <div className="flex justify-center items-center">
            <img
              style={{
                width: "80px",
              }}
              src={DeleteIcon}
              alt=""
            />
          </div>
          <div className="deleteText mt-6">
            Are you sure want to Delete this Status?
          </div>
          <div className="flex justify-between items-center mt-9">
            <button
              className="cancelDelUser"
              onClick={() => {
                setOpenDel(false);
              }}
            >
              Cancel
            </button>
            <button
              className="delUser"
              onClick={() => {
                deleteSection();
              }}
            >
              {delLoader ? <SmallLoader color="" /> : "Delete"}
            </button>
          </div>
        </div>
      </Dialog>
      {/* delete pop ends */}
    </div>
  );
};

export default MeetingType;
