import React, { useState } from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Dialog, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";

const ConstructionDrawings = () => {
  const [expanded, setExpanded] = useState(false);
  const [EditView, setEditView] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <div>
      {" "}
      <div className="mt-3">
        <Accordion
          expanded={expanded}
          onChange={handleExpansion}
          slots={{ transition: Fade as AccordionSlots["transition"] }}
          slotProps={{ transition: { timeout: 400 } }}
          sx={{
            "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
            "& .MuiAccordionDetails-root": {
              display: expanded ? "block" : "none",
            },
          }}
        >
          <AccordionSummary
            style={{ width: "100%" }}
            //   expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <div className="flex justify-between items-center w-full px-3">
              <p className="ProjectStages__Card__Title">Moodboards</p>

              <p className="ProjectStages__UpdatedBy">
                Activity Date: 03:41 PM 23, April 2023
              </p>
              <p className="ProjectStages__UpdatedBy">Total Files : 4</p>
              <div className="flex justify-center items-start">
                {" "}
                <ExpandMoreIcon />{" "}
                {!expanded ? (
                  <p className="ProjectStages__More">View More</p>
                ) : (
                  <p className="ProjectStages__More">View Less</p>
                )}
              </div>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <div className="p-4">
              <div
                className="flex justify-between items-center p-3 bg-[#F5F8FA]"
                style={{ border: "1px solid #DBDBDB" }}
              >
                <div className="flex justify-start items-center">
                  <TurnedInNotIcon />
                  <p className="ProjectStages__Card__Title">Filename1.dwg</p>
                </div>
                <p className="ProjectStages__UpdatedBy">
                  Upload Date: 03:41 PM 23, April 2023
                </p>
                <div className="flex gap-2 items-center">
                  <button
                    className="ProjectStages__Activate"
                    style={{ color: "#344054" }}
                  >
                    View
                  </button>
                  <button
                    style={{ color: "#3E68FE" }}
                    className="ProjectStages__Activate"
                  >
                    Download
                  </button>
                </div>
              </div>
              <div
                className="flex justify-between items-center p-3 bg-[#F5F8FA]"
                style={{ border: "1px solid #DBDBDB" }}
              >
                <div className="flex justify-start items-center">
                  <TurnedInNotIcon />
                  <p className="ProjectStages__Card__Title">Filename1.dwg</p>
                </div>
                <p className="ProjectStages__UpdatedBy">
                  Upload Date: 03:41 PM 23, April 2023
                </p>
                <div className="flex gap-2 items-center">
                  <button
                    className="ProjectStages__Activate"
                    style={{ color: "#344054" }}
                  >
                    View
                  </button>
                  <button
                    style={{ color: "#3E68FE" }}
                    className="ProjectStages__Activate"
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default ConstructionDrawings;
