import React, { useEffect, useState } from "react";
import { Button, Dialog } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RsuitePagination from "../../../../../../Tools/RsuitePagination";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import CloseIcon from "@mui/icons-material/Close";
import Dropzone from "react-dropzone";
import axiosClientInterceptors from "../../../../../../ApiConfig/axiosClientInterceptor";
import {
  selectClientID,
  selectcurrentPageDocument,
  setcurrentPageDocument,
} from "../../../../../../Features/AllClients/AllClients";
import { useSelector } from "react-redux";
import {
  Documentobj,
  getFileTypeobj,
  loaderObjTAsk,
  PinUnPinparam,
} from "../../../../../../../Types";
import { saveAs } from "file-saver";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { PaginationProps } from "antd";
import { AppDispatch } from "@/AllComponents/App/store";
import { useDispatch } from "react-redux";
import moment from "moment";
import LensIcon from "@mui/icons-material/Lens";
import CircularProgress from "@mui/material/CircularProgress";
import { Toaster } from "../../../../../../Tools/Toaster";
import nodata from "../../../../../Assets/nodata.jpg";

const MUIstayle: any = {
  borderRadius: "2px",
  background: "linear-gradient(90deg, #FFD661 0%, #FABE2A 103.54%)",
  boxShadow: "none",
  textTransform: "none",
  padding: "10px",
  color: " #000",
  fontFamily: "gsb",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "24px" /* 171.429% */,
};
const fileNameSplit = (str: any) => {
  if (str) {
    return str.split("/").at(-1).split("-").slice(1).join("-");
  }
};
const Documents = ({ siglestageData }: any) => {
  const clientID = useSelector(selectClientID);
  const currentPage = useSelector(selectcurrentPageDocument);
  const [perPage, setperPage] = useState<any>(10);
  const [TotalCount, setTotalCount] = useState<any>(0);
  const dispatch = useDispatch<AppDispatch>();
  const [uploadFileOpen, setuploadFileOpen] = useState<boolean>(false);
  const [AllDocument, setAllDocument] = useState<Documentobj[]>([]);
  const [search, setsearch] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<string>("");
  const [FileType, setFileType] = useState<string>("");
  const [FiletypeListing, setFiletypeListing] = useState<getFileTypeobj[]>([]);
  const [display, setdisplay] = useState<string>("");
  const [Bluckfile, setBluckfile] = useState<File[]>([]);
  const [Loader, setloader] = useState<loaderObjTAsk>({
    docsLoader: false,
  });
  const [fileState, setfileState] = useState<string>("");
  const [finlefile, setfinlefile] = useState<boolean>(false);
  const [pinunpinID, setpinunpinID] = useState<any>("");

  const handleChangePage: PaginationProps["onChange"] = (page, perPage) => {
    dispatch(setcurrentPageDocument(page));
    setperPage(perPage);
  };
  // accepting file from dropzone and storing it to state
  const handleDrop = (acceptedFiles: any) => {
    setBluckfile((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };
  const deletFile = (i: any) => {
    let filesone = [...Bluckfile];
    filesone.splice(i, 1);
    setBluckfile(filesone);
  };

  const getAllDocument = () => {
    axiosClientInterceptors
      .post(
        `/clients/stages/documents/get-documents?page=${currentPage}&limit=${perPage}&search=${search}`,
        {
          client_id: clientID,
          stage_id: siglestageData._id,
          display: display,
          date:
            dateFilter !== "" ? moment(dateFilter).format("YYYY-MM-DD") : "",
          file_type: FileType,
        }
      )
      .then((response) => {
        const result = response.data.result.data;
        console.log("pagination==>>>>>k", response.data);
        setAllDocument(result);
        setTotalCount(response.data.result.totalCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFileType = () => {
    axiosClientInterceptors
      .get(`/general/config?heading=Other File Category`)
      .then((response) => {
        const result = response.data.result;
        console.log("Other File Category==========================>", result);
        setFiletypeListing(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // upload Doc
  const UploadDocs = () => {
    if (Bluckfile.length === 0) {
      Toaster("info", "select file ");
    } else {
      setloader({
        docsLoader: true,
      });
      const formdata = new FormData();
      Bluckfile.map((value) => formdata.append("docs", value));

      formdata.append("client_id", clientID);
      formdata.append("stage_id", siglestageData._id);
      formdata.append("file_type", fileState);
      formdata.append("section_name", "Pre-Construction");

      const headers = {
        "content-type": "multipart/form-data",
      };
      axiosClientInterceptors
        .post(`/clients/stages/documents`, formdata, {
          headers,
        })

        .then((res) => {
          setloader({
            docsLoader: false,
          });
          console.log(res);
          Toaster("success", res.data.message);

          setuploadFileOpen(false);
          setBluckfile([]);
          setfileState("");
          getAllDocument();
        })
        .catch((err) => {
          // message: "File type is Required!";
          Toaster("info", err.response.data.message);

          setloader({
            docsLoader: false,
          });
          console.log(err);
        });
      // alert("select file type");
    }
  };
  const PinUnPin = () => {
    axiosClientInterceptors
      .patch(`/clients/stages/documents/?doc_id=${pinunpinID} `)
      .then((res) => {
        console.log(res);
        getAllDocument();
        Toaster("success", res.data.message);
      })
      .catch((err) => {
        Toaster("info", err.response.data.message);
        console.log(err);
      });
  };

  useEffect(() => {
    getFileType();
  }, []);
  useEffect(() => {
    getAllDocument();
  }, [currentPage, search, display, dateFilter, FileType, perPage]);
  return (
    <div className="mt-4">
      {/* DropZone */}
      <Dialog
        open={uploadFileOpen}
        onClose={() => {
          setuploadFileOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle">
              Upload Document
            </p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setuploadFileOpen(false);
              }}
            />
          </div>
          <select
            name="DOCTYPE"
            id="DOCTYPE"
            className="ProjectStages___Stage__input w-full mt-2"
            value={fileState}
            onChange={(e) => setfileState(e.target.value)}
          >
            <option value="" selected>
              Select
            </option>
            {FiletypeListing?.map((val: any) => (
              <option value={val._id}>{val.section_name}</option>
            ))}
          </select>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <>
                {Bluckfile?.length > 0 ? (
                  <div className="listingfile" style={{ overflowX: "scroll" }}>
                    <ul style={{ padding: "10px" }}>
                      {Bluckfile?.map((value, i: any) => (
                        <div className="flex items-center">
                          <LensIcon
                            className="me-1"
                            style={{
                              color: " #000000",
                              width: "10px",
                            }}
                          />
                          <li className="fileName">
                            {value?.name ? value?.name : value?.name}
                          </li>
                          <CloseIcon
                            fontSize="small"
                            className="ms-1"
                            onClick={() => {
                              // e.stopPropagation();
                              console.log(value);

                              deletFile(i);
                            }}
                            style={{ color: " #000000" }}
                          />
                        </div>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <div>
                      <div className="mb-3 flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="71"
                          height="51"
                          viewBox="0 0 71 51"
                          fill="none"
                        >
                          <path
                            d="M4.49219 5.3924C4.49219 2.41426 6.90645 0 9.88459 0H20.4238C21.1783 0 21.9293 0.158376 22.6154 0.472263C29.6513 3.69111 31.3036 5.38607 38.6491 6.66482C38.9417 6.71576 39.2427 6.74051 39.5397 6.74051H60.2137C63.1918 6.74051 65.6061 9.15477 65.6061 12.1329V27.8608C65.6061 30.8389 63.1919 33.2532 60.2137 33.2532H9.8846C6.90646 33.2532 4.49219 30.8389 4.49219 27.8608V5.3924Z"
                            fill="#FCC200"
                          />
                          <path
                            d="M0.732739 24.0042C0.346006 21.297 2.44663 18.875 5.18125 18.875H65.9635C68.6474 18.875 70.7329 21.2124 70.4281 23.8789L67.8603 46.3473C67.6009 48.6169 65.6801 50.3307 63.3957 50.3307H8.39101C6.15473 50.3307 4.25876 48.6863 3.9425 46.4725L0.732739 24.0042Z"
                            fill="url(#paint0_linear_1010_81020)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_1010_81020"
                              x1="35.5"
                              y1="11.0111"
                              x2="41.6543"
                              y2="54.5155"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#FFE185" />
                              <stop offset="1" stop-color="#F7BA00" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <input name="fileNames" {...getInputProps()} />
                      <p className="vendor_bulk_upload_text">
                        Drag & Drop Your File here <br /> or
                      </p>
                    </div>
                    <div>
                      <button className="browse_bulk_vendor">
                        Browse Files
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Dropzone>

          {Loader?.docsLoader ? (
            <div className="w-full flex justify-center items-center">
              <CircularProgress color="inherit" size={20} />
            </div>
          ) : (
            <button
              className="ProjectStages__activateButton mt-1"
              onClick={() => {
                UploadDocs();
              }}
            >
              Upload Document
            </button>
          )}
        </div>
      </Dialog>
      {/* are you sure pin */}
      <Dialog
        open={finlefile}
        onClose={() => {
          setfinlefile(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 30,
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "446px",
              //   height: "246px",
            },
          },
        }}
      >
        <div style={{ borderTop: "7px solid  #FCC200" }} className="p-4">
          <div className="flex justify-between items-center">
            <p className="ProjectStagesPopFill__Stage__Tittle"></p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setfinlefile(false);
              }}
            />
          </div>
          <h3 className=" flex justify-center items-center my-8">
            Are you Sure !
          </h3>
          <div className="flex justify-between items-center gap-3">
            <button
              style={{ padding: "10px" }}
              className="w-full bg-red-600 text-white"
              onClick={() => {
                setfinlefile(false);
              }}
            >
              Cancle
            </button>{" "}
            <button
              className="w-full bg-green-500 text-white"
              style={{ padding: "10px" }}
              onClick={() => {
                setfinlefile(false);
                PinUnPin();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Dialog>
      <div className="flex justify-between items-center ">
        <div className="flex justify-start items-center gap-6">
          <div className="group">
            <svg
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon"
              style={{ left: "1rem" }}
            >
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              style={{ width: "400px" }}
              value={search}
              onChange={(e) => setsearch(e.target.value)}
              className="input  "
              type="search"
              placeholder="Search Document "
            />
          </div>
          <select
            name="cars"
            id="cars"
            value={display}
            onChange={(e) => {
              setdisplay(e.target.value);
            }}
            style={{ width: "200px" }}
            className="ProjectStages___Stage__input "
          >
            <option value="" selected disabled>
              Select
            </option>
            <option value="all">All</option>
            <option value="final">Final</option>
          </select>
          <select
            value={FileType}
            onChange={(e) => setFileType(e.target.value)}
            name="cars"
            id="cars"
            className="ProjectStages___Stage__input "
            style={{ width: "200px" }}
          >
            <option value="" selected disabled>
              Select File Type
            </option>
            {FiletypeListing?.map((value) => (
              <option value={value._id}>{value.section_name}</option>
            ))}
            <option value="all">All</option>
          </select>
          <input
            value={dateFilter}
            onChange={(e) => {
              setDateFilter(e.target.value);
            }}
            type="date"
            style={{ width: "200px" }}
            className="ProjectStages___Stage__input"
          />
        </div>

        <Button
          onClick={() => {
            setuploadFileOpen(true);
          }}
          style={MUIstayle}
          variant="contained"
          startIcon={<AddIcon />}
        >
          Upload Files
        </Button>
      </div>
      <div className="allTable bg-white mt-[15px]">
        {AllDocument.length > 0 ? (
          <table className="w-full">
            <thead className="h-[50px] sticky top-0 z-10 bg-[#111111] font-[Gr] text-[14px] text-[#FFFFFF] font-medium">
              <tr className="h-[50px] top-0 z-10">
                <th className="text-start px-6 whitespace-nowrap w-[69px]">
                  SL.No
                </th>
                <th className="text-start  px-6 whitespace-nowrap w-[180px]">
                  File Name
                </th>
                <th className="text-start  px-6 whitespace-nowrap w-[110px]">
                  File Type
                </th>
                <th className="text-start  px-6 whitespace-nowrap w-[150px]">
                  Uploaded by
                </th>
                <th className="text-start  px-6 whitespace-nowrap w-[120px]">
                  Upload Date & Time
                </th>
                <th className="text-start  px-6 whitespace-nowrap w-[130px]">
                  Actions
                </th>
              </tr>
            </thead>
            {AllDocument?.map((value: Documentobj, index: number) => (
              <tbody>
                <tr className="hover:opacity-[0.7] hover:bg-[#F6F6F6] border-b-[2px] border-[#F5F8FA] h-[66px] bg-white cursor-pointer">
                  <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                    {(currentPage - 1) * perPage + index + 1}
                  </td>
                  <td
                    className="px-[20px] font-[Gm] font-medium text-[14px]"
                    title={fileNameSplit(value?.file)}
                  >
                    {value?.isFinal ? (
                      <BookmarkIcon style={{ cursor: "pointer" }} />
                    ) : (
                      <TurnedInNotIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setfinlefile(true);
                          setpinunpinID(value?._id);
                          // PinUnPin({ ID: value?._id });
                        }}
                      />
                    )}{" "}
                    {fileNameSplit(value?.file).slice(0, 25)}...
                  </td>
                  <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                    {value?.file_type}
                  </td>
                  <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                    {value?.uploaded_by}
                  </td>
                  <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                    {value?.createdAt
                      ? moment(value?.createdAt).format("h:mm A, D MMMM YYYY")
                      : "-"}
                  </td>
                  <td className="px-[20px] font-[Gm] font-medium text-[14px]">
                    <div className="flex gap-2 items-center">
                      <button
                        className="ProjectStages__Activate"
                        style={{ color: "#344054" }}
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_IMAGE_URL}/${value?.file}`
                          );
                        }}
                      >
                        View
                      </button>
                      <button
                        style={{ color: "#3E68FE" }}
                        className="ProjectStages__Activate"
                        onClick={() => {
                          saveAs(
                            `${process.env.REACT_APP_IMAGE_URL}/${value?.file}`,
                            `${fileNameSplit(value?.file)}`
                          );
                        }}
                      >
                        Download
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        ) : (
          <div className="flex justify-center items-center mt-28">
            <img className="h-[250px] w-[220px]" src={nodata} alt="no-data" />
            {/* <h3>No Data Found</h3> */}
          </div>
        )}
        <div>
          {/* {clientList?.length === 0 && (
            <div
              className="flex justify-center items-center bg-white mt-[2px] flex-col"
              style={{ height: "72vh" }}
            >
              <img className="h-[250px] w-[220px]" src={nodata} alt="no-data" />
            </div>
          )} */}
        </div>
      </div>
      {AllDocument.length > 0 && (
        <div className="dealsPagination mt-10">
          <RsuitePagination
            handleChangePage={handleChangePage}
            limit={perPage}
            total={TotalCount}
            currentPage={currentPage}
          />
        </div>
      )}
    </div>
  );
};

export default Documents;
